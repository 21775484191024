import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { UserLogInContext } from '../../App';
import './newitem.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Stack, Typography, FormControl, Autocomplete, createFilterOptions, Fab, TableContainer, Snackbar, Alert, Select, MenuItem } from '@mui/material'; //CircularProgress, Slide
import CenterFocusWeakRoundedIcon from '@mui/icons-material/CenterFocusWeakRounded';//import Drawer from '@mui/material/Drawer';//import { pink, purple, green, blue, yellow, red } from '@mui/material/colors';
//import { JustAlert } from '../../components/DialogueAlert.jsx'
import { Table, TableCell, TableBody, TableHead, TableRow, Loader, TabItem, Tabs, SelectField, Icon, ThemeProvider, createTheme } from '@aws-amplify/ui-react';
import { GoogleMap , MarkerF, DirectionsRenderer, InfoWindowF,  } from '@react-google-maps/api'; // InfoWindow useJsApiLoader, withGoogleMap////import { SelectField, Flex } from '@aws-amplify/ui-react';
import { dateConverter, GetLocation, postActivity, sendNote, calculateTime, sendEmails,  addTime, calculateZone, timeZone, Log, fl, fs } from '../../util/Functions.jsx';  // import img from '../../resource/images/img_submit.gif';
//import { sendEmails,  addTime, calculateZone, timeZone} from '../../util/Functions.jsx';  //sendEmails(emailAttributes(toEmail, fromEmail, title, 'John Test', 'Aa Burwood', '60001', '10/02/2024', '144 Burwood Hwy, Burwood VIC 3125', '02/02/2024' ))}
import { Canvas } from './Canvas.jsx';
import styled from '@emotion/styled';



const NewJob = ({action}) => {
    const {  subrole, role, userId, isLoaded, isSuperUser} = useContext(UserLogInContext);    
    
    const [allClientsNames, setAllClientsNames] = useState('');
    const [allClientsIds, setAllClientsIds] = useState();    //const [boxColor, setBoxColor] =useState('transparent');    //const [statuses, setStatuses] = useState([ { statusName:'', statusId:-1 }] );
    const [statusName, setStatusName] = useState([]);
    const [statusId, setStatusId] = useState([] );
    const [requestTypes, setRequestTypes] = useState([ { requestTypeName:'', requestTypeId:'' } ] );
    const [requestType, setRequestType] = useState([]);
    const [requestId, setRequestId] = useState([] );
    const [siteSelected, setSiteSelected] = useState([{}]);//siteName :'', siteID :'' i === newInputValue &&   //const [oneSiteIDSelected, setOneSiteIDSelected] = useState('');//const [oneSiteAddressSelected, setOneSiteAddressSelected] = useState(''); //const [oneSiteSuburbSelected, setOneSiteSuburbSelected] = useState('');    //const [oneSitePostCodeSelected, setOneSitePostCodeSelected] = useState('');
    //const [oneSiteNameSelected, setOneSiteNameSelected] = useState('');
    const [oneSiteStateSelected, setOneSiteStateSelected] = useState('');
    //const [oneSitePhoneSelected, setOneSitePhoneSelected] = useState('');
    const [inputs, setInputs] = useState([{}]);    //const [loaded, setLoaded] = useState(true);
    const [showSite, setShowSite] = useState(false);
    const [orderedPatrs, setOrderedParts] = useState([]);
    const [newOrderedPart, setNewOrderedPart] = useState();
    const [partsNames, setPartsNames] = useState([]);
    const [allContractors, setAllContractors] = useState([]);
    const [allContractorsData, setAllContractorsData] = useState([{}]);
    const [selectedContractor, setSelectedContractor] = useState([]);    // const [loggedBy, setLoggedBy] =useState('');
    const [showC, setShowC] = useState(false);
    const [destination, setDestination] = useState('') //action ==='update' && `${inputs.siteAddress1??''} ${inputs.siteSuburb??''} ${inputs.siteState??''} ${inputs.sitePostCode??''}`);
    const [origin, setOrigin] =useState('');
    const [siteLabel, setSiteLabel]=useState();
    //const [jobPr, setJobPr] =useState(4);
    const [tracker, setTracker]=useState();    //const [trackedEmployess, setTrackedEmployess] =useState([]); // const [selectedStaff, setSelectedStaff] =useState(''); // const [employee, setEmployee] =useState(false); // const [isOpenMod, setIsOpenMod] = useState(true);
    const [tabValue, setTabValue]=useState(0);
    //const [tabPartValue, setTabPartValue]=useState(0);
    const [notes, setNotes] = useState([]);
    const [postNote, setPostNote]=useState();
    const [newNote, setNewNote] = useState('');
    const [centerSite, setCenterSite] = useState({lat: -38.05552, lng: 145.22209 });
    const [centerContract, setCenterContract] = useState({lat: -38.05552, lng: 145.22209 });
    const [defaultCenter, setDefaultCenter] = useState({lat: -37.988733853247, lng: 145.1527787316 });     ///127.98273118 -20.1426268
    const [map, setMap] = useState(/** @type google.maps.Map*/(null));
    const [directionResponse, setDirectionResponse] =useState(null);
    const [distance, setDistance] =useState('');
    const [duration, setDuration] =useState('');
    const [isLatLngPos, setIsLatLngPos] =useState(false);
    const [assignContractor, setAssignContractor]=useState([]);
    const [count, setCount]=useState(0);    //const [buttonColor, setButtonColor] = useState(0);
    //const [siteSupplId, setSiteSupplId] = useState(0);
    const [aa, setAa]=useState(true);
    const [stateOpen, setStateOpen] = useState(false)
    const [updateText, setUpdateText] = useState('')
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [clock, setClock]=useState(600);    //const [libraries, setLibraries] = useState(['places']);
    const [viewAll, setViewAll] = useState(false);    //const [actualActivity,setActualActivity]= useState('')
    const [fromEmail, setFromEmail] = useState(process.env.REACT_APP_API_SUPPORT_EMAIL);
    const [subRoles, setSubRoles]=useState(subrole.split(','));    // const [toEmail, setToEmail] =useState(['aiampolski@figure8services.com.au', 'gmaksymchuk@figure8services.com.au']);    // const [emailTitle, setEmailTitle] =useState('TEST EMAIL');
    const [isOpen, setIsOpen] = useState(false);
    
    const newContractor = useRef(0);    //console.log('Entered times ', newContractor.current++)

    /* global google */ 
    const google = window.google      ////IMPORTANT FOR GOOGLE MAPS TO WORK
    
    /** @type React.MutableRefObect<HTMLInputElement> */
    //const originRef=useRef()
    /** @type React.MutableRefObect<HTMLInputElement> */  
    //const destinationRef=useRef(action ==='update' && `${inputs.siteAddress1??''} ${inputs.siteSuburb??''} ${inputs.siteState??''} ${inputs.sitePostCode??''}`)

    const statusUpdateId=useRef();    //const statusUpdateName=useRef();
    const requestTypeId=useRef();
    const actualActivity = useRef('');
    const scheuleActivity = useRef('');    // const sites = useRef([]);
    const forms = useRef();
    const technician = useRef('');
    const inputRef = useRef();
    const centerEmployee = useRef('');
    const techAddress = useRef('');    //const setNavigationOn =useRef(true)
    
    /** @type React.MutableRefObect<HTMLInputElement> */
    
    let {id} = useParams();
    const navigate = useNavigate();

    const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
    const localHost = process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_LOCAL_DOMAIN + process.env.REACT_APP_API_LOCAL_PORT;
    const DOMAIN = `${process.env.NODE_ENV==='production' ? productionHost : localHost}`; 
    const DEBUG= process.env.REACT_APP_DEBUG  // && console.log(DOMAIN)
    const loading =true;    //const log =(msg, line)=>{ !DEBUG && console.log(msg, 'at line : ' + line) }

    const image = "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";
    const image1 = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuWE1pRmfMlrFeTMyJCWWa4cDjNNTDBOGkus9ligxlnA&s";
    const options = ['all employees'];  

    const isMaps = ()=>{
        if(google?.maps !== undefined){
            console.log(" MAPS DEFINED")
            const geocoder = new google.maps.Geocoder()
            const address = "19/31 Fiveways Boulevard+Keysborough VIC 3173";

            geocoder.geocode( { 'address': address}, function(results, status) {

                if (status === google.maps.GeocoderStatus.OK) {
                        const latitude = results[0].geometry.location.lat();
                    const longitude = results[0].geometry.location.lng();                    
                    console.log(latitude, longitude);
                } 
            }); 
        }
    };   
        
    const Container = styled.div`
        background: ${props => props.primary ? "transparent" : "white"};
        color: ${props => props.primary ? "white" : "transparent"};
        margin-bottom:1ch;
        width: ${props=> props.w };
        height:${props=>props.h};
        padding: 0.4em 1em;
        //border: ${props=> props.g ? "2px solid green" : "2px solid red"};
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        box-shadow:1px 1px 2px 1px lightgray;

    `;              //const SmallContainer = styled(Container)`    `;

    const JobContainer = styled.div`
        background: ${props => props.primary ? "transparent" : "white"};
        color: ${props => props.primary ? "white" : "transparent"};
        // margin-bottom:1ch;
        width: ${props=> props.w };
        height:${props=>props.h};
        padding: 0.4em 1em;
        //border: ${props=> props.g ? "2px solid green" : "2px solid red"};
        //border-radius: 5px;
        display: flex;
        flex-direction: column;
        //box-shadow:0 0 3px  2px gray; `;

        const theme = createTheme({
            name: 'tabs-theme',
            tokens: {
              components: {
                tabs: {
                  borderColor: { value: '{colors.neutral.20}' },
                  item: {
                    color: { value: '{colors.blue.80}' },
                    fontSize: { value: '{fontSizes.xxl}' },
                    fontWeight: { value: '{fontWeights.bold}' },
          
                    _hover: {
                      color: { value: '{colors.blue.60}' },
                    },

                    _focus: {
                      color: { value: '{colors.blue.80}' },
                    },
                    _active: {
                      color: { value: '{colors.blue.80}' },
                      borderColor: { value: '{colors.blue.80}' },
                      backgroundColor: { value: '{colors.blue.10}' },
                    },
                    _disabled: {
                      color: { value: 'gray' },
                      backgroundColor: { value: 'transparent' },
                    },
                  },
                },
              },
            },
          });

    // const Fab = ({ className, children }) => (
    //     <div className={className}>
    //       {children}
    //     </div>
    //   );
    //   //mediumseagreen" rebeccapurple
    //   const SFab = styled.Fab`
    //     --height:3.5em;
    //     --width:9em;
    //     color: mediumseagreen;
    //     background: papayawhip;
    //     font-weight: bold;
    //     font-family:cursive;
    //     box-shadow:0 2px 6px gray;
    //     border: 3px solid ${props=>props.theme.main};
    //     border-radius: 10px;
    //     cursor: pointer;
    //     display: inline-block;
    //      margin-bottom:20px;
    //     // margin-left: 20px;
    //     width: var(--width);
    //     height: var(--height);
    //     align-items: center;
    //     justify-content: center;
    //     display: flex;
    //     padding: 2em 1em;
    //     &:hover{
    //       filter: brightness(0.9);
    //     }
    //     &:active {
    //       filter: brightness(1);
    //       box-shadow: 0 2px 10px gray;
    //     }
    //   `;
    
    useEffect(()=>{      
        //isMaps();
        setInputs(values=>({...values, ['po_number']: ''}))///inputs.po_number , inputs.faultDescription
        setInputs(values=>({...values, ['faultDescriptionForEmail']: ''}))
        setNewOrderedPart(values=>({...values, ['partsQty']: 1}))
        getAllStatuses();
        action ==='update' && getJobDataForUpdate(`${DOMAIN}${process.env.REACT_APP_API_GET_JOB_DATA}${id}`) ;  
        getInitial();   
        getTracker();

    }, []);

    // useEffect(()=>{ {console.log(window.innerWidth)} },[window.innerWidth])         // useEffect(()=>{  getTracker();    DEBUG && console.log("trecker is changed  ??????????????????????????????????") },[selectedStaff]);

    useEffect(()=>{showSite && toAssign() }, [showSite]);

    useEffect(()=>{      
        const timer = setTimeout(() => {
            // viewAll ? setClock(500): setClock(60000)
            setCount(count+1);                
            clearRouts()
            getTracker();

            if (count >= 2) {
                setClock(65000)
                calculateRoute()
                getActivity(inputs.activityGuid, 'all')
            }
            //DEBUG && console.log('Timer is set with the count ', count)
        }, clock);
          
        return ()=>clearTimeout(timer) 
    },[count, viewAll]);

    // useEffect(() => {
    //     const  handleMove = (e)=>{setPosition({ x: e.clientX, y: e.clientY }) }    

    //     window.addEventListener("pointermove", handleMove);
    //     return ()=>{ window.removeEventListener("pointermove", handleMove)};    
    // }, []);                         // const handleGetDataForUpdate= async()=> { //     await getJobDataForUpdate(`${DOMAIN}${process.env.REACT_APP_API_GET_JOB_DATA}${id}`)    //     console.log('ppppppppppppppppppppppppppppppppp') // };

    const handleClose=(cancel)=>{
        //Log( fl( 239, `NewJob.jsx -  handleClose`), `I am out from job update`)
        //console.log( `I am out from job update`)
        
        //Log(fl( 239, `NewJob.jsx -  handleClose`), 'CLEAR', 'TEST', 11, 'HELLO')        
        console.log(`I am out from ${action==='update'?"update":" add new"} job`, inputs);
        setTimeout(() => {
            action==='update' ? navigate(-1):navigate('/')
        }, cancel ? 0 : action==='update'? 1500: 2500);
    };

    const handleNewSite = ()=>{ navigate('/sites/new')};

    const handleAdd= async()=>{
        console.log(inputs);       //getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${inputs.priority===5?4:inputs.priority}/${inputs.siteZone}`)        
        await addNewJob(`${DOMAIN}${process.env.REACT_APP_API_ADD_NEW_JOB}`, inputs)
        //toggleDrawer(true)
        setStateOpen(true)
        handleClose(false);
        /// for testin  /// const newActivityGUID = postActivity(100, inputs.siteLat, inputs.siteLng, `${inputs.siteAddress1 ?? ''}, ${inputs.siteSuburb ?? ''}, ${inputs.siteState ?? ''}, ${inputs.sitePostCode ?? ''}`, 'd68f0155-2406-4dd4-8dd8-e77a281b2e5b', '','', '')
    };

    async function addNewJob(Url, BodyInput) { //console.log(BodyInput) /// text/plain; */*; charset=utf-8
        let body;
        const responseT = await fetch(`${DOMAIN}/api/sentechdata/${BodyInput.siteState}`);
        const respData = await responseT.json();  
        const senTechState_Email = respData[0]?.supplierEmail        // DEBUG && console.log(senTechState_Email)
        DEBUG && console.log( respData[0])
        const  SeniorTechnician = respData[0].supplierId
        body={...BodyInput, ['siteSupplierAccepted']:respData[0].supplierId}

        const requestOptions = {   
            method: 'POST', 
            headers: {'Content-Type':'application/json; charset=utf-8' }, 
            body: JSON.stringify({ body }),    
        } 
        // DEBUG && console.log(inputs.faultDescriptionForEmail);
        // DEBUG && console.log(selectedContractor)
        // DEBUG && console.log(addTime(new Date(), 15, 'h'))

        const slaTemp = new Date(body.slaTime).toLocaleString('en-AU', {
            timeZone: timeZone(body.siteState),
            timeZoneName:'long',
            hour12: false,
            day:"numeric",
            month:"numeric",
            year:"numeric",
            hour: "numeric",
            weekday: "short",
            minute: "numeric",
        })

        DEBUG && console.log(body)
        /////ADDING THE NEW JOB
        const response = await fetch(Url, requestOptions);
        const responseData =  await response.json();  
        //DEBUG && console.log(responseData.insertId)  //created job ID !!! IMPORTANT for emails and sheqsy!!!
        const newJobID = responseData.insertId

        setUpdateText(`Job ${newJobID} has been added`)
        DEBUG && console.log(selectedContractor )

        // Sending email to the selected service provider with all informations about the current job 
        sendEmails(
            //DEBUG ? "aiampolski@figure8services.com.au" : "" , 
            selectedContractor.contractorEmail, 
            fromEmail, 
            `${newJobID} - ${inputs.siteName} requires your attendance by ${slaTemp} `,  
            'newJob',
            selectedContractor.contractorContact, 
            BodyInput.siteName, 
            newJobID, 
            slaTemp, ///new Date(body.slaTime), 
            body.siteAddress1, 
            body.faultDescriptionForEmail, 
            addTime(new Date(), 15, 'm', body.siteState),                 
            body.siteManager,
            body.sitePhone,
            senTechState_Email,
            selectedContractor.contractorPhone,
            SeniorTechnician
            
        )

        /// Creating the new shaqsy activity ID  for the newly created job on the condition that service provider has a sheqsy ID
        if (selectedContractor.contractorSheqsyId !== null ) {
            // const Kumar= '519b158a-2a6c-4393-9cad-5cec7cbd122c'  // const Employee = inputs.sheqsyUId === null? inputs.siteState.toUpperCase()=='WA' || inputs.siteState.toUpperCase()=='NT' && Kumar :inputs.sheqsyUId
            let contactorSheqsyIID ='';

            if(selectedContractor.contractorSheqsyId === ''){
                if(inputs.siteState.toUpperCase() == 'VIC'  || inputs.siteState.toUpperCase() ==='TAS'){
                    contactorSheqsyIID = 'd68f0155-2406-4dd4-8dd8-e77a281b2e5b'
                }else if(inputs.siteState.toUpperCase() === 'NSW' || inputs.siteState.toUpperCase()=== 'ACT'){ 
                    contactorSheqsyIID = 'd68f0155-2406-4dd4-8dd8-e77a281b2e5b'
                }else{
                    contactorSheqsyIID = 'd68f0155-2406-4dd4-8dd8-e77a281b2e5b'
                }
            }else{
                contactorSheqsyIID = selectedContractor.contractorSheqsyId
            }

            const newActivityGUID = await postActivity(newJobID, inputs.siteLat, inputs.siteLng, `${inputs.siteAddress1 ?? ''}, ${inputs.siteSuburb ?? ''}, ${inputs.siteState ?? ''}, ${inputs.sitePostCode ?? ''}`, contactorSheqsyIID, '','', '')
            console.log(newActivityGUID)    //REACT_APP_API_UPDATE_JOB_DATA_GUID
            if (newActivityGUID !==undefined || newActivityGUID !== null || newActivityGUID !=='') {
                const returnStatus= await updateJobData(`${DOMAIN}${process.env.REACT_APP_API_UPDATE_JOB_DATA_GUID}${newJobID}`, newActivityGUID)
                console.log(returnStatus)
            }
        }
    };

/////////////////////////////////////////////////////////....................

    const makePayment = async(pmnt)=>{

        const BodyInput={
            'TESTING_KEY':"TESTING_DATA",
            "AGAIN_KEY":"AAGIN_DATA"
        }
        const options={
            method: 'POST', 
            body: JSON.stringify({BodyInput}),    
            headers: {'Content-Type':'application/json; charset=utf-8'}
        }        // const response = await fetch(`${DOMAIN}/api/payment`, options);           //, options        // const responseData = await response.json();         // console.log(responseData)




    }

    const handleUpdate = async()=>{  
    
        setUpdateText(`Job ${inputs.jobNo} has been updated`)
        let body = {...inputs}
        let senTechState_Email ='';    

        if(inputs.newContractor){            
            const responseSenTech = await fetch(`${DOMAIN}/api/sentechdata/${inputs.siteState}`);
            const SenTechData = await responseSenTech.json();  
            senTechState_Email = SenTechData[0].supplierEmail
            console.log(senTechState_Email)
            body={...inputs, ['siteSupplierAccepted']: senTechState_Email}
        }
        
        const returnStatus = await updateJobData(`${DOMAIN}${process.env.REACT_APP_API_UPDATE_JOB_DATA}${id}`, body);  
        DEBUG &&  console.log(body);   //        console.log(inputs);      
        // console.dir('test')     
        
        if (returnStatus === 'OK' ){
            postNotes(); 
            //DEBUG && console.log('sheqsyUId - '+ inputs.sheqsyUId + "  --activityGuid - " + inputs.activityGuid + ' sheqsyUID - ' + inputs.sheqsyUID )
            const isActivitySID = !!inputs.activityGuid && inputs.activityGuid !== 'undefined' ;
            const isTechnSID = !!inputs.sheqsyUId && inputs.sheqsyUId !== 'undefined';         // console.log(inputs.newContractor)            // console.log(isActivitySID)             // console.log(isTechnSID) //&&             

            if(inputs.newContractor){

                //send email to NEEWLY SELECTED contractor
                const slaTemp =  new Date(inputs.slaDate * 1000).toLocaleString('en-AU', {
                    timeZone: timeZone(body.siteState),
                    timeZoneName:'long',
                    hour12: false,
                    day:"numeric",
                    month:"numeric",
                    year:"numeric",
                    hour: "numeric",
                    weekday: "short",
                    minute: "numeric",
                });

                DEBUG && console.log('hellllllllllllo ', slaTemp)
                DEBUG && console.log(inputs.supplierEmail, fromEmail )
            
                sendEmails(
                    inputs.supplierEmail, fromEmail, 
                    `${inputs.jobNo} - ${inputs.siteName} requires your attendance by ${slaTemp} `,  
                    'newJob',
                    selectedContractor.contractorContact, 
                    inputs.siteName, 
                    inputs.jobNo, 
                    slaTemp,   ///new Date(inputs.slaDate *1000), 
                    inputs.siteAddress1, 
                    inputs.faultDescription, 
                    addTime(new Date() , 15, 'm', body.siteState),            
                    inputs.siteManager,
                    inputs.sitePhone,
                    senTechState_Email,
                    selectedContractor.contractorPhone
                )

                if(isTechnSID && isActivitySID){
                    const isNewTechnSID = inputs.sheqsyUID !== undefined && inputs.sheqsyUID !== 'undefined' && inputs.sheqsyUID !== null && inputs.sheqsyUID !== '';
                    isNewTechnSID && updateActivity('', '', '', `${inputs.siteAddress1} ${inputs.siteSuburb ?? ""} ${inputs.siteState ?? ''}, ${inputs.sitePostCode ?? ''}` , inputs.sheqsyUID, 1, 'employees', inputs.activityGuid)        
                }else{
                    console.log('Not sheqsy details')
                }
                
                //toggleDrawer(true)
            }
            setStateOpen(true)
        } handleClose(false);
    };

    const updateJobData = async (Url, BodyInput)=>{    //console.log(BodyInput) /// text/plain; */*; charset=utf-8    
        const options={
            method: 'PUT', 
            body: JSON.stringify({BodyInput}),    
            headers: {'Content-Type':'application/json; charset=utf-8'}
        }
        const response = await fetch(Url, options);                                
        const responseData = await response.json();   
        return responseData.Status
    };

    const getSLA = async (Url, ...arr) => {
        const response = await fetch(Url);
        const responseData =  await response.json();  
        let a = Date.parse(`${ calculateTime(responseData[0]?.slaTime, inputs.customerId == 1 || inputs.customerId ==8 ?true : false, inputs.dateCreated)}`)   // (slaHours, isWeekends)    
        setInputs(values=>({...values, ['slaDate']:a/1000}))  ///responseData[0].slaTime
        setInputs(values=>({...values, ['slaTime']:a}))
    };

    const getSitesForSelectedCustomer = async(customerId)=>{

        if(customerId === 0 ){return}
        const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_SITES_PER_CUSOMER}${customerId}`)
        const sitesData = await response.json();  
        //console.log(sitesData);

        const tempSites =[];      

        if (role.toUpperCase() ==='Senior Technician'.toUpperCase()){
            sitesData.forEach((site, i)=>{  subRoles.forEach((sRole, i)=>{sRole.toUpperCase() == site.siteState.toUpperCase() && tempSites.push(site) })})

            setSiteSelected(
                {    
                    siteName: tempSites.map((a, i) => a?.siteName  ),
                    siteID: tempSites.map(a=>a.siteId),
                    siteAddress1: tempSites.map(a=>a.siteAddress1),
                    siteSuburb: tempSites.map(a=>a.siteSuburb),
                    siteState: tempSites.map(a=>a.siteState),
                    sitePostCode: tempSites.map(a=>a.sitePostCode),
                    sitePhone: tempSites.map(a=>a.sitePhone),
                    siteZone: tempSites.map(a=>a.siteZone),
                    siteManager:tempSites.map(a=>a.siteManager),
                    position: tempSites.map(a=>a.position),
                    key:tempSites.map((a,i)=> i),
                    //siteNameList: responseData.map(a => a?.siteName !== null && a.siteName +' - ' + a.siteAddress1),
                }  
            )
        }else{
            setSiteSelected(
                {    
                    siteName: sitesData.map((a, i) => a?.siteName ),  //+ ' - '  + a?.siteState.toUpperCase()
                    siteID: sitesData.map(a=>a.siteId),
                    siteAddress1: sitesData.map(a=>a.siteAddress1),
                    siteSuburb: sitesData.map(a=>a.siteSuburb),
                    siteState: sitesData.map(a=>a.siteState),
                    sitePostCode: sitesData.map(a=>a.sitePostCode),
                    sitePhone: sitesData.map(a=>a.sitePhone),
                    siteZone: sitesData.map(a=>a.siteZone),
                    siteManager:sitesData.map(a=>a.siteManager),
                    position: sitesData.map(a=>a.position),
                    key:sitesData.map((a,i)=>i),
                    //siteNameList: responseData.map(a => a?.siteName !== null && a.siteName +' - ' + a.siteAddress1),
                }  
            )            
        }
        //setInputs(values=>({...values, ['customerId']:customerId }))        // console.log('ID : ---------- ', customerId)        // console.log('SITES :     -------------------------------- ', siteSelected)        // if(i == 0 ){            // }else{      //  i===0 ? 'ADD NEW SITE... ' : 
                  
    };

    const getJobDataForUpdate = async(Url) =>{  
        let dataAll, dataCust, dataSite, dataContractor;  

        const response = (await fetch(Url));
        //Log(fl(837,'NewJob.jsx - getJobDataForUpdate'), `response status for job request: `, response.status )
        if (response.status==200) {                   // if HTTP-status is 200-299       // get the response body (the method explained below)
            dataAll = await response.json();             // dataAll = responseData;            // DEBUG && console.log(responseData)            // DEBUG && console.log(dataAll)
        } else {
            alert("HTTP-Error: " + response.status);
        }
    
        let keys =  Object.keys(dataAll);
        keys.forEach(k=>{

            setInputs(values=>({...values, [k]: dataAll[k]})) 
        });

        console.log(dataAll)
        console.log(inputs)
        

        const response1 = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_NAME}${dataAll.customerId}`)
        const responseCustData = await response1.json();  
        dataCust = responseCustData[0];

        const response2 = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_SITE}${dataAll.siteId}`);
        const responseSitetData = await response2.json();  
        dataSite = responseSitetData[0];

        const response3 = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_CONTRACTOR_DATA}${dataAll.siteSupplierId}`);
        const contractorData = await response3.json();  
        dataContractor = contractorData[0];

        //console.log(dataContractor)       

        keys= Object.keys(dataCust);
        keys.forEach(key=>{setInputs(values=>({...values, [key]: dataCust[key]}))});

        keys= Object.keys(dataSite);
        keys.forEach(key=>{setInputs(values=>({...values, [key]: dataSite[key]}))});

        if (dataSite.position !== null){
            setCenterSite({lat: dataSite.position.y, lng: dataSite.position.x} )
            setSiteLabel(dataSite.siteName )
            setDestination(dataSite.siteAddress1)// ${dataSite.siteSuburb}, ${dataSite.siteState}, ${dataSite.sitePostCode}
            setOneSiteStateSelected(dataSite.siteState.toUpperCase())

            // const zone= calculateZone(dataSite.position?.x, dataSite.position?.y, dataSite.siteState.toUpperCase())
            // setInputs(values=>({...values, ['siteZone']:zone}))

        }else{
            setCenterSite(defaultCenter)
            setSiteLabel( `Geolocation Data are not available or not set for this site.
                           Position is set to a Default possition for Figure8 head office. 
                           If you know the site's address type it in the box above for navigational functionality.`
            )
            setDestination(`${dataSite.siteAddress1}, ${dataSite.siteSuburb}, ${dataSite.siteState}, ${dataSite.sitePostCode}`)
            setOneSiteStateSelected(dataSite.siteState.toUpperCase())
        }

        keys= Object.keys(dataContractor);
        keys.forEach(k=>{setInputs(values=>({...values, [k]: dataContractor[k]}))});    
            
        setOrigin(`${dataContractor.supplierPostalAddress}, ${dataContractor.supplierSuburb}, ${dataContractor.supplierState}, ${dataContractor.supplierPostCode}`)
        setCenterContract( dataContractor.position !== null ? {lat: dataContractor.position.y, lng: dataContractor.position.x} : defaultCenter)       //setFaultDescription(data.faultDescription)        //setInputs(values=>({...values, ['statusId']: data['statusId']}))

        setInputs(values=>({...values, ['siteState']: dataSite.siteState.toUpperCase()}))
        setInputs(values=>({...values, ['newContractor']: false}))
        const zone= calculateZone(dataSite.position?.x, dataSite.position?.y, dataSite.siteState.toUpperCase())
        setInputs(values=>({...values, ['siteZone']:zone}))
        //getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${inputs.priority}/${zone}`)   

        statusUpdateId.current = dataAll.statusId
        // statusUpdateName.current=dataAll.statusName

        requestTypeId.current = dataAll.requestTypeId

        getAllStatuses()
        setInputs(values=>({...values, ['priority']: dataAll.priority}));
        //setJobPr(dataAll.priority)
    };

    const setAllSiteData = (site, i)=>{

        //setOneSiteNameSelected(site.siteName[i]);          //setOneSiteSuburbSelected(site.siteSuburb[i]);
        setOneSiteStateSelected(site.siteState[i].toUpperCase());        //setOneSitePostCodeSelected(site.sitePostCode[i]);
        //setOneSitePhoneSelected(site.sitePhone[i])                   //setOneSiteAddressSelected(site.siteAddress1[i]);
        setDestination(`${site.siteAddress1[i]}`);                    //, ${site.siteSuburb[i]}, ${site.siteState[i].toUpperCase()}, ${site.sitePostCode[i]}
        setInputs(values=>({...values, ['siteId']:site.siteID[i] }))
        setInputs(values=>({...values, ['siteName']:site.siteName[i]}))
        setInputs(values=>({...values, ['loggedById']:userId }))
        setInputs(values=>({...values, ['siteZone']:site.siteZone[i] }))
        setInputs(values=>({...values, ['newContractor']: false}))
        setInputs(values=>({...values, ['siteState']: site.siteState[i].toUpperCase()}))
        setInputs(values=>({...values, ['siteLat']:site.position[i]?.y }))
        setInputs(values=>({...values, ['siteLng']:site.position[i]?.x }))
        setInputs(values=>({...values, ['siteAddress1']:`${site.siteAddress1[i]}` })) //${site.siteSuburb[i]}, ${site.siteState[i]}  ${site.sitePostCode[i]}`}
        //setInputs(values=>({...values, ['siteAddress1']:`${site.siteAddress1[i]}` }))
        setInputs(values=>({...values, ['siteSuburb']:`${site.siteSuburb[i]}`}))
        setInputs(values=>({...values, ['siteState']:`${site.siteState[i]}`}))
        setInputs(values=>({...values, ['sitePostCode']:`${site.sitePostCode[i]}`}))
        setInputs(values=>({...values, ['siteManager']:site.siteManager[i]}))
        setInputs(values=>({...values, ['sitePhone']:site.sitePhone[i]}))

        //setInputs(values=>({...values, ['siteOutLetType']:site.siteOutLetType[i]}))

        getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${inputs.priority===5?4:inputs.priority}/${site.siteZone[i]}`)

        if (site.position[i] !==null){
            setCenterSite({lat: site.position[i].y, lng: site.position[i].x })  
            setSiteLabel( site.siteName[i])
            //setLatLng('test')
            setIsLatLngPos(true)

        } else{
            setIsLatLngPos(false);
            setCenterSite(defaultCenter);
            setSiteLabel("Geolocation Data for this Site are not available.");
        }
        
        ///site.position[i] !==null? setCenterSite({lat: site.position[i].y, lng: site.position[i].x }): setCenterSite(defaultCenter)
    };
    
    const updateActivity= async(name, lat, lng, address, user, status, update, actvityId)=>{

         const a = await postActivity(name, lat, lng, address, user, status, update, actvityId)
         console.log(a)
    };

    const clearAllSitesData= ()=>{
        const empty=''        //setOneSiteIDSelected(empty);
        //setOneSiteNameSelected(empty);         //setOneSiteSuburbSelected(empty); 
        setOneSiteStateSelected(empty);         //setOneSitePostCodeSelected(empty); 
        //setOneSitePhoneSelected(empty);        //setOneSiteAddressSelected(empty); 
        setDestination('');        // setInputs(values=>({...values, ['purchaseOrder']:'' })) //po_number        // setInputs(values=>({...values, ['po_number']:'' })) //po_number
        setInputs(values=>({...values, ['siteId']:empty}))
        setInputs(values=>({...values, ['siteZone']:empty }))
        setInputs(values=>({...values, ['newContractor']: false}))
    };

    const getAllStatuses= async ()=>{
        const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_ALL_STATI}`)
        const responseStatusData = await response.json();          //const stName =  await responseStatusData.filter(i => i.statusId===statusUpdateId.current && i)
        //console.log(responseStatusData)             //setStatuses({statusName: responseStatusData.map(i=>i.status), statusId: responseStatusData.map(a=>a.statusId),})

        setStatusName(responseStatusData.map(i=>i.status));
        setStatusId(responseStatusData.map(i=>i.statusId));
            
        const resp = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_ALL_REQUST_TYPES}`)
        const responseRequestTypeData = await resp.json(); 

        setRequestTypes({
            requestTypeName:responseRequestTypeData.map(i=>i.requestType), 
            requestTypeId:responseRequestTypeData.map(a=>a.requestTypeId),
        } )        //setInputs(values=>({...values, ['statusId']: inputs.statusId}))

        setRequestType(responseRequestTypeData.map(i=>i.requestType))
        setRequestId(responseRequestTypeData.map(a=>a.requestTypeId))

        setInputs(v=>({...v, ['statusId']: 1}))
        setInputs(v=>({...v, ['statusName']: 'New'}))

        setInputs(v=>({...v, ['requestTypeId']:1}))
        setInputs(v=>({...v, ['requestType']: 'Site Visit'}))

        if(action==='update'){        
                //statuses
            const stName =  responseStatusData.filter(i => {if(i.statusId === statusUpdateId.current){return (i.status)}})  //console.log( stName[0] ) //statusUpdateName.current=dataAll.statusName        //console.log(stName[0].statusId)            ///const  statusJobName = stName.length > 0 ? stName[0].status === undefined ? '' : stName[0].status : ''
            setInputs(v=>({...v, ['statusId']: statusUpdateId.current}))
            setInputs(v=>({...v, ['statusName']: stName.status}))

            //requests
            const rtName = await responseRequestTypeData.filter(i => {if(i.requestTypeId===requestTypeId.current){return(i)}})             //console.log( rtName.length > 0  )        const requestTypeJobName= rtName.length > 0 ? rtName[0].requestType : ''            
            setInputs(v=>({...v, ['requestTypeId']: requestTypeId.current}))
            setInputs(v=>({...v, ['requestType']: rtName.requestType}))     // const zone= calculateZone(lng, lat, inputs.siteState) // setInputs(values=>({...values, ['siteZone']:zone})) // getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${inputs.priority}/${zone}`)            //DEBUG && console.log(requestTypeId,  requestTypeJobName)
        }
    };

    const getInitial = async()=>{
        const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMERS_NAMES}`);
        const responseData = await response.json();  
        // console.log(responseData)
        setAllClientsNames(responseData.map(d=> d.customerName))      
        setAllClientsIds(responseData.map(d=>{ return {name:d.customerName, id:d.customerId}}) )
        setInputs(values=>({...values, ['priority']: 4}))
        setInputs(values=>({...values, ['loggedById']:userId }))
        
        //console.log(responseData.map(d=> d.customerName))
        //DEBUG && console.clear()
        // const zone= calculateZone(lng, lat, inputs.siteState)
        // setInputs(values=>({...values, ['siteZone']:zone}))
        // getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${inputs.priority}/${zone}`)
    };

    // const handleNewTabClick=(v)=>{
    //     // setContactName("")
    //     // setContactNumber('')   
    //     // setContactEmail('') 
    //     // setContactId('')   
    //     // VALID_contactNumber.current = false
    //     // VALID_contacEmail.current = false
    //     // setContactInputs({}) 
    //     console.log(v)
    // }

    const handleTabClick=(v)=>{
        setTabValue(v)
        ///DEBUG && console.log(v)
    };

    //const handleTaPartbClick=(v)=>{setTabPartValue(v)};
    
    const filterOptions = createFilterOptions(
        {
            matchFrom: 'start',
            ignoreAccents: true,            //stringify: (option) => option.title,
            trim: true,
            ignoreCase:true,            //limit:10,
        }
    );    //const customBoldHandler=()=>{console.log('test')}

    const toolbarOptions = [
        ['bold', 'italic', 'underline'],                  // toggled buttons
        ///['blockquote', 'code-block'],
        //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }],          // dropdown with defaults from theme  , , { 'background': []}
        // [{ 'font': [] }],
        [{ 'align': [] }],
        //['clean'],   
        ['link', 'image'],
                                              // remove formatting button
    ];

    const toolbarOptionsForNotes = [
        ['bold', 'italic', 'underline'],                  // toggled buttons
        ///['blockquote', 'code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }],          // dropdown with defaults from theme  ,   , { 'background': []}
        // [{ 'font': ['Arial', 'Helvetica', 'sans-serif'] }],
        //[{ 'align': [] }],
        [ "link",  'image'],                                      // 'clean', 'video' remove formatting button
                                    
    ];
    
    const modules = React.useMemo(() => ({
          toolbar: {
            container: toolbarOptionsForNotes,            // handlers: {    font: customBoldHandler, },
            history: {
              delay: 10,
              maxStack: 500,
              userOnly: true,
            },
          },
    }), []);


    const getNotes = async(e)=>{
        const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_NOTES}${id}`);
        const responseData = await response.json();  
        setNotes(responseData)
    };

    const getOrderedParts = async(e)=>{
        const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_ORDERED_PARTS}${id}`);
        const responseData = await response.json();  
        setOrderedParts(responseData)         // DEBUG && console.log(responseData)        // DEBUG && console.log(inputs)

        const responseParts = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_PARTS_FOR_CUSTOMER}${inputs.customerId}`);
        const partsData = await responseParts.json();  
        setPartsNames(partsData) ///.map(p=> p.jobPartsDescription))         //DEBUG && console.log(partsData.map(p=> p.jobPartsDescription))

    };

    const getInstallParts = async()=>{ console.log("HELLLLO", id)   }

    const IconArrowDropDown = () => {
        return <Icon pathData="M7 10L12 15L17 10H7Z" />;
    };

    const getViewAll =()=>{setViewAll(!viewAll)  };

    const postNotes = async()=>{
        const url= `${DOMAIN}${process.env.REACT_APP_API_ADD_NOTES}${id}`        // const jPriority = postNote?.jobPriority !==undefined && (postNote?.jobPriority == '1' ? 'Priority is set to Extrime'://postNote?.jobPriority == '2' ? 'Priority is set to Urgent'://postNote?.jobPriority == '3' ? 'Priority is set to Moderate':        //                         postNote?.jobPriority == '4' ? 'Priority is set to Standard':        //                         postNote?.jobPriority == '5' ? 'Priority is set to Low':          //'' )
        !!postNote?.jobStatus && sendNote(`Job Status has changed to ${postNote.jobStatus}`, userId,url)
        !!postNote?.jobRequestType && sendNote(`Request has changed to ${postNote.jobRequestType}`, userId, url)
        !!postNote?.jobPriority && sendNote(`Priority has changed to ${postNote.jobPriority}`, userId, url)
        !!postNote?.nContractor && sendNote(`Service Provider has changed to ${postNote.nContractor}`, userId, url)
        setPostNote('')                 //const responseData = await response.json();   //setNotes(responseData);  //${Math.trunc(Date.now()/1000)}
    };

    const postNewOrderedPart = async()=>{
        // console.log('POSSSTT Part: ', newOrderedPart.partsOrderedDevice)
        const requestOptions = {   
            method: 'POST', 
            headers: {'Content-Type':'application/json; charset=utf-8' }, 
            body: JSON.stringify(newOrderedPart),    
        } 

        if(newOrderedPart.partsOrderedDevice?.trim() !== '' &&  newOrderedPart.partsOrderedDevice !== undefined){
            setNewOrderedPart('')
            setNewOrderedPart(values=>({...values, ['partsOrderedDevice']: 'Select Part ...'}))
            setNewOrderedPart(values=>({...values, ['partsQty']: 1}))
        }

        await fetch(`${DOMAIN}${process.env.REACT_APP_API_ADD_ORDERED_PARTS}`, requestOptions);
        await getOrderedParts()
        
        console.log(requestOptions)


    }

    const postNewNotes = async()=>{

        const bodyNote={
            noteUserId: userId,
            noteDetails:newNote,
            //noteDetails: `<div class="ql-editor"><span class="ql-size-small" style="font-weight: 600; font-family: Helvetica, sans-serif;">${newNote}</span></div>`   //`<p class="ql-size-large">${newNote} </p>`,  background-color: rgb(0, 0, 0); color: rgb(255, 255, 255);  font-size: clamp(0.65rem, .6vw, 1rem); font-weight: bold;
        }        
        const requestOptions = {   
            method: 'POST', 
            headers: {'Content-Type':'application/json; charset=utf-8' }, 
            body: JSON.stringify( {bodyNote} ),    
        } 
        
        if(!!newNote){    
            const result = await fetch(`${DOMAIN}${process.env.REACT_APP_API_ADD_NOTES}${id}`, requestOptions);
            getNotes()
            setNewNote('')
        }
    };

    const getActivity = async(ActivityID, method)=>{        //const currentTime = new Date()        //console.log(ActivityID,  inputs.sheqsyUId)     // const userName='9a26d38fccec4d4bac364aaad02d171c'   // const password='8b385dd449044105a97fd255546e9371';//  const test1 = window.btoa(userName + ":" + password)
        actualActivity.current = null;
        if(ActivityID == undefined || ActivityID ===null || ActivityID ===''){
            ActivityID=''            //console.log('zhopa')
            return;
        }

        if(method==='all'){  
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json; text/plain; charset=utf-8");
            myHeaders.append("Authorization", `${process.env.REACT_APP_SHEQSY_AUTH}`);
            const requestOptions = {   
                method: 'GET' , 
                headers: myHeaders,
                redirect: 'follow'
            }         

            const responseWH = await fetch(process.env.REACT_APP_POST_GET_FROM_WEBHOOK, requestOptions)
            const responseDataWH = await responseWH.json()            
            //console.log(responseDataWH)            //actualActivity.current =  activity.statusText

            responseDataWH.forEach( activity => {    // Looping trough all WH for   //&& activity?.EmployeeUId === inputs.sheqsyUId                 //console.log(activity?.ActivityID)                // || activity.ActivityUId !== null

                if (activity.ActivityUId !== undefined ){
                    if (activity?.ActivityUId === ActivityID ){
                        console.log('aaaaaaaaaaa')
                        if(activity?.EmployeeUId === inputs.sheqsyUId) {technician.current = `${activity?.Employee.FirstName} ${activity?.Employee.LastName}`}
                        if(activity?.Type ==='TaskStatusUpdate'){
                            if (activity?.Message !=='TaskSummarySent'){ actualActivity.current = activity.Message}                       //if(activity?.Forms[0] !== undefined){
                            if (activity?.Message =='TaskSummarySent') {forms.current = activity?.Activity?.Forms[0].Fields[0].Values[0]}          //} //if(activity?.Forms !== undefined){forms.current = activity?.Forms[0].Fields[0].Values[0]}
                        }
                    }
                } 
            })



            ///////////////////// if wh is limited and not providing data
            ////// List of ACTUAL ACTIVITIES statusText values:  Pending, Active, Rejected, Enroute, Arrived, Acepted, Cancelled, Finished 
            //GET All Activities created through Autom8  add Job functionality. ExternalID has the job number and name has "Job#" preffix and job number as a suffix
            const response = await fetch((`${DOMAIN}/api/activities`)) //(process.env.REACT_APP_SHEQSY_ACTIVITY_URL, requestOptions);///`https://autom8systems.com.au/api/callbacks_res` );
            const responseData = await response.json();  

            if (responseData.length > 0){
                responseData.forEach((activity) => {    
                    if (activity.activityUId === ActivityID){  // if(activity.statusText !=='TaskSummarySent'){ // actualActivity.current =  activity.statusText// activity.Message  // }else{ //     actualActivity.current="hello"//activity.statusText  //console.log("oopp", activity)//Activity.Forms[0].Fields[0].Values[0])//     //forms.current = activity.Activity.Forms[0].Fields[0].Values[0]// }
                        if (actualActivity.current == null){                    
                            actualActivity.current = activity.statusText
                        }else{
                            if(actualActivity.current.length == 0){
                                actualActivity.current = activity.statusText
                            }                                                       
                        }
                    }  
                })
            }


            ///////////////////////////////////
            if(ActivityID == 'undefined' || ActivityID == 'null' || ActivityID == null  ){
                ActivityID=''
                return null;
            }    
            
            const resp = await fetch(`${process.env.REACT_APP_SHEQSY_ACTIVITY_URL}/${ActivityID}`,  requestOptions)   //(`${process.env.REACT_APP_SHEQSY_ACTIVITY_URL}/${ActivityID}`, requestOptions );
            const respData = await resp.json();
            scheuleActivity.current = ''             //if(activity?.EmployeeUId === inputs.sheqsyUId) {technician.current = `${activity.Employee.FirstName} ${activity.Employee.LastName}`}

            if(respData.employees?.length > 1){
                respData.employees.forEach(empl => {
                    if(viewAll){
                        scheuleActivity.current = `${scheuleActivity.current} ${empl.name} ${empl.surname} : ${empl.statusText} ` +'\n';
                    }else{
                        if (inputs.sheqsyUId == empl.employeeUId){
                            scheuleActivity.current = `${scheuleActivity.current} ${empl.name} ${empl.surname} : ${empl.statusText} ` +'\n';
                        }
                    }
                    if(empl.employeeUId == inputs.sheqsyUId){technician.current = `${empl.name} ${empl.surname}`}
                }) 

            }else{
                scheuleActivity.current = respData.employees[0]?.surname + " : " + respData.employees[0]?.statusText;
                if(respData.employees[0]?.employeeUId == inputs.sheqsyUId){technician.current =  `${respData.employees[0]?.name} ${respData.employees[0]?.surname}`};         //console.log(respData.employees[0].surname + " : " + respData.employees[0].statusText);                //if(respData.employees[0].forms.length > 0 ){forms.current =  respData.employees[0].forms[0].results[0].answers[0]}
            }

            // scheuleActivity.current = respData.employees[0].statusText  // if(respData.employees[0].forms.length > 0 ){ forms.current =  respData.employees[0].forms[0].results[0].answers[0]}
            //console.log(respData.employees[0].forms[0].results[0].answers[0])     //forms.current = respData.employees[0].forms[0]
            return;   
                    
        }else{      
          return null;
        }        
    };
        
    const getTracker = async()=>{
        // const SHEQSY_Headers = new Headers();
        // SHEQSY_Headers.append("Authorization", `${process.env.REACT_APP_SHEQSY_AUTH}`);
        // SHEQSY_Headers.append("Accept", `*/*`);
        // SHEQSY_Headers.append("Connection", `keep-alive`);

        // // Access-Control-Allow-Origin header
        // const requestOptions = {
        //     method: 'GET',
        //     headers: SHEQSY_Headers,
        //     redirect: 'follow'
        // };

        const response = await fetch(`${DOMAIN}${process.env.REACT_APP_SHEQSY_TRACKER_API}`) ///(process.env.REACT_APP_SHEQSY_TRACKER_URL, requestOptions)  // .then(response => response.json())        // .then(responseData => console.log('ffffffffffffffff  ', responseData)) // .catch(error => console.log('Error : ------------ ', error));

        if( response.ok){
            const responseData = await response.json();  
            setTracker(responseData[0]); 
            //console.log(responseData[0])
        }else{
            setTracker(500);    
        }    
        //setTrackedEmployess(responseData.map((t)=>t.employee.name + " " + t.employee.surname ));
        // DEBUG && console.log(responseData.map((t)=> t.employee.name+ " " + t.employee.surname));
        // DEBUG && console.log(responseData);
                    
        // .then(response => response.json())
        // .then(result => console.log(result))
        // .catch(error => console.log('error', error));

    };
    
    // const {isLoaded} = useJsApiLoader({ 
    //     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    //     libraries:['localContext','places','geometry'],
    //     region:'Australia'
    // })

    const calculateRoute = async ()=>{

        if (origin ===''|| destination ==='' )  return;
        let Begining ='';
        
        if (techAddress.current==""){
            Begining = origin
        }else{
            Begining ={
                lat : techAddress.current.latitude,
                lng: techAddress.current.longitude
            }
        }

        // const start = {
        //     lat : techAddress.current.latitude,
        //     lng: techAddress.current.longitude
        // }

        //let start = (techAddress.current == undefined || techAddress.current == null || techAddress.current =='') ? origin:techAddress.current;
        //const end = '41.850033, -87.6500523';

        const request = {
          origin: Begining, // new google.maps.LatLng(-37.510854, 145.357587),///'Factory 19/31 Keysborough Close, Keysborough, VIC, 3173', 
          destination: centerSite, //destination,
          travelMode: google.maps.TravelMode.DRIVING
        };

        const directionService = new google.maps.DirectionsService()
        
        const results = await directionService.route(request)
        setDirectionResponse(results)
        setDistance(results.routes[0].legs[0].distance.text)
        setDuration(results.routes[0].legs[0].duration.text)
        //setBoxColor('transparent')
        //DEBUG && console.log(results)

    };

    const toggleDrawer = (open) => (event) => {
        console.log(event, open )
 
        if (event?.type ==='click'){
            console.log('IIIIIII', open)
            setStateOpen(false);            ///navigate(-1);
            return;
        }

        if (event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) return ;        // console.log(open)        // setStateOpen(false);        // !open && navigate(-1)
    };     // function(response, status){ console.log(google.maps.DirectionsStatus.OK, status)}            // origin: 'Factory 19/31 Keysborough Close, Keysborough, VIC, 3173',            // destination: destination,//destinationRef.current.value,            // travelMode: 'DRIVING'  //google.maps.TravelMode.DRIVING              //)

    const clearRouts =  ()=>{
        //google.maps.LatLng(null)

        setDirectionResponse(null)
        setDistance('')
        setDuration('')
        //setBoxColor('transparent')
    };

    const centerTOcenter =()=>{
        map.panTo(centerSite)
        map.setZoom(15)
        //clearRouts()
    };

    const getAllContractors= async(state)=>{
        const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_ALL_CONTRACTORS_DATA}${state}`);
        const contractorData = await response.json();  
        DEBUG && console.log(contractorData)
        setAllContractors(contractorData.map((c)=>c.supplier).sort())
        setAllContractorsData(contractorData);        //!DEBUG && console.clear()        // console.log(contractorData.map((c)=>c))        // console.log(contractorData.map((c)=>c.position))
    };

    const toAssign= async ()=>{
        const stateContractors = [];       // let stateDefaultContractor = {};
        let dist = [];
        const circule = 100;

        // DEBUG && console.log(oneSiteStateSelected)
        // DEBUG && console.log(selectedContractor)

        const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_ALL_CONTRACTORS_DATA}${oneSiteStateSelected}`);
        const contractorsData = await response.json();  
        //DEBUG && console.log(contractorsData)
        // if(centerSite.lat === undefined || centerSite.lng === undefined){ 
        for (let x of contractorsData) {
            if(x.supplierRegion.slice(-7).toUpperCase() === 'DEFAULT'){
                setCenterContract(x.position !== null ? {lat: x.position.y, lng: x.position.x} : defaultCenter)
                setOrigin(`${x.supplierPostalAddress}, ${x.supplierSuburb}, ${x.supplierState}, ${x.supplierPostCode}`)
                setSelectedContractor({
                    contractor: x.supplier,
                    contractorId: x.supplierId,
                    contractorPostCode: x.supplierPostCode,
                    contractorEmail: x.supplierEmail,
                    contractorPostalAddress: x.supplierPostalAddress, 
                    contractorRegion: x.Region,
                    contractorState: x.supplierState,
                    contractorSuburb: x.supplierSuburb,
                    position: x.position,
                    contractorContact: x.supplierContactPerson,
                    contractorPhone: x.supplierPhone,
                    contractorSheqsyId: x.sheqsyUId
                } )
                setInputs(values=>({...values, ['sheqsyUID']: x.sheqsyUId}))
                setInputs(values=>({...values, ['siteSupplierId']: x.supplierId}))
                //setInputs(values=>({...values, ['sheqsyUId']: x.sheqsyUId}))
                //setSiteSupplId(x.supplierId)
                setShowC(true)
            }
        }

        if(isLatLngPos == false){ return}    

        //console.log(contractorsData.sort(function(a, b){return b- a}));// const stateContractors=[]   // let stateDefaultContractor ={}; //const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_ALL_CONTRACTORS_DATA}${oneSiteStateSelected}`);    // const contractorsData = await response.json();          // console.log(contractorsData)
        const lat=centerSite.lat  ///y  SITE lat
        const lng=centerSite.lng ///x   SITE lng

        //setInputs(values=>({...values, ['siteZone']:site.siteZone[i] }))
        // console.log (inputs.siteZone)
        // console.log (oneSiteStateSelected)
        
        for (let x of contractorsData) {
            if (x.position !== null){     
                // let zone = '';
                
                dist.push( Math.sqrt(Math.pow(Math.abs(Math.abs(lng) - Math.abs(x.position.x)) * 87.8, 2) + Math.pow(Math.abs(Math.abs(lat) - Math.abs(x.position.y)) * 111, 2)).toFixed(2)*1)   ///.toFixed(2)*1
                // console.log(lng, '  - ' + lat) //location of the site // 144.96330204115574   -37.81355487513573  Melbourne GPO                // console.log(   Math.sqrt(Math.pow(Math.abs(Math.abs(lng) - Math.abs(144.96330)) * 87.8, 2) + Math.pow(Math.abs(Math.abs(lat) - Math.abs(-37.81355)) * 111, 2)).toFixed(2)*1 )
                // console.log(inputs.siteZone)                // console.log(inputs.siteState) // console.log(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${inputs.priority}/${zone}`)
                // console.log(calculateZone(lng, lat, inputs.siteState))

                const zone= calculateZone(lng, lat, inputs.siteState)
                setInputs(values=>({...values, ['siteZone']:zone}))

                getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${inputs.priority}/${zone}`)

                // -37.81355487513573, 144.96330204115574
                stateContractors.push({ 
                    contractor : x.supplier, 
                    contractorId: x.supplierId, 
                    distance: Math.sqrt(Math.pow(Math.abs(Math.abs(lng) - Math.abs(x.position.x)) * 87.8, 2) + Math.pow(Math.abs(Math.abs(lat) - Math.abs(x.position.y)) * 111, 2)).toFixed(2)*1, 
                    position: x.position,
                    contractorPostCode: x.supplierPostCode,
                    contractorPostalAddress: x.supplierPostalAddress,
                    contractorRegion: x.supplierRegion,
                    contractorState: x.supplierState,
                    contractorSuburb: x.supplierSuburb,
                    contractorContact: x.supplierContactPerson,
                    contractorPhone: x.supplierPhone,
                    contractorEmail:x.supplierEmail,
                    contractorSheqsyId: x.sheqsyUId

                })
            }
        }

        //DEBUG && console.log(dist.sort(function(a, b){return a-b}));   
        //DEBUG && console.log(dist)

        let a = dist.sort(function(a, b){return a-b})
        let minNumber = a[0] ///stateContractors[0].distance
        for (let x=0;  x < stateContractors.length; x++) {
        //    console.log(stateContractors[x].distance, stateContractors[x].contractor )
            // console.log(stateContractors[x])
            if ( stateContractors[x].distance <= minNumber && stateContractors[x].distance <= circule ) {
                setAssignContractor({
                        contractor : stateContractors[x].contractor, 
                        contractorId: stateContractors[x].contractorId, 
                        distance: minNumber, 
                        position: stateContractors[x].position,
                        contractorPostCode: stateContractors[x].contractorPostCode,
                        contractorPostalAddress: stateContractors[x].contractorPostalAddress,
                        contractorRegion: stateContractors[x].contractorRegion,
                        contractorState: stateContractors[x].contractorState,
                        contractorSuburb: stateContractors[x].contractorSuburb,
                        contractorContact: stateContractors[x].contractorContact,
                        contractorPhone:stateContractors[x].contractorPhone,
                        contractorEmail:stateContractors[x].contractorEmail,
                        contractorSheqsyId:stateContractors[x].contractorSheqsyId
                })
            
                //DEBUG && console.log({contractor : stateContractors[x].contractor, contractorId: stateContractors[x].contractorId, distance: minNumber})
                //DEBUG && console.log(stateContractors[x])

                setShowC(true)
                setCenterContract(stateContractors[x].position !== null ? {lat: stateContractors[x].position.y, lng: stateContractors[x].position.x} : defaultCenter)
                setOrigin(`${stateContractors[x].contractorPostalAddress}, ${stateContractors[x].contractorSuburb}, ${stateContractors[x].contractorState}, ${stateContractors[x].contractorPostCode}`)
                setSelectedContractor(stateContractors[x])
                //console.log(stateContractors[x])  //selectedContractor.contractorEmail
                //console.log(selectedContractor)
                setInputs(values=>({...values, ['siteSupplierId']: stateContractors[x].contractorId}))

                //{console.log('HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH ; ' ,stateContractors[x]?.contractorSheqsyId)}
                setInputs(values=>({...values, ['sheqsyUID']: stateContractors[x].contractorSheqsyId}))
                //setSiteSupplId(stateContractors[x].contractorId)
            } 
        }

        //DEBUG && console.log( minNumber)        //DEBUG && console.log(assignContractor)        // console.log(stateDefaultContractor)
        // contractorsData.map((contractor, i)=>{        //     // console.log(contractorsData)
        //     //if(contractor.supplier === 'newValue'){circule
        //     if(minNumber < circule ){    
        //         setShowC(true)
        //         setCenterContract(contractor.position !== null ? {lat: contractor.position.y, lng: contractor.position.x} : defaultCenter)
        //         setOrigin(`${contractor.supplierPostalAddress}, ${contractor.supplierSuburb}, ${contractor.supplierState}, ${contractor.supplierPostCode}`)
        //         setSelectedContractor(contractor)
        //         setInputs(values=>({...values, ['siteSupplierId']: contractor.supplierId}))        //         console.log(i)        //         console.log(assignContractor)        //         console.log(contractor)
        //         //console.log(contractor.supplierId)
        //     }
        // })        // console.log(selectedContractor)
    };    // const onLoad = useCallback(function callback(map) {    //     // This is just an example of getting and using the map instance!!! don't just blindly copy!    //     const bounds = new window.google.maps.LatLngBounds(centerSite);    //     map.fitBounds(bounds);    //     setMap(map)    // }, [])
    
    const onUnmount =  useCallback(function callback(map) {
        setMap(null)
        clearRouts()
    }, []);

    const changeContractor = ()=> {
        //setNewContractort(true)
        setInputs(values=>({...values, ['newContractor']: true}))
        DEBUG && console.log(inputs)
        //setPostNote(a =>({...a,  nContractor: `${inputs.supplier}`}))
    };

    const quillOnChange =(content, delta, source, editor)=>{

        //console.log('onBlur source.getText', source.getText()) console.log('onBlur delta', delta)  console.log('onBlur content ', content)  console.log('onBlur content index', content.index) console.log('onBlur editor', editor.getText()) console.log(content)  console.log(delta)           
        setInputs(values=>({...values, ['faultDescriptionForEmail']: editor.getText()}))
        setInputs(values=>({...values, ['faultDescription']: content}))

    }
  
    //const borderSize=(tabNumber, tabVal)=>{return tabNumber === tabVal ?'1px solid blue':'1px dotted gray'}    //const colorTab = (tabNumber, tabVal)=>{return tabNumber === tabVal ? 'red':'black'}
    // const calculateZone=(siteLng, siteLat, siteState)=>{

    //     let dist =0;
    //     let gpoLat = 0;
    //     let gpoLng = 0;

    //     const vicGpoLat = -37.81355487513573;
    //     const vicGpoLng = 144.96330204115574; 

    //     const qldGpoLat = -27.467938659782607;
    //     const qldGpoLng = 153.02791280031772;

    //     const nswGpoLat = -33.86757842094773;
    //     const nswGpoLng = 151.2072171868791;

    //     const waGpoLat = -31.952274084889623;
    //     const waGpoLng = 115.85901122083074;

    //     const tasGpoLat = -42.88238456614443;
    //     const tasGpoLng = 147.33014194802675;

    //     const saGpoLat = -34.92691220801934;
    //     const saGpoLng = 138.59879324157237;

    //     const ntGpoLat = -12.460974351019471;
    //     const ntGpoLng =  130.8419695369975;

    //     const actGpoLat = -35.27803484431844; 
    //     const actGpoLng = 149.12834504448048;

    //     switch (siteState) {
    //         case 'VIC':
    //             gpoLat = vicGpoLat;
    //             gpoLng = vicGpoLng;
    //             break;

    //         case "TAS":
    //             gpoLat = tasGpoLat;
    //             gpoLng = tasGpoLng;
    //             break;

    //         case 'NSW':
    //             gpoLat = nswGpoLat;
    //             gpoLng = nswGpoLng;
    //             break;

    //         case "ACT":
    //             gpoLat = actGpoLat;
    //             gpoLng = actGpoLng;
    //             break;

    //         case 'WA':
    //             gpoLat = waGpoLat;
    //             gpoLng = waGpoLng;
    //             break;

    //         case "SA":
    //             gpoLat = saGpoLat;
    //             gpoLng = saGpoLng;
    //             break;

    //         case 'QLD':
    //             gpoLat = qldGpoLat;
    //             gpoLng = qldGpoLng;
    //             break;

    //         case "NT":
    //             gpoLat = ntGpoLat;
    //             gpoLng = ntGpoLng;
    //             break;
    //     }

    //     dist = Math.sqrt(Math.pow(Math.abs(Math.abs(siteLng) - Math.abs(gpoLng)) * 87.8, 2) + Math.pow(Math.abs(Math.abs(siteLat) - Math.abs(gpoLat)) * 111, 2)).toFixed(2)*1 

    //     if (dist <= 50) {
    //         return 'Metro'
    //     }else{
    //         if (dist <= 100){
    //             DEBUG && console.log("yyyyyy  ", dist)
    //             return 'Remote'
    //         }else{
    //             DEBUG && console.log("aaaaa ", dist)
    //             return 'Remote+'
    //         }        
    //     }
    //     //return Math.sqrt(Math.pow(Math.abs(Math.abs(siteLng) - Math.abs(gpoLng)) * 87.8, 2) + Math.pow(Math.abs(Math.abs(siteLat) - Math.abs(gpoLat)) * 111, 2)).toFixed(2)*1 
    //     //return dist
    // };

    //const focusInput = () => { inputRef.current.focus() };

    const toReturn =()=>{
        try{
            return (                      
                <>                                                                                
                    <Stack  name ='MAIN BOX' width={{xs:'45ch', sm:'55ch', md:'136ch'}} >                                                
                       
                        <Stack  name ='TABS BOX' ////////gap={.5}//direction={{xs: "row", sm: "column" }}//border='1px solid gray' // display='flex' //sx={{boxShadow:`0 0 3px 1px red`}}  //direction={{ xs: "column", sm: "row" }} //spacing={{ xs: 1 }} //direction={{sm: "column", md: "row"}}  
                            //width={{xs:'45ch', sm:'55ch', md:'136ch'}}          //  alignItems='center'// justifyContent='center' //    
                            height={{ xs:'auto',  md:'68ch'}}                         
                        >
                                                                                  {/* <Stack justifyContent='center' alignItems='center' padding={1}><Typography sx={{fontSize:fs(.7, .8, 1.1), fontWeight:600, color:'red'}}>{action==='update'?`Job No: ${inputs.jobNo}`: "Add New Job"} </Typography></Stack>  */}
                            <Tabs  
                                //spacing="relative"
                                justifyContent='space-around'         
                                isLazy     
                                indicatorPosition="top"
                                spacing="equal"                
                                onChange={(tab)=>handleTabClick(parseInt(tab))}            //justifyContent="space-between" //spacing= {'relative'} //marginLeft={10} //paddingBottom={1}//border={'5px solid lightgray'}                                 // onValueChange={(tab)=>console.log(tab)}
                            >
                                {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  SITE      >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}        
                                <TabItem 
                                    className='tab' 
                                    title={<Stack sx={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} >Site</Stack> }                      // backgroundColor={1 ===tabValue ?'aquamarine':'aliceblue'}
                                    border={'1px solid gray'}     //   color={'red'}//colorTab( 0, tabValue)}
                                    borderRadius='5px'                  //   width={110}           //onClick={()=>handleNewTabClick(0)}  
                                >                 
                                    
                                    <Stack 
                                        sx = {{ p:.5, gap:.5}}                         
                                        direction={{ xs: "column", md: "row" }}                         
                                    >                                                                         
                                        {action ==='update'?    
                                            ////// EXISTING SITE  //////////////     
                                            <>                                                                                                                                                                                                    
                                                <Stack 
                                                    sx={{
                                                        boxShadow:'1px 1px 3px gray', 
                                                        width: {xs:'45ch', sm:'55ch', md:'67ch'},   
                                                        //height:{xs:'40ch', sm:'50ch', md:'60ch'},        
                                                        '&:hover': {
                                                            //bgcolor: 'primary.light',
                                                        },                                                        
                                                    }} 
                                                >     
                                                    <Table
                                                        // caption={                                                       
                                                        //     <Stack direction='row' paddingY={2} justifyContent='center' alignItems='center' gap={1}>
                                                        //         <Stack color='navy' fontSize={fs(.7, .88, 1.1)} sx={{fontFamily:'Arial',  fontWeight:600}}> {`Site: `}</Stack>      //<Stack color='red' fontSize={fs(.7, .88, 1.1)} sx={{fontFamily:'Arial', fontWeight:600}} >{inputs.siteName}</Stack>
                                                        //     </Stack>
                                                        // }                                                    
                                                        size="small"
                                                        highlightOnHover={true}
                                                        variation="striped"
                                                        backgroundColor={'antiquewhite'}                        
                                                    >    
                                                        <TableBody >     
                                                            <TableRow >                                                
                                                                <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Name </Typography></TableCell>
                                                                <TableCell border='1px solid lightgray'><Typography color={'red'} fontSize={fs(.69, .79, 1.2)} fontWeight={800} >{inputs.siteName ?? ''}</Typography> </TableCell>                                                
                                                            </TableRow>
                                                            <TableRow >                                                
                                                                <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Site ID</Typography></TableCell>
                                                                <TableCell border='1px solid lightgray' ><Typography fontSize={fs()}>{inputs.siteId ?? ''}  </Typography></TableCell>                                                
                                                            </TableRow>
                                                    
                                                            <TableRow >                                                
                                                                <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Type</Typography></TableCell>
                                                                <TableCell border='1px solid lightgray' ><Typography fontSize={fs()}>{inputs.siteOutLetType ?? ''}  </Typography></TableCell>                                                
                                                            </TableRow>
                                                                                                                                                                            
                                                            <TableRow >                                                
                                                                <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Customer</Typography></TableCell>
                                                                <TableCell border='1px solid lightgray' ><Typography fontSize={fs()}>{inputs.customerName ?? ''}  </Typography></TableCell>                                                
                                                            </TableRow>

                                                            <TableRow >                                                
                                                                <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Zone</Typography></TableCell>
                                                                <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.siteZone ?? ''} </Typography></TableCell>                                                
                                                            </TableRow>
                                                            <TableRow >                                                
                                                                <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Address</Typography></TableCell>
                                                                <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.siteAddress1 ?? ''} </Typography></TableCell>                                                
                                                            </TableRow>
                                                            <TableRow >                                                
                                                                <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>State</Typography></TableCell>
                                                                <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.siteState ?? ''} </Typography></TableCell>                                                
                                                            </TableRow>
                                                            <TableRow >                                                
                                                                <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Phone</Typography></TableCell>
                                                                <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.sitePhone??""}</Typography></TableCell>                                                
                                                            </TableRow>
                                                            <TableRow >                                                
                                                                <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Contact</Typography></TableCell>
                                                                <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.siteManager ?? ''} </Typography></TableCell>                                                
                                                            </TableRow>

                                                        </TableBody >  
                                                    </Table>          
                                                </Stack>

                                                <Stack name='DIVIDER'
                                                    className='divider'                                             
                                                    display={{xs:'none', md:'flex'}}
                                                    padding={'2px'}
                                                    onMouseMove={(e)=>console.log(e.buttons==1 ? e: "blah")}
                                                    sx={{
                                                        '&:hover': {
                                                            cursor:'ew-resize'
                                                        },
                                                    }}
                                                > </Stack>
                                            </>                                               
                                        :
                                            /////// ADD NEW SITE start //////////////
                                            <>
                                            
                                                <Stack     
                                                    sx={{
                                                            boxShadow:'1px 1px 3px gray', 
                                                            width: {xs:'45ch', sm:'55ch', md:'67ch'},                                                             //height:{xs:'40ch', sm:'50ch', md:'60ch'},                            
                                                        }}

                                                >
                                                    {count >=2 ?
                                                        <Autocomplete
                                                            value={inputs.customerName}////    value={clientName??"undefined"}///{clientName} //disabled={action==='update'?true:false}
                                                            required //ref={inputRef}                     //marginTop={'1px'}
                                                            name='customerName'
                                                            options={ role ==='Customer' || role=='Client' || role=='Manager'? subrole.split(','):allClientsNames.sort()}
                                                            isOptionEqualToValue={(option, value) => option?.value === value?.value}                                            //disableClearable                                                                
                                                            size='small'
                                                            filterOptions={filterOptions}                                                                
                                                            onChange={(event, newValue) => {
                                                                    clearAllSitesData()
                                                                    if (newValue === null){
                                                                        setShowSite(false)
                                                                        setDestination('')
                                                                        setInputs(values=>({...values, ['customerName']: undefined}))
                                                                    }else{
                                                                        setInputs(values=>({...values, ['customerName']: newValue}))
                                                                        const a = allClientsIds.filter((i)=>i.name.toUpperCase()== newValue.toUpperCase() && i.id)
                                                                        //console.log('Selecyted Cust',a)
                                                                        setInputs(values=>({...values, ['customerId']:a[0]?.id }))                                                                
                                                                        getSitesForSelectedCustomer(a[0].id > 0 ? a[0].id : 0 )
                                                                        //console.log(a[0].name,  a[0].id) ///[0].id > 0 ? a[0].id : '' )                                                                                    
                                                                    }
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}                                                            //disableClearable
                                                                    label="Select Customer" //{action ==='update'? clientNameFromUpdate : "Clients List"} //value={clientName??"Not defined"}required  // helperText = { selectedClientName ===''?"Select value":""}  // error ={selectedClientName === ''? true:false}
                                                                    inputProps = {{...params.inputProps, type: 'search', }}    
                                                                    style={{width:'40ch'}}
                                                                    sx={{ mt:'.5ch'}}   
                                                                />
                                                            )}
                                                        />
                                                    :
                                                        <Loader  emptyColor={'red'} filledColor={'white'} marginTop ={'10ch'} marginLeft={'20ch'} style={{width: 70}}/>
                                                    }
                                                
                                                    { (!!inputs.customerName && !showSite) &&
                                                        <Stack name='SELECT SITES LIST' marginTop='15px'>                        
                                                            <Autocomplete
                                                                //value={inputs.customerName}
                                                                freeSolo
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                autoHighlight
                                                                required 
                                                                loading={loading}
                                                                isOptionEqualToValue={(option, value) => option.value === value.value} 
                                                                filterOptions={filterOptions}
                                                                name='sites'
                                                                size='small'
                                                                //disableClearable={false}                                                              
                                                                options={siteSelected.siteName}
                                                                renderOption={(props, option) => ( <Box  component='li'{...props} key={option.id} > {option} </Box>)}                                                            
                                                                onChange={(event, newValue) => {
                                                                    console.log(newValue)
                                                                    // clearAllSitesData()
                                                                    if (newValue === null){
                                                                        clearAllSitesData();
                                                                        setShowSite(false);
                                                                        setDestination('');
                                                                                // }else if(newValue =='ADD NEW SITE...'){    
                                                                                //     console.log('------------- ', newValue)
                                                                                //     handleNewSite()

                                                                    }else{
                                                                        setShowSite(true);
                                                                        siteSelected.siteName.map((currentValue, index)=> currentValue == newValue && setAllSiteData(siteSelected, index ));     
                                                                        //console.log(siteSelected.siteName.map((currentValue, index)=> currentValue == newValue && siteSelected)   )
                                                                        //{console.log(newValue)} //siteSelected.siteName.map((val, index)=>val === newValue &&  setInputs(values=>({...values, ['siteId']:siteSelected.siteId[index] })));  
                                                                        //setOneSiteNameSelected(newValue)
                                                                        
                                                                    } 
                                                                } }
                                                                renderInput={(params) => (
                                                                    <TextField  {...params}
                                                                        label={`Select ${inputs.customerName ?? ''} Site`} //inputs.customerName !== null || inputs.customerName !== undefined ? 
                                                                        inputProps = {{...params.inputProps, type: 'search', 
                                                                        // endAdornment: (
                                                                        //     <>
                                                                        //         {loading ? <CircularProgress size={18} /> : null}
                                                                        //         {params.InputProps.endAdornment}
                                                                        //     </>
                                                                        // )                                                                                                                                                                                                                    
                                                                                                                                            
                                                                    }}       
                                                                        style={{width:'40ch'}}    
                                                                        sx={{boxShadow:'1px 1px 2px lightgray',}}   
                                                                    />
                                                                ) }
                                                            />

                                                            {(role =='Administration' || role =='Manager' || isSuperUser) &&
                                                                <Button 
                                                                    sx={{mt:3, width:'20ch'}} 
                                                                    onClick={ handleNewSite } 
                                                                    variant="outlined" 
                                                                ><Typography sx={{fontSize:'clamp(0.65rem, .6vw, 1rem)'}}>Add New Site</Typography>
                                                                </Button>
                                                            }

                                                        </Stack>   
                                                        
                                                    }                                                                                                                                                
                                                
                                                    {showSite &&
                                                        <Stack 
                                                            sx={{boxShadow:'1px 1px 3px gray', 
                                                                width: {xs:'45ch', sm:'55ch', md:'67ch'},   
                                                                //height:{xs:'40ch', sm:'50ch', md:'60ch'},  
                
                                                            }} 
                                                        
                                                        >     
                                                            <Table
                                                                // caption={<Stack direction ='row' paddingY={1} justifyContent='center' alignItems='center'  gap={1}>
                                                                //     <Typography  fontSize={fs()} fontWeight={600}> Adding Job For Site :</Typography><Typography fontSize={fs()} color='red' fontWeight={600}>{inputs.siteName}</Typography></Stack>}
                                                                size="small"
                                                                highlightOnHover={true}
                                                                variation="striped"
                                                                backgroundColor={'antiquewhite'}                        
                                                            >    
                                                                <TableBody > 
                                                                        
                                                                    <TableRow >                                                
                                                                        <TableCell border='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Name </Typography></TableCell>
                                                                        <TableCell border='1px solid lightgray' ><Typography color={'red'} fontSize={fs()} fontWeight={800}> {inputs.siteName ?? ''} </Typography></TableCell>                                                
                                                                    </TableRow>
                                                                    <TableRow >                                                
                                                                        <TableCell border='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Site ID</Typography></TableCell>
                                                                        <TableCell border='1px solid lightgray' ><Typography fontSize={fs()}>{inputs.siteId ?? ''}  </Typography></TableCell>                                                
                                                                    </TableRow>
                                                                                                                                                                
                                                                    <TableRow >                                                
                                                                        <TableCell border='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Customer</Typography></TableCell>
                                                                        <TableCell border='1px solid lightgray' ><Typography  fontSize={fs()}>{inputs.customerName ?? ''}  </Typography></TableCell>                                                
                                                                    </TableRow>

                                                                    <TableRow >                                                
                                                                        <TableCell border='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Zone</Typography></TableCell>
                                                                        <TableCell border='1px solid lightgray' ><Typography fontSize={fs()}>{inputs.siteZone ?? ''} </Typography></TableCell>                                                
                                                                    </TableRow>

                                                                    <TableRow  >                                                
                                                                        <TableCell border='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Address</Typography></TableCell>
                                                                        <TableCell border='1px solid lightgray' ><Typography fontSize={fs()}>{inputs.siteAddress1} </Typography></TableCell>                                                                                                                         
                                                                    </TableRow>
                                                                    <TableRow>                                                
                                                                        <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>State</Typography></TableCell>
                                                                        <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.siteState ?? ''} </Typography></TableCell>                                                
                                                                    </TableRow>
                                                                    <TableRow >                                                
                                                                        <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Phone</Typography></TableCell>
                                                                        <TableCell border='1px solid lightgray' ><Typography fontSize={fs()}>{inputs.sitePhone??""}</Typography></TableCell>                                                
                                                                    </TableRow>
                                                                    <TableRow>                                                
                                                                        <TableCell border='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Contact</Typography></TableCell>
                                                                        <TableCell border='1px solid lightgray' ><Typography fontSize={fs()} >{inputs.siteManager ?? ''} </Typography></TableCell>                                                
                                                                    </TableRow>

                                                                </TableBody >  
                                                            </Table>  
                                                        </Stack>
                                                    }   
                                                </Stack>

                                                <Stack name='DIVIDER'
                                                    className='divider'                                             
                                                    display={{xs:'none', md:'flex'}}
                                                    padding={'2px'}
                                                    onMouseMove={(e)=>console.log(e.buttons==1 ? e: "blah")}
                                                    sx={{
                                                        '&:hover': {
                                                            cursor:'ew-resize'
                                                        },
                                                    }}
                                                > </Stack>
                                            </>                                        
                                        }                                    
                                        {!!destination && isLoaded ?                                        
                                            <Stack name='GOOGLE'
                                                sx={{boxShadow:'1px 1px 3px gray', 
                                                    width: {xs:'45ch', sm:'55ch', md:'67ch'},   
                                                    height:{xs:'40ch', sm:'50ch', md:'60ch'},     
                                                }} 
                                            >
                                                 <Stack borderBottom ='1px solid blue'padding={'2px'}><GetLocation setCenterSite = {setCenterSite} destination={destination} setSiteLabel={setSiteLabel}/> </Stack>                                             
                                                <GoogleMap 
                                                    center = {centerSite}
                                                    zoom={12} 
                                                    mapContainerStyle = {{width:'100%', height:'100%'}}
                                                    onUnmount={onUnmount}
                                                    onLoad={(map)=>{setMap(map)}}
                                                    options={{  
                                                        streetViewControl:false,  //fullscreenControl: false,//keyboardShortcuts:false,//zoomControl:true,
                                                        mapTypeControl: false,    // zoomControlOptions: {style:'SMALL', position:0.0}, 
                                                        disableDoubleClickZoom:true, 
                                                        mapTypeControlOptions:'ROADMAP', 
                                                        gestureHandling:'none'
                                                    }}
                                                > 
                                                    <MarkerF
                                                        position= {centerSite} //label={siteLabel}
                                                        options={{anchorPoint:null, clickable:true }}
                                                        animation={ aa ? google.maps.Animation.DROP : google.maps.Animation.BOUNCE}
                                                        onClick={(e)=>setAa(!aa) } //title={siteLabel}
                                                        icon={{
                                                            path:"M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                                                            fillColor: "yellow", fillOpacity: .8, scale: 1.5, strokeColor: "red",  strokeWeight: 1.2,                                                                
                                                        }}
                                                    >
                                                        <InfoWindowF position={centerSite} >
                                                            <Stack border='1px solid lightgray' padding={'3px'} fontFamily={'Arial'} backgroundColor='pink' fontSize={fs(.6, .7, 1)}>{siteLabel}</Stack>
                                                        </InfoWindowF> {/* destination */}
                                                    </MarkerF>                                                        
                                                </GoogleMap>   

                                            </Stack> 
                                        :   
                                            <Stack name="LOADER"
                                                sx={{boxShadow:'1px 1px 3px gray', 
                                                    width: {xs:'45ch', sm:'55ch', md:'67ch'},   
                                                    height:{xs:'40ch', sm:'50ch', md:'60ch'},  
                                                    justifyContent:'center',
                                                    alignItems:'center'
                                                }} 

                                                //marginLeft={55} marginTop={25}
                                            >
                                                <Loader emptyColor={'green'} filledColor={'white'} style={{width: 100}}/>
                                            </Stack>                      
                                        }                                                                                                           
                                    </Stack>                                                             
                                </TabItem>  

                                    {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  JOBS DETAILS          >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            
                                <TabItem 
                                    className='tab' 
                                    title={<Stack sx={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} >Details</Stack> }                         // backgroundColor={2 === tabValue ?'aquamarine':'aliceblue'}//
                                    border={'1px solid gray'}
                                    borderRadius='5px'
                                    onDoubleClick={getViewAll} //onLoad= {1 === tabValue && getActivity(inputs.activityGuid, 'all')}  //{inputs.activityGuid == undefined || inputs.activityGuid ===null || inputs.activityGuid ===''?'': getActivity(inputs.activityGuid, 'callback') }                                    //onClick ={getActivity(inputs.activityGuid, 'callback')} //{inputs.activityGuid == undefined || inputs.activityGuid ===null || inputs.activityGuid ===''?'': getActivity(inputs.activityGuid, 'api')} 
                                >
                                    <Stack  name='MAIN STACK'                                                      
                                        direction={{ sm: "column", md: "row" }}                         // sx = {{width:{xs:'90ch', sm:'136ch'},height:{ xs:'125ch', sm:'100ch', md:'65ch'}, display:'flex',p:1,gap:1,}}// direction={{ xs: "column", sm: "row" }}                                           
                                        sx = {{p:.5, gap:.5,}}                              //width:{xs: '47ch', sm:'57ch', md:'136ch'},//height:{  md:'65ch'},                                             //boxShadow:'1px 1px 3px gray',                                                                                     
                                    >   
                                                                                    
                                        <Stack name ='TABLE' 
                                            //useFlexGap                                    
                                            sx={{
                                                boxShadow:'1px 1px 3px gray', 
                                                width: {xs:'45ch', sm:'55ch', md:'67ch'},          //height:{ sm:'63ch'},                                     
                                                '&:hover': {
                                                    bgcolor: 'transparent',
                                                },
                                                
                                            }} 
                                        > 
                                            <Table                                                       
                                                size="small"
                                                highlightOnHover={true}
                                                variation="striped"
                                                backgroundColor={'antiquewhite'}                                                                
                                            >  
                                                <TableBody>  
                                                    <TableRow> 
                                                        <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600}}>Job ID</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'red', fontSize:fs(.6, .75, 1.1), fontWeight:800}}>{id ?? 'Adding new job'}</Typography></TableCell>
                                                    </TableRow>                                              
                                                    <TableRow>                                                
                                                        <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600}}>Priority</Typography></TableCell>
                                                        <TableCell border='1px solid lightgray'>                                                        
                                                            <Select                                                            
                                                                value={inputs.priority}
                                                                name="priority"
                                                                size='small'                                            
                                                                onChange={(e) => {
                                                                    const nVal = e.target.value
                                                                    const noteTxt = nVal == 1 ? 'Extreme' : nVal == 2 ? 'Urgent' : nVal == 3 ? 'Moderate' : 'Standard'                                                                        
                                                                    //setJobPr( nVal)
                                                                    setInputs(values=>({...values, ['priority']: nVal}))  
                                                                    getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${nVal }/${inputs.siteZone}` )
                                                                    setPostNote(a =>({...a, jobPriority: noteTxt } ))    
                                                                }}      
                                                            >
                                                                <MenuItem value={1}><Typography fontSize={fs()}>Extreme</Typography></MenuItem>
                                                                <MenuItem value={2}><Typography fontSize={fs()}>Urgent</Typography></MenuItem>
                                                                <MenuItem value={3}><Typography fontSize={fs()}>Moderate</Typography></MenuItem>
                                                                <MenuItem value={4}><Typography fontSize={fs()}>Standard</Typography></MenuItem>
                                                            </Select>                                                                                    
                                                        </TableCell>                                                
                                                    </TableRow>                                                    
                                                    <TableRow >                                                    
                                                        <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600}}>Status</Typography></TableCell>
                                                        <TableCell border='1px solid lightgray'>
                                                            <Select                                                                    
                                                                value={inputs.statusId}
                                                                size='small'
                                                                onChange={(e) => {
                                                                    const NoteTxt = statusName.filter((sName, i)=> {if(statusId[i] == (e.target.value)) return sName })                                                                    
                                                                    const nVal = e.target.value                                                                     
                                                                    setInputs(v =>({...v, ['statusId']: nVal})) 
                                                                    setPostNote(v =>({...v, jobStatus:  NoteTxt[0]}))                                                       
                                                                }}      
                                                            >
                                                               {statusName?.map((s, i)=>{ return( <MenuItem key={i} value={statusId[i]}><Typography fontSize={fs()}>{s}</Typography></MenuItem> )   })}
                                                            </Select>
                                
                                                        </TableCell>                                                
                                                    </TableRow>
                                                    <TableRow >                                                    
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600}}>Request Type</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray'>  

                                                            <Select                                                                    
                                                                value={inputs.requestTypeId}
                                                                size='small'
                                                                disabled={(role.trim().toUpperCase()==='Contractor'.toUpperCase() || role.trim().toUpperCase()=='Field Technician'.toUpperCase()) } 
                                                                onChange={(e) => {
                                                                    console.log('?????????????????????? ', e.target.value)
                                                                    const NoteTxt = requestType.filter((sName, i)=> {if(requestId[i] == (e.target.value)) return sName })
                                                                    const nVal = e.target.value// 
                                                                    setInputs(v =>({...v, ['requestTypeId']: nVal}))
                                                                    setPostNote(v =>({...v, jobRequestType:  NoteTxt[0]}))                                                       
                                                                }}      
                                                            >
                                                               {requestType?.map((s, i)=>{ return( <MenuItem key={i} value={requestId[i]}><Typography fontSize={fs()}>{s}</Typography></MenuItem> )   })}
                                                            </Select>

                                                            {/* <Autocomplete 
                                                                value={inputs.requestType}
                                                                disabled={(role.trim().toUpperCase()==='Contractor'.toUpperCase() || role.trim().toUpperCase()=='Field Technician'.toUpperCase()) } 
                                                                size='small'       // 
                                                                filterOptions={filterOptions}
                                                                options={requestTypes.requestTypeName}  
                                                                onChange={(e, newValue) => { 
                                                                    requestTypes.requestTypeName.map((item, index)=>{
                                                                        if(item === newValue){
                                                                            setInputs(values=>({...values, ['requestTypeId']: requestTypes.requestTypeId[index]}))
                                                                            setInputs(values=>({...values, ['requestType']: requestTypes.requestTypeName[index]}))  //setInputs(values=>({...values, [e.target.name]: e.target.value}))
                                                                            setPostNote(a =>({...a, jobRequestType: `${requestTypes.requestTypeName[index]}`}))
                                                                        }
                                                                    })
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField 
                                                                        {...params} 
                                                                        inputProps = {{...params.inputProps, type: 'search', }} 
                                                                        
                                                                    /> ) }
                                                            /> */}
                                                        </TableCell>                                                
                                                    </TableRow>        
                                                    <TableRow>                                                    
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600}}>Work Order</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray' >
                                                            {action =='new' ? 
                                                                <TextField  
                                                                    value = {inputs.po_number}     // {inputs.po_number ?? ''}
                                                                    //disabled = {action =='update'}    
                                                                    name="po_number"                      
                                                                    size='small'                                                                 
                                                                    onChange={(e)=>{setInputs(values=>({...values, [e.target.name]: e.target.value}))}}///inputs.po_number
                                                                />  
                                                            :
                                                                <Typography sx={{ fontSize:fs()}} > {inputs.po_number}  </Typography>
                                                            }
                                                        </TableCell>                                                
                                                    </TableRow> 
                                                    <TableRow >                                                    
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600 }}>SLA</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray'>
                                                            <Typography style={{color:'#2d187be0', fontSize:fs()}}>
                                                                {inputs.slaDate == undefined || inputs.slaDate == null || inputs.slaDate == '' ? '': `${dateConverter(inputs.slaDate)}` }{Date.now() > inputs.slaDate * 1000 ?' SLA is missed':''}
                                                            </Typography>
                                                        </TableCell>                                                
                                                    </TableRow>                                                                      
                                                    <TableRow >                                                    
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600,  }}>Created By</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray'> {action =='update' && <Typography style={{fontSize:fs()}}>{inputs.userFullName  == undefined || inputs.userFullName == null || inputs.userFullName == '' ? '': inputs.userFullName}
                                                                </Typography>}</TableCell>                                                
                                                    </TableRow>                                                                                                        
                                                    <TableRow >                                                
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600,  }}>Date Created</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray'>{action =='update' &&<Typography style={{ fontSize:fs() }}>{dateConverter(inputs.dateCreated)}</Typography>}</TableCell>                                                                                                        
                                                    </TableRow>           
                                                    <TableRow >                                                    
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600}}>Accepted On</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs()}}>{inputs.siteSupplierAccepted == 1 && dateConverter(inputs.status_time)}</Typography> </TableCell>                                                
                                                    </TableRow>                                                
                                                    <TableRow>                                                
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600}}>En Route</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray'> <Typography style={{color:'#2d187be0', fontSize:fs()}}></Typography></TableCell>                                                
                                                    </TableRow>
                                                    <TableRow>                                                
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600}}>Arrived On Site</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs()}}></Typography></TableCell>                                                
                                                    </TableRow>
                                                    <TableRow>                                                
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs(), fontWeight:600}}>Left Site</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontSize:fs()}}></Typography></TableCell>                                                
                                                    </TableRow>
                                                    <TableRow>                                                
                                                        <TableCell  border='1px solid lightgray'><Typography style = {{color:'#2d187be0', fontSize:fs(), fontWeight:600}}>Completed On</Typography></TableCell>
                                                        <TableCell  border='1px solid lightgray'><Typography style = {{color:'#2d187be0', fontSize:fs()}}> {inputs.dateCompleted == undefined ?'' :   dateConverter(inputs.dateCompleted)} </Typography> </TableCell>                                                
                                                    </TableRow>    
                                                </TableBody>       
                                            </Table>  
                                        </Stack>

                                        <Stack name='DIVIDER' 
                                            className='divider'                                             
                                            display={{xs:'none', md:'flex'}}
                                            padding={'2px'}
                                            onMouseMove={(e)=>console.log(e.buttons==1 ? e: "blah")}
                                            sx={{
                                                '&:hover': {
                                                    cursor:'ew-resize'
                                                },
                                            }}
                                        > </Stack>

                                                                            
                                        <Stack name ='QUILL - NEW (ADD) JOB' sx={{boxShadow:'1px 1px 3px gray', width: {xs: '45ch', sm:'55ch', md:'67ch'}, height:{xs:'40ch', sm:'50ch', md:'60ch'},}}>
                                            <ReactQuill 
                                                theme={action === 'update' && role !=='Administration' && role !=='Manager' && role !=='Customer' ? "bubble":'snow'} //id="faultDescription"                                                //ref={inputRef.current}
                                                readOnly = {action === 'update' && role !=='Administration'}
                                                className ='faultDescription'
                                                style={{backgroundColor:'papayawhip'}}
                                                value={inputs.faultDescription ?? ''}                            //placeholder ="Fault Description..."
                                                modules={{
                                                    'history': {         
                                                        'delay': 10,
                                                        'userOnly': true
                                                    },   
                                                    'toolbar': toolbarOptions
                                                }}   
                                                onChange={quillOnChange }
                                            />
                                        </Stack>
                                                                                                                                                                                                
                                    </Stack>  



                                    {/* <Stack 
                                        sx={{ 
                                            position: "absolute",   //  backgroundColor: "none", borderRadius: "50%", //  border:'1px solid red', //opacity: .6,
                                            transform: `translate(${position.x}px, ${position.y}px)`,            
                                            pointerEvents: "none",
                                            left: 5,
                                            top: 25,                                   //border:'1px solid red',  
                                            fontFamily:'Arial',
                                            color:'blue',
                                            fontSize:fs() 
                                        }}                            
                                    >
                                        {` Job ${id} - pos (x: ${position.x}px, y: ${position.y}px)  `}
                                    </Stack>                                    
                                     */}
                                
                                </TabItem>
                        
                            {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<   SERVICE PROVIDER DETAILS  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            
                                { (isSuperUser || role.toUpperCase()==='ADMINISTRATION' || role.toUpperCase()=='SENIOR TECHNICIAN' || role.toUpperCase()=='MANAGER' || role.toUpperCase()=='CLIENT') && 
                                    <TabItem    
                                        className='tab' 
                                        title={<Stack sx={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} > Service Provider  </Stack> }  
                                        // backgroundColor={3 ===tabValue ?'aquamarine':'aliceblue'}//
                                        border={'1px solid gray'}
                                        borderRadius='5px'                                    
                                        onClick={()=>{ setInputs(values=>({...values, ['supplierCountry']: 'Australia'})) } }
                                    >
                                        <Stack 
                                            direction={{ sm: "column", md: "row" }}                        
                                            sx = {{p:.5, gap:.5,}}                         
                                        >    
                                            {(action ==='update' && !inputs.newContractor) ?             
                                                                /////////// ACTION UPDATE  /////////                                            
                                                <>
                                                    <Stack name='TABLE' 
                                                        sx={{
                                                            boxShadow:'1px 1px 3px gray', 
                                                            width: {xs: '45ch', sm:'55ch', md:'67ch'}, 
                                                            //height:{xs:'40ch', sm:'50ch', md:'60ch'},                                                          
                                                        }} 
                                                    >     
                                                        <Table
                                                            caption={                                                        
                                                                <Stack paddingY={2} alignItems='center'>
                                                                    <Button      
                                                                        className='tab'                                                                  //variant="extended"//size="small"  //border='10px solid gray'//borderRadius= '5px'// fontSize={fs()}
                                                                        sx={{width:'70%', background:'lightsteelblue', boxShadow:'1px 1px 2px 1px steelblue'}}
                                                                        onClick={changeContractor}                                                
                                                                    > 
                                                                        <Typography sx={{fontSize:'clamp(0.75rem, .8vw, 1.1rem)', fontWeight:800, fontFamily:'inherit', color:"steelblue",}}>Change Provider</Typography>
                                                                    </Button>   
                                                                
                                                                </Stack>
                                                            }   
                                                            size="small"
                                                            highlightOnHover={true}
                                                            variation="striped"
                                                            backgroundColor={'antiquewhite'}                        
                                                        >    
                                                            <TableBody border='1px sollid gray' >     

                                                                <TableRow  >                                                
                                                                    <TableCell border='1px solid lightgray' > <Typography color='2d187be0' fontWeight={500} fontSize={fs()}>Name</Typography></TableCell>
                                                                    <TableCell border='1px solid lightgray'>
                                                                        <Typography color={'red'} fontSize={fs(.69, .79, 1.2)} fontWeight={800}  >{inputs.supplier ?? ''}</Typography>    

                                                                                                                                   
                                                                    </TableCell>                                                
                                                                </TableRow>                                                                                                                                 
                    
                                                                <TableRow>                                                
                                                                    <TableCell border='1px solid lightgray'> <Typography style={{color:'#2d187be0', fontWeight:600}} fontSize={fs()}> Address</Typography></TableCell>
                                                                    <TableCell border='1px solid lightgray'> <Typography fontSize={fs()}>{ inputs.supplierPostalAddress ?? ''}</Typography> </TableCell>                                                
                                                                </TableRow>
                                                                <TableRow >                                                
                                                                    <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontWeight:600}} fontSize={fs()}> State</Typography></TableCell>
                                                                    <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{ inputs.supplierState ?? ''} </Typography></TableCell>                                                
                                                                </TableRow>
                                                                <TableRow>                                                
                                                                    <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontWeight:600}} fontSize={fs()}> Phone </Typography></TableCell>
                                                                    <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.supplierPhone??''} </Typography></TableCell>                                                
                                                                </TableRow>
                                                                <TableRow>                                                
                                                                    <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Email</Typography></TableCell>
                                                                    <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.supplierEmail??''}</Typography></TableCell>                                                
                                                                </TableRow>
                                                                <TableRow>                                                
                                                                    <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Contact</Typography></TableCell>
                                                                    <TableCell border='1px solid lightgray'><Typography  fontSize={fs()}>{inputs.supplierContactPerson??''}</Typography></TableCell>                                                
                                                                </TableRow>
                                                                <TableRow>                                                
                                                                    <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Region</Typography></TableCell>
                                                                    <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.supplierRegion??''}</Typography></TableCell>                                                
                                                                </TableRow>
                                                                <TableRow >                                                
                                                                    <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Latitude</Typography></TableCell>
                                                                    <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{centerContract.lat??""}</Typography></TableCell>                                                
                                                                </TableRow>
                                                                <TableRow >                                                
                                                                    <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Longitude</Typography></TableCell>
                                                                    <TableCell border='1px solid lightgray' ><Typography fontSize={fs()}>{centerContract.lng??""}</Typography></TableCell>                                                
                                                                </TableRow>
                                                                {(!inputs.sheqsyUId == null || !inputs.sheqsyUId =='') &&
                                                                    <TableRow >                                        
                                                                        <TableCell border='1px solid lightgray'><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Technician SID</Typography></TableCell>
                                                                        <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.sheqsyUId }</Typography></TableCell>                                                
                                                                    
                                                                    </TableRow>
                                                                }
                                                                {(!inputs.sheqsyUId == null || !inputs.sheqsyUId =='') &&
                                                                    <TableRow >                                                
                                                                        <TableCell border='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600} fontSize={fs()}>Technician Name</Typography></TableCell>
                                                                        <TableCell border='1px solid lightgray'><Typography fontSize={fs()}>{inputs.supplierContactPerson??''}</Typography> </TableCell>                                                
                                                                    </TableRow>


                                                                }

                                                                {/* <TableRow border='1px solid lightgray'>   
                                                                    <TableCell ></TableCell>
                                                                    <TableCell >
                                                                    
                                                                                <Button
                                                                                    variant="extended"                                                     
                                                                                    size="small" 
                                                                                    //border='10px solid gray'
                                                                                    borderRadius= '5px'
                                                                                    sx={{                                                                   
                                                                                        fontSize:fs(), 
                                                                                        fontWeight:600,
                                                                                        width:'45ch', 
                                                                                        height:'5ch', 
                                                                                        ///right:'230%', 
                                                                                        color:"steelblue",
                                                                                        background:'lightsteelblue',
                                                                                        zIndex:0,
                                                                                        boxShadow:'1px 1px 3px 2px steelblue',  
                                                                                        //fontFamily:'Ariel'                                              
                                                                                    }}
                                                                                    onClick={changeContractor}                                                
                                                                                > <Typography fontWeight={600}>Change Service Provider</Typography></Button>   
                                                                
                                                                    </TableCell>                                                
                                                                        
                                                                </TableRow> */}
                                                            </TableBody >  
                                                        </Table>                                                  
                                                    </Stack>

                                                    <Stack name='DIVIDER'
                                                        className='divider'                                             
                                                        display={{xs:'none', md:'flex'}}
                                                        padding={'2px'}
                                                        onMouseMove={(e)=>console.log(e.buttons==1 ? e: "blah")}
                                                        sx={{
                                                            '&:hover': {
                                                                cursor:'ew-resize'
                                                            },
                                                        }}
                                                    > </Stack>
                                                                                                                
                                                    <Stack name='GOOGLE MAP' 
                                                        sx={{
                                                            boxShadow:'1px 1px 3px gray',
                                                            width: {xs: '45ch', sm:'55ch', md:'67ch'}, 
                                                            height:{xs:'40ch', sm:'50ch', md:'60ch'},  

                                                        }} 
                                                    >

                                                        {isLoaded && 
                                                            <GoogleMap 
                                                                center = {centerContract}//{centerSite}
                                                                zoom={13} 
                                                                mapContainerStyle = {{width:'100%', height:'100%'}}
                                                                onUnmount={onUnmount}
                                                                ///onLoad={(map)=>{setMap(map)}}
                                                                options={{ 
                                                                    streetViewControl:false, 
                                                                    fullscreenControl: false, 
                                                                    keyboardShortcuts:false, 
                                                                    zoomControl:true,
                                                                    mapTypeControl: false,
                                                                    disableDoubleClickZoom:true, 
                                                                    gestureHandling:'none'
                                                                }}
                                                            >
                                                                <MarkerF
                                                                    position={centerContract} 
                                                            
                                                                    icon={{
                                                                        path:"M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                                                                        fillColor: "yellow",
                                                                        fillOpacity: 0.9,
                                                                        scale: 1.3,
                                                                        strokeColor: "red",
                                                                        strokeWeight: 1,
                                                                    }}
                                                                    animation={ aa ? google.maps.Animation.DROP : google.maps.Animation.BOUNCE}
                                                                    onClick={(e)=>setAa(!aa) }
                                                                >
                                                                    
                                                                    <InfoWindowF  position={centerContract}>
                                                                        <div style={{ backgroundColor: 'yellow', border: `1px solid gray`, padding:4}}>
                                                                            <Typography style={{color:'#2d187be0', fontSize:fs(.6, .7, 1), fontWeight:600}}> {inputs.supplier ??''}  </Typography>
                                                                        </div>
                                                                    </InfoWindowF>

                                                                </MarkerF>
                                                            </GoogleMap>
                                                        }
                                                    
                                                    </Stack> 
                                                </>
        
                                            :        
                                                            /////// ACTION ADD NEW 
                                                <>
                                                    <Stack sx = {{ gap:1, boxShadow:'1px 1px 3px gray', width:{xs: '50ch', md:'68ch'}}}>  

                                                        <Stack direction="column" sx = {{ p:1, gap:2, boxShadow:'1px 1px 3px gray',  }} >                    
                                                            <FormControl > 

                                                                <Autocomplete
                                                                    required
                                                                    defaultValue={'Australia'}
                                                                    autoComplete
                                                                    name='supplierCountry'
                                                                    options={['Australia','New Zealand']}
                                                                    size='small'
                                                                    //filterOptions={filterOptions}
                                                                    onOpen={()=>{
                                                                        setInputs(values=>({...values, ['supplierCountry']: 'Australia'}))
                                                                        DEBUG && console.log('c')            
                                                                    }}
                                                                    onChange={(event, newValue, c) => { setInputs(values=>({...values, ['supplierCountry']: newValue})) }  }                                                                
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Countries"
                                                                            inputProps = {{...params.inputProps, type: 'search', }}    
                                                                        />
                                                                    )}
                                                                />            
                                                            </FormControl>

                                                            <FormControl > 
                                                                <Autocomplete
                                                                    required
                                                                    name='supplierState'
                                                                    options={inputs['supplierCountry'] ==='New Zealand'?['Auckland','Mount Ruapehu','Nelson','North Island','South Island','New Zealand']:['VIC','NSW','QLD','WA','SA','NT','TAS','ACT']}
                                                                    size='small'
                                                                    //filterOptions={filterOptions}
                                                                    onChange={(event, newState) => { 
                                                                            setInputs(values=>({...values, ['supplierState']: newState})) 
                                                                            getAllContractors(newState)
                                                                        }  
                                                                    }                                                                
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="States"
                                                                            inputProps = {{...params.inputProps, type: 'search', }}    
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>            

                                                            <FormControl > 
                                                                <Autocomplete
                                                                    required 
                                                                    isOptionEqualToValue={(option, value) => option.value === value.value}     //filterOptions={filterOptions}
                                                                    // name='siteId'
                                                                    size='small'
                                                                    autoHighlight  //loading = {true}
                                                                    options={allContractors }
                                                                    fontFamily={'Arial'}
                                                                    onChange={(event, newValue) => {
                                                                        if (newValue == null){
                                                                            setShowC(false)
                                                                            setCenterContract(defaultCenter)
                                                                        }else{

                                                                            //contractorData.map((c)=>c)//siteSelected.siteName.map((val, index)=>val== newValue && setAllSitesData(siteSelected, index ));     
                                                                            //{console.log(newValue)} //siteSelected.siteName.map((val, index)=>val === newValue &&  setInputs(values=>({...values, ['siteId']:siteSelected.siteId[index] })));  
                                                                            
                                                                            allContractorsData.map((a)=>{
                                                                                if(a.supplier === newValue){                                                                                        
                                                                                        setCenterContract(a.position !== null ? {lat: a.position.y, lng: a.position.x} : defaultCenter)
                                                                                        setOrigin(`${a.supplierPostalAddress}, ${a.supplierSuburb}, ${a.supplierState}, ${a.supplierPostCode}`)
                                                                                        console.log(a.sheqsyUID)
                                                                                        console.log(a.sheqsyUId)
                                                                                        setSelectedContractor( {  
                                                                                            contractor: a.supplier, 
                                                                                            contractorContact :a.supplierContactPerson,
                                                                                            contractorPostalAddress:a.supplierPostalAddress,
                                                                                            contractorEmail:a.supplierEmail,
                                                                                            contractorState:a.supplierState,
                                                                                            contractorSuburb:a.supplierSuburb,
                                                                                            contractorPostCode:a.supplierPostCode,
                                                                                            contractorPhone:a.supplierPhone,
                                                                                            position:a.position,
                                                                                            contractorSheqsyId: a.sheqsyUId
                                                                                        } )
                                                                                        
                                                                                        setInputs(values=>({...values, ['siteSupplierId']: a.supplierId}))
                                                                                        setInputs(values=>({...values, ['sheqsyUID']: a.sheqsyUId}))
                                                                                        setInputs(values=>({...values, ['supplierEmail']: a.supplierEmail}))
                                                                                        setInputs(values=>({...values, ['supplierState']: a.supplierState}))
                                                                                        //setSiteSupplId(a.supplierId)
                                                                                        //DEBUG && console.log( inputs.newContractor )
                                                                                        inputs.newContractor && setPostNote(b =>({...b,  nContractor: `${a.supplier}`}))
                                                                                    }
                                                                                }
                                                                            )
                                                                            setShowC(true) 
                                                                        }  }
                                                                    }                                    
                                                                    renderInput={(params) => (
                                                                        <TextField  {...params}
                                                                            label="Service Providers" //{action ==='update'? clientNameFromUpdate : "Clients List"}
                                                                            sx={{fontFamily:'Arial'}}
                                                                            inputProps = {{...params.inputProps, type: 'search', }}           
                                                                        />
                                                                    ) }
                                                                />
                                                            </FormControl>        
                                                        </Stack> 
                                                                
                                                        {showC &&                                                            
                                                            <Stack sx={{boxShadow:'1px 1px 3px gray',  }}  >     
                                                                <Table
                                                                    // caption= { <Stack paddingY={2} paddingX={5}>// <Typography color='steelblue' fontSize={fs()} fontWeight={600}>{selectedContractor.contractor}</Typography>//             </Stack> }
                                                                    size="small"
                                                                    highlightOnHover={true}
                                                                    variation="striped"
                                                                    backgroundColor={'antiquewhite'}                        
                                                                >    
                                                                    <TableBody  >   
                                                                        <TableRow>                                                
                                                                            <TableCell border ='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600}  fontSize={fs()}>Name</Typography></TableCell>
                                                                            <TableCell border ='1px solid lightgray' ><Typography fontWeight={800} color={'red'} fontSize={fs()}> {selectedContractor?.contractor}</Typography></TableCell>                                                
                                                                        </TableRow>                                                                                                                           
                            
                                                                        <TableRow>                                                
                                                                            <TableCell border ='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600}  fontSize={fs()}>Address</Typography></TableCell>
                                                                            <TableCell border ='1px solid lightgray' ><Typography fontSize={fs()} > {selectedContractor.contractorPostalAddress}</Typography></TableCell>                                                
                                                                        </TableRow>

                                                                        <TableRow >                                                
                                                                            <TableCell border ='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600}  fontSize={fs()}>Phone </Typography></TableCell>
                                                                            <TableCell border ='1px solid lightgray'><Typography fontSize={fs()}> {selectedContractor.contractorPhone ?? '0'}  </Typography></TableCell>                                                
                                                                        </TableRow>
                                                                        <TableRow >                                                
                                                                            <TableCell border ='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600}  fontSize={fs()}>Email</Typography></TableCell>
                                                                            <TableCell border ='1px solid lightgray'><Typography fontSize={fs()}>{selectedContractor?.contractorEmail ??''}</Typography></TableCell>                                                
                                                                        </TableRow>
                                                                        <TableRow >                                                
                                                                            <TableCell border ='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600}  fontSize={fs()}>Latitude</Typography></TableCell>
                                                                            <TableCell border ='1px solid lightgray'><Typography fontSize={fs()}>{centerContract?.lat ?? ''}</Typography></TableCell>                                            
                                                                        </TableRow>
                                                                        <TableRow >                                                
                                                                            <TableCell border ='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600}  fontSize={fs()}>Longitude</Typography></TableCell>
                                                                            <TableCell border ='1px solid lightgray'><Typography fontSize={fs()}>{centerContract.lng ?? ''}</Typography></TableCell>                                                
                                                                        </TableRow>
                                                                        
                                                                        <TableRow >                                        
                                                                            <TableCell border ='1px solid lightgray'><Typography color='2d187be0' fontWeight={600}  fontSize={fs()}>Sheqsy ID</Typography></TableCell>
                                                                            <TableCell border ='1px solid lightgray'><Typography fontSize={fs()}>{inputs?.sheqsyUID ?? '' }</Typography>   </TableCell>                                                                                                                        
                                                                        </TableRow>                                                                                                                                    
                                                                        <TableRow >                                                
                                                                            <TableCell border ='1px solid lightgray' ><Typography color='2d187be0' fontWeight={600}  fontSize={fs()}>Technician Name</Typography></TableCell>
                                                                            <TableCell border ='1px solid lightgray' ><Typography fontSize={fs()}>{selectedContractor?.contractorContact ?? ''} </Typography></TableCell>                                                
                                                                        </TableRow>
                                                                        
                                                                    </TableBody >  
                                                                </Table>  
                                                            </Stack>
                                                        } 
                                                    </Stack>

                                                    {showC &&
                                                        <Stack name='GOOGLE MAP' sx={{boxShadow:'1px 1px 5px gray', height:{xs: '40ch', sm:'63ch'}, width:{xs: '50ch',  md:'68ch'} }} >
                                                            <Box height={'100%'} width={'100%'} >
                                                                {isLoaded && 
                                                                    <GoogleMap 
                                                                        center = {centerContract}//{centerSite}
                                                                        zoom={10} 
                                                                        mapContainerStyle = {{width:'100%', height:'100%'}}
                                                                        onUnmount={onUnmount}
                                                                        ///onLoad={(map)=>{setMap(map)}}
                                                                        options={{ 
                                                                            streetViewControl:false, 
                                                                            fullscreenControl: false, 
                                                                            keyboardShortcuts:false, 
                                                                            zoomControl:true,
                                                                            mapTypeControl: false,
                                                                            disableDoubleClickZoom:true, 
                                                                            gestureHandling:'none'
                                                                        }}
                                                                    >
                                                                        <MarkerF
                                                                            position={centerContract} 
                                                                            icon={{path:"M 3 3 h 8 v 8 h -8 Z", fillColor: "yellow", fillOpacity: 0.9, strokeColor:"blue", strokeWeight: 5, scale:2.2 }} 
                                                                            animation={ aa?google.maps.Animation.DROP:google.maps.Animation.BOUNCE}
                                                                            onClick={(e)=>setAa(!aa) }
                                                                        
                                                                        />

                                                                        <InfoWindowF  position={centerContract}>
                                                                            <div style={{ backgroundColor: 'yellow', border: `.1px solid gray`, padding:4}}>
                                                                                <Typography fontSize= {fs(.6, .7, 1)} fontWeight={400}>{selectedContractor.contractor ??''}</Typography>
                                                                            </div>
                                                                        </InfoWindowF>
                                                                    </GoogleMap>
                                                                }
                                                            </Box>
                                                        </Stack> 
                                                    }
        
                                                </>
                                            }       

                                        </Stack> 
            
                                    </TabItem>
                                }
                            {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  NAVIGATION  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>mm */}
                                                    
                                    <TabItem  
                                        className='tab' 
                                        title={ <Stack sx={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} >Navigate</Stack>    }                           // backgroundColor={tabValue == 4 ?'aquamarine':'aliceblue'}//
                                        border={'1px solid gray'}
                                        borderRadius='5px'                   //onLoad={()=>console.log('on LOAAAAAD')} //onClick={()=>handleNewTabClick(1)}  
                                        onClick={clearRouts}
                                    >
                                        <Stack 
                                            direction="row" 
                                            sx = {{
                                                width:{xs: '47ch', sm:'57ch', md:'136ch'},                   
                                                height:{xs:'42ch', sm:'52ch', md:'60ch'}, 
                                                boxShadow:'1px 1px 3px gray', 
                                                paddingY:.5
                                            }} 
                                        >  

                                            <Stack height={'100%'} width={'100%'} >                                                {/* <Box position={'absolute'} height={'93%'} width={'100%'} border='1px solid blue'>      */}
            
                                                    {destination !=='' && isLoaded && tracker !==500  ?
                                                        <>
                                                            <GoogleMap 
                                                                center = {centerSite}
                                                                mapContainerStyle = {{width:'100%', height:'100%'}}
                                                                onUnmount={onUnmount}
                                                                onLoad={(map)=>{                                                    
                                                                    setMap(map)
                                                                    calculateRoute()
                                                                    map.panTo(centerSite)
                                                                    map.setZoom(15)
                                                                }}
                                                            >                                                                                                            
            
                                                                    <Fab 
                                                                        size="small" 
                                                                        sx={{ mt:1, left:'80%'}}
                                                                        color="warning"
                                                                        onClick={ centerTOcenter }
                                                                    > <CenterFocusWeakRoundedIcon/>     
                                                                    </Fab>
            
                                                                    <Fab 
                                                                        variant="extended" 
                                                                        size="small" 
                                                                        color="primary"
                                                                        sx={{mb:'0px', fontSize:12,  height:'22px', left:'81%'}}
                                                                        onClick={ calculateRoute }
                                                                    > recalculate route   
                                                                    </Fab>                                                                        {/* contractor place  marker */}
            
                                                                    <MarkerF position={centerSite}
                                                                        icon={{
                                                                            path:"M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                                                                            fillColor: "yellow",
                                                                            fillOpacity: 0.9,
                                                                            scale: 1.3,
                                                                            strokeColor: "red",
                                                                            strokeWeight: 3,
                                                                        }}
                                                                        animation={ aa ? google.maps.Animation.DROP : google.maps.Animation.BOUNCE}
                                                                        onClick={(e)=>setAa(!aa) }
                                                                    >
                                                                        <InfoWindowF position={centerSite} >
                                                                            <Stack border='1px solid lightgray' padding={'3px'} fontFamily={'Arial'} backgroundColor='pink' fontSize={fs(.6, .7, 1)} >{siteLabel}</Stack>
                                                                        </InfoWindowF>   
                                                                    </MarkerF>                                                                            

                                                                    { (tracker !== 500 && tracker !== undefined ) && 
                                                                        tracker?.map((t, i)=> {
                                                                            //    {console.log( inputs.sheqsyUId, selectedContractor.contractorSheqsyId )}  //    {console.log( t)} //    console.log(t.employee.employeeUId, t.employee.name + ' ' + t.employee.surname,  inputs.sheqsyUId)
                                                                            //    t.type=='Shift'? console.log(t.shift.currentLocation.latitude, t.shift.currentLocation.longitude) : console.log(t.activity.currentLocation.latitude, t.activity.currentLocation.longitude) //    console.log(t.employee.employeeUId, " ----- "+ inputs.sheqsyUId)
                                                                            ///{console.log(t.employee.name)}
                                                                            if(t.employee.employeeUId === inputs.sheqsyUId ){
                                                                                {DEBUG && console.log(t.employee.name.toUpperCase())}
                                                                                const Lat = t.type =='Shift'? t.shift.currentLocation.latitude: t.activity.currentLocation.latitude
                                                                                const Lng = t.type =='Shift'? t.shift.currentLocation.longitude: t.activity.currentLocation.longitude    //console.log(Lat, Lng)
                                                                                centerEmployee.current = (Lat == null ? defaultCenter : {lat: Lat, lng: Lng})
                                                                                techAddress.current= t.type =='Shift'? t.shift.currentLocation: t.activity.currentLocation           // {console.log( t.activity)}  //'Fiveways Boulevard, Keysborough, VIC 3173'/   // {console.log( techAddress.current)} 
                                                                                
                                                                                return (                                                                                                                                                                                                     
                                                                                        <MarkerF 
                                                                                            position={centerEmployee.current} //{lat: parseFloat(t.activity.currentLocation.latitude),  lng: parseFloat(t.activity.currentLocation.longitude)}}
                                                                                            label= {t.employee.name + " " + t.employee.surname  }
                                                                                            cursor= 'pointer'
                                                                                            //icon={{path:"M 3 3 h 8 v 8 h -8 Z", fillColor: "yellow", fillOpacity: 0.7, strokeColor:"green", strokeWeight: 5, scale:2.2 }} 
                                                                                            icon=                                                                       
                                                                                            {{ 
                                                                                                path: 'M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z',
                                                                                                fillColor: "lightgreen", 
                                                                                                fillOpacity: 0.9, 
                                                                                                strokeColor:"red", 
                                                                                                strokeWeight: 3, 
                                                                                                scale:0.9
                                                                                            }}                                                          // options={{zIndex: 10,  }}
                                                                                            title= {`${t.employee.name.trim()} ${t.employee?.surname} - active since ${dateConverter(new Date(t.shift.startDateTime.dateTimeUTC), false)}`}                                                            
                                                                                            animation = { aa?google.maps.Animation.DROP:google.maps.Animation.BOUNCE}
                                                                                            onClick = {(e) => setAa(!aa) }
                                                                                        > 
                                                                                            <InfoWindowF position={centerEmployee.current} >
                                                                                                <div style={{ backgroundColor: 'yellow', border: `.1px solid gray`, padding:4 }} >
                                                                                                    {<Typography fontWeight={600} fontSize={fs(.6, .7, 1)}>{`${t.employee.name} ${t.employee.surname}`}</Typography> }
                                                                                                        {Lat == null && ` GEO LOCATION IS OFF  ` } 
                                                                                                    {/* {<img src={image}/>} */}
                                                                                                </div>
                                                                                            </InfoWindowF>
                                                                                        
                                                                                        
                                                                                        </MarkerF>           
                                                                                                                
                                                                                    
                                                                                )
                                                                                
                                                                            }
                                                                
                                                                        })

                                                                    }
                                                                    { techAddress.current==='' &&                                                                        
                                                                        <MarkerF 
                                                                            position={centerContract}
                                                                            icon={{ 
                                                                                path:"M 1 1 h 8 v 8 h -8 Z", 
                                                                                fillColor: "yellow", 
                                                                                fillOpacity: 0.7, 
                                                                                strokeColor:"brown", 
                                                                                strokeWeight: 4, 
                                                                                scale:2
                                                                            }} 
                                                                            animation={ aa?google.maps.Animation.DROP:google.maps.Animation.BOUNCE}
                                                                            onClick={(e)=>setAa(!aa) }
                                                                        > 

                                                                            <InfoWindowF position={centerContract}  >
                                                                                <Stack border='1px solid lightgray' padding={'3px'} fontFamily={'Arial'} backgroundColor='yellow' fontSize={fs(.6, .7, 1)}>
                                                                                    {action==='update'?inputs.supplier:selectedContractor.contractor }
                                                                                </Stack>
                                                                            </InfoWindowF> 
                                                                        </MarkerF >                                                            
                                                                    }
            
                                                                    {directionResponse !== null ? <DirectionsRenderer directions={directionResponse}/> : google.maps.LatLng(null)}                                                                                                                     
                                                            </GoogleMap>

                                                            <Stack direction = {{xs:'column', md:"row"}} gap={{xs:1, md:7}} color='red' justifyContent='center' alignItems='center'  border='1px solid lightgray'>  
                                                                <Typography 
                                                                    className='onefive'
                                                                    fontSize={14} 
                                                                    fontWeight={800}                                                             
        
                                                                >{distance !=='' && `Travel distance by car: ${distance}`}</Typography>
            
                                                                <Typography                                                             
                                                                    className='onefive'
                                                                    fontSize={14} 
                                                                    fontWeight={800}
                                                                        
                                                                > {duration !=="" && `Estimated travel time: ${duration}`}</Typography>    
                                                            </Stack>
                                                            
                                                        </>                                                        
                                                    :
                                                        <Stack position='relative' height={'6ch'} width={'6ch'} sx={{ml:75, mt:40}} > 
                                                            <Loader size="small" emptyColor={'gray'} filledColor={'red'} style={{width: 100}}/>
                                                        </Stack>        
                                                    }
                
                                            
                                            </Stack>    

                                        </Stack> 
                                    </TabItem>

                                {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  NOTES >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            
                                {action==='update' && 
                                    <TabItem    
                                        className='tab' 
                                        title={<Stack sx={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} >Notes</Stack>}                                                                                  // backgroundColor={5 ===tabValue ?'aquamarine':'aliceblue'}//                                        
                                        border={'1px solid gray'}//borderSize( 5, tabValue)}
                                        borderRadius='5px'             //onClick={()=>handleNewTabClick()} 
                                        onClick={getNotes}             // onConTypographyMenu={(e) => { //     e.preventDefault(); //     DEBUG && console.log("Right Click"); // }}     
                                                              
                                    >
                                                                               
                                        <Stack                                             
                                            direction={{ sm: "column", md: "row" }}    //direction="column" sx = {{ width:{sm:'63ch', md:'135ch'}, height:'65ch',  background:'lightsteelblue'}} gap={.2}                    
                                            sx = {{p:.5, gap:.5,}}                                               
                                        >  

                                            <Stack sx={{ boxShadow:'1px 1px 4px gray', width: {xs:'45ch', sm:'55ch', md:'67ch'}, height:{xs:'40ch', sm:'50ch', md:'60ch'},}} >     

                                                <TableContainer>
                                                    <Table size="small" highlightOnHover={true} variation="striped" backgroundColor={'antiquewhite'} >
                                                        <TableHead >
                                                            <TableRow >
                                                                <TableCell border='1px solid gray' as="th" > <Stack><Typography  fontSize={fs()} fontWeight={600}>Date Added On</Typography></Stack> </TableCell>
                                                                <TableCell border='1px solid gray' as="th" ><Stack><Typography fontSize={fs()} fontWeight={600}>Created By</Typography></Stack></TableCell>
                                                                <TableCell border='1px solid gray' as="th"> <Stack><Typography fontSize={fs()} fontWeight={600}>Description</Typography></Stack></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                
                                                        <TableBody>
                                                            {notes.map((n, i)=>
                                                                <>
                                                                    {/* {console.log(n.noteDetails)} */}
                                                                                                                            
                                                                    <TableRow key={i}>
                                                                        <TableCell style={{fontFamily:'Arial, Helvetica, sans-serif', fontSize:fs(), border:'1px solid gray'}}> {dateConverter(n.noteDate)} </TableCell>
                                                                        <TableCell style={{fontFamily:' Arial, Helvetica, sans-serif', fontSize:fs(), border:'1px solid gray'}}> {n.userFullName} </TableCell>
                                                                        <TableCell style={{fontFamily:'Arial',  border:'1px solid gray'}}>
                                                                            <ReactQuill 
                                                                                theme="bubble"              //id="faultDescription" //ref={inputRef.current}
                                                                                readOnly                 //fontFamily={'Arial'}    
                                                                                className='ql-line'
                                                                                // style={{ 
                                                                                //     // backgroundColor:'papayawhip', //     // border:'1px solid red', //     
                                                                                //      fontFamily:'Arial', //     fontSize:fs(),  fontWeight:400, 
                                                                                //     }}                                                                                
                                                                                
                                                                                //value={n.noteDetails}      //placeholder ="Fault Description..."    font-weight: bold;  <span  class="ql-size-small"     
                                                                                value={`<div><span style="font-weight: bold; font-family: Arial, Helvetica, sans-serif;">${n.noteDetails}</span></div>`}                                                                                                                                                                                                                          
                                                                            />                                                                                                                                                                           
                                                                        </TableCell>                
                                                                    </TableRow>
                                                                </>                                                                                                                                 
                                                            ) }                                                                            
                                                        </TableBody>
                                                    </Table>      
                                                </TableContainer>                                             
                                            </Stack>     

                                            <Stack name='DIVIDER' 
                                                className='divider' display={{xs:'none', md:'flex'}} padding={'2px'} 
                                                //onMouseMove={(e)=>console.log(e.buttons==1 ? e: "blah")} 
                                                sx={{'&:hover': {cursor:'ew-resize'},}}>
                                            </Stack>                                        
                                            
                                            <Stack  sx={{boxShadow:'1px 1px 3px gray', width: {xs: '45ch', sm:'55ch', md:'67ch'}, height:{xs:'40ch', sm:'50ch', md:'60ch'},}}>     
                                                <ReactQuill 
                                                    theme="snow"
                                                    id="faultDescription"                                                //ref={inputRef.current}                                                    
                                                    className ='faultDescription'
                                                    style={{backgroundColor:'papayawhip',  fontSize:fs()}}
                                                    placeholder="Add Notes..."
                                                    value={newNote}                                                                                                                    
                                                    modules={modules}    //     {// 'history': {'delay': 2500,'userOnly': true}, //     'toolbar': toolbarOptionsForNotes, // }}                                                            
                                                    onChange={(content, delta, source, editor)=>{  
                                                        
                                                        // console.log('ONCHANGE : ', delta.ops[1]?.insert);// console.log('ONCHANGE : ', delta.ops);// console.log('ONCHANGE : ', editor.getText()) //.getContents().ops[0].insert);                                                             
                                    
                                                        setNewNote(content) ///editor.getText()) //() getContents().ops[0].insert) // the same as editor.getText() // editor.getHTML() the same as content // source='user'
                                                    }}      

                                                    //onPaste={(e) => console.log("ON PASTE :", e.currentTarget.value)}  
                                                    onBlur={( content, delta, source, editor )=> {

                                                        // console.log('onBlur getText', source.getText()); 
                                                        // console.log('onBlur getText', source); 
                                                        // console.log('onBlur delta', delta); 
                                                        // console.log('onBlur content ', content); 
                                                        // console.log('onBlur content.index ', content.index); 
                                                        // console.log('onBlur editor', editor);

                                                        if(content.index > 0){setIsOpen(true)}
                                                    }}
                                                />                                                                                        
                                            
                                                <Button 
                                                    className='ButtonNote' 
                                                    sx={{color: "white", fontSize:fs(), backgroundColor:'MenuText',}}  //marginBottom:'.2ch', //     // height:'5ch', //     ///right:'230%', 
                                                    onClick={postNewNotes}  // onMouseOver={()=>setButtonColor(true)}  // onMouseOut={()=>setButtonColor(false)}                                      
                                                > Add Note</Button>                                                                                               
                                            </Stack>  

                                        </Stack>


                                        {/* <JustAlert 
                                            isOpen={isOpen} 
                                            openOff={setIsOpen}  
                                            title={'Notes'} 
                                            dialogueTxt={<DialogContentText>The Note you have entered has not been commited yet!<br/>Please click the <strong>Add Note</strong> Button to commit the Note</DialogContentText>}
                                        /> */}
    
                                    </TabItem>
                                }:
            
                                {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< JOB'S PARTS MANAGMENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            
                                {action==='update' &&     
                                    <TabItem   
                                        className='tab'  
                                        title={ <Stack sx={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}}>Parts</Stack> }                // backgroundColor={6 ===tabValue ?'aquamarine':'aliceblue'}
                                        border={'1px solid gray'}
                                        borderRadius='5px'
                                        onClick={getOrderedParts} 
                                    >

                                        <Stack sx = {{  height:'65ch', }}>
                                            <Tabs 
                                                justifyContent='normal'   
                                                //justifyContent='space-evenly'        
                                                isLazy                                 
                                                //spacing='relative' //marginLeft={10} //border={'1px solid black'} //height={'4.4ch'}
                                                //onChange={(v)=>handleTaPartbClick(parseInt(v))}
                                            >
                                                
                                                    {/* ////////////////      PARTS ORDERED ////////////////////// */}                                        
                                                        <TabItem    
                                                            title={<Typography style={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} >Ordered </Typography> }//backgroundColor={0 === tabPartValue ? 'aquamarine':'lightblue'}
                                                            border={'1px solid blue'}
                                                            borderRadius='5px'
                                                            //width='15ch'
                                                            onClick={getOrderedParts} 
                                                        >    
                                                                    
                                                            <TableContainer 
                                                                sx={{                                                                     
                                                                    boxShadow:'1px 1px 4px gray', 
                                                                    width: {xs:'45ch', sm:'55ch', md:'136ch'},                        
                                                                    height:{xs:'52ch', md:'55ch'},
                                                                }}  
                                                                onContextMenu={(e) => { e.preventDefault() }} 
                                                            > 
                                                                <Table                 
                                                                    size="small"
                                                                    highlightOnHover={true}
                                                                    variation="striped"
                                                                    backgroundColor={'antiquewhite'}                                                
                                                                >
                                                                    <TableHead>                                                                    
                                                                        <TableRow >
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}>Date</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}>Name</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}>Qty</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}>Serial#</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}>Asset# </Typography></TableCell>                                                                            {/* <TableCell border='1px solid black' as="th"  width='8ch'><Typography paddingX = '1ch' fontSize={fs()} fontWeight={600}>Manufacturer</Typography></TableCell> */}
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}></Typography></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>

                                                                    <TableBody>
                                                                        <TableRow>   
                                                                            
                                                                            <TableCell style={{ border:'1px solid gray'}}>
                                                                                <Typography fontSize={fs()} border='1px solid gray'>{
                                                                                    (newOrderedPart?.partsOrderedDevice !== 'Select Part ...' && !!newOrderedPart?.partsOrderedDevice) && dateConverter(Math.trunc(Date.now()/1000))
                                                                                }</Typography>
                                                                            </TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}>                                                                                                                                           
                                                                                <SelectField                                                                                                                                             
                                                                                    options={['Select Part ...', ...partsNames.map( p=> p.jobPartsDescription) ]}
                                                                                    labelHidden={true}
                                                                                    size={'small'}
                                                                                    value={newOrderedPart?.partsOrderedDevice  }                
                                                                                    icon={<IconArrowDropDown />}
                                                                                    iconColor="red"
                                                                                    onChange={(e) => {
                                                                                        partsNames.map((p)=> {           
                                                                                            if(e.target.value == 'Select Part ...'){
                                                                                                setNewOrderedPart(null)
                                                                                            } else if(e.target.value == p.jobPartsDescription){
                                                                                                setNewOrderedPart(values=>({...values,                                                                                                             
                                                                                                        ['jobNo']: parseInt(id), 
                                                                                                        ['partsOrderedId']: parseInt(p.partsId),
                                                                                                        ['partsOrderedTechnicianId']: userId,
                                                                                                        ['partsOrderedDevice']: e.target.value,
                                                                                                        ['partsOrderedDeviceModel']: p.jobPartNumber,
                                                                                                        ['partsOrderedAssetNumber']: p.jobPartsAsset == null ?'':  p.jobPartsAsset,
                                                                                                        ['partsOrderedOrderDate']: Math.trunc(Date.now()/1000)
                                                                                                    
                                                                                                }))                                                                                                // setNewOrderedPart(values=>({...values, ['jobNo']: parseInt(id)})) //partsOrderedId        // setNewOrderedPart(values=>({...values, ['partsOrderedId']: parseInt(p.partsId)}))                                                                                                // setNewOrderedPart(values=>({...values, ['partsOrderedTechnicianId']: userId}))// setNewOrderedPart(values=>({...values, ['partsOrderedDevice']: e.target.value}))                                                                                                // setNewOrderedPart(values=>({...values, ['partsOrderedDeviceModel']: p.jobPartNumber}))                                                                                                // setNewOrderedPart(values=>({...values, ['partsOrderedAssetNumber']: p.jobPartsAsset == null ?'':  p.jobPartsAsset }))                                                                                                // //setNewOrderedPart(values=>({...values, ['partsOrderedDeviceManufacturer']: p.jobPartsManufacturer}))                                                                                                 // setNewOrderedPart(values=>({...values, ['partsOrderedOrderDate']: Math.trunc(Date.now()/1000)}))  //setInputs(values=>({...values, ['supplierState']: a.supplierState})) console.log(p.partsId, p.jobPartsType, p.jobPartsManufacturer)
                                                                                            }
                                                                                        }) 
                                                                                    }}
                                                                                ></SelectField>                                                                         
                                                                            </TableCell>

                                                                            <TableCell style={{ border:'1px solid gray'}}>
                                                                                <SelectField
                                                                                        labelHidden={true}
                                                                                        //isMultiple={true}
                                                                                        size={'small'}
                                                                                        value={newOrderedPart?.partsQty}
                                                                                        icon={<IconArrowDropDown />}                                
                                                                                        iconColor="red"
                                                                                        onChange={(e) => setNewOrderedPart(values=>({...values, ['partsQty']: parseInt(e.target.value)}))} 
                                                                                >
                                                                                    <option value="1">1</option>
                                                                                    <option value="2">2</option>
                                                                                    <option value="3">3</option>
                                                                                    <option value="4">4</option>
                                                                                    <option value="5">5</option>
                                                                                    <option value="6">6</option>
                                                                                    <option value="7">7</option>
                                                                                    <option value="8">8</option>
                                                                                    <option value="9">9</option>
                                                                                </SelectField>
                                                                            
                                                                            </TableCell>

                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>{newOrderedPart?.partsOrderedDeviceModel}</Typography></TableCell>

                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>{newOrderedPart?.partsOrderedAssetNumber}</Typography></TableCell>     
                                                                                                                                                   {/* <TableCell  border='1px solid lightgray' fontSize={fs()}>{newOrderedPart?.partsOrderedDeviceManufacturer}</TableCell>  */}
                                                                            <TableCell style={{ border:'1px solid gray'}}>                                                                            
                                                                                    {!!newOrderedPart?.partsOrderedDevice &&
                                                                                        <Button   //className='ButtonNote' !== '' && newOrderedPart?.partsOrderedDevice !== undefined   
                                                                                            size='small'                                                                                            //disabled={newOrderedPart.partsOrderedDevice == '' || newOrderedPart.partsOrderedDevice == undefined }                                         
                                                                                            sx={{ color: "red", background:'lightsteelblue', zIndex:0, boxShadow:'1px 1px 2px red'}}
                                                                                            onClick={postNewOrderedPart}        
                                                                                        > <Typography style={{fontSize: 'clamp(0.7rem, .75vw, 1.1rem)', fontWeight:800}}>Add</Typography></Button>} 
                                                                            
                                                                            </TableCell>                                                                                                                         

                                                                        </TableRow>  

                                                                        {orderedPatrs.map((n, i)=>
                                                                            <TableRow key={i}>                                                                        
                                                                                <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>{dateConverter(n.partsOrderedOrderDate )}</Typography></TableCell>
                                                                                <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>{n.partsOrderedDevice}</Typography></TableCell>
                                                                                <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>{n.partsQty}</Typography></TableCell>
                                                                                <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>{n.partsOrderedDeviceModel}</Typography></TableCell>
                                                                                <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>{n.partsOrderedAssetNumber == 'null' ?'': n.partsOrderedAssetNumber }</Typography></TableCell> {/* <TableCell fontSize={fs()}>{n.partsOrderedDeviceManufacturer}</TableCell>  */}
                                                                                <TableCell style={{ border:'1px solid gray'}}> <Button sx={{ color: "steelblue", background:'lightsteelblue', zIndex:0, boxShadow:'1px 0px 2px 1px steelblue'}}><Typography style={{fontSize: 'clamp(0.65rem, .6vw, .9rem)', fontWeight:600}}> Update </Typography></Button></TableCell>                                                                                                                          
                                                                            </TableRow>
                                                                        ) }

                                                                    </TableBody>
                                                                </Table>      
                                                            </TableContainer>    
                                                        </TabItem>

                                                    {/* ////////////////    PARTS INSTALLED ////////////////////// <Stack sx={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} >Details</Stack>*/}
                                                        <TabItem    
                                                            title={<Typography style={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} >Installed </Typography>}// backgroundColor={1 === tabPartValue ?'aquamarine':'lightblue'}
                                                            border={'1px solid green'}
                                                            borderRadius='5px' //width='25ch' onClick={getInstallParts} //width='15ch' 
                                                        >
                                                                                                                    
                                                            <TableContainer
                                                                sx={{boxShadow:'1px 1px 4px gray', width: {xs:'45ch', sm:'55ch', md:'136ch'}, height:{xs:'52ch',  md:'55ch'},}}   
                                                                onContextMenu={(e) => { e.preventDefault() }} 
                                                            > 
                                                                <Table                 
                                                                    size="small"
                                                                    highlightOnHover={true}
                                                                    variation="striped"
                                                                    backgroundColor={'antiquewhite'}                                                
                                                                >
                                                                    <TableHead>                                                                
                                                                        <TableRow >
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}> <Typography fontSize={fs()} fontWeight={600}>Date</Typography> </TableCell>                                                                    
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}> <Typography fontSize={fs()} fontWeight={600}>Part Name</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}> <Typography fontSize={fs()} fontWeight={600}>Quantity</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}> <Typography fontSize={fs()} fontWeight={600}>Loc Number</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}> <Typography fontSize={fs()} fontWeight={600}>Number</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}> <Typography fontSize={fs()} fontWeight={600}></Typography></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>

                                                                    <TableBody>

                                                                        <TableRow >
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>12/12/2024</Typography></TableCell>                                                                    
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>CABLE - 1.8m USB Cable for DS40 & RX1 Printer (Male A to Male B USB)</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>3</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>2221</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>44</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}>
                                                                                        <Button 
                                                                                            size='small'                                                                              
                                                                                            sx={{  background:'lightsteelblue', zIndex:0, boxShadow:'0 0 2px blue'}}
                                                                                            onClick={(e)=>console.log('test')
                                                                                            }        
                                                                                        > <Typography style={{fontSize: 'clamp(0.6rem, .7vw, 1rem)', fontWeight:800}}>Update</Typography></Button> 
                                                                                
                                                                                
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow >
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>03/10/2023</Typography></TableCell>                                                                    
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>CAMERA - Canon 1300D Kit (inc Charger, Battery & Lens)</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>1</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>2334</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>12</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}>
                                                                                
                                                                                <Button 
                                                                                    size='small'                                                                              
                                                                                    sx={{  background:'lightsteelblue', zIndex:0, boxShadow:'0 0 2px blue'}}
                                                                                    onClick={(e)=>console.log('test')
                                                                                    }        
                                                                                > <Typography style={{fontSize: 'clamp(0.6rem, .7vw, 1rem)', fontWeight:800}}>Update</Typography></Button> 
                                                                            </TableCell>
                                                                        </TableRow>


                                                                    </TableBody>

                                                                </Table>       
                                                            </TableContainer>   
                                                        </TabItem>

                                                    {/* ////////////////    PARTS REMOVED ////////////////////// */}
                                                        <TabItem    
                                                            title={<Typography style={{color:'#2d187be0',fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} >Removed </Typography>}// backgroundColor={2 ===tabPartValue ?'aquamarine':'lightblue'}
                                                            border={'1px solid red'}
                                                            borderRadius='5px'                                                            //width='15ch'                                                              
                                                            onClick={()=>console.log('Parts Removed')} 
                                                        >
                                                            <TableContainer 
                                                                sx={{                                                                
                                                                    boxShadow:'1px 1px 4px gray', 
                                                                    width: {xs:'45ch', sm:'55ch', md:'136ch'},                        
                                                                    height:{xs:'52ch',  md:'55ch'},
                                                                }}    
                                                                onContextMenu={(e) => { e.preventDefault() }} 
                                                            > 
                                                                <Table                 
                                                                    size="small"
                                                                    highlightOnHover={true}
                                                                    variation="striped"
                                                                    backgroundColor={'antiquewhite'}                                                
                                                                >
                                                                    <TableHead>                                                                
                                                                        <TableRow >
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}>Date</Typography></TableCell>                                                                    
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}>Part Name</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}>Quantity</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}>Loc Number</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}>Number</Typography></TableCell>
                                                                            <TableCell as="th" style={{ border:'1px solid gray'}}><Typography fontSize={fs()} fontWeight={600}></Typography></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>

                                                                    <TableBody>
                                                                        <TableRow >
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>06/11/2023</Typography></TableCell>                                                                    
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>Printer - DNP - RX-1</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>3</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>2221</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}><Typography fontSize={fs()}>444</Typography></TableCell>
                                                                            <TableCell style={{ border:'1px solid gray'}}>
                                                                                
                                                                            <Button 
                                                                                size='small'                                                                              
                                                                                sx={{  background:'lightsteelblue', zIndex:0, boxShadow:'0 0 2px blue'}}
                                                                                onClick={(e)=>console.log('test')}        
                                                                            > <Typography style={{fontSize: 'clamp(0.6rem, .7vw, 1rem)', fontWeight:800}}>Update</Typography></Button> 
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>

                                                                </Table>       
                                                            </TableContainer>  
                                                        </TabItem>
                                                
                                            </Tabs>                                    
                                        </Stack>

                                    </TabItem>
                                }   
            
                                {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  SIGN >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            
                                {action==='update' &&     
                                    <TabItem    
                                        className='tab' 
                                        title={ <Stack sx={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} >Signature</Stack> }                                          // backgroundColor={7 ===tabValue ?'aquamarine':'aliceblue'}//
                                         border={'1px solid gray'}
                                        borderRadius='5px'                                        //onClick={getOrderedParts} 
                                    >
                                        <Stack  name='MAIN STACK'                                                      
                                            direction={{ sm: "column", md: "row" }}                        
                                            sx = {{p:.5, gap:.5,}}                                                                                                    
                                        >   
                                                                                    
                                            <Stack name = 'first pain'                                                                            
                                                sx={{
                                                    boxShadow:'1px 1px 4px gray', 
                                                    width: {xs:'45ch', sm:'55ch', md:'67ch'},                        //ight:{ sm:'63ch'},     
                                                    p:{xs:10, md:0},
                                                    //justifyContent:'center' ,     
                                                    alignItems:'center',
                                                    '&:hover': {bgcolor: 'transparent',}
                                                    
                                                }} 
                                            > 
                                                <Canvas width={300} height={120} jpbNumber={inputs.jobNo}/>      
                                            </Stack> 

                                            <Stack name='DIVIDER' 
                                                className='divider'                                             
                                                display={{xs:'none', md:'flex'}}
                                                padding={'2px'}
                                                onMouseMove={(e)=>console.log(e.buttons==1 ? e: "blah")}
                                                sx={{ '&:hover': { cursor:'ew-resize' }}}
                                            > </Stack>
                                                                            
                                            <Stack name ='second pain' 
                                                sx={{
                                                    boxShadow:'1px 1px 3px gray',
                                                    width: {xs: '45ch', sm:'55ch', md:'67ch'}, 
                                                    height:{xs:'40ch', sm:'50ch', md:'60ch'},                                              
                                                }}                             
                                            >
                                            </Stack>



                                        </Stack>

                                    </TabItem>
                                }   

                                {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<   -------  PAYMENTS  -------------  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                                {action==='update' && (isSuperUser || role.trim().toUpperCase()==='ADMINISTRATION' || role.trim().toUpperCase()=='MANAGER' || role.trim().toUpperCase()=='CLIENT') && 
                                    <TabItem 
                                        className='tab'                                            
                                        title={<Stack sx={{color:'#2d187be0', fontWeight:600, fontSize:fs(), fontFamily:'Arial'}} >Payments</Stack>}// backgroundColor={8 ===tabValue ?'aquamarine':'aliceblue'}//
                                        border={'1px solid gray'}
                                        borderRadius='5px'                                        //onClick={()=>{ setInputs(values=>({...values, ['supplierCountry']: 'Australia'})) } }
                                    >
                                        <Stack  name='MAIN STACK'                                                      
                                            direction={{ sm: "column", md: "row" }}                        
                                            sx = {{p:.5, gap:.5,}}                                                                                                    
                                        >   
                                                                                    
                                            <Stack name = 'first pain'                                                                            
                                                sx={{
                                                    boxShadow:'1px 1px 4px gray', 
                                                    width: {xs:'45ch', sm:'55ch', md:'67ch'},                        //ight:{ sm:'63ch'},     
                                                    p:{xs:10, md:0},
                                                    justifyContent:'center' ,     
                                                    alignItems:'center',
                                                    '&:hover': {bgcolor: 'transparent',}
                                                    
                                                }} 
                                            > 

                                                <Button 
                                                    className='Button1'                                                                                                                                            
                                                    onClick={makePayment }
                                                    variant="outlined"                                                                                        
                                                ><Typography sx={{fontSize:'clamp(0.65rem, .6vw, 1rem)'}}>Payment</Typography> </Button>

                                            </Stack> 

                                            <Stack name='DIVIDER' 
                                                className='divider'                                             
                                                display={{xs:'none', md:'flex'}}
                                                padding={'2px'}
                                                onMouseMove={(e)=>console.log(e.buttons==1 ? e: "blah")}
                                                sx={{
                                                    '&:hover': {
                                                        cursor:'ew-resize'
                                                    },
                                                }}
                                            > </Stack>
                                                                            
                                            <Stack name ='second pain' 
                                                sx={{
                                                    boxShadow:'1px 1px 3px gray',
                                                    width: {xs: '45ch', sm:'55ch', md:'67ch'}, 
                                                    height:{xs:'40ch', sm:'50ch', md:'60ch'},                                              
                                                }}                             
                                            >
                                            </Stack>
                                        </Stack>
            
                                    </TabItem>
                                } 

                                                                    {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Job ID LABEL   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}  
                                                            {/* <TabItem                                         
                                                                    title={<Typography color='red' fontSize={fs(.7, .88, 1.1)} fontWeight={800}>{action==='update' ?`Job: ${id}`:'Adding New Job'}</Typography>}  
                                                                    isDisabled= {true}                                            //backgroundColor={7 ===tabValue ?'aquamarine':'aliceblue'}////border={borderSize(7, tabValue)}//borderRadius='5px'                                    
                                                                    
                                                            ></TabItem>                        */}
                            </Tabs>

                        </Stack> 

                        <Stack  name ='BUTTON BOX' 
                            direction={{sm: "column", md: "row"}}  //width={{xs:'55ch', sm:'90ch', md:'137ch'}}
                            gap={{xs:2, sm:4, md:6}}
                            alignItems='center'
                            justifyContent='center'
                            sx={{pb:1, pt:3,  }}                              
                        >                   
                            <Button 
                                className='Button1 tab'
                                onClick={action==='update'? handleUpdate: handleAdd }
                                variant="outlined"
                                disabled={action==='new' && (inputs.po_number?.trim().length < 4 || inputs.faultDescriptionForEmail?.trim().length < 4)}                                                                
                            ><Typography sx={{fontSize:fs()}}>{action==='update'? `Updating  ` : 'Add '} </Typography>  </Button> 

                            <Button 
                                className='Button1 tab'                                //sx={{ fontSize: 'clamp(0.65rem, .6vw, 1rem)'}}  'clamp(0.65rem, .6vw, 1rem)'
                                variant="outlined"   
                                onClick={()=>handleClose(true)} 
                            ><Typography sx={{fontSize:fs()}}>close</Typography>  </Button>                            
                        </Stack>
                        
                    </Stack>

                
                    <Snackbar 
                        open={stateOpen} 
                        autoHideDuration={6000} 
                        //TransitionComponent={'left'}
                        onClose={toggleDrawer(false)}
                    >
                        <Alert
                            onClose={toggleDrawer(false)}
                            //anchorOrigin={{vertical: 'top', horizontal: 'left' }}
                            severity="success"
                            variant="filled"
                            // sx={{ width: '100%' }}
                        >   <Typography>{updateText} </Typography></Alert>
                    </Snackbar>

                </>          
        
            )

        }catch(err){
            //Log(fl(3571,'NewJob.jsx - ERROOR'),err); 
            console.log(err); 
        }
    }

    return (  <ThemeProvider theme={theme} colorMode="light">
                {toReturn()}
            </ThemeProvider>
        )
}
export default NewJob;





















































        //CUSTOMER CREATED

        // {
        //     id: 'CU00180C05JN7F',
        //     created_at: '2024-08-08T02:16:59.058Z',
        //     email: 'user@example.com',
        //     given_name: 'Test',
        //     family_name: 'Osborne',
        //     company_name: null,
        //     address_line1: '27 Acer Road',
        //     address_line2: 'Apt 2',
        //     address_line3: null,
        //     city: 'Keysborough',
        //     region: 'VIC',
        //     postal_code: '3173',
        //     country_code: 'AU',
        //     language: 'en',
        //     phone_number: null,
        //     metadata: {},
        //     __response__: {
        //       headers: {
        //         date: 'Thu, 08 Aug 2024 02:16:59 GMT',
        //         'content-type': 'application/json',
        //         'transfer-encoding': 'chunked',
        //         connection: 'close',
        //         location: '/enterprise/customers/CU00180C05JN7F',
        //         pragma: 'no-cache',
        //         'cache-control': 'no-store',
        //         etag: 'W/"f35dcca91458859a306a92e11679c4f3"',
        //         'x-request-id': '23BF003BEDC4_0A1462F41F92_66B42A94_15E2B0001',
        //         'strict-transport-security': 'max-age=31556926; includeSubDomains; preload',
        //         vary: 'Origin,Accept-Encoding',
        //         'x-xss-protection': '1; mode=block',
        //         'x-content-type-options': 'nosniff',
        //         'ratelimit-limit': '1600',
        //         'ratelimit-remaining': '1599',
        //         'ratelimit-reset': 'Thu, 08 Aug 2024 02:17:00 GMT',
        //         'content-encoding': 'gzip',
        //         via: '1.1 google',
        //         'cf-cache-status': 'DYNAMIC',
        //         server: 'cloudflare',
        //         'cf-ray': '8afc01e7c8d829a2-MEL'
        //       },
        //       statusCode: 201,
        //       statusMessage: 'Created',
        //       url: 'https://api-sandbox.gocardless.com/customers'
        //     }
        //   }


    ////COOMPANY CUSTOMER  use the company name field company_name: 
    //   id: 'CU001834ARBEDP',

    //   created_at: '2024-08-11T23:45:06.884Z',
    //   email: 'user122@example.com',
    //   given_name: null,
    //   family_name: null,
    //   company_name: 'Test PTY LTD',
    //   address_line1: null,
    //   address_line2: null,
    //   address_line3: null,
    //   city: null,
    //   region: null,
    //   postal_code: null,
    //   country_code: 'AU',
    //   language: 'en',
    //   phone_number: null,


    /// BANK ACCOUNT for the created customer starts with BA

    // {
    //     id: 'BA0011YSF8D9FY',
    //     created_at: '2024-08-08T04:01:08.505Z',
    //     account_number_ending: '44',
    //     account_holder_name: 'TEST OSBORNE',
    //     account_type: null,
    //     bank_name: 'Commonwealth Bank of Australia',
    //     currency: 'AUD',
    //     country_code: 'AU',
    //     metadata: {},
    //     enabled: true,
    //     links: { customer: 'CU00180C05JN7F' },
    //     __response__: {
    //       headers: {
    //         date: 'Thu, 08 Aug 2024 04:01:08 GMT',
    //         'content-type': 'application/json',
    //         'transfer-encoding': 'chunked',
    //         connection: 'close',
    //         location: '/enterprise/customer_bank_accounts/BA0011YSF8D9FY',
    //         pragma: 'no-cache',
    //         'cache-control': 'no-store',
    //         etag: 'W/"5e801db4aa60ec2a8b4f5791923c0593"',
    //         'x-request-id': '82D30286E1EC_0A146FCD1F92_66B44304_1E2030001',
    //         'strict-transport-security': 'max-age=31556926; includeSubDomains; preload',
    //         vary: 'Origin,Accept-Encoding',
    //         'x-xss-protection': '1; mode=block',
    //         'x-content-type-options': 'nosniff',
    //         'ratelimit-limit': '1600',
    //         'ratelimit-remaining': '1599',
    //         'ratelimit-reset': 'Thu, 08 Aug 2024 04:02:00 GMT',
    //         'content-encoding': 'gzip',
    //         via: '1.1 google',
    //         'cf-cache-status': 'DYNAMIC',
    //         server: 'cloudflare',
    //         'cf-ray': '8afc9a7aac7e2edc-MEL'
    //       },
    //       statusCode: 201,
    //       statusMessage: 'Created',
    //       url: 'https://api-sandbox.gocardless.com/customer_bank_accounts'
    //     }
    //   }


        ////??????????  MANDATE

        // consent_parameters : null
        // created_at : "2024-08-08T04:15:19.515Z"
        // funds_settlement : "managed"
        // id : "MD00123A06KDTY"
        // links {           
        //     creditor : "CR00007YHTZTDZ"
        //     customer : "CU00180C05JN7F"        
        //     customer_bank_account : "BA0011YSF8D9FY"
        // }
        // metadata : {}
        // next_possible_charge_date : "2024-08-09"
        // payments_require_approval : false
        // reference : "FIGURE8-NGMZ6TQ"
        // scheme : "becs"
        // status : "pending_submission"
        // verified_at : null



        // {   id: 'MD00123A06KDTY',
        //     created_at: '2024-08-08T04:15:19.515Z',
        //     reference: 'FIGURE8-NGMZ6TQ',
        //     status: 'pending_submission',
        //     scheme: 'becs',
        //     next_possible_charge_date: '2024-08-09',
        //     payments_require_approval: false,
        //     metadata: {},
        //     links: {
        //       customer_bank_account: 'BA0011YSF8D9FY',
        //       creditor: 'CR00007YHTZTDZ',
        //       customer: 'CU00180C05JN7F'
        //     },
        //     consent_parameters: null,
        //     verified_at: null,
        //     funds_settlement: 'managed',
        //     __response__: {
        //       headers: {
        //         date: 'Thu, 08 Aug 2024 04:15:19 GMT',
        //         'content-type': 'application/json',
        //         'transfer-encoding': 'chunked',
        //         connection: 'close',
        //         location: '/enterprise/mandates/MD00123A06KDTY',
        //         pragma: 'no-cache',
        //         'cache-control': 'no-store',
        //         etag: 'W/"fa64c4dd11b73cb4b5f057d4aaf1786d"',
        //         'x-request-id': '23BF13C0DCFC_0A147E591F92_66B44651_1F9980001',
        //         'strict-transport-security': 'max-age=31556926; includeSubDomains; preload',
        //         vary: 'Origin,Accept-Encoding',
        //         'x-xss-protection': '1; mode=block',
        //         'x-content-type-options': 'nosniff',
        //         'ratelimit-limit': '1600',
        //         'ratelimit-remaining': '1599',
        //         'ratelimit-reset': 'Thu, 08 Aug 2024 04:16:00 GMT',
        //         'content-encoding': 'gzip',
        //         via: '1.1 google',
        //         'cf-cache-status': 'DYNAMIC',
        //         server: 'cloudflare',
        //         'cf-ray': '8afcaf4188e02b35-MEL'
        //       },
        //       statusCode: 201,
        //       statusMessage: 'Created',
        //       url: 'https://api-sandbox.gocardless.com/mandates'
        //     }
        //   }

        /// GENERATED PAYMENT

        // client json:

            // First Payment::

            // amount : 205
            // amount_refunded : 0
            // charge_date : "2024-08-09"
            // created_at : "2024-08-08T04:42:33.159Z"
            // currency : "AUD"
            // description : null
            // fx : {fx_currency: null, fx_amount: null, exchange_rate: null, estimated_exchange_rate: null}
            // id : "PM00AJ2GD3YP6X"
            // links  : {mandate: 'MD00123A06KDTY', creditor: 'CR00007YHTZTDZ'}
            // metadata : {}
            // reference : null
            // retry_if_possible : false
            // status :  "pending_submission"


            //Second payment::::

            // amount: 207
            // amount_refunded: 0
            // charge_date: "2024-08-09"
            // created_at: "2024-08-08T04:47:52.468Z"
            // currency: "AUD"
            // description: null
            // fx: {fx_currency: null, fx_amount: null, exchange_rate: null, estimated_exchange_rate: null}
            // id: "PM00AJ2GDV1E5C"
            // links: {mandate: 'MD00123A06KDTY', creditor: 'CR00007YHTZTDZ'}
            // metadata: {}
            // reference: null
            // retry_if_possible: false
            // status: "pending_submission"

        //Server SIde :


        // First Payment::

        // {
        //     id: 'PM00AJ2GD3YP6X',
        //     created_at: '2024-08-08T04:42:33.159Z',
        //     charge_date: '2024-08-09',
        //     amount: 205,
        //     description: null,
        //     currency: 'AUD',
        //     status: 'pending_submission',
        //     amount_refunded: 0,
        //     reference: null,
        //     metadata: {},
        //     fx: {
        //       fx_currency: null,
        //       fx_amount: null,
        //       exchange_rate: null,
        //       estimated_exchange_rate: null
        //     },
        //     links: { mandate: 'MD00123A06KDTY', creditor: 'CR00007YHTZTDZ' },
        //     retry_if_possible: false,
        //     __response__: {
        //       headers: {
        //         date: 'Thu, 08 Aug 2024 04:42:33 GMT',
        //         'content-type': 'application/json',
        //         'transfer-encoding': 'chunked',
        //         connection: 'close',
        //         location: '/enterprise/payments/PM00AJ2GD3YP6X',
        //         pragma: 'no-cache',
        //         'cache-control': 'no-store',
        //         etag: 'W/"096559e807654970e966107bb62a78e4"',
        //         'x-request-id': '23BF12D3CFE2_0A1483EB1F92_66B44CB9_21D7F0001',
        //         'strict-transport-security': 'max-age=31556926; includeSubDomains; preload',
        //         vary: 'Origin,Accept-Encoding',
        //         'x-xss-protection': '1; mode=block',
        //         'x-content-type-options': 'nosniff',
        //         'ratelimit-limit': '1600',
        //         'ratelimit-remaining': '1598',
        //         'ratelimit-reset': 'Thu, 08 Aug 2024 04:43:00 GMT',
        //         'content-encoding': 'gzip',
        //         via: '1.1 google',
        //         'cf-cache-status': 'DYNAMIC',
        //         server: 'cloudflare',
        //         'cf-ray': '8afcd7241e032b2e-MEL'
        //       },
        //       statusCode: 201,
        //       statusMessage: 'Created',
        //       url: 'https://api-sandbox.gocardless.com/payments'
        //     }
        //   }


        // Second Payment::

        // {
        //     id: 'PM00AJ2GDV1E5C',
        //     created_at: '2024-08-08T04:47:52.468Z',
        //     charge_date: '2024-08-09',
        //     amount: 207,
        //     description: null,
        //     currency: 'AUD',
        //     status: 'pending_submission',
        //     amount_refunded: 0,
        //     reference: null,
        //     metadata: {},
        //     fx: {
        //       fx_currency: null,
        //       fx_amount: null,
        //       exchange_rate: null,
        //       estimated_exchange_rate: null
        //     },
        //     links: { mandate: 'MD00123A06KDTY', creditor: 'CR00007YHTZTDZ' },
        //     retry_if_possible: false,
        //     __response__: {
        //       headers: {
        //         date: 'Thu, 08 Aug 2024 04:47:52 GMT',
        //         'content-type': 'application/json',
        //         'transfer-encoding': 'chunked',
        //         connection: 'close',
        //         location: '/enterprise/payments/PM00AJ2GDV1E5C',
        //         pragma: 'no-cache',
        //         'cache-control': 'no-store',
        //         etag: 'W/"cf8b4a31a5d5229a633a645c3d23a32f"',
        //         'x-request-id': '82D30288C5F4_0A143B201F92_66B44DF3_22E580001',
        //         'strict-transport-security': 'max-age=31556926; includeSubDomains; preload',
        //         vary: 'Origin,Accept-Encoding',
        //         'x-xss-protection': '1; mode=block',
        //         'x-content-type-options': 'nosniff',
        //         'ratelimit-limit': '1600',
        //         'ratelimit-remaining': '1599',
        //         'ratelimit-reset': 'Thu, 08 Aug 2024 04:48:00 GMT',
        //         'content-encoding': 'gzip',
        //         via: '1.1 google',
        //         'cf-cache-status': 'DYNAMIC',
        //         server: 'cloudflare',
        //         'cf-ray': '8afcdef00c947c79-LAX'
        //       },
        //       statusCode: 201,
        //       statusMessage: 'Created',
        //       url: 'https://api-sandbox.gocardless.com/payments'
        //     }
        //   }



                                                            //  <Autocomplete
                                                            //     value={inputs.statusName} //disabled={action==='update'?true:false}   // defaultValue={statuses.statusName[0]??''}// name="statusName"// id='statusName'
                                                            //     size='small'  //loading = {true}//{loading}// autoHighlight                                                            
                                                            //     disabled={role ==='Contractor' || role =='Field Technician' } //width='20ch'// filterOptions={filterOptions}
                                                            //     options={statuses?.statusName} 
                                                            //     onChange={(e, newValue) => {statuses.statusName.map((item, i)=>{     
                                                            //         console.log(newValue, item)
                                                            //         console.log(statuses.statusId[i],  i)                                                                                                                                                                                       
                                                            //             if(item === newValue){
                                                            //                 setInputs(v =>({...v, ['statusId']: statuses.statusId[i]}) )
                                                            //                 setInputs(v =>({...v, ['statusName']: statuses.statusName[i]}))   //setInputs(values=>({...values, ['siteSupplierId']: siteSupplId}))      // console.log(siteSupplId, 'ddddddddddddddddddddddddddddddddd')
                                                            //                 setPostNote(v =>({...v, jobStatus: `${statuses.statusName[i]}`}))
                                                            //             } 
                                                            //         })
                                                            //     }}                                                    //onBlur={e=> DEBUG && console.log('onBlur')}
                                                            //     renderInput={(params) => (                                                                                                                                   
                                                            //         <TextField
                                                            //             {...params}                                                                                
                                                            //             placeholder={inputs.statusName} 
                                                            //             inputProps = {{...params.inputProps, type: 'search', }} 
                                                            //             //sx={{boxShadow:'1px 1px 1px lightgray', }}                                                                                   
                                                            //         />
                                                            //     ) }
                                                            // />      


                                                            // // {/* <Autocomplete
                                                            // //     value={ jobPr == 1 ? 'Extreme' : jobPr == 2 ? 'Urgent' : jobPr == 3 ? 'Moderate' : 'Standard' }
                                                            // //     name="priority"
                                                            // //     size='small'
                                                            
                                                            // //     disabled={(role==='Contractor'|| role=='Field Technician') }                                                    //width='20ch'                           // filterOptions={filterOptions}
                                                            // //     options={['Extreme','Urgent','Moderate','Standard']}  
                                                            // //     onChange={(e, newValue) => {
                                                            // //         setJobPr( newValue == 'Extreme' ? 1 : newValue == 'Urgent' ? 2 : newValue == 'Moderate' ?  3 : 4 )
                                                            // //         setInputs(values=>({...values, ['priority']: newValue == 'Extreme' ? 1 : newValue == 'Urgent' ? 2 : newValue == 'Moderate' ?  3 : 4 }))  
                                                            // //         getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${newValue == 'Extreme' ? 1 : newValue == 'Urgent' ? 2 : newValue == 'Moderate' ?  3 : 4}/${inputs.siteZone}` )
                                                            // //         setPostNote(a =>({...a, jobPriority: newValue}))    
                                                            // //     }}                                                    
                                                            // //     renderInput={(params) => (
                                                            // //         <TextField  
                                                            // //             {...params} 
                                                            // //             inputProps = {{...params.inputProps, type: 'search', }} //style={{width:'29ch', fontSize:fs(), fontFamily:'Arial'}}     //sx={{boxShadow:'1px 1px 1px lightgray', fontFamily:'Arial', fontSize:fs(), fontWeight:800}}     
                                                            // //         />
                                                            // //     ) }/>



                                                    // {/* <TextAreaField
                                                    //     autoComplete="off"
                                                    //     hasError={false}
                                                    //     isDisabled={false}
                                                    //     isReadOnly={false}
                                                    //     isRequired={false}
                                                    //     labelHidden={false} 
                                                    //     placeholder="Enter New Notes ..."
                                                    //     rows="5"
                                                    //     style={{width:'110ch', marginTop:'0ch'}}
                                                    //     value={newNote}
                                                    //     onChange={(e)=>{setNewNote( e.currentTarget.value)}}
                                                    //     // onKeyDown={(code)=>{console.log(code.code)
                                                    //     // if (code.code =='Enter'){console.log(code.code)} 
                                                    //     // onBlur={(e=>setPostNote(a =>({...a,  newNote: e.target.value})))}
                                                    //     // onInput={(e) => DEBUG && console.info('input fired:', e.currentTarget.value)}
                                                    //     // onCopy={(e) =>DEBUG &&  console.info('onCopy fired:', e.currentTarget.value)}
                                                    //     // onCut={(e) =>DEBUG &&  console.info('onCut fired:', e.currentTarget.value)}
                                                    //     // onPaste={(e) =>DEBUG &&  console.info('onPaste fired:', e.currentTarget.value)}
                                                    //     // onSelect={(e) =>DEBUG &&  console.info('onSelect fired:', e.currentTarget.value.substring(e.currentTarget.selectionStart, e.currentTarget.selectionEnd  ))}
                                                        
                                                    // /> */}




                                        // Box 
                                        //                     component="form" 
                                        //                     //direction={{ xs: "column", sm: "row" }}     //onClick={toggleDrawer(false)} //onKeyDown={toggleDrawer(false)} sx={{'& .MuiTextField-root': {height:'100%' }, border:'1px solid red' }}   width={{sm:'65ch', md:'137ch'}}  
                                        //                     autoComplete="off"  

                                                // {/* <Autocomplete
                                                //     value={ jobPr == 1 ? 'Extreme' : jobPr == 2 ? 'Urgent' : jobPr == 3 ? 'Moderate' : 'Standard' }
                                                //     name="priority"
                                                //     size='small'
                                                //     disabled={(role.trim().toUpperCase()==='Contractor'.toUpperCase() || role.trim().toUpperCase()=='Field Technician'.toUpperCase()) }                                                    //width='20ch'                           // filterOptions={filterOptions}
                                                //     options={['Extreme','Urgent','Moderate','Standard']}  
                                                //     onChange={(e, newValue) => {
                                                //         setJobPr( newValue == 'Extreme' ? 1 : newValue == 'Urgent' ? 2 : newValue == 'Moderate' ?  3 : 4 )
                                                //         setInputs(values=>({...values, ['priority']: newValue == 'Extreme' ? 1 : newValue == 'Urgent' ? 2 : newValue == 'Moderate' ?  3 : 4 }))  
                                                //         getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${newValue == 'Extreme' ? 1 : newValue == 'Urgent' ? 2 : newValue == 'Moderate' ?  3 : 4}/${inputs.siteZone}` )
                                                //         setPostNote(a =>({...a, jobPriority: newValue}))    
                                                //     }}                                                    
                                                //     renderInput={(params) => (
                                                //         <TextField  {...params} inputProps = {{...params.inputProps, type: 'search', }} sx={{boxShadow:'1px 1px 2px 2px lightgray', ml:'1ch', }}  style={{width:'25ch'}} />
                                                //     ) }
                                                // /> */}

                                                // {/* <SmallContainer h='80px' mt="1px" ml="2px" b='transparent'>
                                                //     <Typography sx={{ fontSize:fs(), fontWeight:600,  , ml:1}} >Job Status </Typography>      
            
                                                //     <Autocomplete
                                                //         value={inputs.statusName} //disabled={action==='update'?true:false}   // defaultValue={statuses.statusName[0]??''}
                                                //         // name="statusName"
                                                //         // id='statusName'
                                                //         size='small'
                                                //         disabled={(role.trim().toUpperCase()==='Contractor'.toUpperCase() || role.trim().toUpperCase()=='Field Technician'.toUpperCase()) }                                                    //width='20ch'                           // filterOptions={filterOptions}
                                                //         options={statuses.statusName}  //loading = {true}//{loading}// autoHighlight
                                                //         onChange={(e, newValue) => { statuses.statusName.map((item, index)=>{                                                            
                                                //             if(item===newValue){
                                                //                 setInputs(values=>({...values, ['statusId']: statuses.statusId[index]}))
                                                //                 setInputs(values=>({...values, ['statusName']: statuses.statusName[index]}))       //setInputs(values=>({...values, ['siteSupplierId']: siteSupplId}))      // console.log(siteSupplId, 'ddddddddddddddddddddddddddddddddd')
                                                //                 setPostNote(a =>({...a,  jobStatus: `${statuses.statusName[index]}`}))
                                                //             } })
                                                //         }}                                                    //onBlur={e=> DEBUG && console.log('onBlur')}
                                                //         renderInput={(params) => (
                                                //             <TextField
                                                //                 {...params}
                                                //                 placeholder={inputs.statusName} 
                                                //                 inputProps = {{...params.inputProps, type: 'search', }}                    //style={{width:'32ch',}}    
                                                //                 //sx={{boxShadow:'0 0 1px 2px lightgray', ml:'-2ch'}}   
                                                                
                                                //             />
                                                //         ) }
                                                //     />
                                                // </SmallContainer> */}
                                        
                                                // {/* <SmallContainer h='80px' mt="5px" ml="1px" b='transparent'>            
                                                //     <Typography sx={{ fontSize:14, fontWeight:600,  , ml:1}}>Request Type </Typography>   
                                                //     <Autocomplete name="REQUEST TYPE"                                    
                                                //         // id='requestType'
                                                //         disabled={(role.trim().toUpperCase()==='Contractor'.toUpperCase() || role.trim().toUpperCase()=='Field Technician'.toUpperCase()) } 
                                                //         size='small'       // filterOptions={filterOptions}
                                                //         options={requestTypes.requestTypeName}  
                                                //         onChange={(e, newValue) => { 
                                                //             requestTypes.requestTypeName.map((item, index)=>{
                                                //                 if(item === newValue){
                                                //                     setInputs(values=>({...values, ['requestTypeId']: requestTypes.requestTypeId[index]}))
                                                //                     setInputs(values=>({...values, ['requestType']: requestTypes.requestTypeName[index]}))  //setInputs(values=>({...values, [e.target.name]: e.target.value}))
                                                //                     setPostNote(a =>({...a, jobRequestType: `${requestTypes.requestTypeName[index]}`}))
                                                //                 }
                                                //             })
                                                //         }}
                                                //         renderInput={(params) => (
                                                //             <TextField {...params}
                                                //                 placeholder={inputs.requestType}
                                                //                 inputProps = {{...params.inputProps, type: 'search', }}    
                                                //                                                                         //sx={{boxShadow:'0 0 1px 2px lightgray',}}   
                                                //             />
                                                //         ) }
                                                //     />
                                                // </SmallContainer>                                  */}


                    // {/* <Drawer
                    //         variant="temporary"
                    //         anchor={'bottom'}
                    //         open={stateOpen}
                    //         onClose={toggleDrawer(false)}
                    //     >
                    //         <Stack style={{height: '25ch'}}>
                    //             <Typography paddingX = '10ch' paddingY = '5ch' fontSize={16} fontWeight={600}>{updateText}</Typography>
                    //         </Stack>
                    //     </Drawer> */}


    // const validateField = (inputText, searchedFlag)=>  {return searchedFlag==="" ? true: inputText.match(searchedFlag) ? true: inputText.length===0 ? true: false }

    // const handleChange=(e)=>{
    //     const names= e.target.name
    //     const value = e.target.value
    //     process.env.REACT_APP_DEBUG && console.log(e)
    //     //flag ? parseInt(value) : value
    //     setInputs(values=>({...values, [names]: value}));  //value, valid: 
    // }  
   ///"employeeUId": "a6c0fbfc-d118-4a67-a904-acb67ca1f223","employeeUId": "a6c0fbfc-d118-4a67-a904-acb67ca1f223",


//    {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  TRACKER >>>>>>>>>>>>>>>>>>>>>>>    */} 
//    { (inputs.sheqsyUId !== null && action=='update' )  &&    
//    <TabItem  
//        title={<Text fontSize={16}>Technician Location</Text>}  
//        backgroundColor={6 ===tabValue ?'aquamarine':'aliceblue'}//
//        border={borderSize(6, tabValue)}
//        borderRadius={30}
//        // onBlur={()=>{
//        //         ///setSelectedStaff('');
//        //         centerEmployee.current =''
//        //     }
//        // }
//        //onClick={()=>{ clearRouts()}}
//    >

//        <Stack direction="column" sx = {{ width: '175ch' , height:'75ch', mt:2, border:'5px solid lightgray'}} >  
//            {isLoaded && 

//                <GoogleMap 
//                    center = {centerEmployee.current ==''  ? {lat:-29, lng:134} : centerEmployee.current }
//                    zoom={centerEmployee.current =='' ? 5 : 10 }
//                    mapContainerStyle = {{width:'100%', height:'95%'}}
//                    // onLoad={(map)=>{
//                    //     // console.log('new MMMMAAAPPPP uuuuuuuuuuuu KKKKKKKKKK ', tracker !== undefined )
//                    //     // { tracker.map(t => console.log(t.employee.employeeUId))  }
//                    //     //setMap(map)
//                    //     //getTracker()
//                    // }}
//                    onLoad={(map)=>{
                               
//                        setMap(map)
//                        //getTracker()
//                        //calculateRoute()
//                        // map.panTo(centerSite)
//                        // map.setZoom(15)
//                    }}
//                    onUnmount={onUnmount}
//                >

//                    <>
//                        <Fab variant="extended" size="small"  
//                            color='primary' 
//                            sx={{  ,fontSize:12, width:'90px', height:'22px', left:'80%', mt:0, mb:-1.3}}
//                            onClick={getTracker}
//                        > Refresh</Fab>

//                        { tracker !== undefined  && tracker.map((t, i)=> {
//                                //console.log(inputs.sheqsyUId, t.employee.employeeUId )
//                                if(t.employee.employeeUId ===  inputs.sheqsyUId){
//                                    centerEmployee.current =( t.activity.currentLocation.latitude == null ? defaultCenter: {lat: t.activity.currentLocation.latitude, lng: t.activity.currentLocation.longitude})
//                                    techAddress.current= t.activity.currentLocation.address
                                   
//                                    return (                         
//                                        <> 
//                                            <MarkerF 
//                                                position={centerEmployee.current}//{lat: parseFloat(t.activity.currentLocation.latitude),  lng: parseFloat(t.activity.currentLocation.longitude)}}
//                                                label= {t.employee.name + " " + t.employee.surname  }
//                                                cursor= 'pointer'
//                                                title= {`${t.employee.name.trim()} ${t.employee.surname} - active since ${dateConverter(new Date(t.activity.startDateTime.dateTimeUTC), false)}`}                                                            
//                                            />

//                                                <>
//                                                    <SearchBox destination={t.activity.currentLocation.address}/>
//                                                    <Fab 
//                                                        size="small" sx={{ mt:-5, left:'66%', height:'2px'}} color="warning"
//                                                        onClick={centerTOcenter}
//                                                    >  <CenterFocusWeakRoundedIcon/> </Fab>

//                                                    <Fab 
//                                                        variant="extended" 
//                                                        size="small" 
//                                                        color="primary"
//                                                        sx={{mt:-5,  , fontSize:12, width:'145px', height:'22px', left:'68%'}}
//                                                        onClick={calculateRoute}
//                                                    >  calculate route </Fab>
//                                                </>
                                           
//                                            <InfoWindowF position={centerEmployee.current} >
//                                                <div style={{ backgroundColor: 'yellow', border: ".1px solid purple", padding:7,  fontSize: 15,  , fontWeight:500}}>
//                                                    {<div>{`${t.employee.name} ${t.employee.surname}`}</div> } { t.activity.currentLocation.latitude == null && ` GEO LOCATION IS OFF - last known address - ${t.activity.currentLocation.address}` }
//                                                    {/* {<img src={image}/>} */}
//                                                </div>
//                                            </InfoWindowF>
//                                            {/* { <DirectionsRenderer directions={directionResponse}/> } */}
                                       
//                                        </>
//                                    )
                           
//                                }
//                            }) 
//                        }
//                    </>  
//                </GoogleMap>  
//            }    

//        </Stack> 

//    </TabItem>
// }


//             {/* //currentIndex={contactKey}  //indicatorPosition="top"  //defaultIndex={contactsNumbers===0 ? 0: contactsNumbers }  */}
//                                                 {/* <Stack direction="row" sx={{width: '70ch',  ml:15, mt:0}} > 
//     <Stack sx={{height:'8ch',   }}  > 

//         <TextField      
//             label='Reference Number'
//             name='cw_number'
//             required
//             value={inputs.cw_number}//{jobRefNumber}
//             size='small'
//             sx = {{width: '35ch'}}
//             onChange={(e)=>{setInputs(values=>({...values, [e.target.name]: e.target.value}))}}
//             onClick={handleClick}
//         /> 

//     </Stack>

//     <Stack sx={{ height:'8ch',   }}  > 
//         <TextField  
//             value = {inputs.po_number}    ///{pOrder} 
//             name="po_number"
//             label='Purchase Order'
//             size='small'
//             required
//             sx = {{ width: '35ch',}}
//             onChange={(e) => {setInputs(values=>({...values, [e.target.name]: e.target.value}))}}
//         />
//     </Stack>    
// </Stack> */}



        // <SelectField
    //     value={selectedStaff }
    //     options={ tracker !== undefined && ['All Employees', ...tracker.map((t)=>t.employee.name.trim() + " " + t.employee.surname.trim()) ]}  ///['lions', 'tigers', 'bears']}
    //     size="small" 
    //     onChange={(e) => {
    //         setSelectedStaff(e.target.value)
    //         setEmployee(true)
    //     }}  inputs.sheqsyUId
    // ></SelectField> 
// else{
//     return (  
//         <InfoWindowF position={defaultCenter} >
//             <div style={{ backgroundColor: 'pink', border: "2px solid red", padding:15,  fontSize: 20,  , fontWeight:600}}>
//                 {<div>{`Unable to get Location !`}</div> }
//                 {/* { t.activity.currentLocation.latitude == null && ` GEO LOCATION IS OFF - last known address - ${t.activity.currentLocation.address}` } */}
//                 {/* {<img src={image}/>} */}
//             </div>
//         </InfoWindowF>
//     )

// }


//     <TabItem  
//     title={<Text fontSize={16}>Staff Location</Text>}  
//     backgroundColor={6 ===tabValue ?'aquamarine':'aliceblue'}//
//     border={ 6 === tabValue ?'5px solid gray':'5px solid transparent'}
//     borderRadius={30}
//     onBlur={()=>{
//             setSelectedStaff('');
//             centerEmployee.current =''
//         }
//     }
// >
// <Stack direction="column" sx = {{ width: '175ch' , height:'75ch', mt:2, border:'5px solid lightgray'}} >  

    

//     <SelectField
//         value={selectedStaff }
//         options={ tracker !== undefined && ['All Employees', ...tracker.map((t)=>t.employee.name.trim() + " " + t.employee.surname.trim()) ]}  ///['lions', 'tigers', 'bears']}
//         size="small" 
//         onChange={(e) => {
//             setSelectedStaff(e.target.value)
//             setEmployee(true)
//         }}
//     ></SelectField>
    
//     <GoogleMap 
//         center = {centerEmployee.current =='' || selectedStaff == 'All Employees' ? {lat:-29, lng:134} : centerEmployee.current } //{{lat:-29, lng:134}}  centerEmployee.current =='' ||
//         zoom={centerEmployee.current =='' || selectedStaff == 'All Employees'  ? 5 : 10 }
//         mapContainerStyle = {{width:'100%', height:'95%'}}
//         onLoad={(map)=>{
//             console.log('new MMMMAAAPPPP ', map)
//             setMap(map)
//         }}
//     >

//         <>
//             <Fab 
//                 variant="extended" 
//                 size="small" 
//                 color="primary"
//                 sx={{  ,fontSize:12, width:'90px', height:'22px', left:'80%'}}
//                 onClick={getTracker}
//             > {'Refresh'}    
//             </Fab>

//             {/* {DEBUG && console.log('tracker 111 is ' , selectedStaff =="" ? "empty":selectedStaff)}
//             {DEBUG && console.log('centerEmployee 111 is ' , centerEmployee.current == ''? "mpty": centerEmployee.current)} */}
            

//             { (tracker !== undefined)  && tracker.map((t, i)=> {

//                     //{DEBUG && console.log(t)}
//                         //t.employee.name.trim() + " " + t.employee.surname.trim() ==   console.log(t.activity.currentLocation.latitude,  t.activity.currentLocation.longitude)
//                     if(selectedStaff =='' || selectedStaff =='All Employees' ){
//                         //centerEmployee.current = '' //({lat: t.activity.currentLocation.latitude,  lng:t.activity.currentLocation.longitude})
//                         {DEBUG && console.log("ALLL", t.activity.currentLocation.latitude == null? 'null': t.activity.currentLocation.latitude )}
//                         return (

//                             <MarkerF 
//                                 position= {t.activity.currentLocation.latitude == null ? defaultCenter: {lat: t.activity.currentLocation.latitude,  lng:t.activity.currentLocation.longitude} }
//                                 label={t.employee.name + " " + t.employee.surname}
//                                 cursor= 'pointer'
//                             />

//                         )
//                     }else{

//                         // {DEBUG && console.log("INDIV", t.activity.currentLocation.latitude ==null && "NULLLL")}

//                         if(t.employee.name.trim() + " " + t.employee.surname.trim() ===  selectedStaff){

//                             centerEmployee.current =( t.activity.currentLocation.latitude == null ? defaultCenter: {lat: t.activity.currentLocation.latitude, lng: t.activity.currentLocation.longitude})
                    
//                             return (                                                                                         
//                                 <> 
//                                     <MarkerF 
//                                         position={centerEmployee.current}//{lat: parseFloat(t.activity.currentLocation.latitude),  lng: parseFloat(t.activity.currentLocation.longitude)}}
//                                         label= {t.employee.name + " " + t.employee.surname  }
//                                         cursor= 'pointer'
//                                         title= {t.employee.name + " " + t.employee.surname + " active " + dateConverter(new Date(t.activity.startDateTime.dateTimeUTC), false)}                                                            
//                                     />
                                    
//                                     <InfoWindowF position={centerEmployee.current}  >

//                                         <div style={{ backgroundColor: 'yellow', border: "1px solid gray", paddingLeft:4,  paddingRight:4, fontSize: 12,  , fontWeight:500}}>
//                                             {/* {<img src= {image1} className='avatar'/>} */}
//                                             {/* {t.employee.name + " " + t.employee.surname + " " } */}
//                                             {t.employee.name + " " + t.employee.surname + " active  " + dateConverter(new Date(t.activity.startDateTime.dateTimeUTC), false) }
//                                             { t.activity.currentLocation.latitude == null ? ` GEO LOCATION IS OFF - address - "${t.activity.currentLocation.address} "` 
//                                                                                           :` - address is "${t.activity.currentLocation.address} "`}
//                                             {/* {<img src={image}/>} */}
//                                             {/* // + new Date(t.activity.startDateTime.dateTimeUTC)} */}
//                                         </div>

//                                     </InfoWindowF>
//                                 </>
//                             )
                    
//                         }
//                     }

//                     }
//               ) 
//             }
//         </>  
//     </GoogleMap>  
    
// </Stack> 

// </TabItem>



















//?????????????????????????????????????????????????????????????????????????????????????????????






            // {/* <TabItem   title={<Text fontFamily={'cursive'} fontSize={16}>Fault Description</Text>}  
            //                     backgroundColor={3 ===tabValue ?'aquamarine':'aliceblue'}//
            //                     border={ 3 ===tabValue ?'5px solid gray':'5px solid transparent'}
            //                     borderRadius={30}
            //                     //onClick={()=>handleNewTabClick()}  
            //         >
            //             <Stack direction="column" sx = {{ width: '175ch' , height:'75ch', mt:2, border:'5px solid lightgray'}} >       
            //                 <ReactQuill 
            //                     theme="snow"
            //                     id="faultDescription"
            //                     className ='faultDescription'
            //                     value={inputs.faultDescription??''} 
            //                     //defaultValue ={inputs.faultDescription??''} 
            //                     placeholder ="Fault Description"
            //                     modules={{
            //                         'history': {         
            //                             'delay': 2500,
            //                             'userOnly': true
            //                         },   
            //                         'toolbar': toolbarOptions
            //                     }}         
            //                     onChange={(content, delta, source, editor)=>{
            //                         ///process.env.REACT_APP_DEBUG==='on' && console.log( delta, content)
            //                         setInputs(values=>({...values, ['faultDescription']: content}))
            //                     }}  
            //                 />
            //             </Stack> 

            //         </TabItem> */}







                                                    // {/* <StandaloneSearchBox>
                                                    
                                                    //     <input
                                                    //         type="Typography"
                                                    //         value={origin} ////{`${inputs.siteAddress1??''} ${inputs.siteSuburb??''} ${inputs.siteState??''} ${inputs.sitePostCode??''}`} 
                                                    //         //ref={destinationRef}
                                                    //         onChange={(e)=>setOrigin(e.target.value)}
                                                    //         style={{
                                                    //             top:'4px',
                                                    //             boxSizing: `border-box`,
                                                    //             border: `1px solid transparent`,
                                                    //             width: `270px`,
                                                    //             height: `32px`,
                                                    //             padding: `0 10px`,
                                                    //             borderRadius: `3px`,
                                                    //             boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                    //             fontSize: `14px`,
                                                    //             outline: `none`,
                                                    //             textOverflow: `ellipses`,
                                                    //             position: "absolute",
                                                    //             left: "1%",
                                                    //             //marginLeft: "-120px"
                                                    //         }}
                                                    //     />
                                                                            
                                                    // </StandaloneSearchBox>
                                                    // <StandaloneSearchBox>

                                                    //     <input
                                                    //         type="text"
                                                    //         value={destination} ////{`${inputs.siteAddress1??''} ${inputs.siteSuburb??''} ${inputs.siteState??''} ${inputs.sitePostCode??''}`} 
                                                    //         //ref={destinationRef}
                                                    //         onChange={(e)=>setDestination(e.target.value)}
                                                    //         style={{
                                                    //             top:'4px',
                                                    //             boxSizing: `border-box`,
                                                    //             border: `1px solid transparent`,
                                                    //             width: `300px`,
                                                    //             height: `32px`,
                                                    //             padding: `0 10px`,
                                                    //             borderRadius: `3px`,
                                                    //             boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                    //             fontSize: `14px`,
                                                    //             outline: `none`,
                                                    //             textOverflow: `ellipses`,
                                                    //             position: "absolute",
                                                    //             left: "38%",
                                                    //             //marginLeft: "-120px"
                                                    //         }}
                                                    //     />

                                                    // </StandaloneSearchBox> */}





                
                   //  {/* <FormControl >  */}
                    //    {/* <InputLabel htmlFor="faultDescription">Fault Description</InputLabel>     */}
                    //     {/* <OutlinedInput
                    //         id="faultDescription"
                    //         name='faultDescription'
                    //         label="Fault Description"
                    //         multiline
                    //         rows={15}
                    //         value={inputs.faultDescription??''} ///{`${inputs.statusId??1}`}/setInputs(values=>({...values, ['statusName']: statuses.statusName[index]}))
                    //         sx = {{ width: '54ch', }}
                    //         onChange={(e)=>{setInputs(values=>({...values, [e.target.id]: e.target.value}))}}  //setFaultDescription(e.target.value) 
                    //     /> */}


///////>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>




// import React, {useState, useRef, useEffect } from 'react';
// import Button from '@mui/material/Button';     
// import Autocomplete, {createFilterOptions } from '@mui/material/Autocomplete'; 
// import TextField from '@mui/material/TextField';  
// import Stack from '@mui/material/Stack'; 
// import Typography from '@mui/material/Typography'; 
// import Box from '@mui/material/Box'; 
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// // import InputAdornment from '@mui/material/InputAdornment';
// // import FormHelperText from '@mui/material/FormHelperText';
// import FormControl from '@mui/material/FormControl';
// import './newitem.scss';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { useParams, useNavigate} from "react-router-dom";
// import { FormControlLabel, FormLabel, Radio, RadioGroup, Tab, Tabs } from '@mui/material';
// import { pink, purple, green, blue, yellow, red } from '@mui/material/colors';
// import PropTypes from 'prop-types';
// import { PhonelinkLockRounded } from '@mui/icons-material';



// const NewJob = ({action}) => {
//     //const [DOMAIN1, setURL] = useState(`${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_API_PORT}`)
//     //const [clientName, setClientName] = useState('');
//     const [allClientsNames, setAllClientsNames] = useState('');
//     const [allClientsIds, setAllClientsIds] = useState();
//     const [text, setText] =useState('');
//     const [statuses, setStatuses] = useState([ { 
//         statusName:'', 
//         statusId:''
//     } ] );

//     const [requestTypes, setRequestTypes] = useState([ { 
//         requestTypeName:'', 
//         requestTypeId:''
//     } ] );

//     const [siteSelected, setSiteSelected] = useState([{}]);//siteName :'', siteID :'' i === newInputValue && 
//     const [oneSiteIDSelected, setOneSiteIDSelected] = useState('');
//     const [oneSiteAddressSelected, setOneSiteAddressSelected] = useState('');
//     const [oneSiteSuburbSelected, setOneSiteSuburbSelected] = useState('');
//     const [oneSiteStateSelected, setOneSiteStateSelected] = useState('');
//     const [oneSitePostCodeSelected, setOneSitePostCodeSelected] = useState('');
//     const [oneSitePhoneSelected, setOneSitePhoneSelected] = useState('');
//     const [fautDescription, setFaultDescription]= useState('')
//     const [inputs, setInputs] = useState([{}]);
//     const [loaded, setLoaded] = useState(true);

//     const [value, setValue] = useState(0);
//     const [font, setFont]=useState({ , fontWeight:800, fontSize:14})
    

//     const statusUpdateId=useRef();
//     const requestTypeId=useRef();
//     //const VALID = useRef(false);

//     let {id} = useParams();
//     const navigate = useNavigate();

//     const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
//     const localHost = process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_LOCAL_DOMAIN + process.env.REACT_APP_API_LOCAL_PORT;
//     const DOMAIN = `${process.env.NODE_ENV==='production'?productionHost:localHost}`; 
//     const DEBUG= process.env.REACT_APP_DEBUG ==='on'?true:false // && console.log(DOMAIN)
//     const log =(msg)=>{ DEBUG && console.log(msg) }


  
//     function TabPanel(props) {
//       const { children, value, index, ...other } = props;
    
//       return (
//         <div
//           role="tabpanel"
//           hidden={value !== index}
//           id={`simple-tabpanel-${index}`}
//           aria-labelledby={`simple-tab-${index}`}
//           {...other}
//         >
//           {value === index && (
//             <Box sx={{ p: 3 }}>
//               <Typography>{children}</Typography>
//             </Box>
//           )}
//         </div>
//       );
//     }
    
//     TabPanel.propTypes = {
//       children: PropTypes.node,
//       index: PropTypes.number.isRequired,
//       value: PropTypes.number.isRequired,
//     };

//     function BasicTabs() {
//         const handleChange = (event, newValue) => {setValue(newValue)};
    
//         return (
//           <Box sx={{ width: '100%' }}>
    
//             <Box sx={{ borderBottom: 2, borderColor: 'gray'}}  >
              
              
//               <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons  selectionFollowsFocus textColor="secondary" >
    
//                 <Tab icon={<PhonelinkLockRounded />} iconPosition="start"  label="Item One"  sx={font} />
//                 <Tab label="Fault Description" sx={{ , fontWeight:800, fontSize:14}}  /> 
//                 <Tab label="Item Three" sx={font} />
//                 <Tab label="Item Five" sx={font}/>
//                 <Tab label="Item Six" sx={font}/>
    
//               </Tabs>
//             </Box>

//               <TabPanel value={value} index={0}   >
//                     <Stack direction="row" spacing={2} sx={{width:'175ch', height: '60ch', ml:1, border:'.1px dotted gray'}}>
//                         <Stack direction="column"  > 
                                
//                                 <ReactQuill 
//                                     ///theme="snow"
//                                     id="faultDescription"
//                                     className ='faultDescription'
//                                     value={inputs.faultDescription} 
//                                     defaultValue ={inputs.faultDescription??''} 
//                                     placeholder ="Fault Description"
//                                     // modules={{
//                                     //     'history': {         
//                                     //         'delay': 2500,
//                                     //         'userOnly': true
//                                     //     },   
//                                     //     'toolbar': toolbarOptions
//                                     // }}         
//                                     onChange={(content, delta, source, editor)=>{
//                                         process.env.REACT_APP_DEBUG==='on' && console.log()
//                                         setText(content)
//                                         setInputs(values=>({...values, ['faultDescription']: content}))
//                                     }}  
//                                 />
                    
//                         </Stack>    
//                     </Stack>                
//               </TabPanel>
    
//               <TabPanel value={value} index={1} > 
//                     <Stack direction="row" spacing={2} sx={{width:'175ch', height: '60ch', ml:1, border:'.1px dotted gray'}}>

//                         {action ==='update'? 
//                             <Stack direction="column" sx = {{width: '58ch'}}>

//                                 <Stack direction="row" sx = {{width: '52ch', height: '6ch'}} > 
//                                     <Stack sx = {{width: '20ch', ml:1}}> 
//                                         <Typography 
//                                             fontSize={18} 
//                                             fontWeight={600}
//                                             fontFamily={'cursive'}
//                                         > Customer: </Typography>
//                                     </Stack>
//                                     <Stack sx = {{width: '30ch', ml:'1ch'}} > 
//                                         <Typography  sx={{ color: pink[800] }} fontSize={18} fontWeight={600} fontFamily={'cursive'}>
//                                             {inputs.customerName??''} 
//                                         </Typography>
//                                     </Stack>
//                                 </Stack> 

//                                 <Stack direction="row" sx = {{width: '65ch', height: '6ch'}}> 
//                                     <Stack sx = {{width: '20ch', ml:'1ch'}} >
//                                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}> Site ID: </Typography>
//                                     </Stack>
//                                     <Stack sx = {{width: '40ch', ml:'1ch'}} >
//                                         <Typography fontSize={16} fontWeight={400} fontFamily={'cursive'}>{inputs.siteId??''} </Typography>
//                                     </Stack>
//                                 </Stack> 

//                                 <Stack direction="row" sx = {{width: '65ch', height: '6ch'}} > 
//                                     <Stack sx = {{width: '20ch', ml:'1ch'}} >
//                                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}>Name:</Typography>
//                                     </Stack>
//                                     <Stack sx = {{width: '40ch', ml:'1ch'}} >
//                                         <Typography fontSize={16} fontWeight={400} fontFamily={'cursive'}>{inputs.siteName??''} </Typography>
//                                     </Stack>
//                                 </Stack> 

//                                 <Stack direction="row" sx = {{width: '52ch', height: '15ch'}}> 
//                                     <Stack sx = {{width:'20ch', ml:'1ch'}} >
//                                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}> Address: </Typography>
//                                     </Stack>
//                                     <Stack sx = {{width: '30ch', ml:'1ch'}} >
//                                         <Typography fontSize={16} fontWeight={400} fontFamily={'cursive'}>
//                                             { `${inputs.siteAddress1??''}, ${inputs.siteSuburb??''},  
//                                                 ${inputs.siteState??''},  
//                                                 ${inputs.sitePostCode??''}`
//                                             } 
//                                         </Typography>
//                                     </Stack>
                                        
//                                 </Stack>  

//                                 <Stack direction="row" sx = {{width: '52ch', height: '6ch'}}> 
//                                     <Stack sx = {{width: '20ch', ml:'1ch'}} >
//                                         <Typography  fontSize={18} fontWeight={600} fontFamily={'cursive'}> Phone: </Typography>
//                                     </Stack>
//                                     <Stack sx = {{width: '30ch', ml:'1ch'}} >
//                                         <Typography  fontSize={16} fontWeight={400} fontFamily={'cursive'}>{inputs.sitePhone??''} </Typography>
//                                     </Stack>
//                                 </Stack>

//                                 <Stack direction="row" sx = {{width: '52ch', height: '6ch'}}> 
//                                     <Stack sx = {{width: '20ch', ml:'1ch'}} >
//                                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}>Job Ref No: </Typography>
//                                     </Stack>
//                                     <Stack sx = {{width: '30ch', ml:'1ch'}} >
//                                         <Typography fontSize={16} fontWeight={400} fontFamily={'cursive'}>{inputs.po_number??""} </Typography>
//                                     </Stack>
//                                 </Stack> 

//                                 <Stack direction="row" sx = {{width: '52ch', height: '6ch'}}> 
//                                     <Stack sx = {{width: '20ch', ml:'1ch'}} >
//                                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}>Purchase Order: </Typography>
//                                     </Stack>
//                                     <Stack sx = {{width: '30ch', ml:'1ch', }}>
//                                         <Typography fontSize={16} fontWeight={400}fontFamily={'cursive'}>{inputs.purchaseOrder??""} </Typography>
//                                     </Stack>
//                                 </Stack>  
//                             </Stack>
//                             ///////////UPDATE JOB end/////////
//                         :
//                             /////// ADD NEW JOB start
//                             <Stack direction="column" sx = {{width: '60ch'}} >
                                
//                                 <FormControl sx = {{width: '40ch', ml:1}}> 
//                                     <Autocomplete
//                                         value={inputs.customerName}////    value={clientName??"undefined"}///{clientName} //disabled={action==='update'?true:false}
//                                         //inputValue={inputs.customerName}
//                                         required
//                                         id="customerId"
//                                         name='customerName'
//                                         options={allClientsNames}
//                                         isOptionEqualToValue={(option, value) => option.value === value.value}
//                                         //disableClearable
//                                         size='small'
//                                         filterOptions={filterOptions}
//                                         onChange={(event, newValue) => {
//                                                 clearAllSitesData()
//                                                 setInputs(values=>({...values, ['customerName']: newValue}))
//                                                 const a = allClientsIds.filter((i)=>`${i.name}`=== newValue && i.id)
//                                                 setInputs(values=>({...values, ['customerId']:a[0].id }))
//                                                 getSitesForSelectedCustomer(a[0].id > 0 ? a[0].id : '' )
//                                             } 
//                                         }


//                                         // onInputChange={(event, newInputValue) => {
//                                         //          {console.log(allClientsNames)}
//                                         //          {console.log(newInputValue)}
//                                         //         //newInputValue===""&& clearAllSitesData()   
//                                         //                 clearAllSitesData()
//                                         //                 setInputs(values=>({...values, ['customerName']: newInputValue}))
//                                         //                 const a = allClientsIds.filter((i)=>`${i.name}`=== newInputValue && i.id)
//                                         //                 setInputs(values=>({...values, ['customerId']:a[0].id }))
//                                         //                 getSitesForSelectedCustomer(a[0].id > 0 ? a[0].id:'' )                            
//                                         // } }//loading = {true}//{loading}// autoHighlight
                                                                            
//                                         renderInput={(params) => (
//                                             <TextField
//                                                 {...params}
//                                                 //disableClearable
//                                                 label="Customers" //{action ==='update'? clientNameFromUpdate : "Clients List"} //value={clientName??"Not defined"}required  // helperText = { selectedClientName ===''?"Select value":""}  // error ={selectedClientName === ''? true:false}
//                                                 inputProps = {{...params.inputProps, type: 'search', }}    
//                                             />
//                                         )}
//                                     />
//                                 </FormControl>


//                                 <FormControl  sx = {{width:'40ch', my:2, ml:1}}> 

//                                     <Autocomplete
//                                         //value={siteSelected.length>0?'Sites being loaded':'Select Sites'}
//                                         required 
//                                         isOptionEqualToValue={(option, value) => option.value === value.value} 
//                                         filterOptions={filterOptions}
//                                         name='siteId'
//                                         size='small'
//                                         //disableClearable={false}
//                                         disabled={!inputs.customerName}
//                                         autoHighlight  //loading = {true}
//                                         options={siteSelected.siteName }
//                                         onChange={(event, newValue) => {
//                                             siteSelected.siteName.map((val, index)=>val== newValue && setAllSitesData(siteSelected, index ));     
//                                             {console.log(newValue, siteSelected.siteName)} //siteSelected.siteName.map((val, index)=>val === newValue &&  setInputs(values=>({...values, ['siteId']:siteSelected.siteId[index] })));  
//                                             }
//                                         }                                    
//                                         // onInputChange={(event, newInputValue) => {
//                                         //         // {console.log(newInputValue)}
//                                         //         newInputValue===""&& clearAllSitesData()
//                                         //         //setAll(...newInputValue )                                    
//                                         //     } 
//                                         // }
//                                         renderInput={(params) => (
//                                                 <TextField  {...params}
//                                                     label="Sites" //{action ==='update'? clientNameFromUpdate : "Clients List"}
//                                                     inputProps = {{...params.inputProps, type: 'search', }}           
//                                                 />
//                                             )
//                                         }
//                                     />
//                                 </FormControl>    

                                
//                                 <Stack direction="row" sx = {{width: '58ch', mt:'2ch'}}> 
//                                     <Stack sx = {{width: '15ch'}} marginLeft={2}>
//                                         <Typography fontSize={18} fontWeight={600}fontFamily={'cursive'}>Address:</Typography>
//                                     </Stack>
//                                     <Stack sx = {{width: '40ch'}} marginLeft={1}>
//                                         <Typography fontSize={16} fontWeight={400}>
//                                             {
//                                                 ` ${oneSiteAddressSelected ??''},
//                                                 ${oneSiteSuburbSelected ??''},
//                                                 ${oneSiteStateSelected??''},
//                                                 ${oneSitePostCodeSelected??''}
//                                                 `
//                                             }
//                                         </Typography>
//                                     </Stack>
//                                 </Stack>  

                            
//                                 <Stack direction="row" sx = {{width: '58ch', mt:'2ch'}}> 
//                                     <Stack sx = {{width: '15ch'}} ml={2}>
//                                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}>Phone: </Typography>
//                                     </Stack>
//                                     <Stack sx = {{width: '40ch'}} marginLeft={1}>
//                                         <Typography fontSize={16} fontWeight={400}>
//                                             {
//                                                 `${oneSitePhoneSelected??'Not Supplied'}`
//                                             }
//                                         </Typography>
//                                     </Stack>
//                                 </Stack>                              

//                                 <Stack direction="column" sx={{ ml:2, mt:3}} spacing={4} > 
//                                     <FormControl variant="outlined"> 
//                                         <InputLabel htmlFor="po_number" fontFamily={'cursive'}>Job Ref. Number</InputLabel>                
//                                             <OutlinedInput   
//                                                 label='Job Ref. Number'
//                                                 required
//                                                 value={inputs.po_number}//{jobRefNumber}
//                                                 size='small'
//                                                 id='po_number'
//                                                 name='po_number'
//                                                 sx = {{width: '50ch'}}
//                                                 onChange={(e)=>{setInputs(values=>({...values, [e.target.id]: e.target.value}))}}
//                                             /> 
//                                     </FormControl>

//                                     <FormControl variant="outlined" > 
//                                         <InputLabel htmlFor="purchaseOrder" >Purchase Order</InputLabel>                
//                                             <OutlinedInput  
//                                                 value = {inputs.purchaseOrder}    
//                                                 size='small'
//                                                 name='purchaseOrder'
//                                                 id='purchaseOrder'
//                                                 required
//                                                 sx = {{ width: '50ch',}}
//                                                 onChange={(e) => {
//                                                     setInputs(values=>({...values, [e.target.id]: e.target.value}))
                                                
                                                
//                                                 }}
//                                             />
//                                     </FormControl> 
                                        
//                                 </Stack>
//                             </Stack>
//                         }

//                         <Stack direction="column" spacing={1}  width={'40ch'}>
//                             <FormControl>
//                                 <Typography sx={{ml:1, fontSize:18, fontWeight:600,  }} > Priority*: </Typography>
//                                 {/* <FormLabel id="radio1" fontSize={18} fontWeight={800}>Priority</FormLabel> */}
//                                     <RadioGroup
//                                         row
//                                         name="priority"
//                                         sx={{width:'3ch'}}
//                                         id='radioGroup'
//                                         value={String(inputs.priority)}
//                                         onChange={(e) => {setInputs(values=>({...values, [e.target.name]: parseInt(e.target.value)}))}}
//                                     >
//                                         <FormControlLabel 
//                                             value="1" 
//                                             control={
//                                                 <Radio 
//                                                     size="medium" 
//                                                     sx={{color: red[800],'&.Mui-checked': {color: red[800],}}}
//                                                 />
//                                             } 
//                                             label="Extreme"  
//                                             fontFamily='cursive'
//                                             sx={{color: red[800],'&.Mui-checked': {color: purple[600],}}}
//                                         />
//                                         <FormControlLabel value="2" control={<Radio size="medium" sx={{color: purple[600],'&.Mui-checked': {color: purple[600],},}} />} label="Urgent" sx={{color: purple[600],'&.Mui-checked': {color: purple[600],},}}/>
//                                         <FormControlLabel value="3" control={<Radio size="medium" sx={{color: blue[800],'&.Mui-checked': {color: blue[600],},}}/>} label="Moderate" sx={{color: blue[800],'&.Mui-checked': {color: blue[600],},}}/>
//                                         <FormControlLabel value="4" control={<Radio size="medium" sx={{color: green[800],'&.Mui-checked': {color: green[600],},}}/>} label="Standart" sx={{color: green[800],'&.Mui-checked': {color: green[600],},}}/>
//                                         <FormControlLabel value="5" control={<Radio size="medium" sx={{color: yellow[800],'&.Mui-checked': {color: yellow[600],},}}/>} label="Low" sx={{color: yellow[800],'&.Mui-checked': {color: yellow[600]}, }} />
//                                         {/* <FormControlLabel value="6" control={<Radio size="small" sx={{color: pink[800],'&.Mui-checked': {color: pink[600],},}} />} label={inputs.priority} /> */}
//                                     </RadioGroup>
//                             </FormControl>

//                             <FormControl sx = {{width: '30ch'}}> 
//                             <Typography sx={{ml:1, fontSize:18, fontWeight:600,  }} >Status*: </Typography>

//                                 <Autocomplete
//                                     //value={statuses.statusName[1]??1} //disabled={action==='update'?true:false}         // defaultValue={statuses.statusName[0]??''}
//                                     name="statusName"
//                                     id='statusName'
//                                     size='small'
//                                     // filterOptions={filterOptions}
//                                     options={statuses.statusName}  //loading = {true}//{loading}// autoHighlight
//                                     onChange={(e, newValue) => { 
//                                         statuses.statusName.map((item, index)=>{
//                                             if(item===newValue){
//                                                 setInputs(values=>({...values, ['statusId']: statuses.statusId[index]}))
//                                                 setInputs(values=>({...values, ['statusName']: statuses.statusName[index]}))
//                                             }
//                                         })
//                                     }}
//                                     renderInput={(params) => (
//                                         <TextField
//                                             {...params}
//                                             placeholder={inputs.statusName} 
//                                             inputProps = {{...params.inputProps, type: 'search', }}        
//                                         />
//                                         )
//                                     }
//                                 />
//                             </FormControl>

//                             <FormControl sx = {{width: '30ch'}}> 
//                                 <Typography sx={{ml:1, fontSize:18, fontWeight:600,  }} >Request Type*: </Typography>
//                                 <Autocomplete
//                                     name="requestType"
//                                     id='requestType'
//                                     size='small'
//                                     // filterOptions={filterOptions}
//                                     options={requestTypes.requestTypeName}  
//                                     onChange={(e, newValue) => { 
//                                         requestTypes.requestTypeName.map((item, index)=>{
//                                             if(item===newValue){
//                                                 setInputs(values=>({...values, ['requestTypeId']: requestTypes.requestTypeId[index]}))
//                                                 setInputs(values=>({...values, ['requestType']: requestTypes.requestTypeName[index]}))
//                                             }
//                                         })
//                                     }}
//                                     renderInput={(params) => (
//                                         <TextField {...params}
//                                             placeholder={inputs.requestType}
//                                             inputProps = {{...params.inputProps, type: 'search', }}        
//                                         />
//                                         )
//                                     }
//                                 />
//                             </FormControl>

                            
//                         </Stack>         

  

//                     </Stack>     
//               </TabPanel>

    
//               <TabPanel value={value} index={2} x={'hello'}>
        
//                     <Stack direction="row" spacing={2} sx={{width:'175ch', height: '60ch', ml:1, border:'1px solid red'}}>
//                         <h2>ddddddd</h2>
  
//                   </Stack>       
                    
            
//               </TabPanel>
            
//           </Box>
//         );
//       }




//     useEffect(()=>{      
//         getAllStatuses();
//         //handleGetDataForUpdate()
//         getInitial();   
//         setLoaded(true);
//     }, []);

//     const handleGetDataForUpdate=()=>{
//         getJobDataForUpdate(`${DOMAIN}${process.env.REACT_APP_API_GET_JOB_DATA}${id}`);
//     }

//     const handleClose=()=>{
//         log(`I am out from ${action==='update'?"update":" add new"} job`, inputs);
//         navigate(-1);
//     }

//     const handleAdd=()=>{
//         process.env.REACT_APP_DEBUG && console.log(inputs);
//          //addNewJob(`${DOMAIN}${process.env.REACT_APP_API_ADD_NEW_JOB}`, inputs)
//         navigate(-1);
//     }

//     const handleUpdate=()=>{
//         process.env.REACT_APP_DEBUG && console.log(inputs);
//         process.env.REACT_APP_DEBUG && console.log(id);
//         updateJobData(`${DOMAIN}${process.env.REACT_APP_API_UPDATE_JOB_DATA}${id}`, inputs)
//         navigate(-1);
//     } 

//     async function updateJobData(Url, BodyInput) {    //console.log(BodyInput) /// text/plain; */*; charset=utf-8
//         const requestOptions = {   
//             method: 'PUT', 
//             body: JSON.stringify({ BodyInput }),    
//             headers: {'Content-Type':'application/json; charset=utf-8' },   
//         } 
//         const response = await fetch(Url, requestOptions);
//         const responseData = await response.json();  
//     }

//     const getSitesForSelectedCustomer = async(id)=>{
//         const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_SITES_PER_CUSOMER}${id??0}`)
//         const responseData = await response.json();  

//         setSiteSelected(
//             {
//                 siteName: responseData.map(a => a.siteName !== null?a.siteName:''), 
//                 siteID: responseData.map(a=>a.siteId),
//                 siteAddress1: responseData.map(a=>a.siteAddress1),
//                 siteSuburb: responseData.map(a=>a.siteSuburb),
//                 siteState: responseData.map(a=>a.siteState),
//                 sitePostCode: responseData.map(a=>a.sitePostCode),
//                 sitePhone: responseData.map(a=>a.sitePhone)
//             }
//         )
//         log(responseData.map(i=> i.siteName !== null?i.siteName:''))
//         log(responseData.map(i=>i.siteId))
//         log(responseData.map(i=>i.siteAddress1))
//     } 

//     async function getJobDataForUpdate(Url) {      
//         const response = await fetch(Url);
//         const responseData = await response.json();  
//         const data=responseData[0];

//         const response1 = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_NAME}${data.customerId}`)
//         const responseCustData = await response1.json();  
//         const dataCust=responseCustData[0];

//         const response2 = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_SITE}${data.siteId}`);
//         const responseSitetData = await response2.json();  
//         const dataSite=responseSitetData[0];

//         let keys= Object.keys(data);
//         keys.forEach(k=>{setInputs(values=>({...values, [k]: data[k]})) });
//         setText(data.faultDescription)


//         keys= Object.keys(dataCust);
//         keys.forEach(k=>{setInputs(values=>({...values, [k]: dataCust[k]}))});

//         keys= Object.keys(dataSite);
//         keys.forEach(k=>{setInputs(values=>({...values, [k]: dataSite[k]}))});

//         //setFaultDescription(data.faultDescription)
//        //setInputs(values=>({...values, ['statusId']: data['statusId']}))
//         setInputs(values=>({...values, ['siteState']: dataSite['siteState'].toUpperCase()}))
//         getAllStatuses()
//         statusUpdateId.current=data.statusId
//         requestTypeId.current=data.requestTypeId
//     }

//     const validateField = (inputText, searchedFlag)=>  {return searchedFlag==="" ? true: inputText.match(searchedFlag) ? true: inputText.length===0 ? true: false }

//     const handleChange=(e)=>{
//         const names= e.target.name
//         const value = e.target.value
//         process.env.REACT_APP_DEBUG && console.log(e)
//         //flag ? parseInt(value) : value
//         setInputs(values=>({...values, [names]: value}));  //value, valid: 
//     }  

//     const handleChange1 = (e) => {
//         process.env.REACT_APP_DEBUG && console.log(e.target.value)
//         //setClientName(e.target.value);
//     };

//     const setAllSitesData =(site, i)=>{
//         setOneSiteIDSelected(site.siteID[i]);
//         //setOneSiteNameSelected(site.siteName[i]);  
//         setOneSiteSuburbSelected(site.siteSuburb[i]);
//         setOneSiteStateSelected(site.siteState[i].toUpperCase());
//         setOneSitePostCodeSelected(site.sitePostCode[i]);
//         setOneSitePhoneSelected(site.sitePhone[i])
//         setOneSiteAddressSelected(site.siteAddress1[i]);
//         setInputs(values=>({...values, ['siteId']:site.siteID[i] }))
//     }

//     const clearAllSitesData= ()=>{
//         const empty=''
//         setOneSiteIDSelected(empty);
//         //setOneSiteNameSelected(empty); 
//         setOneSiteSuburbSelected(empty); 
//         setOneSiteStateSelected(empty); 
//         setOneSitePostCodeSelected(empty); 
//         setOneSitePhoneSelected(empty);
//         setOneSiteAddressSelected(empty); 
//         // setInputs(values=>({...values, ['purchaseOrder']:'' })) //po_number
//         // setInputs(values=>({...values, ['po_number']:'' })) //po_number
//         setInputs(values=>({...values, ['siteId']:empty}))

//     }
//     const getAllStatuses= async ()=>{
//         const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_ALL_STATI}`)
//         const responseStatusData = await response.json();  
//         const stName =  await responseStatusData.filter(i => i.statusId===statusUpdateId.current && i)
//         setStatuses(
//             {
//                 statusName:responseStatusData.map(i=>i.status), 
//                 statusId:responseStatusData.map(a=>a.statusId),
//             }
//         )
//         const resp = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_ALL_REQUST_TYPES}`)
//         const responseRequestTypeData = await resp.json(); 
//         setRequestTypes(
//             {
//                 requestTypeName:responseRequestTypeData.map(i=>i.requestType), 
//                 requestTypeId:responseRequestTypeData.map(a=>a.requestTypeId),
//             }
//         )

//         //setInputs(values=>({...values, ['statusId']: inputs.statusId}))
         
//         setInputs(values=>({...values, ['statusId']: 1}))
//         setInputs(values=>({...values, ['statusName']: 'New'}))
//         setInputs(values=>({...values, ['requestTypeId']:1}))
//         setInputs(values=>({...values, ['requestType']: 'Site Visit'}))

//         if(action==='update'){
//             // const stName =  await responseStatusData.filter(i => i.statusId===statusUpdateId.current && i)
//             console.log( stName[0].status  ) 
//             const  statusJobName = stName[0].status == undefined ? '' : stName[0].status
//             setInputs(values=>({...values, ['statusId']: statusUpdateId.current}))
//             setInputs(values=>({...values, ['statusName']: statusJobName}))

//             const rtName = responseRequestTypeData.filter(i=>i.requestTypeId===requestTypeId.current&&i)
//             const requestTypeJobName= rtName[0].requestType 
//             setInputs(values=>({...values, ['requestTypeId']: requestTypeId.current}))
//             setInputs(values=>({...values, ['requestType']: requestTypeJobName}))
//         }
//     }

//     const getInitial = async()=>{
//         const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMERS_NAMES}`);
//         const responseData = await response.json();  
//         setAllClientsNames(responseData.map(d=> d.customerName))      
//         setAllClientsIds(responseData.map(d=>{ return {name:d.customerName, id:d.customerId}}) )
//         setInputs(values=>({...values, ['priority']: 4}))
//         console.log(responseData.map(d=> d.customerName))
//     }

//     const filterOptions = createFilterOptions(
//         {
//             matchFrom: 'start',
//             ignoreAccents: true,
//             //stringify: (option) => option.title,
//             trim: true,
//             //ignoreCase:true,
//             //limit:10,
//         }
//     );

//     const toolbarOptions = [
//         ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
//         ['blockquote', 'code-block'],
      
//         [{ 'header': 1 }, { 'header': 2 }],               // custom button values
//         [{ 'list': 'ordered'}, { 'list': 'bullet' }],
//         [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
//         [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
//         [{ 'direction': 'rtl' }],                         // text direction
      
//         [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
//         [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
//         [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
//         [{ 'font': [] }],
//         [{ 'align': [] }],

//         ['link', 'image'],
      
//         ['clean']                                         // remove formatting button
//     ];

//     if (action ==='update' && loaded){
//         handleGetDataForUpdate()
//         setLoaded(false)
//     }

//     return (
//         <Box
//             component="form"   //noValidate       //border='1px solid red'
//             autoComplete="off"
//             height={'104ch'}
//             sx={{'& .MuiTextField-root': {m: .7}, 
//                  width:'100%', 
//                 // height:"100%",   
//                 '&:hover': { 
//                     //background: pink[600],
//                     opacity: [0.9, 0.8, 1],
//                  },
//             } }
//         >
//             <Stack 
//                 direction="row" 
//                 justifyContent="center" 
//                 alignItems="center" 
//                 sx={{ mb:8, mt:4, mr:20 }} 
//             >       
//                 <Typography 
//                     //sx={{ flex: '1 1 100%' }}
//                     variant="div" 
//                     className='onefive'
//                     fontSize={22} 
//                     fontWeight={800}
//                     fontFamily={'cursive'}
                
//                 >
//                     {action==='update'?`Job No: ${inputs.jobNo}`: "Add New Job"}
//                 </Typography>
//             </Stack>



//             <BasicTabs/>
            
//             <Stack direction="row" spacing={4}  sx={{pb:50, ml:'20ch'}}>

//                 <Button 
//                     className='Button1'
//                     onClick={action==='update'? handleUpdate : handleAdd }
//                     variant="outlined"
//                     size='small'
//                     sx={{marginLeft:40, fontWeight:600, fontSize:16,  }} 
//                 >
//                     {action==='update'? 'Update ' : 'Add '} 
//                 </Button>

//                 <Button 
//                     className='Button1'
//                     onClick={handleClose} 
//                     variant="outlined" 
//                     size='small'
//                     //color="success" 
//                     sx={{ fontWeight:600, fontSize:16,  }}
//                 > 
//                     Close 
//                 </Button>
//             </Stack>    
   
//         </Box>  

//   )
// }

// export default NewJob










                // {/* <Stack direction="row" sx = {{width: '58ch', mt:'1.5ch' }}> 
                //     <Stack sx = {{width: '20ch'}} marginLeft={2}>
                //         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}>Site ID: </Typography>
                //     </Stack>
                //     <Stack sx = {{width: '30ch'}} marginLeft={1}>
                //         <Typography fontSize={16} fontWeight={400}>{oneSiteIDSelected??''}</Typography>
                //     </Stack>
                // </Stack>   */}

                
                //  {/* <Typography marginLeft={1} fontSize={18} fontWeight={800}> 
                //     {`  ${inputs.requestTypeId} = ${inputs.requestType}, 
                //         ${inputs.statusId} = ${inputs.statusName},  ${inputs.priority} =  Priority        `}  
                // </Typography>  */}

                // {/* //={`${statuses.statusName[0]} ${statuses.statusId[3]} ${inputs.statusId}`}     */}







                    
    //         <Stack 
    //         direction="row" 
    //         spacing={2}
    //         //border='1px solid black'  
    //         sx={{width:'170ch', height: '60ch', ml:1}}
    //    >
    //         {action ==='update'? 
    //             <Stack direction="column" sx = {{width: '58ch'}}>

    //                 <Stack direction="row" sx = {{width: '52ch', height: '6ch'}} > 
    //                     <Stack sx = {{width: '20ch', ml:1}}> 
    //                         <Typography 
    //                             fontSize={18} 
    //                             fontWeight={600}
    //                             fontFamily={'cursive'}
    //                         > Customer: </Typography>
    //                     </Stack>
    //                     <Stack sx = {{width: '30ch', ml:'1ch'}} > 
    //                         <Typography  sx={{ color: pink[800] }} fontSize={18} fontWeight={600} fontFamily={'cursive'}>
    //                             {inputs.customerName??''} 
    //                         </Typography>
    //                     </Stack>
    //                 </Stack> 

    //                 <Stack direction="row" sx = {{width: '65ch', height: '6ch'}}> 
    //                     <Stack sx = {{width: '20ch', ml:'1ch'}} >
    //                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}> Site ID: </Typography>
    //                     </Stack>
    //                     <Stack sx = {{width: '40ch', ml:'1ch'}} >
    //                         <Typography fontSize={16} fontWeight={400} fontFamily={'cursive'}>{inputs.siteId??''} </Typography>
    //                     </Stack>
    //                 </Stack> 

    //                 <Stack direction="row" sx = {{width: '65ch', height: '6ch'}} > 
    //                     <Stack sx = {{width: '20ch', ml:'1ch'}} >
    //                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}>Name:</Typography>
    //                     </Stack>
    //                     <Stack sx = {{width: '40ch', ml:'1ch'}} >
    //                         <Typography fontSize={16} fontWeight={400} fontFamily={'cursive'}>{inputs.siteName??''} </Typography>
    //                     </Stack>
    //                 </Stack> 

    //                 <Stack direction="row" sx = {{width: '52ch', height: '14ch'}}> 
    //                     <Stack sx = {{width:'20ch', ml:'1ch'}} >
    //                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}> Address: </Typography>
    //                     </Stack>
    //                     <Stack sx = {{width: '30ch', ml:'1ch'}} >
    //                         <Typography fontSize={16} fontWeight={400} fontFamily={'cursive'}>
    //                             { `${inputs.siteAddress1??''}, ${inputs.siteSuburb??''},  
    //                                 ${inputs.siteState??''},  
    //                                 ${inputs.sitePostCode??''}`
    //                             } 
    //                         </Typography>
    //                     </Stack>
                            
    //                 </Stack>  

    //                 <Stack direction="row" sx = {{width: '52ch', height: '6ch'}}> 
    //                     <Stack sx = {{width: '20ch', ml:'1ch'}} >
    //                         <Typography  fontSize={18} fontWeight={600} fontFamily={'cursive'}> Phone: </Typography>
    //                     </Stack>
    //                     <Stack sx = {{width: '30ch', ml:'1ch'}} >
    //                         <Typography  fontSize={16} fontWeight={400} fontFamily={'cursive'}>{inputs.sitePhone??''} </Typography>
    //                     </Stack>
    //                 </Stack>

    //                 <Stack direction="row" sx = {{width: '52ch', height: '6ch'}}> 
    //                     <Stack sx = {{width: '20ch', ml:'1ch'}} >
    //                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}>Job Ref No: </Typography>
    //                     </Stack>
    //                     <Stack sx = {{width: '30ch', ml:'1ch'}} >
    //                         <Typography fontSize={16} fontWeight={400} fontFamily={'cursive'}>{inputs.po_number??""} </Typography>
    //                     </Stack>
    //                 </Stack> 

    //                 <Stack direction="row" sx = {{width: '52ch', height: '6ch'}}> 
    //                     <Stack sx = {{width: '20ch', ml:'1ch'}} >
    //                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}>Purchase Order: </Typography>
    //                     </Stack>
    //                     <Stack sx = {{width: '30ch', ml:'1ch', }}>
    //                         <Typography fontSize={16} fontWeight={400}fontFamily={'cursive'}>{inputs.purchaseOrder??""} </Typography>
    //                     </Stack>
    //                 </Stack>  
    //             </Stack>
    //             ///////////UPDATE JOB end/////////
    //         :
    //             /////// ADD NEW JOB start
    //             <Stack direction="column" sx = {{width: '60ch'}} >
                    
    //                 <FormControl sx = {{width: '40ch', ml:1}}> 
    //                     <Autocomplete
    //                         value={inputs.customerName}////    value={clientName??"undefined"}///{clientName} //disabled={action==='update'?true:false}
    //                         //inputValue={inputs.customerName}
    //                         required
    //                         id="customerId"
    //                         name='customerName'
    //                         options={allClientsNames}
    //                         isOptionEqualToValue={(option, value) => option.value === value.value}
    //                         //disableClearable
    //                         size='small'
    //                         filterOptions={filterOptions}
    //                         onChange={(event, newValue) => {
    //                                 clearAllSitesData()
    //                                 setInputs(values=>({...values, ['customerName']: newValue}))
    //                                 const a = allClientsIds.filter((i)=>`${i.name}`=== newValue && i.id)
    //                                 setInputs(values=>({...values, ['customerId']:a[0].id }))
    //                                 getSitesForSelectedCustomer(a[0].id > 0 ? a[0].id : '' )
    //                             } 
    //                         }


    //                         // onInputChange={(event, newInputValue) => {
    //                         //          {console.log(allClientsNames)}
    //                         //          {console.log(newInputValue)}
    //                         //         //newInputValue===""&& clearAllSitesData()   
    //                         //                 clearAllSitesData()
    //                         //                 setInputs(values=>({...values, ['customerName']: newInputValue}))
    //                         //                 const a = allClientsIds.filter((i)=>`${i.name}`=== newInputValue && i.id)
    //                         //                 setInputs(values=>({...values, ['customerId']:a[0].id }))
    //                         //                 getSitesForSelectedCustomer(a[0].id > 0 ? a[0].id:'' )                            
    //                         // } }//loading = {true}//{loading}// autoHighlight
                                                                
    //                         renderInput={(params) => (
    //                             <TextField
    //                                 {...params}
    //                                 //disableClearable
    //                                 label="Customers" //{action ==='update'? clientNameFromUpdate : "Clients List"} //value={clientName??"Not defined"}required  // helperText = { selectedClientName ===''?"Select value":""}  // error ={selectedClientName === ''? true:false}
    //                                 inputProps = {{...params.inputProps, type: 'search', }}    
    //                             />
    //                         )}
    //                     />
    //                 </FormControl>


    //                 <FormControl  sx = {{width:'40ch', my:2, ml:1}}> 

    //                     <Autocomplete
    //                         //value={siteSelected.length>0?'Sites being loaded':'Select Sites'}
    //                         required 
    //                         isOptionEqualToValue={(option, value) => option.value === value.value} 
    //                         filterOptions={filterOptions}
    //                         name='siteId'
    //                         size='small'
    //                         //disableClearable={false}
    //                         disabled={!inputs.customerName}
    //                         autoHighlight  //loading = {true}
    //                         options={siteSelected.siteName }
    //                         onChange={(event, newValue) => {
    //                             siteSelected.siteName.map((val, index)=>val== newValue && setAllSitesData(siteSelected, index ));     
    //                             {console.log(newValue, siteSelected.siteName)} //siteSelected.siteName.map((val, index)=>val === newValue &&  setInputs(values=>({...values, ['siteId']:siteSelected.siteId[index] })));  
    //                             }
    //                         }                                    
    //                         // onInputChange={(event, newInputValue) => {
    //                         //         // {console.log(newInputValue)}
    //                         //         newInputValue===""&& clearAllSitesData()
    //                         //         //setAll(...newInputValue )                                    
    //                         //     } 
    //                         // }
    //                         renderInput={(params) => (
    //                                 <TextField  {...params}
    //                                     label="Sites" //{action ==='update'? clientNameFromUpdate : "Clients List"}
    //                                     inputProps = {{...params.inputProps, type: 'search', }}           
    //                                 />
    //                             )
    //                         }
    //                     />
    //                 </FormControl>    



                    
    //                 <Stack direction="row" sx = {{width: '58ch', mt:'2ch'}}> 
    //                     <Stack sx = {{width: '15ch'}} marginLeft={2}>
    //                         <Typography fontSize={18} fontWeight={600}fontFamily={'cursive'}>Address:</Typography>
    //                     </Stack>
    //                     <Stack sx = {{width: '40ch'}} marginLeft={1}>
    //                         <Typography fontSize={16} fontWeight={400}>
    //                             {
    //                                 ` ${oneSiteAddressSelected ??''},
    //                                 ${oneSiteSuburbSelected ??''},
    //                                 ${oneSiteStateSelected??''},
    //                                 ${oneSitePostCodeSelected??''}
    //                                 `
    //                             }
    //                         </Typography>
    //                     </Stack>
    //                 </Stack>  

                
    //                 <Stack direction="row" sx = {{width: '58ch', mt:'2ch'}}> 
    //                     <Stack sx = {{width: '15ch'}} ml={2}>
    //                         <Typography fontSize={18} fontWeight={600} fontFamily={'cursive'}>Phone: </Typography>
    //                     </Stack>
    //                     <Stack sx = {{width: '40ch'}} marginLeft={1}>
    //                         <Typography fontSize={16} fontWeight={400}>
    //                             {
    //                                 `${oneSitePhoneSelected??'Not Supplied'}`
    //                             }
    //                         </Typography>
    //                     </Stack>
    //                 </Stack>                              

    //                 <Stack direction="column" sx={{ ml:2, mt:3}} spacing={4} > 
    //                     <FormControl variant="outlined"> 
    //                         <InputLabel htmlFor="po_number" fontFamily={'cursive'}>Job Ref. Number</InputLabel>                
    //                             <OutlinedInput   
    //                                 label='Job Ref. Number'
    //                                 required
    //                                 value={inputs.po_number}//{jobRefNumber}
    //                                 size='small'
    //                                 id='po_number'
    //                                 name='po_number'
    //                                 sx = {{width: '50ch'}}
    //                                 onChange={(e)=>{setInputs(values=>({...values, [e.target.id]: e.target.value}))}}
    //                             /> 
    //                     </FormControl>

    //                     <FormControl variant="outlined" > 
    //                         <InputLabel htmlFor="purchaseOrder" >Purchase Order</InputLabel>                
    //                             <OutlinedInput  
    //                                 value = {inputs.purchaseOrder}    ///{pOrder} 
    //                                 size='small'
    //                                 name='purchaseOrder'
    //                                 id='purchaseOrder'
    //                                 required
    //                                 sx = {{ width: '50ch',}}
    //                                 onChange={(e) => {setInputs(values=>({...values, [e.target.id]: e.target.value}))}}
    //                             />
    //                     </FormControl> 
                            
    //                 </Stack>
    //             </Stack>
    //         }

    //         <Stack direction="column" spacing={1}  width={'40ch'}>
    //             <FormControl>
    //                 <Typography sx={{ml:1, fontSize:18, fontWeight:600,  }} > Priority*: </Typography>
    //                 {/* <FormLabel id="radio1" fontSize={18} fontWeight={800}>Priority</FormLabel> */}
    //                     <RadioGroup
    //                         row
    //                         name="priority"
    //                         sx={{width:'3ch'}}
    //                         id='radioGroup'
    //                         value={String(inputs.priority)}
    //                         onChange={(e) => {setInputs(values=>({...values, [e.target.name]: parseInt(e.target.value)}))}}
    //                     >
    //                         <FormControlLabel 
    //                             value="1" 
    //                             control={
    //                                 <Radio 
    //                                     size="medium" 
    //                                     sx={{color: red[800],'&.Mui-checked': {color: red[800],}}}
    //                                 />
    //                             } 
    //                             label="Extreme"  
    //                             fontFamily='cursive'
    //                             sx={{color: red[800],'&.Mui-checked': {color: purple[600],}}}
    //                         />
    //                         <FormControlLabel value="2" control={<Radio size="medium" sx={{color: purple[600],'&.Mui-checked': {color: purple[600],},}} />} label="Urgent" sx={{color: purple[600],'&.Mui-checked': {color: purple[600],},}}/>
    //                         <FormControlLabel value="3" control={<Radio size="medium" sx={{color: blue[800],'&.Mui-checked': {color: blue[600],},}}/>} label="Moderate" sx={{color: blue[800],'&.Mui-checked': {color: blue[600],},}}/>
    //                         <FormControlLabel value="4" control={<Radio size="medium" sx={{color: green[800],'&.Mui-checked': {color: green[600],},}}/>} label="Standart" sx={{color: green[800],'&.Mui-checked': {color: green[600],},}}/>
    //                         <FormControlLabel value="5" control={<Radio size="medium" sx={{color: yellow[800],'&.Mui-checked': {color: yellow[600],},}}/>} label="Low" sx={{color: yellow[800],'&.Mui-checked': {color: yellow[600]}, }} />
    //                         {/* <FormControlLabel value="6" control={<Radio size="small" sx={{color: pink[800],'&.Mui-checked': {color: pink[600],},}} />} label={inputs.priority} /> */}
    //                     </RadioGroup>
    //             </FormControl>

    //             <FormControl sx = {{width: '30ch'}}> 
    //             <Typography sx={{ml:1, fontSize:18, fontWeight:600,  }} >Status*: </Typography>

    //                 <Autocomplete
    //                     //value={statuses.statusName[1]??1} //disabled={action==='update'?true:false}         // defaultValue={statuses.statusName[0]??''}
    //                     name="statusName"
    //                     id='statusName'
    //                     size='small'
    //                     // filterOptions={filterOptions}
    //                     options={statuses.statusName}  //loading = {true}//{loading}// autoHighlight
    //                     onChange={(e, newValue) => { 
    //                         statuses.statusName.map((item, index)=>{
    //                             if(item===newValue){
    //                                 setInputs(values=>({...values, ['statusId']: statuses.statusId[index]}))
    //                                 setInputs(values=>({...values, ['statusName']: statuses.statusName[index]}))
    //                             }
    //                         })
    //                     }}
    //                     renderInput={(params) => (
    //                         <TextField
    //                             {...params}
    //                             placeholder={inputs.statusName} 
    //                             inputProps = {{...params.inputProps, type: 'search', }}        
    //                         />
    //                         )
    //                     }
    //                 />
    //             </FormControl>

    //             <FormControl sx = {{width: '30ch'}}> 
    //                 <Typography sx={{ml:1, fontSize:18, fontWeight:600,  }} >Request Type*: </Typography>
    //                 <Autocomplete
    //                     name="requestType"
    //                     id='requestType'
    //                     size='small'
    //                     // filterOptions={filterOptions}
    //                     options={requestTypes.requestTypeName}  
    //                     onChange={(e, newValue) => { 
    //                         requestTypes.requestTypeName.map((item, index)=>{
    //                             if(item===newValue){
    //                                 setInputs(values=>({...values, ['requestTypeId']: requestTypes.requestTypeId[index]}))
    //                                 setInputs(values=>({...values, ['requestType']: requestTypes.requestTypeName[index]}))
    //                             }
    //                         })
    //                     }}
    //                     renderInput={(params) => (
    //                         <TextField {...params}
    //                             placeholder={inputs.requestType}
    //                             inputProps = {{...params.inputProps, type: 'search', }}        
    //                         />
    //                         )
    //                     }
    //                 />
    //             </FormControl>
    //         </Stack>         

    //         <Stack direction="column" sx = {{ width: '80ch', }} > 
    //                 <Typography sx={{ml:1, fontSize:18, fontWeight:600,  }} > Fault Description: </Typography> 
    //                 <ReactQuill 
    //                     ///theme="snow"
    //                     id="faultDescription"
    //                     className ='faultDescription'
    //                     value={inputs.faultDescription??''} 
    //                     //defaultValue ={inputs.faultDescription??''} 
    //                     placeholder ="Fault Description"
    //                     modules={{
    //                         'history': {         
    //                             'delay': 2500,
    //                             'userOnly': true
    //                         },   
    //                         'toolbar': toolbarOptions
    //                     }}         
    //                     onChange={(content, delta, source, editor)=>{
    //                         process.env.REACT_APP_DEBUG==='on' && console.log( delta)
    //                         setInputs(values=>({...values, ['faultDescription']: content}))
    //                     }}  
    //                 />
    //             {/* </FormControl> */}
    //         </Stack>    

    //     </Stack>     
     
    //     <Stack direction="row" spacing={4}  sx={{pb:50, ml:'20ch'}}>

    //         <Button 
    //             className='Button1'
    //             onClick={action==='update'? handleUpdate : handleAdd }
    //             variant="outlined"
    //             size='small'
    //             sx={{marginLeft:40, fontWeight:600, fontSize:16,  }} 
    //         >
    //             {action==='update'? 'Update ' : 'Add '} 
    //         </Button>

    //         <Button 
    //             className='Button1'
    //             onClick={handleClose} 
    //             variant="outlined" 
    //             size='small'
    //             //color="success" 
    //             sx={{ fontWeight:600, fontSize:16,  }}
    //         > 
    //             Close 
    //         </Button>
    //     </Stack>     


                                                //     {/* <Stack direction="row" sx = {{width: '65ch', height: '6ch'}}> 
                                                //     <Stack sx = {{width: '20ch', ml:'1ch'}} >
                                                //         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Reference No: </Typography>
                                                //     </Stack>
                                                //     <Stack sx = {{width: '30ch', ml:'1ch'}} >
                                                //         <Typography fontSize={16} fontWeight={400} fontFamily={'cursive'}>{inputs.cw_number??""} </Typography>
                                                //     </Stack>
                                                // </Stack> 

                                                // <Stack direction="row" sx = {{width: '65ch', height: '6ch'}}> 
                                                //     <Stack sx = {{width: '20ch', ml:'1ch'}} >
                                                //         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Purchase Order: </Typography>
                                                //     </Stack>
                                                //     <Stack sx = {{width: '30ch', ml:'1ch', }}>
                                                //         <Typography fontSize={16} fontWeight={400}fontFamily={'cursive'}>{inputs.po_number ?? ''} </Typography>
                                                //     </Stack>
                                                // </Stack>   */}


                                                
    // const dateConverter = (inputDate)=>{
    //     let mainDate;
    //     console.log(typeof inputDate === 'number' ? 'date passed as number' :'date passed as string')
    //     if (typeof inputDate === 'number'){
    //         mainDate = new Date(inputDate *1000)

    //     }else{
    //         mainDate =  new Date(parseInt(inputDate.padEnd(13,"0"))) 

    //     }
        
    //     // convert from 10 numbers to 13 numbers   new Date(parseInt(inputDate.padEnd(13,"0"))) ///
    //     let options = {
    //         //weekday: "short",
    //         day:"numeric",
    //         month:"numeric",
    //         year:"numeric",

    //         hour: "numeric",
    //         //era: "long",
    //         //dayPeriod:"long",
    //         //dayPeriod: "short",
    //         minute: "numeric",
    //         //second: "numeric",
    //         //timeZone: "Australia/Sydney",
    //         //hour12: false,
    //         //timeZoneName: "short",
    //     };
    //     //  let a = Date.parse(new Date).toString().slice(0,10)
    //     //  let b = Date.parse(new Date)
    //     //  console.log(a, b)
    //     return mainDate.toLocaleString('en-AU', options)
    // };












//     <Stack  direction="column" sx={{ml:1, gap:2, mt:2}}>

//     <Stack  direction="row">
//         <Typography sx={{ fontSize:16, fontWeight:600, width:'12ch',  }} >
//             {inputs.dateCreated == undefined || inputs.dateCreated == null || inputs.dateCreated == '' ? '': `Created on: ` } 
//         </Typography>
//         {/* {console.log(inputs.dateCreated == undefined || inputs.dateCreated == null || inputs.dateCreated == '' ? '': dateConverter(inputs.dateCreated))} */}
//         <Typography sx={{ fontSize:14, fontWeight:400,  , pl:3}} >
//             {inputs.dateCreated == undefined || inputs.dateCreated == null || inputs.dateCreated == '' ? '': ` ${dateConverter(inputs.dateCreated)}` }                                      
//         </Typography>
//     </Stack>


//     <Stack  direction="row" >
//         <Typography sx={{ fontSize:16, fontWeight:600, width:'12ch',  }} >SLA: </Typography>
//         {/* {console.log(inputs.dateCreated == undefined || inputs.dateCreated == null || inputs.dateCreated == '' ? '': dateConverter(inputs.dateCreated))} */}
//         <Typography sx={{ color: Date.now() > inputs.slaDate *1000 ?'red':'',  fontSize:14, fontWeight:400,  , pl:3}} >
//             {inputs.slaDate == undefined || inputs.slaDate == null || inputs.slaDate == '' ? '': `${dateConverter(inputs.slaDate)}` }{Date.now() > inputs.slaDate * 1000 ?' SLA is missed':''}                                                
//         </Typography>
//     </Stack>

//     <Stack  direction="row" >
//         <Typography sx={{fontSize:16,fontWeight:600,width:'12ch', }}>
//             {inputs.userFullName  == undefined || inputs.userFullName == null || inputs.userFullName == '' ?
//                 '':
//                 `Logged By : `
//             }
//         </Typography>

//         <Typography 
//             sx={{ fontSize:14, fontWeight:400,  , pl:3}} 
//         > 
//             {inputs.userFullName  == undefined || inputs.userFullName == null || inputs.userFullName == '' ?
//                 '': 
//                 inputs.userFullName
//             }
//         </Typography>
//     </Stack>

    
//     <Stack direction="row" sx={{ width:'53ch',   }}> 
//             <Typography sx={{ fontSize:16, fontWeight:600, width:'12ch',  , ml:0}} >Work Order: </Typography>
//             <Typography sx={{ fontSize:14, fontWeight:400, width:'34ch',  , pl:3}} >
//                 {inputs.po_number ?? ''} 
//             </Typography>
//     </Stack>    

//     {inputs.siteSupplierAccepted ==1 && 
//         <Stack direction="row" sx={{ width:'53ch',   }}> 

//             <Typography sx={{ fontSize:16, fontWeight:600, width:'12ch',  , ml:0}} >
//                 {`Job accepted:  `}
//             </Typography>
//             <Typography sx={{ fontSize:14, fontWeight:400, width:'34ch',  , pl:3}} >
//                 {dateConverter(inputs.status_time)}
//             </Typography>
//         </Stack>     
//     }                                  
    
// </Stack>


                                                            // {/* <Stack direction="row" sx = {{width: '80ch', height:'6ch'}}  > 
                                                            //     <Stack sx = {{width: '20ch'}}> 
                                                            //         <Typography  fontSize={16}  fontWeight={600}  fontFamily={'cursive'} >Contractor:</Typography>
                                                            //     </Stack>
                                                            //     <Stack sx = {{width: '55ch'}} > 
                                                            //         <Typography  sx={{ color: pink[800] }} fontSize={16} fontWeight={600} fontFamily={'cursive'}>
                                                            //             {inputs.supplier??''} 
                                                            //         </Typography>
                                                            //     </Stack>
                                                            // </Stack>  */}
            
                                        
                                                            // {/* <Stack direction="row" sx = {{width: '110ch', height: '6ch' }}> 
                                                            
                                                            //     <Stack sx = {{width:'20ch'}} >
                                                            //         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}> Address: </Typography>
                                                            //     </Stack>
                                                            //     <Stack sx = {{width: '90ch'}} >
                                                            //         <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>
                                                            //         { `${inputs.supplierPostalAddress ?? ''}`    } 
                                                            //         </Typography>
                                                            //     </Stack>
                                                                    
                                                            // </Stack>   */}
            
                                                            // {/* <Stack direction="row" sx = {{width: '65ch', height: '6ch'}}> 
                                                            //     <Stack sx = {{width: '20ch'}} >
                                                            //         <Typography  fontSize={16} fontWeight={600} fontFamily={'cursive'}> Phone: </Typography>
                                                            //     </Stack>
                                                            //     <Stack sx = {{width: '30ch'}} >
                                                            //         <Typography  fontSize={14} fontWeight={400} fontFamily={'cursive'}>{inputs.supplierPhone??''} </Typography>
                                                            //     </Stack>
                                                            // </Stack>
            
                                                            // <Stack direction="row" sx = {{width: '65ch', height: '6ch'}}> 
                                                            //     <Stack sx = {{width: '20ch'}} >
                                                            //         <Typography  fontSize={16} fontWeight={600} fontFamily={'cursive'}> Email: </Typography>
                                                            //     </Stack>
                                                            //     <Stack sx = {{width: '30ch'}} >
                                                            //         <Typography  fontSize={14} fontWeight={400} fontFamily={'cursive'}>
                                                            //         {inputs.supplierEmail??''} 
                                                            //         </Typography>
                                                            //     </Stack>
                                                            
                                                            // </Stack> */}
            
            
                                                            // {/* <Stack direction="row" sx = {{ height: '6ch'}}> 
                                                            //     <Stack sx = {{width: '20ch'}} >
                                                            //         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Latitude: </Typography>
                                                            //     </Stack>
                                                            //     <Stack sx = {{width: '30ch'}} >
                                                            //         <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{centerContract.lat??""} </Typography>
                                                            //     </Stack>
                                                            // </Stack> 
            
                                                            // <Stack direction="row" sx = {{width: '70ch', height: '6ch'}}> 
                                                            //     <Stack sx = {{width: '20ch'}} >
                                                            //         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Longitude: </Typography>
                                                            //     </Stack>
                                                            //     <Stack sx = {{width: '30ch',  }}>
                                                            //         <Typography fontSize={14} fontWeight={400}fontFamily={'cursive'}>{centerContract.lng??""}  </Typography>
                                                            //     </Stack>
                                                            // </Stack>   */}



        // {/* // <Stack direction="row" sx = {{width: '70ch'}}> 
        //     //     <Stack sx = {{width: '20ch'}} >
        //     //         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Technician SID: </Typography>
        //     //     </Stack>
        //     //     <Stack sx = {{width: '45ch', ml:1 }}>
        //     //         <Typography fontSize={14} fontWeight={400}fontFamily={'cursive'}>{inputs.sheqsyUId }  </Typography>
        //     //     </Stack>
        //     // </Stack>
        //      */}
    

        // {/* <Stack direction="row" sx = {{width: '65ch', mt:2}} > 
        //     <Stack sx = {{width: '20ch', }} >
        //         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Technician Name:</Typography>  
        //     </Stack>
        //     <Stack sx = {{width: '40ch', ml:1}} >
        //         <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{inputs.supplierContactPerson??''} </Typography>
        //     </Stack>
        // </Stack>  */}


        
                                                        // <Stack  name='FIELDs ON ADD'
                                                        //     sx = {{width: '190ch',  mt:2}} gap={2}
                                                        // >
    
                                                        //     <Stack direction="row" sx = {{width: '190ch'}} > 
                                                        //         <Stack sx = {{width: '15ch', ml:1}} >
                                                        //             <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Site Name:</Typography>
                                                        //         </Stack>
                                                        //         <Stack sx = {{width: '150ch', ml:1}} >
                                                        //             <Typography  sx={{ color: pink[800] }}  fontSize={16} fontWeight={600} fontFamily={'cursive'}>{oneSiteNameSelected ?? ''} </Typography>
                                                        //         </Stack>
                                                        //     </Stack> 
    
                                                        //     <Stack direction="row" sx = {{width: '120ch', }}> 
                                                        //         <Stack sx = {{width: '15ch', ml:1}} >
                                                        //             <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Address:</Typography>
                                                        //         </Stack>
    
                                                        //         <Stack sx = {{width: '104ch', ml:1}} >
                                                        //             <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}> {destination} </Typography>
                                                        //         </Stack>
                                                        //     </Stack> 
    
                                                        //     <Stack direction="row" sx = {{width: '60ch',}}> 
                                                        //         <Stack sx = {{width: '15ch'}} ml={1}>
                                                        //             <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Phone: </Typography>
                                                        //         </Stack>
                                                        //         <Stack sx = {{width: '40ch'}} marginLeft={1}>
                                                        //             <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>
                                                        //                 {`${oneSitePhoneSelected??'Not Supplied'}` }
                                                        //             </Typography>
                                                        //         </Stack>
                                                        //     </Stack> 
    
                                                        //     <Stack direction="row" sx = {{width: '60ch', }}> 
                                                        //         <Stack sx = {{width: '15ch'}} ml={1}> <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Zone: </Typography> </Stack>
                                                        //         <Stack sx = {{width: '40ch'}} marginLeft={1}> 
                                                        //             <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}> 
                                                        //                 {`${inputs.siteZone ??'Not Supplied'}`} 
                                                        //             </Typography>             
                                                        //         </Stack>
                                                        //     </Stack> 
                                                    
                                                        //     <Stack direction="row" sx = {{width: '60ch'}} > 
                                                        //         <Stack sx = {{width: '15ch', ml:1}} >
                                                        //             <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Contact:</Typography>
                                                        //         </Stack>
                                                        //         <Stack sx = {{width: '40ch', ml:1}} >
                                                        //             <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{inputs.siteManager??''} </Typography>
                                                        //         </Stack>
                                                        //     </Stack>                                             
    
                                                        // </Stack>















                                                        // <Box
        //     //className ='container'
        //     //width= "100%"
        //     component="form"   //noValidate    
        //     autoComplete="off"
        //     //height= '100%' //height={'104ch'}            //.border = '1px solid red'
        //     //sx={{'& .MuiTextField-root': { ml:33, width: '100ch'}, }} 
        // >

        //     <Stack name='HEADER' direction="row" justifyContent="center" alignItems="center" sx={{ flex: '1 1 100%' , my:2,}} >       
        //         <Typography
        //             fontSize={22} 
        //             fontWeight={800}
        //             fontFamily={'cursive'}
        //             color='darkblue'
        //         >{action==='update'?`Update Job No: ${inputs.jobNo}`: "Add New Job"}   </Typography>
        //     </Stack>

        //     <Stack name='TABS' border={'1px solid gray'}  >    
             
        //         <Tabs 
        //             //justifyContent='normal'
        //             spacing='relative'  
        //             marginTop={0}
        //             paddingBottom={1}
        //             border={'1px solid lightgray'}
        //             onChange={(v)=>handleTabClick(parseInt(v))}
        //         >

        //             {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  SITE DETAILS     >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

        //             <TabItem   
        //                 title={<Text fontFamily={'cursive'} fontSize={14} fontWeight={800}>Site</Text>}  
        //                 backgroundColor={0 ===tabValue ?'aquamarine':'aliceblue'}
        //                 border={borderSize( 0, tabValue)}
        //                 borderRadius='15ch'                        //width={110}           //onClick={()=>handleNewTabClick(0)}  
        //             >     
                

                        
        //                     <Stack direction="row" sx = {{ width: '155ch', height:'75ch', mt:1,}} >  

        //                         <Stack direction="column"  sx={{width:'185ch', height:'85ch',}}>

        //                             {action ==='update'? 
        //                                 <Stack direction="row" sx = {{width: '170ch'}}>

        //                                     <Stack direction="column" gap={1} sx = {{width: '50ch', mt:2, border:'1px solid red'}}>

        //                                         <Stack direction="row" sx = {{width: '65ch', height:'4ch'}} > 
        //                                             <Stack sx = {{width: '20ch', ml:1}}> 
        //                                                 <Typography  fontSize={16} fontWeight={600}  fontFamily={'cursive'}>Customer: </Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '30ch'}} > 
        //                                                 <Typography  sx={{ color: pink[800] }} fontSize={18} fontWeight={600} fontFamily={'cursive'}>
        //                                                     {inputs.customerName ?? ''} 
        //                                                 </Typography>
        //                                             </Stack>
        //                                         </Stack> 

        //                                         <Stack direction="row" sx = {{width: '105ch', height: '4ch'}} > 
        //                                             <Stack sx = {{width: '20ch', ml:'1ch'}} >
        //                                                 <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}> Name:</Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '65ch', ml:'0ch'}} >
        //                                                 <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{inputs.siteName??''} </Typography>
        //                                             </Stack>
        //                                         </Stack> 

        //                                         <Stack direction="row" sx = {{width: '65ch', height: '4ch'}} > 
        //                                             <Stack sx = {{width: '20ch', ml:'1ch'}} >
        //                                                 <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Zone:</Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '40ch', ml:'0ch'}} >
        //                                                 <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{inputs.siteZone??''} </Typography>
        //                                             </Stack>
        //                                         </Stack> 

        //                                         <Stack direction="row" sx = {{width: '95ch', height: '4ch', mr:'20ch' }}> 
        //                                             <Stack sx = {{width:'20ch', ml:'1ch'}} >
        //                                                 <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Address: </Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '60ch'}} >
        //                                                 <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{ inputs.siteAddress1??''}</Typography>
        //                                             </Stack>    
        //                                         </Stack>  

        //                                     </Stack>

        //                                     <Stack direction="column" border ='1px solid red'  sx = {{width: '40ch', height:'10ch', ml:15, mt:2}}>

        //                                         <Stack direction="row" sx = {{width: '65ch', height: '5ch'}}> 
        //                                             <Stack sx = {{width: '20ch', ml:'1ch'}} >
        //                                                 <Typography  fontSize={16} fontWeight={600} fontFamily={'cursive'}> Phone: </Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '30ch', ml:'.8ch'}} >
        //                                                 <Typography  fontSize={14} fontWeight={400} fontFamily={'cursive'}>{inputs.sitePhone??''} </Typography>
        //                                             </Stack>
        //                                         </Stack>

        //                                         <Stack direction="row" sx = {{width: '65ch', height:'5ch'}} > 
        //                                             <Stack sx = {{width: '20ch', ml:'1ch'}} >
        //                                                 <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Contact:</Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '40ch', ml:'1ch'}} >
        //                                                 <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{inputs.siteManager??''} </Typography>
        //                                             </Stack>
        //                                         </Stack>                                             

        //                                     </Stack>
        //                                 </Stack>
        //                                 ///////////UPDATE SITE end/////////
        //                             :
        //                                 /////// ADD NEW SITE start
        //                                 <Stack direction="row" marginLeft={4} >
        //                                     <Stack direction="column" >

        //                                         <SmallContainer g h={!showSite ? '18ch' : '6ch'} mt="1px" ml="5px" w='60ch'  >   

        //                                                 {count >=3 ?
        //                                                     // <SmallContainer  g h='4ch' mt='1px' p noborder w='90ch' c='lightblue'> 
        //                                                         <Autocomplete
        //                                                             value={inputs.customerName}////    value={clientName??"undefined"}///{clientName} //disabled={action==='update'?true:false}
        //                                                             required
        //                                                             //ref={inputRef}
        //                                                             //marginTop={'1px'}
        //                                                             name='customerName'
        //                                                             options={role == "DOTWA" ? ["Dot Wa"] : allClientsNames}
        //                                                             //isOptionEqualToValue={(option, value) => option.value === value.value}
        //                                                             //disableClearable
        //                                                             size='small'
        //                                                             filterOptions={filterOptions}
        //                                                             onChange={(event, newValue) => {
        //                                                                     clearAllSitesData()
        //                                                                     setInputs(values=>({...values, ['customerName']: newValue}))
        //                                                                     const a = allClientsIds.filter((i)=>`${i.name}`=== newValue && i.id)
        //                                                                     if (newValue === null){
        //                                                                         clearAllSitesData()
        //                                                                         setShowSite(false)
        //                                                                         setDestination('')
        //                                                                         setInputs(values=>({...values, ['customerName']: undefined}))
        //                                                                     }else{
        //                                                                         setInputs(values=>({...values, ['customerId']:a[0].id }))                                                                
        //                                                                         getSitesForSelectedCustomer(a[0].id > 0 ? a[0].id : 0 )
        //                                                                         console.log(a[0].name,  a[0].id) ///[0].id > 0 ? a[0].id : '' )
                                                                                
        //                                                                     }
        //                                                             }}
        //                                                             renderInput={(params) => (
        //                                                                 <TextField
        //                                                                     {...params}                                                            //disableClearable
        //                                                                     label="Customers" //{action ==='update'? clientNameFromUpdate : "Clients List"} //value={clientName??"Not defined"}required  // helperText = { selectedClientName ===''?"Select value":""}  // error ={selectedClientName === ''? true:false}
        //                                                                     inputProps = {{...params.inputProps, type: 'search', }}    
        //                                                                     style={{width:'50ch'}}
        //                                                                     sx={{boxShadow:'0 0 1px 2px lightgray', mt:'.5ch'}}   
        //                                                                 />
        //                                                             )}
        //                                                         />
        //                                                     // </SmallContainer>
                                                            
        //                                                 :
        //                                                     <Loader  emptyColor={'gray'} filledColor={'red'} marginTop ={'10ch'} marginLeft={'30ch'} style={{width: 100}}/>
        //                                                 }
                                                
        //                                             { (inputs.customerName !== undefined  && !showSite) &&
                                                
        //                                                 <Stack name='ADD SITES LIST'
        //                                                     marginTop='2ch'
        //                                                 >                        
        //                                                     <Autocomplete
        //                                                         //value={inputs.customerName}
        //                                                         required 
        //                                                         isOptionEqualToValue={(option, value) => option.value === value.value} 
        //                                                         filterOptions={filterOptions}
        //                                                         name='sites'
        //                                                         size='small'
        //                                                         //disableClearable={false}
        //                                                         autoHighlight                                                                   
        //                                                         key={siteSelected.key}                                                                                          
        //                                                         options={siteSelected.siteName}
        //                                                         onChange={(event, newValue) => {
        //                                                             console.log(newValue)
        //                                                             // clearAllSitesData()
        //                                                             if (newValue === null){
        //                                                                 clearAllSitesData()
        //                                                                 setShowSite(false)
        //                                                                 setDestination('')
        //                                                             }else if(newValue =='ADD NEW SITE...'){    
        //                                                                 console.log('------------- ', newValue)
        //                                                                 handleNewSite ()

        //                                                             }else{
        //                                                                 setShowSite(true);
        //                                                                 siteSelected.siteName.map((currentValue, index)=> currentValue == newValue && setAllSiteData(siteSelected, index ));     
        //                                                                 //{console.log(newValue)} //siteSelected.siteName.map((val, index)=>val === newValue &&  setInputs(values=>({...values, ['siteId']:siteSelected.siteId[index] })));  
        //                                                                 //setOneSiteNameSelected(newValue)
                                                                        
        //                                                             } 
        //                                                         } }
        //                                                         renderInput={(params) => (
        //                                                             <TextField  {...params}
        //                                                                 label={`Select Site for ${inputs.customerName !== null || inputs.customerName !== undefined ? inputs.customerName :''}`}
        //                                                                 inputProps = {{...params.inputProps, type: 'search', }}       
        //                                                                 style={{width:'50ch'}}    
        //                                                                 sx={{boxShadow:'0 0 1px 2px lightgray',}}   
        //                                                             />
        //                                                         ) }
        //                                                     />

        //                                                     <Fab 
        //                                                         variant="extended" 
        //                                                         size="small" 
        //                                                         color="success" 
        //                                                         sx={{width:'25ch', height: '3ch', ml:'30px', mt:'20px', , fontSize:10}}
        //                                                         onClick={ handleNewSite }
        //                                                     > Add New Site  </Fab>
        //                                                 </Stack>   
                                                        
        //                                             }                                                                                                                                                

        //                                         </SmallContainer>   
                                                
                                            
        //                                         {showSite &&
        //                                             <Stack  name='FIELDs ON ADD'
        //                                                 sx = {{width: '190ch',  mt:2}} gap={2}
        //                                             >

        //                                                 <Stack direction="row" sx = {{width: '190ch'}} > 
        //                                                     <Stack sx = {{width: '15ch', ml:1}} >
        //                                                         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Site Name:</Typography>
        //                                                     </Stack>
        //                                                     <Stack sx = {{width: '150ch', ml:1}} >
        //                                                         <Typography  sx={{ color: pink[800] }}  fontSize={16} fontWeight={600} fontFamily={'cursive'}>{oneSiteNameSelected ?? ''} </Typography>
        //                                                     </Stack>
        //                                                 </Stack> 

        //                                                 <Stack direction="row" sx = {{width: '120ch', }}> 
        //                                                     <Stack sx = {{width: '15ch', ml:1}} >
        //                                                         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Address:</Typography>
        //                                                     </Stack>

        //                                                     <Stack sx = {{width: '104ch', ml:1}} >
        //                                                         <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}> {destination} </Typography>
        //                                                     </Stack>
        //                                                 </Stack> 

        //                                                 <Stack direction="row" sx = {{width: '60ch',}}> 
        //                                                     <Stack sx = {{width: '15ch'}} ml={1}>
        //                                                         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Phone: </Typography>
        //                                                     </Stack>
        //                                                     <Stack sx = {{width: '40ch'}} marginLeft={1}>
        //                                                         <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>
        //                                                             {`${oneSitePhoneSelected??'Not Supplied'}` }
        //                                                         </Typography>
        //                                                     </Stack>
        //                                                 </Stack> 

        //                                                 <Stack direction="row" sx = {{width: '60ch', }}> 
        //                                                     <Stack sx = {{width: '15ch'}} ml={1}> <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Zone: </Typography> </Stack>
        //                                                     <Stack sx = {{width: '40ch'}} marginLeft={1}> 
        //                                                         <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}> 
        //                                                             {`${inputs.siteZone ??'Not Supplied'}`} 
        //                                                         </Typography>             
        //                                                     </Stack>
        //                                                 </Stack> 
                                                
        //                                                 <Stack direction="row" sx = {{width: '60ch'}} > 
        //                                                     <Stack sx = {{width: '15ch', ml:1}} >
        //                                                         <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Contact:</Typography>
        //                                                     </Stack>
        //                                                     <Stack sx = {{width: '40ch', ml:1}} >
        //                                                         <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{inputs.siteManager??''} </Typography>
        //                                                     </Stack>
        //                                                 </Stack>                                             

        //                                             </Stack>
        //                                         }   
        //                                     </Stack>
        //                                 </Stack>

        //                             }

        //                             {destination !=='' && isLoaded ?
                                        
        //                                 <Stack   >
        //                                     <Stack height={'40ch'} width={'50ch'} marginLeft={2} marginTop={7} border='1px solid blue' >

        //                                         <GetLocation setCenterSite = {setCenterSite} destination={destination} setSiteLabel={setSiteLabel}/>    
                                            
        //                                         <Box height={'100%'} width={'100%'} >                                              
        //                                             <GoogleMap 
        //                                                 center = {centerSite}
        //                                                 zoom={12} 
        //                                                 mapContainerStyle = {{width:'100%', height:'100%'}}
        //                                                 onUnmount={onUnmount}
        //                                                 onLoad={(map)=>{setMap(map)}}
        //                                                 options={{  
        //                                                     streetViewControl:false, 
        //                                                     fullscreenControl: false, 
        //                                                     keyboardShortcuts:false, 
        //                                                     zoomControl:true,
        //                                                     mapTypeControl: false,
        //                                                     zoomControlOptions: {
        //                                                         //style:'SMALL',
        //                                                         //position:0.0
        //                                                     }, 
        //                                                     disableDoubleClickZoom:true, 
        //                                                     mapTypeControlOptions:'ROADMAP', 
        //                                                     gestureHandling:'none'
        //                                                 }}
        //                                             > 
        //                                                 <MarkerF
        //                                                     position= {centerSite}  
        //                                                     //label={siteLabel}
        //                                                     // options={{
        //                                                     //     anchorPoint:null,
        //                                                     //     clickable:true
        //                                                     // }}
        //                                                     animation={ aa ? google.maps.Animation.DROP:google.maps.Animation.BOUNCE}
        //                                                     onClick={(e)=>setAa(!aa) }
        //                                                     // title={siteLabel}
        //                                                     icon={{
        //                                                         path:"M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
        //                                                         fillColor: "yellow",
        //                                                         fillOpacity: 0.9,
        //                                                         scale: 1.7,
        //                                                         strokeColor: "red",
        //                                                         strokeWeight: 4,
        //                                                         //title: "Title",
        //                                                     }}
        //                                                 />
                                                        
        //                                                 <InfoWindowF position={centerSite} >
        //                                                     <div style={{ backgroundColor: 'lightpink', border: `.1px solid gray`, padding:2, fontSize: 14,  , fontWeight:600}}>
        //                                                         {siteLabel}   {/* destination */}
        //                                                     </div>
        //                                                 </InfoWindowF>
                                                        
        //                                             </GoogleMap>   
                                                            
        //                                         </Box>
        //                                     </Stack>  
                                        
        //                                 </Stack>

        //                             :
        //                                 <Stack height={'25ch'} width={'45ch'} marginLeft={55} marginTop={35}> 
        //                                     {/* <Loader size="small" emptyColor={'grey'} filledColor={'red'} style={{width: 50}}/> */}
        //                                 </Stack>  
            
        //                             }      

        //                         </Stack>  
                        
        //                     </Stack> 
                    

        //             </TabItem>     

        //             {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  JOBS DETAILS          >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

        //             <TabItem   
        //                 title={<Text fontFamily={'cursive'} fontSize={12}> Job Details </Text> }                     
        //                 backgroundColor={1 === tabValue ?'aquamarine':'aliceblue'}//
        //                 border={borderSize( 1, tabValue)}
        //                 borderRadius='15ch'
        //                 onDoubleClick={getViewAll}
        //                 //onLoad= {1 === tabValue && getActivity(inputs.activityGuid, 'all')}  //{inputs.activityGuid == undefined || inputs.activityGuid ===null || inputs.activityGuid ===''?'': getActivity(inputs.activityGuid, 'callback') }
        //                 //onClick ={getActivity(inputs.activityGuid, 'callback')}
        //                 //{inputs.activityGuid == undefined || inputs.activityGuid ===null || inputs.activityGuid ===''?'': getActivity(inputs.activityGuid, 'api')} 
        //             >
        //                 {/* {console.log(Date.parse(new Date()), inputs.slaDate * 1000)}  {console.log(Date.parse(new Date()) > inputs.slaDate *1000 ?'red': 'black')}   // Date.parse(new Date() - is NOW*/}            
        //                 <Stack direction="row" sx = {{ width: '155ch', height:'75ch', mt:1,}} >   

        //                     <Stack direction="column" sx={{ width: '45ch'}} >

        //                             <Container g w='35ch' primary>

        //                                 <SmallContainer h='20px' mt="-18px" ml="1px" w='15ch'>
        //                                     <Typography sx={{ fontSize:14, fontWeight:800,  , mt:-.7  }}>Job Priority</Typography>
        //                                 </SmallContainer>

        //                                 <FormControl sx = {{ ml:2, mt:1}}>
        //                                     {/* <FormLabel><Typography sx={{ fontSize:16, fontWeight:600,  , my:1, }}>Job Priority :</Typography></FormLabel> */}                                        
        //                                     <RadioGroup
        //                                         //row={false}
        //                                         name="priority"
        //                                         id='radioGroup'
        //                                         defaultValue={4}
        //                                         value={String(inputs.priority)}
        //                                         onChange={(e) => {
        //                                             setInputs(values=>({...values, [e.target.name]: parseInt(e.target.value)}))  //console.log(inputs.siteZone, e.target.value )  //DEBUG && console.log(parseInt(e.target.value))
        //                                             getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${parseInt(e.target.value)}/${inputs.siteZone}`,parseInt(e.target.value))
        //                                             setPostNote(a =>({...a, jobPriority: `${e.target.value}`}))
        //                                         }}
        //                                     >
        //                                         <FormControlLabel 
        //                                             value={1} 
        //                                             control={<Radio size='small' sx={{color:'transparent','&.Mui-checked':{color:'#ff8a80'}}} />} 
        //                                             label={<text style={{ , fontSize:14, fontWeight:600}}>Extreme</text>} 
        //                                             sx={{color: '#ff8a80' }}  
        //                                             labelPlacement="end"
        //                                         />
        //                                         <FormControlLabel value={2} control={<Radio size='small' sx={{color:'transparent','&.Mui-checked':{color: purple[600]} }}/>} label={<text style={{ , fontSize:14}}>Urgent</text>}  sx={{color: purple[600], fontSize:10,  }}  labelPlacement="end"/>
        //                                         <FormControlLabel value={3} control={<Radio size='small' sx={{color:'transparent','&.Mui-checked':{color: blue[600]}}}/>} label={<text style={{ , fontSize:14}}>Moderate</text>} sx={{color: blue[600], fontSize:10, }}  labelPlacement="end"/>
        //                                         <FormControlLabel value={4} control={<Radio size='small' sx={{color:'transparent','&.Mui-checked':{color: green[600]}}}/>} label={<text style={{ , fontSize:14}}>Standard</text>}  sx={{color: green[600], fontSize:10,  }}  labelPlacement="end"/>
        //                                         {/* <FormControlLabel value="5" control={<Radio size="small" sx={{color:'transparent','&.Mui-checked':{color: yellow[700]}}}/>} label="Low" sx={{color: yellow[700] }} /> */}
        //                                         {/* <FormControlLabel value="6" control={<Radio size="small" sx={{color: pink[800],'&.Mui-checked': {color: pink[600],},}} />} label={inputs.priority} /> */}
        //                                     </RadioGroup>
        //                                 </FormControl>
        //                             </Container>
                            

        //                         <Stack  direction="column" sx={{ mt:1,  ml:1}} >    

        //                             <SmallContainer  h='80px' mt="0px" ml="2px" w='35ch' b='transparent' >
        //                                 <Typography 
        //                                     sx={{ fontSize:14, fontWeight:600,  , ml:3.5 }} 
        //                                 >Job Status</Typography>      

        //                                 <Autocomplete
        //                                     //value={inputs.statusName} //disabled={action==='update'?true:false}         //
        //                                     // defaultValue={statuses.statusName[0]??''}
        //                                     // name="statusName"
        //                                     // id='statusName'
        //                                     size='small'
        //                                     width='20ch' 
        //                                     // filterOptions={filterOptions}
        //                                     options={statuses.statusName}  //loading = {true}//{loading}// autoHighlight
        //                                     onChange={(e, newValue) => { 
        //                                         statuses.statusName.map((item, index)=>{
                                                    
        //                                             if(item===newValue){
        //                                                 setInputs(values=>({...values, ['statusId']: statuses.statusId[index]}))
        //                                                 setInputs(values=>({...values, ['statusName']: statuses.statusName[index]}))
        //                                                 //setInputs(values=>({...values, ['siteSupplierId']: siteSupplId}))      // console.log(siteSupplId, 'ddddddddddddddddddddddddddddddddd')
        //                                                 setPostNote(a =>({...a,  jobStatus: `${statuses.statusName[index]}`}))
        //                                             }
        //                                         })
        //                                     }}

        //                                     onBlur={e=> DEBUG && console.log('onBlur')}
        //                                     renderInput={(params) => (
        //                                         <TextField
        //                                             {...params}
        //                                             placeholder={inputs.statusName} 
        //                                             inputProps = {{...params.inputProps, type: 'search', }}    
        //                                             style={{width:'32ch'}}    
        //                                             sx={{boxShadow:'0 0 1px 2px lightgray',}}   
                                                    
        //                                         />
        //                                         )
        //                                     }
        //                                 />
        //                             </SmallContainer>
                            
        //                             <SmallContainer g h='80px' mt="1ch" ml="2px" w='35ch' b='transparent'>    

        //                                 <Typography sx={{ fontSize:14, fontWeight:600,  , ml:3.5}}>Request Type </Typography>   

        //                                 <Autocomplete
        //                                     // name="requestType"                                     // id='requestType'
        //                                     size='small'       // filterOptions={filterOptions}
        //                                     options={requestTypes.requestTypeName}  
        //                                     onChange={(e, newValue) => { 
        //                                         requestTypes.requestTypeName.map((item, index)=>{
        //                                             if(item === newValue){
        //                                                 setInputs(values=>({...values, ['requestTypeId']: requestTypes.requestTypeId[index]}))
        //                                                 setInputs(values=>({...values, ['requestType']: requestTypes.requestTypeName[index]}))  //setInputs(values=>({...values, [e.target.name]: e.target.value}))
        //                                                 setPostNote(a =>({...a, jobRequestType: `${requestTypes.requestTypeName[index]}`}))
        //                                             }
        //                                         })
        //                                     }}
        //                                     renderInput={(params) => (
        //                                             <TextField {...params}
        //                                                 placeholder={inputs.requestType}
        //                                                 inputProps = {{...params.inputProps, type: 'search', }}    
        //                                                 style={{width:'32ch', }} 
        //                                                 sx={{boxShadow:'0 0 1px 2px lightgray',}}   
                                                    
        //                                             />
        //                                         )
        //                                     }
        //                                 />
        //                             </SmallContainer>                                                        
        //                         </Stack>

        //                         {action !=='update' &&                                    
        //                             <Stack name="'Work Order' "  sx={{ marginTop:'2ch', marginLeft:1.5,  }} > 
        //                                 <TextField  
        //                                     value = {inputs.po_number}    ///{pOrder} 
        //                                     name="po_number"
        //                                     label='Work Order'                        
        //                                     size='small' 
        //                                     style={{width:'40ch', mr:1}} 
        //                                     sx={{boxShadow:'2px 2px 3px 2px lightgray', borderRadius:'5px'}}   
        //                                     onChange={(e) => {setInputs(values=>({...values, [e.target.name]: e.target.value}))}}
        //                                 />   
            
        //                             </Stack>
        //                         }

        //                     </Stack>     
                
        //                     <SmallContainer h='40ch' w='100ch' mt="7px" ml='1px' b='papayawhip' p primary>
        //                         <ReactQuill 
        //                             theme={action === 'update' && role !=='Administration' ?"bubble":'snow'}
        //                             id="faultDescription"
        //                             //ref={inputRef.current}
        //                             readOnly = {action === 'update' && role !=='Administration'}
        //                             className ='faultDescription'
        //                             value={inputs.faultDescription ?? ''} 
        //                             //placeholder ="Fault Description..."
        //                             modules={{
        //                                 'history': {         
        //                                     'delay': 2500,
        //                                     'userOnly': true
        //                                 },   
        //                                 'toolbar': toolbarOptions
        //                             }}         
        //                             onChange={(content, delta, source, editor)=>{
        //                                 // console.log(delta.ops[0])
        //                                 // console.log(delta.ops[1])
        //                                 // console.log( source)
        //                                 // console.log(editor.getContents())
        //                                 // console.log(editor.getSelection())
        //                                 // console.log(editor.getText())
        //                                 // console.log(editor.getBounds())         
        //                                 //console.log(content)                                     
        //                                 // focusInput()
        //                                 setInputs(values=>({...values, ['faultDescriptionForEmail']: editor.getText()}))
        //                                 setInputs(values=>({...values, ['faultDescription']: content}))
        //                             }}  
        //                         />
        //                     </SmallContainer>
                            
        //                 </Stack> 
                        

        //                 {action =='update' &&                  
        //                     <Stack direction="row" sx={{ml:1, mt:-26.1, mb:0}} > 
        //                         <SmallContainer h='20ch' w='74ch' mt="1px" ml='1px'  p primary>                           

        //                             <Table
        //                                 //caption={<Stack border='1px solid lightgray' paddingY={1}><Typography color='#2d187be0' fontSize={18} fontWeight={600} fontFamily={'cursive'}>System Information</Typography></Stack>}
        //                                 size="small"
        //                                 highlightOnHover={true}
        //                                 variation="striped"
        //                                 backgroundColor={'antiquewhite'}
        //                                 //width='100ch'

        //                             >    
        //                                 <TableBody >          
                                        
        //                                     <TableRow height='3ch'>                                                
        //                                         <TableCell width='20ch'><text style={{color:'#2d187be0', fontSize:14, fontWeight:600,  }}>Created on</text></TableCell>
        //                                         <TableCell width="35ch"><text style={{color:'#2d187be0', fontSize:14, fontWeight:400,  }}>{dateConverter(inputs.dateCreated)}</text></TableCell>                                                
        //                                     </TableRow>

        //                                     <TableRow height='3ch'>                                                    
        //                                         <TableCell width="20ch" ><text style={{color:'#2d187be0', fontSize:14, fontWeight:600,  }}>SLA</text></TableCell>
        //                                         <TableCell width="35ch"><text style={{color:'#2d187be0', fontSize:14, fontWeight:400,  }}>{inputs.slaDate == undefined || inputs.slaDate == null || inputs.slaDate == '' ? '': `${dateConverter(inputs.slaDate)}` }{Date.now() > inputs.slaDate * 1000 ?' SLA is missed':''}  </text> </TableCell>                                                
        //                                     </TableRow>
        //                                     <TableRow height='3ch'>                                                    
        //                                         <TableCell width="20ch" ><text style={{color:'#2d187be0', fontSize:14, fontWeight:600,  }}>Logged By</text></TableCell>
        //                                         <TableCell width="35ch"><text style={{color:'#2d187be0', fontSize:14, fontWeight:400,  }}>{inputs.userFullName  == undefined || inputs.userFullName == null || inputs.userFullName == '' ?
        //                                                         '':inputs.userFullName}</text> </TableCell>                                                
        //                                     </TableRow>

        //                                     <TableRow height='3ch'>                                                    
        //                                         <TableCell width="20ch" ><text style={{color:'#2d187be0', fontSize:14, fontWeight:600,  }}>Work Order</text></TableCell>
        //                                         <TableCell width="35ch"><text style={{color:'#2d187be0', fontSize:14, fontWeight:400,  }}>{inputs.po_number ?? ''} </text> </TableCell>                                                
        //                                     </TableRow>
                                            
                                            
        //                                     <TableRow height='3ch'>                                                    
        //                                         <TableCell width="20ch" ><text style={{color:'#2d187be0', fontSize:14, fontWeight:600,  }}>Job Accepted On</text></TableCell>
        //                                         <TableCell width="35ch"><text style={{color:'#2d187be0', fontSize:14, fontWeight:400,  }}>{inputs.siteSupplierAccepted ==1 && dateConverter(inputs.status_time)}</text> </TableCell>                                                
        //                                     </TableRow>

                                            
        //                                 </TableBody >  
        //                             </Table>  
        //                         </SmallContainer>    

        //                             { (group[0] == 'Admin' || role =='Administration') &&   
                                        
        //                                 <SmallContainer h='20ch' w='70ch' mt="1px" ml='0px'  p primary> 
        //                                     <Table
        //                                         //caption={<Stack border='1px solid lightgray' paddingY={1}><Typography color='#2d187be0' fontSize={18} fontWeight={600} fontFamily={'cursive'}>System Information</Typography></Stack>}
        //                                         size="small"
        //                                         highlightOnHover={true}
        //                                         variation="striped"
        //                                         //backgroundColor={'antiquewhite'}
        //                                         //width='100ch'
                                            
        //                                     >    
        //                                         <TableBody >          
                                                
        //                                             <TableRow height='3ch'>                                                
        //                                                 <TableCell width='25ch'><text style={{color:'#2d187be0', fontSize:14, fontWeight:600,  }}></text>Activity UID</TableCell>
        //                                                 <TableCell width='55ch'><text style={{color:'#2d187be0', fontSize:14, fontWeight:400,  }}></text>{inputs.activityGuid == undefined || inputs.activityGuid == null || inputs.activityGuid == '' ?  '': `${(inputs.activityGuid)} `} </TableCell>                                                
        //                                             </TableRow>

        //                                             <TableRow height='3ch'>                                                    
        //                                                 <TableCell width="25ch"><text style={{color:'#2d187be0', fontSize:14, fontWeight:600,  }}></text>Assigned Technician</TableCell>
        //                                                 <TableCell width='55ch'><text style={{color:'#2d187be0', fontSize:14, fontWeight:400,  }}></text> {technician.current ?? ''}  </TableCell>     
        //                                                 {/* - ID : {inputs.sheqsyUId }                                          */}
        //                                             </TableRow>
        //                                             <TableRow height='3ch'>                                                    
        //                                                 <TableCell width="25ch" ><text style={{color:'#2d187be0', fontSize:14, fontWeight:600,  }}>Schedule task status </text></TableCell>
        //                                                 <TableCell width='55ch'> <text style={{color:'#2d187be0', fontSize:14, fontWeight:400,  }}>
        //                                                     {inputs.activityGuid == undefined || inputs.activityGuid == null || inputs.activityGuid == '' ?  '': scheuleActivity.current  } </text>
        //                                                 </TableCell>                                                
        //                                             </TableRow>

        //                                             <TableRow height='3ch'>                                                    
        //                                                 <TableCell width="25ch" ><text style={{color:'#2d187be0', fontSize:14, fontWeight:600,  }}></text>Current task status</TableCell>
        //                                                 <TableCell width='55ch'><text style={{color:'#2d187be0', fontSize:14, fontWeight:400,  }}> </text>{inputs.activityGuid == undefined || inputs.activityGuid == null || inputs.activityGuid == '' ? '': `${actualActivity.current} ` }       </TableCell>                                                
        //                                             </TableRow>
                                                    
                                                    
        //                                                 <TableRow height='3ch'>                                                    
        //                                                     <TableCell width="25ch" ><text style={{color:'#2d187be0', fontSize:14, fontWeight:600,  }}>Is job complited</text></TableCell>
        //                                                     <TableCell width='55ch'><text style={{color:'#2d187be0', fontSize:14, fontWeight:400,  }}>{inputs.activityGuid == undefined || inputs.activityGuid == null || inputs.activityGuid == '' || forms.current ===undefined ?'':`${forms.current}`} </text> </TableCell>                                                
        //                                                 </TableRow>

                                                    
        //                                         </TableBody >  
        //                                     </Table>  
        //                                 </SmallContainer>
        //                             }                                                                        

        //                     </Stack>
        //                 }
                            
                    
        //             </TabItem>


        //             {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  CONTRACTOR DETAILS  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

        //             { (group[0] == 'Admin' || role==='Administration') && 
        //                 <TabItem    
        //                     title={<Text fontFamily={'cursive'} fontSize={14}>Contractor</Text>}  
        //                     backgroundColor={2 ===tabValue ?'aquamarine':'aliceblue'}//
        //                     border={borderSize( 2, tabValue)}
        //                     borderRadius='15ch'
        //                     onClick={()=>{ setInputs(values=>({...values, ['supplierCountry']: 'Australia'})) } }
        //                 >
        //                     <Stack direction="row" sx = {{ width: '155ch', height:'75ch', mt:1}} >     


        //                             {action ==='update' && !newContractor? 

        //                             /////////// UPDATE STARTS  /////////
                                
        //                                 <Stack direction="row" sx = {{width: '180ch',  mt:0}}>

        //                                     <Stack name='CONTR FIELDS' direction="column" sx = {{width: '100ch', }}>


        //                                         <Stack direction="row" sx = {{width: '80ch', height:'6ch'}}  > 
        //                                             <Stack sx = {{width: '20ch'}}> 
        //                                                 <Typography  fontSize={16}  fontWeight={600}  fontFamily={'cursive'} >Contractor:</Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '55ch'}} > 
        //                                                 <Typography  sx={{ color: pink[800] }} fontSize={16} fontWeight={600} fontFamily={'cursive'}>
        //                                                     {inputs.supplier??''} 
        //                                                 </Typography>
        //                                             </Stack>
        //                                         </Stack> 

                            
        //                                         <Stack direction="row" sx = {{width: '110ch', height: '6ch' }}> 
                                                
        //                                             <Stack sx = {{width:'20ch'}} >
        //                                                 <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}> Address: </Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '90ch'}} >
        //                                                 <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>
        //                                                     { `${inputs.supplierPostalAddress ?? ''}`    } 
        //                                                 </Typography>
        //                                             </Stack>
                                                        
        //                                         </Stack>  

        //                                         <Stack direction="row" sx = {{width: '65ch', height: '6ch'}}> 
        //                                             <Stack sx = {{width: '20ch'}} >
        //                                                 <Typography  fontSize={16} fontWeight={600} fontFamily={'cursive'}> Phone: </Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '30ch'}} >
        //                                                 <Typography  fontSize={14} fontWeight={400} fontFamily={'cursive'}>{inputs.supplierPhone??''} </Typography>
        //                                             </Stack>
        //                                         </Stack>

        //                                         <Stack direction="row" sx = {{width: '65ch', height: '6ch'}}> 
        //                                             <Stack sx = {{width: '20ch'}} >
        //                                                 <Typography  fontSize={16} fontWeight={600} fontFamily={'cursive'}> Email: </Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '30ch'}} >
        //                                                 <Typography  fontSize={14} fontWeight={400} fontFamily={'cursive'}>
        //                                                     {inputs.supplierEmail??''} 
        //                                                 </Typography>
        //                                             </Stack>
                                                
        //                                         </Stack>


        //                                         <Stack direction="row" sx = {{ height: '6ch'}}> 
        //                                             <Stack sx = {{width: '20ch'}} >
        //                                                 <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Latitude: </Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '30ch'}} >
        //                                                 <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{centerContract.lat??""} </Typography>
        //                                             </Stack>
        //                                         </Stack> 

        //                                         <Stack direction="row" sx = {{width: '70ch', height: '6ch'}}> 
        //                                             <Stack sx = {{width: '20ch'}} >
        //                                                 <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Longitude: </Typography>
        //                                             </Stack>
        //                                             <Stack sx = {{width: '30ch',  }}>
        //                                                 <Typography fontSize={14} fontWeight={400}fontFamily={'cursive'}>{centerContract.lng??""}  </Typography>
        //                                             </Stack>
        //                                         </Stack>  


        //                                         <Stack direction="column" height={'45ch'} width={'50ch'} marginTop={0}>
        //                                             <Box height={'100%'} width={'100%'} left={0} marginLeft={0} marginTop={2} border='1px solid blue'>
        //                                                 {isLoaded && 
        //                                                     <GoogleMap 
        //                                                         center = {centerContract}//{centerSite}
        //                                                         zoom={13} 
        //                                                         mapContainerStyle = {{width:'100%', height:'100%'}}
        //                                                         onUnmount={onUnmount}
        //                                                         ///onLoad={(map)=>{setMap(map)}}
        //                                                         options={{ 
        //                                                             streetViewControl:false, 
        //                                                             fullscreenControl: false, 
        //                                                             keyboardShortcuts:false, 
        //                                                             zoomControl:true,
        //                                                             mapTypeControl: false,
        //                                                             disableDoubleClickZoom:true, 
        //                                                             gestureHandling:'none'
        //                                                         }}
        //                                                     >
        //                                                         <MarkerF
        //                                                             position={centerContract} 
        //                                                             icon={{path:"M 3 3 h 8 v 8 h -8 Z", fillColor: "yellow", fillOpacity: 0.9, strokeColor:"blue", strokeWeight: 5, scale:2.2 }} 
        //                                                             animation={ aa?google.maps.Animation.DROP:google.maps.Animation.BOUNCE}
        //                                                             onClick={(e)=>setAa(!aa) }
                                                                
        //                                                         />

        //                                                         <InfoWindowF  position={centerContract}>
        //                                                             <div style={{ backgroundColor: 'yellow', border: `.1px solid gray`, padding:4, fontSize: 14,  , fontWeight:600}}>
        //                                                                 {inputs.supplier ??''}
        //                                                             </div>
        //                                                         </InfoWindowF>
        //                                                     </GoogleMap>
        //                                                 }
        //                                             </Box>
        //                                         </Stack>   

        //                                     </Stack>

        //                                     <Stack name='Technician' sx = {{width: '85h', ml: '1ch'}} >                                                

        //                                         {(!inputs.sheqsyUId == null || !inputs.sheqsyUId =='') &&

        //                                             <Stack direction="row" sx = {{width: '70ch'}}> 
        //                                                 <Stack sx = {{width: '20ch'}} >
        //                                                     <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Technician SID: </Typography>
        //                                                 </Stack>
        //                                                 <Stack sx = {{width: '45ch', ml:1 }}>
        //                                                     <Typography fontSize={14} fontWeight={400}fontFamily={'cursive'}>{inputs.sheqsyUId }  </Typography>
        //                                                 </Stack>
        //                                             </Stack>

        //                                         }

        //                                         <Stack direction="row" sx = {{width: '65ch', mt:2}} > 
        //                                             <Stack sx = {{width: '20ch', }} >
        //                                                 <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Technician Name:</Typography>  
        //                                             </Stack>
        //                                             <Stack sx = {{width: '40ch', ml:1}} >
        //                                                 <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{inputs.supplierContactPerson??''} </Typography>
        //                                             </Stack>
        //                                         </Stack> 

        //                                         <Stack direction="row" marginTop={5}> 
        //                                             <Fab 
        //                                                 variant="extended"                                                     
        //                                                 size="small" 
        //                                                 color="mediumseagreen" 
        //                                                 border='10px solid gray'
        //                                                 //borderRadius= '5px'
        //                                                 sx={{
        //                                                      , 
        //                                                     fontSize:14, 
        //                                                     fontWeight:800,
        //                                                     width:'43ch', 
        //                                                     height:'8ch', 
        //                                                     ///right:'230%', 
        //                                                     color:"mediumseagreen",
        //                                                     background: 'papayawhip',
        //                                                     zIndex:0,
        //                                                     boxShadow:'2px 1px 4px 2px gray',                                                
        //                                                 }}
        //                                                 onClick={changeContractor}                                                
        //                                             > Change Contractor / Technician 
        //                                             </Fab>                                            
        //                                         </Stack> 

        //                                     </Stack>
                                                                                    
        //                                 </Stack>

        //                                 ///////////UPDATE End/////////

        //                             :

        //                                 /////// ADD NEW Start

        //                                 <Stack name= 'ADD NEW ' direction="column" sx = {{width: '170ch'}} >

        //                                     <Stack name= 'ADD NEW DROPDOWNS FORM CONTROLS' direction="row" sx = {{width: '160ch'}}>

        //                                         <FormControl sx = {{width: '25ch', ml:1}}> 
        //                                             <Autocomplete
        //                                                 required
        //                                                 defaultValue={'Australia'}
        //                                                 autoComplete
        //                                                 name='supplierCountry'
        //                                                 options={['Australia','New Zealand']}
        //                                                 size='small'
        //                                                 //filterOptions={filterOptions}
        //                                                 onOpen={()=>{
        //                                                     setInputs(values=>({...values, ['supplierCountry']: 'Australia'}))
        //                                                     DEBUG && console.log('c')

        //                                                 }}
        //                                                 onChange={(event, newValue, c) => { 
        //                                                     setInputs(values=>({...values, ['supplierCountry']: newValue}))
        //                                                     DEBUG && console.log(c)

        //                                                 }  }                                                                
        //                                                 renderInput={(params) => (
        //                                                     <TextField
        //                                                         {...params}
        //                                                         label="Countries"

        //                                                         inputProps = {{...params.inputProps, type: 'search', }}    
        //                                                     />
        //                                                 )}
        //                                             />

        //                                         </FormControl>

        //                                         <FormControl sx = {{width: '15ch', ml:1, mt:0}}> 
        //                                             <Autocomplete
        //                                                 required
        //                                                 name='supplierState'
        //                                                 options={inputs['supplierCountry'] ==='New Zealand'?['Auckland','Mount Ruapehu','Nelson','North Island','South Island','New Zealand']:['VIC','NSW','QLD','WA','SA','NT','TAS','ACT']}
        //                                                 size='small'
        //                                                 //filterOptions={filterOptions}
        //                                                 onChange={(event, newValue) => { 
        //                                                         setInputs(values=>({...values, ['supplierState']: newValue})) 
        //                                                         getAllContractors(newValue)
        //                                                     }  
        //                                                 }                                                                
        //                                                 renderInput={(params) => (
        //                                                     <TextField
        //                                                         {...params}
        //                                                         label="States"
        //                                                         inputProps = {{...params.inputProps, type: 'search', }}    
        //                                                     />
        //                                                 )}
        //                                             />
        //                                         </FormControl>


        //                                         <FormControl  sx = {{width:'40ch', my:0, ml:1}}> 

        //                                             <Autocomplete
        //                                                 required 
        //                                                 // isOptionEqualToValue={(option, value) => option.value === value.value} 
        //                                                 //filterOptions={filterOptions}
        //                                                 // name='siteId'
        //                                                 size='small'
        //                                                 autoHighlight  //loading = {true}
        //                                                 options={allContractors }
        //                                                 onChange={(event, newValue) => {
        //                                                     if (newValue == null){
        //                                                         setShowC(false)
        //                                                     }else{

        //                                                         //contractorData.map((c)=>c)
        //                                                         //siteSelected.siteName.map((val, index)=>val== newValue && setAllSitesData(siteSelected, index ));     
        //                                                         //{console.log(newValue)} //siteSelected.siteName.map((val, index)=>val === newValue &&  setInputs(values=>({...values, ['siteId']:siteSelected.siteId[index] })));  
                                                                
        //                                                         allContractorsData.map((a)=>{
        //                                                             if(a.supplier === newValue){
                                                                            
        //                                                                     setCenterContract(a.position !== null ? {lat: a.position.y, lng: a.position.x} : defaultCenter)
        //                                                                     setOrigin(`${a.supplierPostalAddress}, ${a.supplierSuburb}, ${a.supplierState}, ${a.supplierPostCode}`)
        //                                                                     // DEBUG && 
        //                                                                     console.log(a)
        //                                                                     setSelectedContractor(
        //                                                                         {   contractor: a.supplier, 
        //                                                                             contractorContact :a.supplierContactPerson,
        //                                                                             contractorPostalAddress:a.supplierPostalAddress,
        //                                                                             contractorEmail:a.supplierEmail,
        //                                                                             contractorState:a.supplierState,
        //                                                                             contractorSuburb:a.supplierSuburb,
        //                                                                             contractorPostCode:a.supplierPostCode,
        //                                                                             contractorPhone:a.supplierPhone,
        //                                                                             position:a.position,
        //                                                                             contractorSheqsyId: a.sheqsyUId
        //                                                                         }
        //                                                                     )
                                                                            
        //                                                                     setInputs(values=>({...values, ['siteSupplierId']: a.supplierId}))
        //                                                                     setInputs(values=>({...values, ['sheqsyUID']: a.sheqsyUId}))
        //                                                                     setSiteSupplId(a.supplierId)
        //                                                                     DEBUG && console.log( inputs.newContractor )
        //                                                                     inputs.newContractor && setPostNote(b =>({...b,  nContractor: `${a.supplier}`}))
        //                                                                 }
        //                                                             }
        //                                                         )
        //                                                         setShowC(true) 
        //                                                     } 
        //                                                     }
        //                                                 }                                    
        //                                                 renderInput={(params) => (
        //                                                     <TextField  {...params}
        //                                                         label="Contractors" //{action ==='update'? clientNameFromUpdate : "Clients List"}
        //                                                         inputProps = {{...params.inputProps, type: 'search', }}           
        //                                                     />
        //                                                 ) }
        //                                             />
        //                                         </FormControl>



        //                                     </Stack> 
                                                
        //                                     {showC &&
        //                                         <Stack name='WHEN CONTR SELECTED' direction="row">  
                                                
        //                                                 <Stack direction= 'column' gap={'1ch'} sx = {{width: '100ch', mt:1.3}}>

        //                                                     <Stack name='NAME' direction="row" sx = {{width: '90ch', mt:1}}> 
        //                                                         <Stack sx = {{width: '15ch'}} marginLeft={2}>
        //                                                             <Typography fontSize={16} fontWeight={600}fontFamily={'cursive'}>Name:</Typography>
        //                                                         </Stack>

        //                                                         <Stack sx = {{width: '60ch'}} marginLeft={1}>
        //                                                             <Typography sx={{ color: pink[800] }} fontSize={16} fontWeight={600} fontFamily={'cursive'}>
        //                                                                 {selectedContractor.contractor}
        //                                                             </Typography>
        //                                                         </Stack>
        //                                                     </Stack> 

        //                                                     <Stack direction="row" sx = {{width: '170ch', mt:1.2}}> 
        //                                                         <Stack sx = {{width: '15ch'}} marginLeft={2}>
        //                                                             <Typography fontSize={16} fontWeight={600}fontFamily={'cursive'}>Address:</Typography>
        //                                                         </Stack>

        //                                                         <Stack sx = {{width: '124ch'}} marginLeft={1}>
        //                                                             <Typography fontSize={14} fontWeight={400}>
        //                                                                 {`${selectedContractor.contractorPostalAddress}, ${selectedContractor.contractorSuburb}, ${selectedContractor.contractorState},  ${selectedContractor.contractorPostCode}` }
        //                                                             </Typography>
        //                                                         </Stack>
        //                                                     </Stack> 

        //                                                     <Stack direction="row" sx = {{width: '90ch', mt:1.8}}> 
        //                                                         <Stack sx = {{width: '15ch', ml:2}}>
        //                                                             <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Phone: </Typography>
        //                                                         </Stack>
        //                                                         <Stack sx = {{width: '60ch', ml:1}} >
        //                                                             <Typography fontSize={14} fontWeight={400}>
        //                                                                 {selectedContractor.contractorPhone ?? '0'}
        //                                                             </Typography>

        //                                                         </Stack>
        //                                                     </Stack> 

        //                                                     <Stack direction="row" sx  = {{width: '90ch', mt:1.8}}> 
        //                                                         <Stack sx = {{width: '15ch', ml:2}} >
        //                                                             <Typography  fontSize={16} fontWeight={600} fontFamily={'cursive'}> Email: </Typography>
        //                                                         </Stack>
        //                                                         <Stack sx = {{width: '60ch', ml:1}} >
        //                                                             <Typography  fontSize={14} fontWeight={400} fontFamily={'cursive'}>
        //                                                                 {selectedContractor.contractorEmail ??''} 
        //                                                             </Typography>
        //                                                         </Stack>
                                                            
        //                                                     </Stack>

        //                                                     <Stack direction="row" sx = {{width: '90ch', mt:1.8}}> 
        //                                                         <Stack sx = {{width: '15ch', ml:2}} >
        //                                                             <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Latitude: </Typography>
        //                                                         </Stack>
        //                                                         <Stack sx = {{width:'60ch', ml:1}} >
        //                                                             <Typography fontSize={14} fontWeight={400} fontFamily={'cursive'}>{centerContract.lat??""} </Typography>
        //                                                         </Stack>
        //                                                     </Stack> 
                    
        //                                                     <Stack direction="row" sx = {{width: '90ch', mt:1.8}}> 
        //                                                         <Stack sx = {{width: '15ch', ml:2}} >
        //                                                             <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Longitude: </Typography>
        //                                                         </Stack>
        //                                                         <Stack sx = {{width: '60ch', ml:1 }}>
        //                                                             <Typography fontSize={14} fontWeight={400}fontFamily={'cursive'}>{centerContract.lng??""}  </Typography>
        //                                                         </Stack>
        //                                                     </Stack>  

                                                            
        //                                                     <Stack name = 'GoogleMap' direction="column" height={'40ch'} width={'40ch'}>
        //                                                         <Box height={'100%'} width={'100%'} marginLeft={2} marginTop={1} border='1px solid blue'>

        //                                                             <GoogleMap 
        //                                                                 center = {selectedContractor.position !== null ? {lng:selectedContractor.position.x, lat: selectedContractor.position.y}:defaultCenter}//{centerSite}
        //                                                                 zoom={13} 
        //                                                                 mapContainerStyle = {{width:'100%', height:'100%'}}
        //                                                                 onUnmount={onUnmount}
        //                                                                 //onLoad={(map)=>{setMap(map)}}
        //                                                                 options={{  
        //                                                                     streetViewControl:false, 
        //                                                                     fullscreenControl: false, 
        //                                                                     keyboardShortcuts:false, 
        //                                                                     zoomControl:true,
        //                                                                     mapTypeControl: false,
        //                                                                     disableDoubleClickZoom:true, 
        //                                                                     gestureHandling:'none'
        //                                                                 }}
        //                                                             >
        //                                                                 <MarkerF  
        //                                                                     position={selectedContractor.position !== null ? {lng:selectedContractor.position.x, lat: selectedContractor.position.y}:defaultCenter }
        //                                                                     icon={{
        //                                                                         path:"M 3 3 h 8 v 8 h -8 Z", fillColor: "yellow", fillOpacity: 0.9, strokeColor:"blue", strokeWeight: 5, scale:2.2
        //                                                                         // path:
        //                                                                         //   "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
        //                                                                         // fillColor: "yellow",
        //                                                                         // fillOpacity: 0.9,
        //                                                                         // scale: 2,
        //                                                                         // strokeColor: "blue",
        //                                                                         // strokeWeight: 3,
        //                                                                         }}
        //                                                                     animation={ aa?google.maps.Animation.DROP:google.maps.Animation.BOUNCE}
        //                                                                     onClick={(e)=>setAa(!aa) }
        //                                                                 />
        //                                                                 <InfoWindowF  
        //                                                                     position={centerContract} 
        //                                                                 >
        //                                                                     <div style={{ backgroundColor: 'yellow', border: `1px solid gray`, padding:4, fontSize:14,  , fontWeight:600}}>
        //                                                                         {selectedContractor.contractor}
                                                                                
        //                                                                     </div>
        //                                                                 </InfoWindowF>
        //                                                             </GoogleMap>
        //                                                         </Box>
        //                                                     </Stack>   

        //                                                 </Stack>

        //                                                 <Stack direction="column" sx = {{width: '100ch', mt:1.3}}>
                                                                        
        //                                                     <Stack direction="row" sx = {{width: '95ch', height: '6ch'}}> 
        //                                                         <Stack sx = {{width: '20ch'}} >
        //                                                             <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Technician SID: </Typography>
        //                                                         </Stack>
        //                                                         <Stack sx = {{width: '70ch', ml:0 }}>
        //                                                             <Typography fontSize={14} fontWeight={400}fontFamily={'cursive'}>{inputs.sheqsyUID ?? ""}  </Typography>
        //                                                         </Stack>
        //                                                     </Stack>  

        //                                                     <Stack direction="row" sx = {{width: '95ch'}}> 
        //                                                         <Stack sx = {{width: '20ch'}} >
        //                                                             <Typography fontSize={16} fontWeight={600} fontFamily={'cursive'}>Technician: </Typography>
        //                                                         </Stack>
        //                                                         <Stack sx = {{width: '70ch', }} >
        //                                                             <Typography fontSize={14} fontWeight={400}>{selectedContractor.contractorContact ?? "Bob"}    </Typography>
        //                                                         </Stack>
        //                                                     </Stack> 

        //                                                 </Stack>
        //                                         </Stack>
        //                                     }   

        //                                 </Stack>
        //                             }       

        //                         {/* </Stack>   */}
        //                     </Stack> 

        //                 </TabItem>
        //             }
        //             {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  NAVIGATION  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>mm */}

        //             <TabItem  
        //                 title={<Text fontFamily={'cursive'} fontSize={14}>Navigation</Text>}  
        //                 backgroundColor={tabValue == 3 ?'aquamarine':'aliceblue'}//
        //                 border={borderSize( 3, tabValue)}
        //                 borderRadius='15ch'
        //                 //onLoad={()=>console.log('on LOAAAAAD')}
        //                 //onClick={()=>handleNewTabClick(1)}  
        //                 onClick={clearRouts}
        //             >
        //                 <Stack direction="row" sx = {{ width: '155ch', height:'75ch', mt:1}} >  
        //                     <Stack position={'relative'} height={'100%'} width={'100%'}>                        
        //                         <Box position={'absolute'} height={'100%'} width={'100%'} left={0} top={0} border='1px solid gray'>     


        //                                 {destination !=='' && isLoaded && tracker !==500 ?
        //                                     <>
        //                                         <GoogleMap 
        //                                             center = {centerSite}
        //                                             mapContainerStyle = {{width:'100%', height:'100%'}}
        //                                             onUnmount={onUnmount}
        //                                             onLoad={(map)=>{                                                    
        //                                                 setMap(map)
        //                                                 calculateRoute()
        //                                                 map.panTo(centerSite)
        //                                                 map.setZoom(15)
        //                                             }}
        //                                         >
        //                                             <Box 
        //                                                 position={'absolute'} 
        //                                                 height={'85px'} 
        //                                                 width={'1000px'} 
        //                                                 left={220} 
        //                                                 // top={2} 
        //                                             >
        //                                                 {/* {console.log(origin,  techAddress.current )} */}
        //                                                 {/* //{(techAddress.current =='' || techAddress.current == null || techAddress.current == undefined) && */}

        //                                                 <SearchBox 
        //                                                     destination={destination}  
        //                                                     setDestination={setDestination} 
        //                                                     origin={techAddress.current =='' || techAddress.current ==null || techAddress.current == undefined ? origin: ''  }  
        //                                                     setOrigin={setOrigin}  
        //                                                 />
                                                        

        //                                                 <Fab 
        //                                                     size="small" 
        //                                                     sx={{ mt:-.1, left:'86%'}}
        //                                                     color="warning"
        //                                                     onClick={ centerTOcenter }
        //                                                 > <CenterFocusWeakRoundedIcon/> </Fab>

        //                                                 <Fab 
        //                                                     variant="extended" 
        //                                                     size="small" 
        //                                                     color="primary"
        //                                                     sx={{mb:'3px',  , fontSize:12, width:'145px', height:'22px', left:'86.9%'}}
        //                                                     onClick={ calculateRoute }
        //                                                 > calculate route </Fab>
        //                                 {/* contractor place  marker */}

        //                                                 <InfoWindowF  position={centerContract}  >
        //                                                     <div style={{ backgroundColor: 'yellow', border: `1px solid gray`, padding:5, fontSize: 14,  , fontWeight:600}}>
        //                                                         {action==='update'?inputs.supplier:selectedContractor.contractor }    
        //                                                     </div>
        //                                                 </InfoWindowF> 

        //                                                 <MarkerF 
        //                                                     position={centerContract}
        //                                                     icon={{ 
        //                                                         path:"M 1 1 h 8 v 8 h -8 Z", 
        //                                                         fillColor: "yellow", 
        //                                                         fillOpacity: 0.7, 
        //                                                         strokeColor:"brown", 
        //                                                         strokeWeight: 4, 
        //                                                         scale:2
        //                                                     }} 
        //                                                     animation={ aa?google.maps.Animation.DROP:google.maps.Animation.BOUNCE}
        //                                                     onClick={(e)=>setAa(!aa) }
        //                                                 />
        //                                 {/* contractor place  marker end*/}                                                

        //                                 {/* site marker */}
        //                                                 <MarkerF position={centerSite}
        //                                                     icon={{
        //                                                         path:"M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
        //                                                         fillColor: "yellow",
        //                                                         fillOpacity: 0.9,
        //                                                         scale: 1.3,
        //                                                         strokeColor: "red",
        //                                                         strokeWeight: 3,
        //                                                     }}
        //                                                     animation={ aa?google.maps.Animation.DROP:google.maps.Animation.BOUNCE}
        //                                                     onClick={(e)=>setAa(!aa) }
        //                                                 />

        //                                                 <InfoWindowF position={centerSite} >
        //                                                     <div style={{ backgroundColor: 'lightpink', border: `1px solid gray`, padding:5, fontSize: 14,  , fontWeight:600}}>
        //                                                         {siteLabel}    
        //                                                     </div>
        //                                                 </InfoWindowF>   
                                
        //                                                 { tracker !== undefined && 
        //                                                     tracker?.map((t, i)=> {

        //                                                         //    {console.log( inputs.sheqsyUId, selectedContractor.contractorSheqsyId )}
        //                                                         //    {console.log( t)}
        //                                                         //    console.log(t.employee.employeeUId, t.employee.name + ' ' + t.employee.surname,  inputs.sheqsyUId)
        //                                                         //    t.type=='Shift'? console.log(t.shift.currentLocation.latitude, t.shift.currentLocation.longitude) :
        //                                                         //                     console.log(t.activity.currentLocation.latitude, t.activity.currentLocation.longitude)         
        //                                                         //    console.log(t.employee.employeeUId, " ----- "+ inputs.sheqsyUId)

        //                                                         if(t.employee.employeeUId === inputs.sheqsyUId || selectedContractor.contractorSheqsyId === t.employee.employeeUId ){

        //                                                             const Lat = t.type =='Shift'? t.shift.currentLocation.latitude: t.activity.currentLocation.latitude
        //                                                             const Lng = t.type =='Shift'? t.shift.currentLocation.longitude: t.activity.currentLocation.longitude
        //                                                             //console.log(Lat, Lng)

        //                                                             centerEmployee.current = (Lat == null ? defaultCenter : {lat: Lat, lng: Lng})
        //                                                             techAddress.current= t.type =='Shift'? t.shift.currentLocation: t.activity.currentLocation
        //                                                             // {console.log( t.activity)}  //'Fiveways Boulevard, Keysborough, VIC 3173'/
        //                                                             // {console.log( techAddress.current)} 
                                                                    
        //                                                             return (                         
        //                                                                 <> 
        //                                                                     {/* techn marker */}
        //                                                                     <MarkerF 
        //                                                                         position={centerEmployee.current} //{lat: parseFloat(t.activity.currentLocation.latitude),  lng: parseFloat(t.activity.currentLocation.longitude)}}
        //                                                                         label= {t.employee.name + " " + t.employee.surname  }
        //                                                                         cursor= 'pointer'
        //                                                                         //icon={{path:"M 3 3 h 8 v 8 h -8 Z", fillColor: "yellow", fillOpacity: 0.7, strokeColor:"green", strokeWeight: 5, scale:2.2 }} 
        //                                                                         icon=                                                                       
        //                                                                         {{ 
        //                                                                             path: 'M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z',
        //                                                                             fillColor: "lightgreen", 
        //                                                                             fillOpacity: 0.9, 
        //                                                                             strokeColor:"red", 
        //                                                                             strokeWeight: 3, 
        //                                                                             scale:0.9
        //                                                                         }} 
        //                                                                         // options={{
        //                                                                         //     zIndex: 10,
        //                                                                         // }}
        //                                                                         title= {`${t.employee.name.trim()} ${t.employee?.surname} - active since ${dateConverter(new Date(t.shift.startDateTime.dateTimeUTC), false)}`}                                                            
        //                                                                         animation = { aa?google.maps.Animation.DROP:google.maps.Animation.BOUNCE}
        //                                                                         onClick = {(e) => setAa(!aa) }
        //                                                                     />

        //                                                                                         {/* <MarkerF
        //                                                                                                     key={marker.id}
        //                                                                                                     position={{ lat: marker.lat, lng: marker.lng }}
        //                                                                                                     options={{
        //                                                                                                         zIndex: marker.id === id ? 1 : 0,
        //                                                                                                         icon:
        //                                                                                                         marker.id === id
        //                                                                                                             ? "./map-pin-icon-red.svg"
        //                                                                                                             : "./map-pin-icon-blk.svg",
        //                                                                                                     }}
        //                                                                                             /> */}

        //                                                                     <InfoWindowF position={centerEmployee.current} >
        //                                                                         <div style={{backgroundColor: 'lightgreen', border: ".1px solid black", padding:4, fontSize: 14,  , fontWeight:600}} >
        //                                                                             {<div>{`${t.employee.name} ${t.employee.surname}`}</div> }
        //                                                                                 {Lat == null && ` GEO LOCATION IS OFF  ` } 
        //                                                                             {/* {<img src={image}/>} */}
        //                                                                         </div>
        //                                                                     </InfoWindowF>
        //                                                                 {/* techn marker */}
        //                                                                     {/* { directionResponse !== null &&  <DirectionsRenderer directions={directionResponse==null ? null : directionResponse}/> } */}
        //                                                                 </>
        //                                                             )
                                                                    
        //                                                         }
        //                                                     })
        //                                                 }

        //                                                 {directionResponse !== null ? <DirectionsRenderer directions={directionResponse}/> : google.maps.LatLng(null)} 
        //                                             </Box>  
                                                    
        //                                         </GoogleMap>

        //                                         <Stack direction="row" gap={1} color='red' sx={{mt:2, ml:1, width:'70ch',  fontSize:16, fontWeight:1000, }}>  
        //                                             <Typography 
        //                                                 //sx={{ flex: '1 1 1%', ml:2}}
        //                                                 variant="div" 
        //                                                 className='onefive'
        //                                                 fontSize={16} 
        //                                                 // fontWeight={800}
        //                                                 fontFamily={'cursive'}

        //                                             >{distance !=='' && `Distance by car: ${distance};`}</Typography>

        //                                             <Typography 
        //                                                 //sx={{ flex: '1 1 1%', ml:2}}
        //                                                 variant="div" 
        //                                                 className='onefive'
        //                                                 // fontSize={16} 
        //                                                 // fontWeight={800}
        //                                                 fontFamily={'cursive'}

        //                                             > {duration !=="" && `Travel time: ${duration}`}</Typography>    
        //                                         </Stack>
        //                                     </>
                                            
        //                                 :
        //                                     <Stack position='relative' height={'6ch'} width={'6ch'} sx={{ml:100}} marginLeft={50} marginTop={40}> 
        //                                         <Loader size="small" emptyColor={'gray'} filledColor={'red'} style={{width: 100}}/>
        //                                     </Stack>        
        //                                 }

        //                         </Box>
        //                     </Stack>    
        //                 </Stack> 
        //             </TabItem>

        //             {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  NOTES >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

        //             {action==='update' && 
        //                 <TabItem    
        //                     title={<Text fontFamily={'cursive'} fontSize={14}>Notes</Text>}  
        //                     backgroundColor={4 ===tabValue ?'aquamarine':'aliceblue'}//
        //                     border={borderSize( 4, tabValue)}
        //                     borderRadius='15ch'                                           //onClick={()=>handleNewTabClick()} 
        //                     onClick={getNotes}  
        //                     onContextMenu={(e) => {
        //                         e.preventDefault();
        //                         DEBUG && console.log("Right Click");
        //                     }}
        //                 >
                            
        //                     <Stack direction="column" sx = {{ height:'75ch', width:'155ch', mt:1}} >  
                                                            
        //                         <TableContainer 
        //                             sx={{ height:'54ch', width:'150ch', ml:1, border: "1px solid gray"}} 
        //                             onContextMenu={(e) => {
        //                                 e.preventDefault();
        //                                 DEBUG && console.log("Right Click 44" );
        //                             }}
        //                         > 
        //                             <Table 
        //                                 //caption={<Stack border='1px solid lightgray' paddingY={4}><Typography color='#2d187be0' fontSize={16} fontWeight={600} fontFamily={'cursive'}> Notes</Typography></Stack>}
        //                                 size="small"
        //                                 highlightOnHover={true}
        //                                 variation="striped"
        //                                 backgroundColor={'antiquewhite'}
                                        
        //                             >
        //                                 <TableHead
        //                                     onContextMenu={(e) => {
        //                                         e.preventDefault();
        //                                         DEBUG && console.log("Right Click 33");
                                            
        //                                     }}
                                        
        //                                 >
        //                                     <TableRow >
        //                                         <TableCell as="th" width='120px' fontSize={16} fontFamily={'cursive'}>Date</TableCell>
        //                                         <TableCell as="th" width='120px' fontSize={16} fontFamily={'cursive'}>User</TableCell>
        //                                         <TableCell as="th" fontSize={16} fontFamily={'cursive'}>Description</TableCell>
        //                                     </TableRow>
        //                                 </TableHead>

        //                                 <TableBody>
        //                                     {notes.map((n, i)=>
        //                                         <TableRow 
        //                                             onClick={()=>console.log('test ', i)}
        //                                             onContextMenu={(e) => {
        //                                                 e.preventDefault();
        //                                                 // console.log( e.pageX, e.pageY)
        //                                                 // console.log( n.noteDetails, i,)
        //                                             }}
        //                                         >
        //                                             <TableCell width = '25ch' fontSize={14} fontFamily={'cursive'}>{dateConverter(n.noteDate)}</TableCell>
        //                                             <TableCell width = '20ch' fontSize={14} fontFamily={'cursive'}>{n.userFullName}</TableCell>
        //                                             <TableCell fontSize={14} fontFamily={'cursive'}>{n.noteDetails}</TableCell>

        //                                         </TableRow>

        //                                         )    
        //                                     }

        //                                 </TableBody>
        //                             </Table>       
        //                         </TableContainer>   

        //                         {/* <Stack  
        //                             direction="row" 
        //                             sx = {{ width: '185ch', height:'20ch', mt:0, ml:0}}  
        //                         >   */}

        //                         <Stack name='ADD NOTE TEXTAREA' sx = {{ width:'150ch', height:'20ch', mt:0, ml:1}}     >  
        //                             <TextAreaField
        //                                 autoComplete="off"
        //                                 //descriptiveText="Enter a valid last name"
        //                                 //  direction="column"
        //                                 hasError={false}
        //                                 isDisabled={false}
        //                                 isReadOnly={false}
        //                                 isRequired={false}
        //                                 labelHidden={false} 
        //                                 name="last_name"
        //                                 placeholder="Enter New Notes ..."
        //                                 rows="5"
        //                                 value={newNote}
        //                                 onChange={(e)=>{ 
        //                                     setNewNote( e.currentTarget.value)}
        //                                 }

        //                                 // onKeyDown={(code)=>{console.log(code.code)
        //                                 // if (code.code =='Enter'){console.log(code.code)} 
        //                                 // onBlur={(e=>setPostNote(a =>({...a,  newNote: e.target.value})))}
        //                                 // onInput={(e) => DEBUG && console.info('input fired:', e.currentTarget.value)}
        //                                 // onCopy={(e) =>DEBUG &&  console.info('onCopy fired:', e.currentTarget.value)}
        //                                 // onCut={(e) =>DEBUG &&  console.info('onCut fired:', e.currentTarget.value)}
        //                                 // onPaste={(e) =>DEBUG &&  console.info('onPaste fired:', e.currentTarget.value)}
        //                                 // onSelect={(e) =>DEBUG &&  console.info('onSelect fired:', e.currentTarget.value.substring(e.currentTarget.selectionStart, e.currentTarget.selectionEnd  ))}
                                        
        //                             />

        //                             <Stack name='ADD NOTES FAB_BUTTON' sx = {{ mt:1, ml:'85ch', }}>                                                    
        //                                 <Fab 
        //                                     variant="extended"                                                     
        //                                     size="small" 
        //                                     color="mediumseagreen" 
        //                                     //border='10px solid gray'
        //                                     //borderRadius= '5px'
        //                                     sx={{
        //                                          , 
        //                                         fontSize:10, 
        //                                         fontWeight:800,
        //                                         width:'17ch', 
        //                                         // height:'5ch', 
        //                                         ///right:'230%', 
        //                                         color:"mediumseagreen",
        //                                         background: 'papayawhip',
        //                                         zIndex:0,
        //                                         boxShadow:'0 0 2px 2px gray',   
                                                                                                
        //                                     }}
        //                                     onClick={postNewNotes}                                                
        //                                 > Add Note </Fab>                                                                                                                                  
                                        
        //                             </Stack>
                                    
        //                         </Stack>                                                                            
                                    
        //                         {/* </Stack> */}
                                
        //                     </Stack> 
                            
        //                 </TabItem>
        //             }

        //             {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  PARTS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

        //             {action==='update' &&     
        //                 <TabItem    
        //                     title={<Text fontFamily={'cursive'} fontSize={14}>Parts </Text>}  
        //                     backgroundColor={5 ===tabValue ?'aquamarine':'aliceblue'}//
        //                     border={borderSize( 5, tabValue)}
        //                     borderRadius='15ch'
        //                     onClick={getOrderedParts} 
        //                 >
        //                     <Stack direction="column" sx = {{ width: '155ch' , height:'75ch',mt:1}} >     

        //                         <TableContainer 
        //                             sx={{ height:'54ch', width:'150ch', ml:1, border: "1px solid gray"}} 
        //                             onContextMenu={(e) => {
        //                                 e.preventDefault();
        //                                 DEBUG && console.log("Right Click 44" );
        //                             }}
        //                         > 
        //                             <Table
        //                                 highlightOnHover={true}
        //                                 height={'53px'}
        //                                 variation="striped"
        //                             >
        //                                 <TableHead
        //                                     onContextMenu={(e) => {
        //                                         // e.preventDefault();
        //                                         // console.log("Right Click 33");
                                            
        //                                     }}
                                        
        //                                 >
        //                                     <TableRow >
        //                                         <TableCell as="th" width='120px' fontSize={16} fontFamily={'cursive'}>Date</TableCell>
        //                                         <TableCell as="th" width='120px' fontSize={16} fontFamily={'cursive'}>By</TableCell>
        //                                         <TableCell as="th" fontSize={16} fontFamily={'cursive'}>Id</TableCell>
        //                                         <TableCell as="th" fontSize={16} fontFamily={'cursive'}>Dispatch To</TableCell>
        //                                         <TableCell as="th" fontSize={16} fontFamily={'cursive'}>Consigment Date</TableCell>
        //                                         <TableCell as="th" fontSize={16} fontFamily={'cursive'}>Carier</TableCell>
        //                                         <TableCell as="th" fontSize={16} fontFamily={'cursive'}>Consigment Note</TableCell>
        //                                     </TableRow>
        //                                 </TableHead>

        //                                 <TableBody>
        //                                     {orderedPatrs.map((n, i)=>
        //                                         <TableRow 
        //                                             onClick={()=>DEBUG && console.log('test ', i)}
        //                                             onContextMenu={(e) => {
        //                                                 e.preventDefault();
        //                                                 DEBUG && console.log( e.pageX, e.pageY)
        //                                                 DEBUG && console.log( n.noteDetails, i,)
        //                                             }}
        //                                         >
        //                                             <TableCell width = '25ch' fontSize={14} fontFamily={'cursive'}>{dateConverter(n.jobPartsOrderDate )}</TableCell>
        //                                             <TableCell fontSize={14} fontFamily={'cursive'}>{'ME'}</TableCell>
        //                                             <TableCell fontSize={14} fontFamily={'cursive'}>{n.partsOrderedGroupid}</TableCell>
        //                                             <TableCell fontSize={14} fontFamily={'cursive'}>{n.jobPartsConsignmentTo}</TableCell>
        //                                             <TableCell fontSize={14} fontFamily={'cursive'}>{dateConverter(n.jobPartsConsignmentDate)}</TableCell>
        //                                             <TableCell fontSize={14} fontFamily={'cursive'}>{n.jobPartsConsignmentCarrier}</TableCell> 
        //                                             <TableCell fontSize={14} fontFamily={'cursive'}>{n.jobPartsConsignmentNote}</TableCell> 
                                                    
                                                    

        //                                         </TableRow>

        //                                         )    
        //                                     }

        //                                 </TableBody>
        //                             </Table>       
        //                         </TableContainer>   

        //                     </Stack> 
        //                 </TabItem>
        //             }   

        //             {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  SIGN >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

        //             {action==='update' &&     
        //                 <TabItem    
        //                     title={<Text fontFamily={'cursive'} fontSize={14}>Signature </Text>}  
        //                     backgroundColor={6 ===tabValue ?'aquamarine':'aliceblue'}//
        //                     border={borderSize( 6, tabValue)}
        //                     borderRadius='15ch'
        //                     onClick={getOrderedParts} 
        //                 >
        //                     <Stack direction="column" sx = {{ width: '155ch' , height:'75ch',  mt:1}} >     

        //                         <Canvas width={800} height={450} jpbNumber={inputs.jobNo}/>
                                
        //                     </Stack> 

        //                 </TabItem>
        //             }    
                    
        //         </Tabs>
           
        //     </Stack>     

        
        //     <Stack name='BUTTONS' className='stackButtons' direction="row" sx={{ mt:'2ch'}} >

        //         <Button 
        //             className='Button1'
        //             onClick={action==='update'? handleUpdate: handleAdd }
        //             variant="outlined"
        //             //size='small'
        //             sx={{fontWeight:800, fontSize:10,  , borderRadius:'5px'}} 
        //         ><text >{action==='update'? 'Update ' : 'Add '} </text>    
        //         </Button>

        //         <Button 
        //             className='Button1'
        //             onClick={handleClose} 
        //             variant="outlined" 
        //             sx={{fontWeight:800, fontSize:10,  , borderRadius:'5px'}} 
        //         > <text >close </text>     
        //         </Button>
                
        //     </Stack>     
    
        // </Box>  



        




























// {/* <Container g w='43ch' h='19ch' ml="10ch" primary>        
//                                                 <Typography width='12ch' sx={{paddingX: 1, fontSize:14, fontWeight:600,  , mt:-1, ml:1, color:'black', boxShadow:'1px 1px 2px 1px lightgray', borderRadius:1 }}>
//                                                     Job Priority
//                                                 </Typography>
                                                
//                                                 <FormControl 
//                                                     size = 'small' 
//                                                     sx = {{ ml:2, mt:0}} 
//                                                     disabled={(role.trim().toUpperCase()==='Contractor'.toUpperCase()  || role.trim().toUpperCase()=='Field Technician'.toUpperCase()) } 
                                            //    >                                                                                                                                                     {/* <FormLabel><Typography sx={{ fontSize:16, fontWeight:600,  , my:1, }}>Job Priority :</Typography></FormLabel> */}                                        
                                            //         <RadioGroup
                                            //             //row={false}
                                            //             name="priority"
                                            //             id='radioGroup'
                                            //             defaultValue={4}
                                            //             value={String(inputs.priority)}
                                            //             onChange={(e) => {
                                            //                 setInputs(values=>({...values, [e.target.name]: parseInt(e.target.value)}))  //console.log(inputs.siteZone, e.target.value )  //DEBUG && console.log(parseInt(e.target.value))
                                            //                 getSLA(`${DOMAIN}${process.env.REACT_APP_API_GET_SLA}${inputs.customerId}/${parseInt(e.target.value)}/${inputs.siteZone}`,parseInt(e.target.value))
                                            //                 setPostNote(a =>({...a, jobPriority: `${e.target.value}`}))
                                            //             }}
                                            //         >
                                            //             <FormControlLabel 
                                            //                 value={1} 
                                            //                 control={<Radio size='small' sx={{color:'transparent','&.Mui-checked':{color:'#ff8a80'}}} />} 
                                            //                 label={<text style={{ , fontSize:14, fontWeight:600}}>Extreme</text>} 
                                            //                 sx={{color: '#ff8a80' }}  
                                            //                 labelPlacement="end"
                                            //             />
                                            //             <FormControlLabel 
                                            //                 value={2} 
                                            //                 control={<Radio size='small' sx={{color:'transparent','&.Mui-checked':{color: purple[600]} }}/>} 
                                            //                 label={<text style={{ , fontSize:14}}>Urgent</text>}  
                                            //                 sx={{color: purple[600], fontSize:10,  }}  
                                            //                 labelPlacement="end"
                                            //             />
                                            //             <FormControlLabel value={3} control={<Radio size='small' sx={{color:'transparent','&.Mui-checked':{color: blue[600]}}}/>} label={<text style={{ , fontSize:14}}>Moderate</text>} sx={{color: blue[600], fontSize:10, }}  labelPlacement="end"/>
                                            //             <FormControlLabel value={4} control={<Radio size='small' sx={{color:'transparent','&.Mui-checked':{color: green[600]}}}/>} label={<text style={{ , fontSize:14}}>Standard</text>}  sx={{color: green[600], fontSize:10,  }}  labelPlacement="end"/>
                                            //             {/* <FormControlLabel value="5" control={<Radio size="small" sx={{color:'transparent','&.Mui-checked':{color: yellow[700]}}}/>} label="Low" sx={{color: yellow[700] }} /> */}
                                            //             {/* <FormControlLabel value="6" control={<Radio size="small" sx={{color: pink[800],'&.Mui-checked': {color: pink[600],},}} />} label={inputs.priority} /> */}
                                            //         </RadioGroup>
                                            //     </FormControl>
                                            // </Container> */}




    // <Autocomplete 
                                                                            //     name='partsNameList' 
                                                                            //     //value={newOrderedPart.partsOrderedDevice  } 
                                                                            //     //freeSolo={true}
                                                                            //     required = {true}
                                                                            //     size='small'                                    
                                                                            //     id="partsOrderedDevice"
                                                                            //     options={ partsNames.map(p=> p.jobPartsDescription) }
                                                                            //     //autoHighlight

                                                                            //     onChange={(event, newInputValue) => {
                                                                            //         console.log(' --------------- ',newInputValue )
                                                                            //         console.log(' --------------- ',event.target.outerText )

                                                                            //         if(newInputValue === null){
                                                                            //             //setNewOrderedPart(values=>({...values, ['jobNo']: parseInt(id)}))
                                                                            //             //setNewOrderedPart(values=>({...values, ['partsOrderedTechnicianId']: userId}))
                                                                            //             setNewOrderedPart(values=>({...values, ['partsOrderedDevice']: ''}))
                                                                            //             setNewOrderedPart(values=>({...values, ['partsOrderedDeviceModel']: ''}))
                                                                            //             setNewOrderedPart(values=>({...values, ['partsOrderedDeviceManufacturer']: ''})) 
                                                                            //             //setNewOrderedPart(values=>({...values, ['partsQty']: 1}))
                                                                            //         }else{
                                                                            //             partsNames.map((p)=> {
                                                                            //                 if(newInputValue==p.jobPartsDescription){
                                                                            //                     setNewOrderedPart(values=>({...values, ['jobNo']: parseInt(id)})) //partsOrderedId
                                                                            //                     setNewOrderedPart(values=>({...values, ['partsOrderedId']: parseInt(p.partsId)}))
                                                                            //                     setNewOrderedPart(values=>({...values, ['partsOrderedTechnicianId']: userId}))
                                                                            //                     setNewOrderedPart(values=>({...values, ['partsOrderedDevice']: newInputValue}))
                                                                            //                     setNewOrderedPart(values=>({...values, ['partsOrderedDeviceModel']: p.jobPartNumber}))
                                                                            //                     setNewOrderedPart(values=>({...values, ['partsOrderedDeviceManufacturer']: p.jobPartsManufacturer})) 
                                                                            //                     setNewOrderedPart(values=>({...values, ['partsOrderedOrderDate']: Math.trunc(Date.now()/1000)}))  //setInputs(values=>({...values, ['supplierState']: a.supplierState})) console.log(p.partsId, p.jobPartsType, p.jobPartsManufacturer)
                                                                            //                 }
                                                                            //             })
                                                                            //         }
                                                                                    
                                                                            //     } }
                                                                            //     renderInput={(params) => (
                                                                            //         <TextField
                                                                            //             {...params}
                                                                            //             style={{width:'80ch'}}
                                                                            //             sx={{boxShadow: `1px 1px 2px  rgba(0, 0, 0, 0.3)`}}                
                                                                            //             inputProps = {{...params.inputProps, type: 'search', }}       
                                                                            //         />
                                                                            //     )}
                                                                            // />    