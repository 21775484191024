import React, {useState, useContext, useEffect } from "react";
import {UserLogInContext} from '../../App.js'
import './home.scss';       // import Navbar from '../../components/Navbar.jsx';// import Sidebar from '../../components/Sidebar.jsx';//import MainFrame from '../../components/MainFrame.jsx';
import { useLocation } from 'react-router-dom';
import { ModChangePassword } from '../../util/Functions.jsx'; // ModAlert, authentUser, changePswd, dateConverter //import { Paper } from "@mui/material";// import { Button, Heading } from '@aws-amplify/ui-react'; // import ExitToAppIcon from "@mui/icons-material/ExitToApp";  //  import Main from '../../components/Main.jsx'; // import CountrySelect from '../../util/CountryProvider.jsx'//{signOut, loggedInUser, loggedInGroup}///
import { useAuthenticator  } from "@aws-amplify/ui-react";  
import { useNavigate  } from "react-router-dom";
import ApplicationBar from '../../components/AppBar.jsx';
import { Fade, Typography } from '@mui/material'; 

const{REACT_APP_API_HTTP,
    REACT_APP_API_LOCAL_DOMAIN,
    REACT_APP_API_LOCAL_PORT, 
    NODE_ENV,
    REACT_APP_API_TEMPPSW,
    REACT_APP_API_CLEARTEMPPSW,
    REACT_APP_API_TEST,
    REACT_APP_DEBUG
}=process.env  
const DEBUG =  REACT_APP_DEBUG ;

const Home = () => {
  const [alertBox, setAlertBox] = useState(false);   // const [txt, setTxt]= useState('');  //const [resp, setResp] = useState('')   //  const [tempPsw, setTempPsw] = useState('')
  const [changePswResponse, setChangePswResponse] =useState(); //  const [count, setCount] = useState(0);     //const productionHost = REACT_APP_API_HTTP + window.location.host;  const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
  // const [ancorElAdmin, setAncorElAdmin] = useState(null);  // const [anchorElUser, setAnchorElUser] = useState(null);
  const [show, setShow] = useState(false) 
  const { group,  role,} = useContext(UserLogInContext); //const hostname=window.location.hostname; // const port ='3002'; // const host = hostname +":"+port  //const [DOMAIN1, setDOMAIN1]= useState(`http://${NODE_ENV==='production'?window.location.host:window.location.hostname +':3002'}`) 
  const { user, signOut, route, authStatus} = useAuthenticator((context) =>[context.user, context.route, context.authStatus]);    // const isAdmin = group[0] =='Admin' || user.attributes['custom:Role'] =='Administration' || user.attributes['custom:Role'] =='Administrator';  
  const navigate = useNavigate();  
  const location = useLocation();  

  const DOMAIN = `${NODE_ENV === 'production' ? REACT_APP_API_HTTP + window.location.host : REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT}`; //const url_main=`${DOMAIN}${REACT_APP_API_TEST}`;  

  useEffect(()=>{     
    const controller = new AbortController()
    
    getTempPsw_DB(`${DOMAIN}${REACT_APP_API_TEMPPSW}${user.username}`)   //getData(DOMAIN + '/api/2')  //getData(DOMAIN + '/api/t?test=hello&yyy=ooo')  //postData(DOMAIN + '/api/2') //return (()=> console.log(user, role))
    return () => { controller.abort() };
  },[]);   

  useEffect(()=>{  

    if(changePswResponse !==undefined){
      DEBUG &&  console.log(' psw if: ', changePswResponse)
      changePswResponse ? cleanTempPsw(`${DOMAIN}${REACT_APP_API_CLEARTEMPPSW}${user.username}`) : signOut()
    }

  },[changePswResponse] );

  const onClickHandler = () => {  setShow(!show)} 

  const handleTabClose = (e) => {
     //  e.preventDefault();     // console.log()
    console.log('BEFORE UNLOAD event triggered ', e);  //const confirm_result = confirm("Are you sure you want to quit?");
    navigate('/')    //localStorage.clear()   //window.open('', '_self', '').close();
    signOut()
    return (e.returnValue ='Are you sure you want to exit ?');
  };

  const getData = async(url)=>{
    const response = await fetch(url)
    DEBUG &&  console.log("%s %d", url,  response.status) //DEBUG &&  console.log("%d", response.status)
    if(response.status >= 200 && response.status<=299){
      console.log("%c Server Responded with staus : %d", "color:blue; font-weight:bold;", response.status)
      const responseData = await response.text();        //console.log(responseData.Responce, responseData.Responce[2].customerName);
      DEBUG &&   console.log("%c%s", "color:green; font-weight:bold;", responseData)      // setTxt(responseData)      // setResp(responseData.Responce[2].customerName)   
    }else{
      console.log("%c Error: %d", "color:red; font-weight:bold;", response.status)
    }
             
  };

  const postData =async(url)=>{

    const requestOptions = {   
        method: 'POST', 
        headers: {'Content-Type':'application/json; charset=utf-8' }, 
        body: JSON.stringify({ 'firstname': 'HELLO WORD' }),    
    } 
    const response = await fetch(url,  requestOptions)
    const responseData = await response.json();  
    console.log("%s %d", url,  response.status)
    console.log(responseData)
  }

  const getTempPsw_DB = async(url)=> {
    const response = await fetch(url)
    const status= response.status
    const responseData = await response.json();      // console.log(status, responseData[0].passWord);    ///setTempPsw(responseData[0].passWord)
    (status==200 && responseData[0]?.passWord !== '' ) && setAlertBox(true)      
  };

  const cleanTempPsw = async(url)=> {
    const response = await fetch(url)
    const status= response.status
    const responseData = await response.json();      //console.log(status, responseData);    ///setTempPsw(responseData[0].passWord)    //(status==200 && responseData[0].passWord !== '' ) && setAlertBox(true)      
  };

  return (

    <>
      {!alertBox && <ApplicationBar tableName={'home'} message={'msg'} setMessage={'test'} modalResponce={'modalResponce'}/>}
      <div className="mainContainer">                                   
        <div className="title">
          <h3 onMouseOver={onClickHandler} className='gradientText'>Welcome to Juxaroo</h3> 
          <div >    
            <Fade in={show} appear={false} timeout={100}>               
              <Typography > **</Typography>               
            </Fade> 
          </div> 
        </div>      
      </div>

      <ModChangePassword isOpen={alertBox} setIsOpen={setAlertBox} userLoginName={user.username} setChangePswResponse={setChangePswResponse}/>
    </>

  )
}
export default  Home;


    // {/* <Button onClick={onClickHandler} color="success"></Button> */}

// {/* <h1 style={{ color: "green" }}>Gks</h1> <img src= "https://media.geeksforgeeks.org/wp-content/uploads/20210608021423/Output.gif"   height={200} width={200}   />  */}

//   useEffect(() => {  
//     DEBUG && console.log('useEffect ENTRY')
//     window.addEventListener('beforeunload', handleTabClose);
//     return () => {
//       DEBUG && console.log('useEffect CLEAN UP')
//       window.removeEventListener('beforeunload', handleTabClose) 
//     };
//  },[]);

  // useEffect(() => {
  //   // execute on location change
  //   setCount(c=>c + 1);
  //   DEBUG && console.log('Location changed! ' + location, count); 
  // }, [location]);





















    // <div className='home'>  
    //   <Sidebar />

    //   <div className="homeContainer">
    //     <Navbar />

// {/* <div className="title"> <h1 className='gradientText'>Welcome to Autom8</h1>  {/* <h1 className='gradientText'>{`Client app runs on  ${DOMAIN} at ${txt.Server} backend ver:${txt.Version}. Following text is sourced from table = 'client',  cliendID = 3 >>  ${resp}  <<  ${count}  ${location.pathname} ` }</h1>    setTxt(responseData.Responce[3].customerName)       // - a message from DB through Nodejs middleware to this client -- ${txt}`} </div> */}

          
//           {/* <div className="title"> <h1 className='gradientText'>Welcome to Autom8</h1> </div> */}
//                               {/*{window.onbeforeunload = (event)=>{event.returnValue = "Write something here.";}}*/}
//                                 {/* <div className="title">{user}</div>
//                                 <div className="title">{group}</div>
//                                 <div className="title">{subId}</div>
//                                 <div className="title">{role}</div>
//                                 <div className="title">{subrole}</div>
//                                 <div className="title">{customerrole}</div>
//                                 <div className="title">{userFullName}</div>
//                                 <div className="title">{userId}</div>
//                                 <div className="title">{dateConverter(time)}</div> */}
