import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Slide,
  Stack,
} from "@mui/material";
import { LoginSearchAddress, Log, fl } from "../util/Functions.jsx";


//let a = pattern.test(text)  true/ false

export const DialogueAlert = ({  isOpen,  openOff,  setValue,  title,  dialogueTxt,}) => {
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({title: "",    year: ""});

  useEffect(() => {    isOpen && trigger()  }, [isOpen]);

  const handleClose = () => {
    setDialogValue({title: "", year: "",});
    openOff(false);
    toggleOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();    // setValue({   title: dialogValue.title, year: parseInt(dialogValue.year, 10),   });
    setValue(dialogValue.title);
    handleClose();
  };

  const trigger = () => {setTimeout(() => { toggleOpen(true)})};

  return (
    <>
      <Dialog 
        aria-hidden={false}
        open={open} 
        onClose={handleClose}
      >
        <form  onSubmit={handleSubmit}       >

          <DialogTitle>{title}</DialogTitle>

          <DialogContent>
            <DialogContentText>{dialogueTxt}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"              //id="name"
              value={dialogValue.title}
              placeholder="No"
              onChange={(event) => setDialogValue({...dialogValue,  title: event.target.value, }) }
              type="text"
              variant="standard"
            />

            {/* 
              <TextField
                    margin="dense"
                    value={dialogValue.year}
                    onChange={(event) => setDialogValue({ ...dialogValue,    year: event.target.value, })}   
                        label="year"
                        type="number"
                        variant="standard"                                          
              /> 
            */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Ok</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const DialogueResponce = ({isOpen,  setIsOpen, setValue, buttonFlag, title,  dialogueTxt, setIinitialFlag}) => {

  const [open, toggleOpen] = useState(false);

  useEffect(() => {    
    isOpen && trigger(); // console.log(!!setIinitialFlag)    
  },[isOpen]);

  const handleClose = () => {  //  setIsOpen(false);
    toggleOpen(false);
    !!setIinitialFlag && setIinitialFlag(false)    //buttonFlag && window.location.reload()
  };

  const handleSubmit = () => {
    setValue(true);
    handleClose();
  };

  const trigger = () => { setTimeout(() => {
    toggleOpen(true)      //setIinitialFlag(true)
  }) };

  const Transition = React.forwardRef(function Transition(props, ref) {return <Slide direction="up"  {...props} />  });

  return (
    <Dialog
      aria-hidden={false}
      open={open} 
      onClose={handleClose}      //TransitionComponent={'up'}     // fullScreen={buttonFlag}      //fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers ={buttonFlag}> {<Typography fontFamily={'Arial' } fontSize='clamp(0.65rem, .8rem, 1.1rem)'>{dialogueTxt}</Typography>} </DialogContent>

      <DialogActions sx={{justifyContent:'center', alignItems:'center', gap:2}}>
        {buttonFlag ?
            <Button 
              className='Button1'               
              variant="outlined"          
              sx={{ fontSize: 'clamp(0.65rem, .7rem, 1rem)' }} 
              onClick={handleClose}
            ><Typography fontSize='clamp(.6rem, .7rem, 1rem)'> Close</Typography></Button>        
        :        
          <>
            <Button 
              className='Button1'               
              variant="outlined"          
              onClick={handleSubmit}
              sx={{ fontSize: 'clamp(0.6rem, .7rem, 1rem)' }}  
            > <Typography fontSize='clamp(.6rem, .7rem, 1rem)'> Yes</Typography></Button> 

            <Button 
              className='Button1'               
              variant="outlined"          
              sx={{ fontSize: 'clamp(0.65rem, .7rem, 1rem)' }}          
              onClick={handleClose}
            > <Typography fontSize='clamp(.62rem, .72rem, 1rem)'>No</Typography></Button>
          </>
        }
      </DialogActions>
    </Dialog>
  );
};

export const JustAlert = ({ isOpen, openOff, title, dialogueTxt }) => {
  const [open, toggleOpen] = useState(false);  // const [obj, setObj] =useState({test:'hello', test1:'hellow again'} )

  const arr = ["ww", "dd"]; // for of  //const obj = { test: "hello", test1: "hellow again" }; //for (const element in obj) {   console.log(obj[element])}
  const fruits = new Map([    ["apples", 500],    ["bananas", 300],    ["oranges", 200],  ]);

  // useEffect(() => {
  //   //console.log("test 1 ");
  //   return () => {   console.log("test 2")    };
  // }, [isOpen]);

  useEffect(() => {

    // try {
    //   for (const [key, value] of fruits) {
    //     console.log( key + " -- " + value);
    //   }

    //   for (const [key, value] of arr) {
    //     console.log( key + " -- " + value);
    //   }

    //   for (const e of arr) {
    //     console.log( e);
    //   }

    //   for (const [key, value] in arr) {
    //     console.log("arrrrr 222: ", arr[key]);
    //     console.log("1111: ", value);
    //   }
    // } catch (err) {  console.log(err)}
    
    isOpen && trigger();
  }, [isOpen]);

  const handleClose = () => {
    openOff(false);
    toggleOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const trigger = () => { setTimeout(() => { toggleOpen(true) }, 100) };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogueTxt}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
            {/* <Button type="submit">Ok</Button> */}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const DialogueAddressResponce = ({  isOpen,  setIsOpen,  setValue,  title}) => {

  const [open, toggleOpen] = useState(false);

  useEffect(() => {isOpen && trigger()}, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    toggleOpen(false);
  };

  const handleSubmit = () => {handleClose()};

  const trigger = () => { setTimeout(() => {toggleOpen(true)})};

  return (
    <Dialog
      open={open} 
      onClose={handleClose}     
      //fullWidth={true}
      
    >
      <DialogTitle sx={{textAlign:'center'}}><Typography  fontSize={18} fontWeight={800}> {title} </Typography> </DialogTitle>

      <DialogActions sx={{justifyContent:'center', alignItems:'center'}}>
          <Stack>
                <LoginSearchAddress                            
                    setDestination={setValue}
                    size={414}
                />
            </Stack>
  
          <Button onClick={handleSubmit}> OK  </Button>        
    
      </DialogActions>
    </Dialog>
  );
};
