import React, {useState, useRef, useEffect } from 'react';
import { Box, Stack, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@mui/material";
import './newitem.scss';
import { useParams, useNavigate, useLocation } from "react-router-dom"; //import NewContact from './NewContact';//import { Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react';
import { Table, TableCell, TableBody, TableHead, TableRow, Tabs, TabItem, SelectField, CheckboxField, useAuthenticator, Alert } from '@aws-amplify/ui-react';// {  Item, Tabs, SelectField, Icon } from '@aws-amplify/ui-react';
import {JustAlert} from '../DialogueAlert.jsx'


const NewWarehouse = ({action}) => {
    const [customersAll, setCustomersAll] = useState([{}]);
    const [selectedCustomer, setSelectedCustomer] = useState('');    //const [partNumber, setPartNumber] = useState('');    //const [customerEmail, setCustomerEmail] = useState('');
    const [inputs, setInputs] = useState({});
    const [openPartUpdate, setOpenPartUpdate] = useState(false);
    const [openPartInStockAdd, setOpenPartInStockAdd] = useState(false);
    const [dialogValue, setDialogValue] = useState({});
    const [dialogText, setDialogText] = useState('');
    const [dialogFieldName,setDialogFieldName] = useState('')
    const [alertActive, setAlertActive] = useState(false);
    const [tabPartValue, setTabPartValue]=useState(0);
    const [partInStockDetails, setPartInStockDetails]=useState();
    const [locationList, setLocationList] = useState()//{locationId:null, locationName:null});
    const [backGrounColor, setBackGrounColor] = useState({  color:null, 
                                                            index:null,
                                                            column:null });
    
    const [alertDetails, setAlertDetails] = useState({  text:'', 
                                                        heading:'', 
                                                        variation:'warning' })
    const VALID = useRef(false)
    const countEntries = useRef(0)
    const customersNames =useRef([]);
    let {id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const { route } = useAuthenticator((context) => [context.route]);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);

    //console.log(user.attributes)
    console.log(route, authStatus )
    console.log(countEntries.current= countEntries.current +1)

    const {REACT_APP_API_GET_CUSTOMERS_LIST, 
            REACT_APP_DEBUG, 
            NODE_ENV, 
            REACT_APP_API_HTTP, 
            REACT_APP_API_LOCAL_DOMAIN, 
            REACT_APP_API_LOCAL_PORT,
            REACT_APP_API_GET_PART_LOCATIONS} = process.env

    const productionHost=REACT_APP_API_HTTP + window.location.host
    const localHost=REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT
    const DOMAIN =`${NODE_ENV==='production' ? productionHost : localHost}`;    
    const DEBUG = REACT_APP_DEBUG;


    useEffect(()=>{
        // /////getCustomersList(); // customers list for parts assigment                         //DEBUG && console.log('PART ID : ', id)
        // /////getLocationList();
        //getTenant();
        //  ////action ==='update' && getPartsData(`${DOMAIN}${process.env.REACT_APP_API_GET_PARTS_DATA}${id}`) 
        action ==='update' && getWarehousesData(`${DOMAIN}${'/api/warehouses/'}${id}`)
    }, []);


    const handleCloseDialog = ()=>{
        setDialogValue({});
        setDialogFieldName('')
        setOpenPartUpdate(false);
    };

    const handleCloseDialogAdd = ()=>{
        setDialogValue({});
        setOpenPartInStockAdd(false)
        console.log('hhhhhhh')
    
    }
          
    const handleChagesForPartInStock = async(e) => {
        e.preventDefault();
        console.log(dialogValue)

        if(dialogValue.fieldName !== undefined){
            const requestOptions = {   
                method: 'PUT', 
                headers: {'Content-Type':'application/json; charset=utf-8' }, 
                body: JSON.stringify({ dialogValue }),    
            } 
            const response = await fetch(`${DOMAIN}${'/api/stockpartsdata/1'}`, requestOptions);
            handleCloseDialog();
            getPartsData(`${DOMAIN}${process.env.REACT_APP_API_GET_PARTS_DATA}${id}`)
        }else{
            handleCloseDialog();
            console.log('underfined')
        }
    };

    const handleAddForPartInStock = async(e) => {
        e.preventDefault();
        console.log(dialogValue, id)

        if(dialogValue.locationId !== undefined){
            const requestOptions = {   
                method: 'POST', 
                headers: {'Content-Type':'application/json; charset=utf-8' }, 
                body: JSON.stringify({ dialogValue }),    
            } 
            const response = await fetch(`${DOMAIN}${'/api/stockpartsdata/1'}`, requestOptions);
            if (response.status == 200){
                // const r = await response.json(response) // console.log(r)
                setAlertDetails({ text:`You have added part to Stock repository`,heading:'',variation:'success' })
                getAlert()                //setAlertActive(!alertActive)

            }else{
                console.log("ERROR response : " + await response.json(response))
            }
        }else{
            setAlertDetails({ text:'You must supply Location value', heading:'Error Adding Part:', variation:'warning' })
            getAlert()            //setAlertActive(!alertActive)
        }
        handleCloseDialogAdd();
        getPartsData(`${DOMAIN}${process.env.REACT_APP_API_GET_PARTS_DATA}${id}`)
    };
    

    const getAlert = ()=>{
        setAlertActive(true)
        setTimeout(() => {setAlertActive(false)}, 6000)
    }    


    const getPartsData = async(Url)=>{  //getCustomerData
        const response = await fetch(Url);
        if(response.status ==200){
            const responseCustomersData = await response.json();
            const part = responseCustomersData[0]; 
            DEBUG && console.log(Url, part)

            const keys= Object.keys(part)
            keys.forEach(key=>{setInputs(values=>({...values, [key]: part[key], isValidated: true,}))})
            setSelectedCustomer(part.customerName)    
            await getStockPartsData(id)                
            VALID.current = true;

        }else{
            DEBUG && console.log(`Error: status ${response.status} returned` )
        }

    };
    const getWarehousesData = async(Url)=>{  //getCustomerData
        const response = await fetch(Url);
        if(response.status ==200){
            const respData = await response.json();
            const wh = respData[0]; 
            DEBUG && console.log(Url, wh)
    
            const keys= Object.keys(wh)
            keys.forEach(key=>{setInputs(values=>({...values, [key]: wh[key]}))})
            // setSelectedCustomer(part.customerName)    
            // await getStockPartsData(id)                
            VALID.current = true;

        }else{
            DEBUG && console.log(`Error: status ${response.status} returned` )
            DEBUG && console.log(response.statusText)
        }

    };


    const handleClose=()=>{navigate(-1)}


    // adding new a part to db (not in stock though)
    const handleAdd=()=>{ 
        DEBUG && console.log(inputs)
        postNewUsertData(`${DOMAIN}${process.env.REACT_APP_API_ADD_NEW_PARTS_DATA}`, inputs)
        handleClose()
    };


    //// PARTS IN STOCK (STOCK DATA)
    const getStockPartsData= async (id)=>{    
        const response = await fetch(`${DOMAIN}${'/api/stockpartsdata/'}${id}`); //     console.log(`${DOMAIN}${'/api/stockpartsdata/'}${id}`)
        if(response.status ==200){
            const responseData = await response.json();  
            setPartInStockDetails(responseData)
        }else{
            DEBUG && console.log(`Error: status ${response.status} returned for ${'/api/stockpartsdata/'}${id}` )
        }    
    }

    const handleUpdate=()=>{ 
        DEBUG && console.log(inputs)
        updateWH(`${DOMAIN}${'/api/warehouses/'}${id}`, inputs)
        handleClose();
    } 

    const updateWH = async (Url, BodyInput)=> {    //console.log(BodyInput) /// text/plain; */*; charset=utf-8
        
        const requestOptions = {   
            method: 'PUT', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8' },   
        } 
        const response = await fetch(Url, requestOptions);

        const responseData = await response.json();  
        console.log(responseData)

    }

    const postNewUsertData = async (Url, BodyInput)=> {    //console.log(BodyInput) /// text/plain; */*; charset=utf-8
        const requestOptions = {   
            method: 'POST', 
            headers: {'Content-Type':'application/json; charset=utf-8' }, 
            body: JSON.stringify({ BodyInput }),    
        } 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  
        DEBUG && console.log(responseData)
    }


    const handleChange=(e, validated)=>{
        const names= e.target.name
        const value = e.target.value
        setInputs(values=>({...values, [names]: value}));  //value, valid: +
        setInputs(values=>({...values, ['warehouseId']: id}));  //value, valid: 
    }  
    

  return (
    
    <Stack width = '82ch' height='40ch'>     

        <Stack overflow='auto'  sx={{flex: '1 1 100%',  boxShadow:'1px 1px 3px lightgray'}}>                 {/* <Stack name='TABLE' sx={{ width: '85ch', height:'50ch'}}>      */}
            
            <Table
                caption={
                    <Stack direction='row' backgroundColor= 'lightsteelblue' border='1px solid gray' paddingY={1} gap={2} justifyContent='center'>
                        <Typography color={'InfoText'} fontSize={'clamp(0.75rem, .8vw, 1.2rem)'} fontWeight={400}>{action=='update' ?'Updating Warehouse:':'Adding Warehouse'} </Typography>
                        <Typography color={'red'} fontSize={'clamp(0.75rem, .8vw, 1.2rem)'} fontWeight={600}>{inputs.warehouseName}</Typography>
                    </Stack>
                }
                size="small"
                highlightOnHover={true}
                variation="striped"
                border='1px solid lightgray'
                backgroundColor={'antiquewhite'}                        
            >    
                <TableBody  >     

                    <TableRow >                                                
                        <TableCell border='1px solid lightgray'> <Typography style={{ fontWeight:500}}>Warehouse Name</Typography></TableCell>

                        <TableCell border='1px solid lightgray' >
                            <TextField  
                                value={inputs.warehouseName }                     
                                required
                                fullWidth
                                autoFocus  
                                autoComplete={true}    
                                multiline                                                               
                                size='small'     //        VALID.current = validateField(e.target.value, notEmpty)
                                name ="warehouseName"                                            //fontSize={'12'} //error={!VALID.current || inputs.jobPartsDescription === ''? true:false}//helperText={inputs.jobPartsDescription === ''? "populate with some value":''} 
                                onChange={(e)=>{ handleChange(e) }}
                            />
                            
                        </TableCell>          

                    </TableRow>                                                                                                                                 
                    
                    <TableRow >                                                
                        <TableCell border='1px solid lightgray'> <Typography style={{ fontWeight:500}}>Warehouse Description</Typography></TableCell>

                        <TableCell border='1px solid lightgray' >
                            <TextField  
                                value={inputs.warehouseDescription}  
                                multiline                   
                                //required
                                fullWidth                                        //autoFocus                                          //multiline                         
                                size='small'     //        VALID.current = validateField(e.target.value, notEmpty)
                                name ="warehouseDescription"    //style={{ fontSize:10}}  //error={!VALID.current || inputs.jobPartsDescription === ''? true:false}//helperText={inputs.jobPartsDescription === ''? "populate with some value":''} 
                                onChange={(e)=>{ handleChange(e) }}
                            />
                            
                        </TableCell>          

                    </TableRow>   
                </TableBody >  
            </Table>  

            <Stack name="BUTTONS Control" className='stackButtons'  sx={{flex: '1 1 100%', mt:2, mb:1}} direction="row" gap={5}  justifyContent="center" alignItems="center"  > 
                        
            
                        <Button 
                            className='Button1'
                            onClick={ action==='update'? handleUpdate : handleAdd }     
                            variant="outlined" 
                            sx={{ fontSize:'clamp(0.65rem, .6vw, 1rem)',  borderRadius:'5px', width:'20ch'}} 
                        > {action==='update'? 'Update ' : 'Add '} 
                        </Button>
                    

                        {action==='update' && 
                            <Button 
                                className='Button1' //onClick={openDialogAdd}//{active === 1? handleDelete: ReActivate} 
                                variant="outlined" 
                                sx={{color:'red',fontSize:'clamp(0.65rem, .6vw, 1rem)', borderRadius:'5px', width:'20ch'}} 
                            >  Delete
                            </Button>
                        }

                        <Button 
                            className='Button1'
                            onClick={handleClose} 
                            variant="outlined" 
                            sx={{ fontSize:'clamp(0.65rem, .6vw, 1rem)',  borderRadius:'5px', width:'20ch'}} 
                        > close
                        </Button>



            </Stack>  

        </Stack>
        

        <Dialog open={openPartInStockAdd} onClose={handleCloseDialogAdd} fullScreen={false} maxWidth={true}>
            <form onSubmit={handleAddForPartInStock}>
                <DialogTitle >
                    <Stack direction='row' gap={2}>
                        <Typography fontSize={16} fontWeight={500}> {`Add to inventory:  `}</Typography>
                        <Typography color='blue' fontSize={16} fontWeight={500}> {inputs.jobPartsDescription}</Typography>
                    </Stack>
                </DialogTitle>  {/* {' partsId: ' + dialogValue?.partsId + ' , jobPartIsSerialized : '+ dialogValue?.jobPartIsSerialized + ' , partQty: ' + dialogValue?.partQty} */}                                
                <DialogContent>
                    
                    <Stack gap={3}>

                        {dialogValue?.jobPartIsSerialized == 'Yes' && 
                            <>
                                <Stack name='SERIAL NUM'>
                                    <DialogContentText> <Typography style={{fontWeight:500}}>{'Serial Number:'} </Typography></DialogContentText>
                                    <TextField
                                        autoFocus                        //margin="dense" //variant="standard"
                                        value={dialogValue.partSerialNumber} 
                                        type="text"                                    
                                        onChange={(e) => {setDialogValue(values=>({...values, ['partSerialNumber']: e.target.value}))}}
                                    />
                                </Stack> 

                                <Stack name='ASSET NUM'>
                                    <DialogContentText> <Typography style={{ fontWeight:500}}>{'Asset Number:'} </Typography></DialogContentText>                            
                                    <TextField                                                                    
                                        value={dialogValue.partsAssetNumber} //autoFocus //margin="dense" // console.log(addPartInStockDetails)  //variant="standard"
                                        type="text"                                    
                                        onChange={(e) => { setDialogValue(values=>({...values, ['partsAssetNumber']: e.target.value})) }}
                                    />
                                </Stack>
                            </> 
                        }
                        <Stack name='LOCATIONS'>     
                                
                            <DialogContentText> 
                                <Typography style={{fontWeight:500}}>{'Locations:'} </Typography>                    
                            </DialogContentText>
                            {/* {console.log(partInStockDetails?.map(p => p.locationName))} */}

                            <SelectField
                                labelHidden={true} //icon={false} //size={'small'}
                                value={dialogValue.locationName}                            
                                iconColor="red"
                                onChange={(e) => { setDialogValue(values=>({...values, ['locationId']: e.target.value})) }  } 
                            > 
                                {                        
                                    locationList?.map( l=> {  

                                        // let a =[]
                                        // partInStockDetails?.map((p, i)=>{  a.push(p.locationName)  } )   
                                        // console.log(a[0])                                        
                                        
                                        // a.find((b)=>{
                                        //     console.log( b )
                                        //     //console.log( b  === l.locationName && l.locationName)
                                        // })

                                        return  (<option value={l.locationId} > <Typography fontSize={12}>{l.locationName}</Typography></option> )
                                    } )
                                    
                                }
                                            
                            </SelectField>
                        </Stack>

                        <Stack name='QTY NUM'>     
                            <DialogContentText> <Typography style={{ fontWeight:500}}>{'Quantity: '} </Typography></DialogContentText>
                            <SelectField
                                labelHidden={true}   //disabled={true}  //icon={false}   //size={'small'} //value={dialogValue.partsQty}                            
                                iconColor="red"
                                icon={dialogValue?.jobPartIsSerialized == 'Yes' && true}
                                disabled={dialogValue?.jobPartIsSerialized == 'Yes' && true}
                                options={['1','2','3','4','5','6','7','8','9','10','11','12','13','14', '15']}
                                onChange={(e) => setDialogValue(values=>({...values, ['partQty']: parseInt(e.target.value)}))} 
                            ></SelectField>
                        </Stack>   
                    </Stack>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogAdd}><Typography fontWeight={500}>Cancel</Typography></Button>
                    <Button type="submit" ><Typography fontWeight={500}>OK</Typography></Button>
                </DialogActions>
            </form>
        </Dialog> 

            
        <Dialog open={openPartUpdate} onClose={handleCloseDialog} maxWidth={true}>
            <form onSubmit={handleChagesForPartInStock}>
                <DialogTitle>

                    <Stack direction={'row'} gap={2}> 
                        <Typography fontSize={18} fontWeight={800}>{`Updating: `} </Typography>
                        <Typography fontSize={18} fontWeight={600} color='blue' >{inputs.jobPartsDescription}</Typography>  
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText> <Typography style={{ fontWeight:600}} sx={{ color: 'text.primary' }}>{dialogText} :</Typography></DialogContentText>
                        <Stack gap={1}>
                            {(dialogText == 'Asset Number' || dialogText == 'Serial Number') &&  
                                <TextField
                                    autoFocus                                    //margin="dense"
                                    value={dialogValue[dialogFieldName]}
                                    type="text"                                    //variant="standard"
                                    onChange={(e) => {
                                        setDialogValue(values=>({...values, [dialogFieldName]: e.target.value}));
                                        setDialogValue(values=>({...values, ['fieldName']: dialogFieldName}));
                                        setDialogValue(values=>({...values, ['fieldValue']: e.target.value}));
                                    }}
                                />
                            }

                            {dialogText == 'Location' && 
                                <SelectField
                                    labelHidden={true}                                    //icon={false}                                                    
                                    size={'small'}                                    //value={dialogValue.locationName}                            
                                    iconColor="blue"                                    ///options={locationList?.map(l=> l.locationName)}//{['1','2','3','4','5','6','7']}
                                    onChange={(e) => {
                                        setDialogValue(values=>({...values, ['locationId']: e.target.value}))
                                        setDialogValue(values=>({...values, ['fieldName']: dialogFieldName}));
                                        setDialogValue(values=>({...values, ['fieldValue']: e.target.value}));
                                    }  } 
                                > 
                                    {locationList.map(l => { 
                                        return ( <option value={l.locationId}><Typography fontWeight={600}>{l.locationName}</Typography></option>)
                                    } )}
                                </SelectField>
                            }

                            {dialogText == 'Quantity' && 
                                <SelectField
                                    labelHidden={true}                                    //icon={false}                                                    
                                    size={'small'}
                                    value={dialogValue.partsQty}                            
                                    iconColor="red"
                                    //options={['','0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15']}
                                    onChange={(e) => {
                                        setDialogValue(values=>({...values, ['partQty']: e.target.value}))
                                        setDialogValue(values=>({...values, ['fieldName']: dialogFieldName}));
                                        setDialogValue(values=>({...values, ['fieldValue']: e.target.value}));
                                    
                                    }  } 
                                >       
                                    <option value="1">Select Qty</option>
                                    <option value="0">Remove the part from the stock</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>    
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>                    
                                </SelectField>
                            }
                        </Stack>                            
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}><Typography fontWeight={600}>Cancel</Typography></Button>
                    <Button type="submit"><Typography fontWeight={600}>OK</Typography></Button>
                </DialogActions>
            </form>
        </Dialog> 

        {alertActive && (
            <Alert 
                isDismissible={true}
                hasIcon={true} // 
                heading={alertDetails.heading}
                variation={alertDetails.variation} //"info" "error" warning success //colorTheme={alertDetails.colorTheme} //onDismiss ={()=> setAlertActive(!alertActive)}
            >  {alertDetails.text }
            </Alert>
        )}  

        <JustAlert isOpen= {alertActive} openOff= {setAlertActive}  title= {'title'} dialogueTxt= {'dialogueTxt'}></JustAlert> 

    </Stack>  

  )
}
export default NewWarehouse

