import React, {useState, useRef, useEffect, useContext  } from 'react';
import { useNavigate} from "react-router-dom";
import { UserLogInContext } from '../../App'// import { Link } from "react-router-dom";//import MainFrame from '../../components/MainFrame.jsx';         // import Sidebar from "../../components/Sidebar.jsx";// import Navbar from "../../components/Navbar.jsx";
import '../../resource/images/autom8-logo.png'
import Button from '@mui/material/Button';     //import Autocomplete from '@mui/material/Autocomplete';  
import {TextField, Stack} from '@mui/material';  //import  from '@mui/material/Typography'; //import Box from '@mui/material/Box'; // import TextField from '@mui/material/TextField';
import "./profile.scss";
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import { PhoneNumberField, Table, TableRow, TableBody, TableCell, useAuthenticator } from '@aws-amplify/ui-react';
import { mailformat, mobileNumber} from '../../components/Regex.js'; 
import { ModPrompt, dateConverter } from '../../util/Functions.jsx';//import styled from 'styled-components';
import ApplicationBar from '../../components/AppBar.jsx';
import { fs } from "../../util/Functions.jsx";//import {PasswordInput} from '../../components/Styles.jsx'

// const Container = styled.div`
//     background: ${props => props.bgc};
//     //color: ${props => props.primary ? "black" : "transparent"};
//     margin-top:1ch;
//     padding: 6ch 6ch;
//     padding-top:2ch;
//     //border: 3px solid ${props=> props.bc } ;
//     border-radius: 10px;
//     display: flex;
//     flex-direction: column;
//     height:${props=>props.h};
//     width:${props=>props.w};
//     box-shadow:0 0 6px 4px ${props=>props.bc};
// `;

const {
    REACT_APP_API_HTTP,
    REACT_APP_API_LOCAL_DOMAIN,
    REACT_APP_API_LOCAL_PORT,
    REACT_APP_USER_POOL_ID, 
    REACT_APP_CLIENT_ID,     //REACT_APP_DEBUG,     //REACT_APP_AWS_REGION,
    NODE_ENV,REACT_APP_API_UPDATE_USER_DATA,
    REACT_APP_API_GET_USER_DATA_PROFILE
  } = process.env;
  
const productionHost = REACT_APP_API_HTTP + window.location.host;
const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
const DOMAIN = `${NODE_ENV==='production'? productionHost : localHost}`; 
//const debug = REACT_APP_DEBUG ==="on";

const poolData = {
    UserPoolId: REACT_APP_USER_POOL_ID, // Your user pool id here
    ClientId: REACT_APP_CLIENT_ID, // Your client id here
};

export const Profile = () => {

    const { subId, role, isSuperUser, time} = useContext(UserLogInContext);    //console.log(role, customerrole)    //let  {id}  = useParams();
    const navigate = useNavigate();     //const { tokens } = useTheme();    //const {authStatus} = useAuthenticator();         // console.log(userfullname)
    const { user } = useAuthenticator((context) => [ context.user]);  // console.log(user.signInUserSession.idToken.payload['cognito:groups'] !== undefined ? user.signInUserSession.idToken.payload['cognito:groups'][0]:'pp')        // console.log(user.attributes)
    
    ///const [userName, setUserName] = useState(user.username);
    const [userFullName, setUserFullName] = useState(user.attributes.family_name);
    const [userFirstName, setUserFirstName] = useState(user.attributes.given_name);  //(user.attributes.given_name user.attributes.family_name  // `${userFirstName} ${userFullName}`
    const [userSelectedType, setUserSelectedType] = useState('');
    const [id, setId]=useState();    //const [userEmail, setUserEmail] = useState('aiampolski@figure8services.com.au');     //const [verification, setVerification] = useState(false);
    const [userSubType, setUserSubType] = useState('');
    //const [active, setActive] = useState(false);    //const [userType, setUserType] = useState([]);
    const [inputs, setInputs] = useState({});    //const [checked, setChecked] = useState(true);
    const [phoneNum, setPhoneNum] = useState(user.attributes.phone_number === undefined ? "" : user.attributes.phone_number.slice(3,13) );  //0455230620
    const [dialCode, setDialCode]= useState(user.attributes.phone_number === undefined ? "" :user.attributes.phone_number.slice(0,3));    //const [confirm, setConfirm] = useState(false);    //const [open, setOpen] = useState(false);
    const [alertBox, setAlertBox] = useState(false);
    const [alertText, setAlertText] = useState('');    //const [alertTitle, setAlertTitle] =useState('');    //const [password, setPassword] = useState('');
    const [alertPassword, setAlertPassword] = useState('');
    
    //const statusPro = useRef(false)
    const userAccessToken = useRef(`CognitoIdentityServiceProvider.${REACT_APP_CLIENT_ID}.${user.username}.userData`)
    //const userData = useRef(sessionStorage.getItem('username'))
    const inputRef = useRef(''); //    
    const dialCodeRef = useRef('');
    const confirm = useRef(false)
    const VALID = useRef(false);    //const VALID_fullName = useRef(false);
    const VALID_email = useRef(true);
    const VALID_active = useRef(false);    //    const VALID_businesParther = useRef(false);        //const countryCode = useRef(null)    //const debug = process.env.REACT_APP_DEBUG ==='on';    //const getUserTypes=()=>{setUserType(['Administration', 'Manager', 'Senior Technician','Field Technician', 'Contractor', 'Customer', 'Client'])};

    useEffect(()=>{ 
        const a = JSON.parse(localStorage.getItem(userAccessToken.current))  
        //const b = userData.current
        getUserData_From_DB(`${DOMAIN}${REACT_APP_API_GET_USER_DATA_PROFILE}${subId}`);
        //console.log(a.UserAttributes )
        //console.log(b)
    }, []);

    useEffect(()=>{
        // const element = document.getElementById("body");        // console.log('ppppppppppppppppppppppppppppppppppppppppppppp')        // console.log(window.getComputedStyle(element).fontSize)        // console.log(window.getComputedStyle(element, null).fontFamily)
        //console.log('use effect ---------------------------- ',  alertPassword)
        alertBox && CONFIRM()       
         
    },[alertPassword]);

    const validateField = (inputText, flag)=>{
        flag ==='' ? VALID.current = true: inputText.match(flag)? VALID.current = true: VALID.current = false
        return VALID.current;
    };

    const handleClose = ()=>{navigate('/') };

    // const handleUpdate = ()=>{
    //     // const invalidFields=[];
    //     // for (const key in inputs) {            //debug && console.log(`${key}: ${inputs[key]}`) ////to remove
    //     //     inputs[key]==="INVALID" && invalidFields.push(key)
    //     // }
    //     // if (invalidFields.length > 0){        //debug && console.log(invalidFields)  ////to remove//
    //     //     //setErrorFields(invalidFields)
    //     // }else{ //console.log(`${DOMAIN}${REACT_APP_API_UPDATE_USER_DATA}${id}`, inputs)
    //     updateUser_In_DB(`${DOMAIN}${REACT_APP_API_UPDATE_USER_DATA}${id}`, inputs);
    //     //handleClose();          //window.location.reload()      //          // }
    // };

    async function getUserData_From_DB(Url) {  
        const response = await fetch(Url);
        const responseData = await response.json();
        const data = responseData[0];          //console.log(data)

        const keys= Object.keys(data)
        keys.forEach(k=>{setInputs(values=>({...values, [k]: data[k]}))})

        setId(data.userId)        //setUserName(user);        //setUserFullName(data.userFullName);
        setUserSelectedType(isSuperUser ? "SUPER USER" : data.userType);        // setHashPassWord(data.passWord==='removed'||data.passWord==='Removed' || data.passWord ==''? '':''//verifyHashedPassword(data.passWord) ); //setUserEmail(data.userEmail);
        setUserSubType(isSuperUser ? "SUPER USER" : data.userSubType);        //setPhoneNum(data.phoneNumber); //.slice( data.phoneNumber.indexOf(" ")).trim())     ///{(data.phoneNumber ?? setDialCode('+61') )|| setDialCode(data.phoneNumber.slice(0, data.phoneNumber.indexOf(" ")).trim())}
        //setActive(data.active === 1 ? true : false);        //setChecked(data.active === 1 ? false : true)        ///setSubAttrib(data.sub ===null || data.sub ==='null' || data.sub==='' ? "EMPTY": data.sub);

        !data.phoneNumber ? console.log('===== no phone number----')    :  console.log(data.phoneNumber)
        // if(data.phoneNumber == null || data.phoneNumber === 'null'|| data.phoneNumber===''){//     //setDialCode('+61') //setPhoneNum(''); //dialCodeRef.current = '+61'
        // }else{//     //console.log(data.phoneNumber.slice(1, data.phoneNumber.indexOf(")")).trim() ) //console.log(data.phoneNumber.slice(data.phoneNumber.indexOf(")")+1).trim()) //dialCodeRef.current = data.phoneNumber.slice(1, data.phoneNumber.indexOf(")")).trim()            //setDialCode(data.phoneNumber.slice(1, data.phoneNumber.indexOf(")")).trim());            //setPhoneNum(data.phoneNumber.slice(data.phoneNumber.indexOf(")")+1).trim());// }
        VALID.current = true;       //VALID_fullName.current = true;
        VALID_email.current = validateField(data.userEmail, mailformat);
        VALID_active.current = true;        //VALID_businesParther.current=true;
    };

    async function updateUser_In_DB(Url, BodyInput) {   
        console.log(BodyInput) /// text/plain; */*; charset=utf-8        let success =false;
        const requestOptions = {   
            method: 'PUT', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8'}, 
        } 
        const response = await fetch(Url, requestOptions);
        console.log(response.status)
        const responseData = await response.json();  
        console.log(responseData.changedRows)
        console.log(responseData.affectedRows)
        console.log(responseData)
        //success=responseData.changedRows===1
        
        // if(success){
        //     // const a = window.confirm('\nProfile details have been changed successfuly. \nPlease log out and log back in again to see new changes')       // a && navigate('/');
        //     setVerification(true)
        // }else{
        //     setVerification(false)
        // }        
    };

    function promtpMe(){
        console.log(alertBox)
        setAlertBox(true)
        setAlertText('Please enter your password')
    }

    ///////////////////////////////

    const CONFIRM = async()=>{

        console.log('LLLLLLLLLLLLLLLLLLLLLLLLLLL')
        const userPool = new CognitoUserPool(poolData)
        const cognitoUser = new CognitoUser({
            Username: user.username,  //testaccount
            Pool: userPool,
        });
    
        await authentUser(cognitoUser);   
                  
        confirm.current && await getUserSession(cognitoUser);
        confirm.current && await updateAttributes(cognitoUser);        //await changePswd(userName)

        // setTimeout(()=>{
        //     setAlertBox(false)
        //     //navigate(-2)
        //     window.location.reload();
        // });
            
        setAlertBox(false)
        navigate(-2)
        window.location.reload();
        
    };
    //////////////////////////////

    const authentUser = async (cognitoUser) => {    
        //let psw = prompt("Please enter password","")        
        if (alertPassword?.length > 0){
            const authenticationDetails = new AuthenticationDetails( {
                    Username : user.username, 
                    Password : alertPassword
            });     
            return new Promise((resolve, reject) => cognitoUser.authenticateUser( authenticationDetails, 
                {   
                    onSuccess: result => { 
                        resolve(result);  //                          console.log(result, '  -----   success');
                        setInputs(values=>({...values, ['active']:1}))
                        confirm.current= true                           
                        return;
                    },

                    onFailure: err => {
                        reject(err)
                        const missUserName='Missing required parameter USERNAME'
                        const userNotConfirm='User is not confirmed'
                        const incorectNamePsw ='Incorrect username or password'
                        setInputs(values=>({...values, ['active']:0}))                                
                        if (err.message.includes(missUserName)){
                            alert( "You have not supplied the user name for confirmation" )
                        }else if(err.message.includes(userNotConfirm)){
                            alert( "User is not confirmed. Validation link has been sent to the user. User has not used the supplied link to validate their account yet" )
                        }else if(err.message.includes(incorectNamePsw)){
                            alert( "You have entered incorrect password" )
                        }else{
                            alert( "Something went wrong, please try again later" )
                        }
                        confirm.current= false
                        return;            //alert(`Password you entered is different \nto the password we have for this user. This could either mean: \n\n1. You entered wrong password\n2. Password you entered is an original password \nand it assumed that user logged in to Autom8 \nand changed password successfuly`)
                    } 
                }                 
            ));
        } else{                       
            handleClose()//console.log('No Psw is provided')         
        }       
    }; 

    // const changePswd =(userName)=>{

    //     const userPool = new CognitoUserPool(poolData)
    //     const cognitouser = new CognitoUser({
    //         Username: userName,  //testaccount
    //         Pool: userPool,
    //     });

    //     const newUserPassword ='!Rachel_11?';
    //     const oldUserPassword  ='Figure8_services' ;

    //     return new Promise((resolve, reject) =>
    //         cognitouser.changePassword( oldUserPassword, newUserPassword,  (err, result)=>{
    //             if (err) {
    //                 alert( err.message || JSON.stringify(err));
    //                 reject(err.message || JSON.stringify(err));                    
    //                 return false;
    //             }
    //             resolve(result) // -- returns  CognitoUserSession                +
    //             console.log(result)
    //             return true;

    //         },
    //         )
    //     )
    // } ;  

    const getUserSession = (cognitoUser) => {

        return new Promise((resolve, reject) =>
            cognitoUser.getSession( (err, result)=>{
                if (err) {
                    alert( err.message || JSON.stringify(err));
                    reject(err.message || JSON.stringify(err));
                    confirm.current= false
                    return;
                }
                resolve(result) // -- returns  CognitoUserSession                //console.log(result)
                setInputs(values=>({...values, ['acive']: 1}))                 //statusPro.current = true;                
                confirm.current= true
            },
        
        ));    
    } 

    const updateAttributes = (cognitoUser)=>{
        console.log(cognitoUser)

        const attributeList = [];        // const attributeRole = new CognitoUserAttribute({        //     Name: 'custom:Role',        //     Value: userSelectedType,        // });

        const attributeFamilyName = new CognitoUserAttribute( {
            Name: 'family_name',   //custom:CustomerRole
            Value: userFullName,
        });

        const attributeFirstName = new CognitoUserAttribute( {
            Name: 'given_name',   
            Value: userFirstName,
        });

        const attributePhone = new CognitoUserAttribute( {
            Name: 'phone_number',   
            Value: inputs.phoneNumber,
        });
        
        //attributeList.push(attributeRole);  //console.log(attributeRole.getValue(), attributeName.getValue())
        attributeList.push(attributeFamilyName);
        attributeList.push(attributeFirstName);
        attributeList.push(attributePhone);

        return new Promise((resolve, reject) =>
        
            cognitoUser.updateAttributes( attributeList, function(err, result) {
                if (err) {
                    reject(err.message || JSON.stringify(err));
                    alert( err.message || JSON.stringify(err));
                    return;
                }
                resolve(result) // if success returnes "SUCCESS"
                updateUser_In_DB(`${DOMAIN}${REACT_APP_API_UPDATE_USER_DATA}${id}`, inputs);
            })
        )    
    } 

    const handleOnDialCodeChange = (e)=> {
         //setPhoneNum('');        // setDialCode(e.target.value )
        e.preventDefault();         //setPhoneNum('');         //dialCodeRef.current= e.target.value
        setDialCode(dialCodeRef.current.value);
        // console.log(e.target.value)
        // console.log(dialCodeRef.current.value)
        // console.log(`${dialCodeRef.current.value} ${phoneNum}` )  
        //setPhoneNum(`(${dialCodeRef.current.value}) ${phoneNum}`)
        setInputs(values=>({...values, ['phoneNumber']: `${dialCodeRef.current.value}${phoneNum}`}))   
    };

    const handleChangePhone=(e) =>{         // console.log(dialCodeRef.current.value, phoneNum)        //setPhoneNum(e.target.value.trim().replaceAll(' ','')); //setInputs(values=>({...values, ['phoneNumber']: `${dialCodeRef.current.value} ${e.target.value}`}))
        //console.log(dialCode )          //dialCodeRef.current.value="+10"
        setInputs(values=>({...values, ['phoneNumber']: `${dialCode}${e.target.value}`}))         // console.log(`(${dialCode}) ${e.target.value}`)           // console.log(dialCodeRef.current )          //(e.target.value.replaceAll(' ',''))        // console.log(inputRef.current.value)
        setPhoneNum(e.target.value)         //dialCodeRef.current.value phoneNum
        
    };
    
    // const theme = {
    //     name: 'phonenumber-theme',
    //     tokens: {
    //       components: {
    //         phonenumberfield: {
    //           //these design tokens will apply to both the select and input that are part of the phonenumber field
    //           color: { value: '{colors.blue.60}' },
    //           fontSize: { value: '{fontSizes.small}' },
    //           fontFamily: { value: '{fontFamily.arial}' },
    //           borderColor: { value: '{colors.green.60}' },
    //           _focus: {orderColor: { value: '{colors.neutral.100}' },
    //           },
    //         },
    //       },
    //     },
    // };

    return (
        < >
            <ApplicationBar />
        
            <Stack className="profileScreen" >     
                                                
                <Stack className='itemTable'>              

                    <Table
                        caption={<Stack className='caption' paddingY={2}> Profile Details</Stack>}
                        size="small"
                        highlightOnHover={true}
                        variation="striped"
                        backgroundColor={'antiquewhite'} //width='100ch'
                    >
                        <TableBody> 

                            <TableRow>
                                    
                                <TableCell className='tableCellName'>Name</TableCell>
                                <TableCell className='tableCellVal' >                                                                                          
                                    <TextField   
                                        //className='tableCellVal'
                                        sx={{ backgroundColor:'azure',} } 
                                        value={userFirstName} 
                                        autoFocus={true}
                                        required
                                        size='small'  
                                        fullWidth                                                    
                                                                            
                                        onChange={(e)=>{
                                            setUserFirstName(e.target.value)
                                            setInputs(values=>({...values, ['userFullName']:`${e.target.value} ${userFullName}`  }))
                                        }}
                                    />                                                 
                                </TableCell>                                                                                       
                            </TableRow>

                            <TableRow>                                        
                                <TableCell className='tableCellName'>Surname </TableCell>
                                <TableCell className='tableCellVal'>                                  
                                    <TextField   
                                        //className='tableCellVal'
                                        sx={{ backgroundColor:'azure',} } 
                                        value={userFullName} 
                                        autoFocus={true}
                                        required
                                        size='small'  
                                        fullWidth                                            //style={{fontWeight:600, color:'#2d187be0', width:'40ch', backgroundColor:'azure'} }                                   
                                        onChange={(e)=>{
                                            setUserFullName(e.target.value)
                                            setInputs(values=>({...values, ['userFullName']:`${userFirstName} ${e.target.value}`  }))
                                        }}
                                    />                                                   
                                </TableCell>                                                                                       
                            </TableRow>

                            <TableRow>
                                
                                <TableCell className='tableCellName'>Mobile Number</TableCell>
                                <TableCell className='tableCellVal'>
                                        <PhoneNumberField
                                            //className='tableCellVal'
                                            //sx={{ backgroundColor:'azure',} } 
                                            defaultDialCode={dialCode}
                                            dialCodeList={['+61', '+55', '+44', '+64']}
                                            ref={inputRef}
                                            dialCodeRef={dialCodeRef}                                                //borderwidth={tokens.borderWidths.large}
                                            value={phoneNum} //`${phoneNum === '' ?'' :phoneNum}`}      //placeholder="Phone number" //border={`1px solid ${tokens.colors.green[60]}`}
                                            labelHidden //dialCodeLabel="Dial code" //phonenumberfield ="1111" ///width={'42.8ch'} //backgroundColor={'azure'}
                                            type='tel'
                                            hasError={phoneNum.trim() !==''? phoneNum.trim().match(mobileNumber) !== null? false:true:true}
                                            errorMessage="Not a valid phone number! 😱"
                                            onChange={handleChangePhone}    ///{e=>setPhoneNum(e.target.value)}                     //onBlur={onBlur}             //onCountryCodeChange={handleOnCountryCodeChange}
                                            onDialCodeChange={handleOnDialCodeChange}
                                        />                                    
                                </TableCell>                                              
                            </TableRow>
                        
                            <TableRow >
                                <TableCell className='tableCellName'> Loggin ID</TableCell>
                                <TableCell className='tableCellVal'>{user.username}</TableCell>                                                
                            </TableRow>

                            <TableRow  >
                                
                                <TableCell className='tableCellName'>Cognito ID </TableCell>
                                <TableCell className='tableCellVal'> {subId}</TableCell>
                                
                            </TableRow>

                            <TableRow  >                                    
                                <TableCell className='tableCellName'>A8 User ID</TableCell>
                                <TableCell className='tableCellVal'>{inputs.userId}</TableCell>                                                
                            </TableRow>

                            <TableRow >                                    
                                <TableCell className='tableCellName'>Type </TableCell>
                                <TableCell className='tableCellVal'>{userSelectedType} </TableCell>                                                
                            </TableRow>

                            <TableRow>
                                
                                <TableCell className='tableCellName'>                                        
                                        {role.toUpperCase() ==='Customer'.toUpperCase()  || role.toUpperCase()  ==='Client'.toUpperCase()  || role.toUpperCase() ==='Manager'.toUpperCase()  ? 
                                                userSubType.indexOf(',') !== -1 ? 
                                                'Managing Customers' :
                                                'Managing Customer '
                                            : role.toUpperCase() ==='Senior Technician'.toUpperCase() ? userSubType.indexOf(',') !== -1 ?'Managing States':'Managing State'
                                            : role.toUpperCase() ==='Field Technician'.toUpperCase() ? 'Managing State'
                                            : role.toUpperCase() ==='Contractor'.toUpperCase() ? "Contrasctor's Entity": 'Role'
                                        } 
                                    
                                </TableCell>

                                <TableCell className='tableCellVal'>{userSubType.replaceAll(',', ",  ")}</TableCell>                                                
                            </TableRow>

                            <TableRow  >                                        
                                <TableCell className='tableCellName'>Log in Time </TableCell>
                                <TableCell className='tableCellVal'>{dateConverter(time)}</TableCell>                                                
                            </TableRow>

                        </TableBody > 
                        
                    </Table>                

                    <Button 
                        className='Button1 tab'    
                        sx={{mt:'2ch', fontSize:fs()}}                  
                        disabled={!phoneNum.trim() !==''? phoneNum.trim().match(mobileNumber) !== null? false:true:true}                        //sx={{ fontSize: 'clamp(0.67rem, .7vw, 1rem)', width:'clamp(300px, 50%, 800px)' }}
                        variant='outlined'
                        onClick={promtpMe}  
                                                                                 
                    >Update</Button>

                    <Button 
                        className='Button1 tab'                         //sx={{ fontSize: 'clamp(0.67rem, .7vw, 1rem)', width:'clamp(300px, 50%, 800px)' }}
                        sx={{mt:'2ch', fontSize:fs()}}       
                        variant='outlined'
                        onClick={handleClose}   
                      
                    >Close</Button>
                
                
                </Stack>   

            </Stack>   

            <ModPrompt 
                isOpen= {alertBox} 
                //setIsOpen ={setAlertBox}                
                description={alertText} 
                setResponse={setAlertPassword} 
                isPassword={true}
            />               
        </>
    
    )
};


    //  {/* <Stack className='newItemP'> </Stack>   */}
    // {/* <Container bc="lightgray" w='100ch' h='77ch' bgc=''>  */}

    // if (flag==='confirm'){//const authenticationDetails = new AuthenticationDetails({Username : userName, Password : window.prompt("Please enter password","")})///"!Hello_11?"});   //authentUser();        //     //.deleteUser((a)=>{console.log(a)})); //console.log(user.getUsername());  //ge6tUserSession();        // }else{        //     authentUser();        // }             
