import React, { useState, useContext, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import { UserLogInContext } from "../App";
import AppBar from "@mui/material/AppBar";
import { Box, Toolbar, IconButton, Stack } from "@mui/material"; // import  from '@mui/material/Toolbar';// import  from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem"; // import {AccountCircle, MenuIcon } from '@mui/icons-material';
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase"; ///import AdbIcon from '@mui/icons-material/Adb'; //// import Navbar from '../../components/Navbar.jsx';// import Sidebar from '../../components/Sidebar.jsx';//import { useLocation } from "react-router-dom"; //import { ModChangePassword } from '../../util/Functions.jsx'; // ModAlert, authentUser, changePswd, dateConverter //import { Paper } from "@mui/material";// import { Button, Heading } from '@aws-amplify/ui-react'; // import ExitToAppIcon from "@mui/icons-material/ExitToApp";  //  import Main from '../../components/Main.jsx'; // import CountrySelect from '../../util/CountryProvider.jsx'//{signOut, loggedInUser, loggedInGroup}///
import { useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";//import pic from "../resource/images/Fred.png";
import pic from "../../src/resource/images/juxaroo.jpg"; // import { DialogueResponce } from "../components/DialogueAlert.jsx";
import "../components/jobgrid.scss";// import logo from "../resource/images/logo.png"; // import { deepOrange, deepPurple } from '@mui/material/colors';
import { setHeader } from "./jobGridTableName";
import {  Log, fl, publishEvent, fs } from "../util/Functions.jsx";

// const StyledToolbar = styled.Toolbar(({ theme }) => (
  
//   {
//     variant:"dense", 
//     //background: `${props => props.bgc}`,
//     // alignItems: "flex-start",
//     // justifyContent: "flex-start",
//     // paddingTop: theme.spacing(1),
//     // paddingBottom: theme.spacing("1px"),
//     // boxShadow:theme.shadows[4],
//     // marginLeft: "1px",
//     // Override media queries injected by theme.mixins.toolbar
//     "@media all": { height: 60 },
//     // '@media all (hover: hover)':{background: 'none'    },
//     // '@media all and (min-width: 580px) and (max-width: 700px)':{height: 170},
//     // '@media ( 700px <= width <= 1000px)':{height: 30},
    
//   }
// ));

const StyledSearch = styled("div")(({ theme }) => (
  {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": { backgroundColor: alpha(theme.palette.common.white, 0.35) },
    //  marginRight: theme.spacing(2),
    marginLeft: 1,
    boxShadow:theme.shadows[2],
    //width: "auto",
    //height:'35px',
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(3),
    //   width: "auto",
    // },
  }
));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  //padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
  //border:'1px solid red',
  width: '3ch'
  //[theme.breakpoints.up('md')]: {width: '2ch'},
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0, 0, 0, 0),    // vertical padding + font size from searchIcon
    paddingLeft: `calc( ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("xs")]: { width: "3ch" },
    [theme.breakpoints.up("md")]: { width: "12ch" },
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": { marginTop: theme.spacing(0) },
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": { backgroundColor: alpha(theme.palette.common.white, 0.25) },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    //color: "inherit", //backgroundColor:'inherit',//theme.palette.info,
    border: "1px dotted white",
    fontSize: 14,
    padding: "5px 1px 5px 20px",
    //marginRight: "10px",    //transition: theme.transitions.create(["border-color", "box-shadow"]),
    transition: theme.transitions.create("width"),
      // Use the system font instead of the default Roboto font.
    [theme.breakpoints.up("xs")]: { width: "4ch" },
    [theme.breakpoints.up("md")]: { width: "15ch" },
    fontFamily: [
      //     '-apple-system',
      //     'BlinkMacSystemFont',
      //     '"Segoe UI"',
      //     'Roboto',
      //     '"Helvetica Neue"',
      "Arial",
      // "sans-serif",
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ],
    //].join(','),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const  onDialogClose= ()=>{   
  const event = new CustomEvent('onDialogClose', {detail: "Main Dialog"});
  document.dispatchEvent(event)
}

// const showList = () => {
//   publishEvent('rightmouseclick');
// }

const ApplicationBar = ({  tableName,  setMessage,  modalResponce,  setModalResponse,}) => {
  const [ancorElAdmin, setAncorElAdmin] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isJobsMenu, setIsJobsMenu] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [msg, setMsg] = useState("");
  const [modal, setModal] = useState(""); //const [fields, setFields] =useState([])
  const { tokens } = useTheme();   // const [open, setOpen] = useState(false);  // const [dialogValue, setDialogValue] = useState(false);  // const [initialFlag, setInitialFlag] = useState(true)
  const [windowSize, setWindowSize] =useState({
                                                 innerWidth:0,
                                                 innerHeight:0,
                                                 outerWidth:0,
                                                 outerHeight:0

                                                }
                                              )
  const { group, userfullname, isCorporate } = useContext(UserLogInContext); //const hostname=window.location.hostname; // const port ='3002'; // const host = hostname +":"+port  //const [DOMAIN1, setDOMAIN1]= useState(`http://${NODE_ENV==='production'?window.location.host:window.location.hostname +':3002'}`)
  const { user,  authStatus, signOut } = useAuthenticator((context) => [context.user, context.route, context.authStatus,]);  //console.log(user) //route, authStatus, tableName)    // console.log(sessionStorage.getItem("username"))  console.log(position)
  

  useEffect(() => {    

    window.addEventListener("pagehide", ()=>handleClose(isCorporate) );
    return () => {window.removeEventListener("pagehide",  ()=>handleClose(isCorporate) )  };
  },[]);

  useEffect(() => {

    window.addEventListener("contextmenu", handlRightMouseClick);
    return ()=>{window.removeEventListener("contextmenu", handlRightMouseClick)}; //auxclick resize //handlRightMouseClick
  }, []);

  useEffect(() => {

    window.addEventListener("resize", handlResize(true), true);
    return () => {window.removeEventListener("resize", handlResize(true), {signal:true})}; //auxclick resize
  }, []);

  // useEffect(() => {
  //   function  dblclick (e){console.log(document.activeElement.id, e.x, e.y)}   //console.log( this, '==========================')  // console.log(document.activeElement.id); //   console.log(document.activeElement.textContent);  //Log(document.activeElement.id)   

  //   window.addEventListener("dblclick", dblclick);
  //   return () => {window.removeEventListener("dblclick", dblclick) };
  // }, []);

  useEffect(() => {
    function keydown(e) {
      const obj = JSON.parse(localStorage.getItem('aws-amplify-cacheHello_Key')) //console.log(document.activeElement)  //console.log( e.key,) // console.log( localStorage.getItem('CognitoIdentityServiceProvider.64h81hl6ntvf7m36lr17hb2h7e.testaccount.clockDrift'))      // console.log(obj.data)
    } 

    document.addEventListener("keydown", keydown);
    return () => {document.removeEventListener("keydown", keydown) };
  }, []);

  useEffect(() => {
    function handleMove(e) { setPosition({ x: e.clientX, y: e.clientY }) }

    window.addEventListener("pointermove", handleMove);
    return () => { window.removeEventListener("pointermove", handleMove)};

  }, []);

  let fields = setHeader(tableName).map((f, i) => {
    return (
      <MenuItem
        disabled={f.disabled}
        value={`${f.tName == undefined ? "" : f.tName}${f.id}`}
        key={i}
      >
        {f.label}
      </MenuItem>
    ); //disabled={f.disableSelection}
  });  //fields= [<MenuItem value='' >{''}</MenuItem>, ...fields]

  const isAdmin = group[0] === "Admin" || user.attributes["custom:Role"] == "Administration" || user.attributes["custom:Role"] == "Administrator";
  const navigate = useNavigate();   //  const loc = useLocation();

  const sOut = ()=>{  signOut()  }

  const handleClose = (flagCorp) => {       // window.location.assign(window.location.origin) //https://www.theage.com.au/') //window.location.replace(window.location.origin) //window.location.assign('https://www.theage.com.au/')  
    
    flagCorp ? window.location.assign(`${window.location.origin}/business`) : window.location.assign(window.location.origin); 

    const t = JSON.parse(localStorage.getItem('aws-amplify-cacheHello_Key'))

    Log(fl(217, 'AppBar.jsx - handleClose'), window.history.length,  authStatus, flagCorp );  
    Log(fl(218, 'AppBar.jsx - handleClose windowlocation'), window.location.href, window.location.origin) ///performance.navigation.type);
    Log(fl(219, 'AppBar.jsx - handleClose sessionStorage'), sessionStorage);
    Log(fl(220, 'AppBar.jsx - handleClose  aws-amplify-cacheHello_Key Val'), t);
    Log(fl(221, 'AppBar.jsx - handleCloselocalStorage '), localStorage)

    sessionStorage.clear();  
    localStorage.clear();  
    sOut();            
     
  };

  const handleOpenAdminMenu = (q) => (e) => {
    setIsJobsMenu(q);
    setAncorElAdmin(e.currentTarget);
  };

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseAdminMenu = () => {
    setAncorElAdmin(null);
    setIsJobsMenu(false);
  };

  const handleCloseUserMenu = () => {setAnchorElUser(null) };

  const handler = (flag) => {
    try {
      console.log("msg : " + msg, 'flag : ' + flag);                      //const a = isNaN(msg); // :console.log('table name is not supplied'); //console.log(isNaN(msg))        //console.log(tableName, msg);
      
      tableName !== undefined 
      ? 
        tableName === "jobbook" 
        ? 
          setMessage(msg) 
        : 
          setMessage(msg) 
      : 
        setMsg("");                            //tableName !== undefined  ? setMessage(false ) : setMessage(msg);

      if (!flag) {
        console.log("HANDLER CLICK FUNCTION modalResponce == ", modalResponce,  'iiiiiii-modal ==  ' + modal);
        setMsg("");
        msg == "" && setModalResponse(undefined);
        setModal("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const doNavigate = (path) => {
    navigate(path);      //console.log(modalResponce);    console.log(modal);

    if (!modalResponce ) {        
      setModal("");      //setMessage("");  //setModalResponse(undefined);
      handleCloseAdminMenu();
    }
  };  // const handleBrowserTabClose = (e) => {

  //    Log(265, performance, sessionStorage, localStorage)
  //    handleClose(e)
     
  //   // if (performance.navigation.type === 1) {sOut()}
  //     // if (performance === performance.TYPE_RELOAD) {
  //     //   console.info( "Reload detected" );
  //     // } else {
  //     //   console.info( "Reload not detected");
  //     // }
    
  //   // else {
  //   //   console.info("window.performance is not supported");
  //   // }
  //   //e.preventDefault();           // navigate(0) window.location.assign('https://www.theage.com.au/')//window.location.origin)    
  //   //document.location.reload(true)     // e.returnValue = true          // "Are you sure you want to exit ?"
  //   // localStorage.clear();  
  //   // sessionStorage.clear();//    Log(fl(272,' AppBar '), "ON_BEFORE_UNLOAD event triggered ", e, authStatus, location, isCorporate)//  console.log("BEFORE UNLOAD event triggered ", e); //const confirm_result = confirm("Are you sure you want to quit?");      //getHome()      //localStorage.clear()      //window.open('', '_self', '').close();
  //   // if(authStatus == 'authenticated'){
      
  //   //   setOpen(true)
  //   //   sOut()
  //   //   Log(fl(276, 'AppBar - handleBrowserTabClose'), 'SIGNING OUT FROM THE APP  ------------------------------ ', loc.pathname,e)        //handleClose(isCorporate);

  //   //   isCorporate ? navigate('/business') : navigate('/'); 
  //   // }//    //signOut();    //location.refresh()      //return ( "Are you sure you want to exit ?");
  // };

  const handlResize =(flag) => (e) => {
    if (flag){      ///e.preventDefault(); // console.log(e.target.outerWidth); // console.log(e.target.innerWidth); // console.log(e.target.outerHeight); // console.log(e.target.innerHeight);// console.log(e.target);

      setWindowSize({                                                 
        innerWidth: e.target.innerWidth,
        innerHeight: e.target.innerHeight,
        outerWidth:e.target.outerWidth,
        outerHeight:e.target.outerHeight
      })      // console.log( "Screen Y = " + e.screenY,  ",  Screen X = " + e.screenX, ",  Viewpost Y = " + e.y,  ViewPort X = " + e.x );    
    }
    return (e.returnValue = "hello ");
  };

  const handlRightMouseClick = (e) => {
    e.preventDefault();      
    console.log(e.type);      // console.log( "Screen Y = " + e.screenY, ",  Screen X = " + e.screenX, ",  Viewpost Y = " + e.y, ",  ViewPort X = " + e.x );
    //showList()
    publishEvent('rightmouseclick');
        
    return (e.returnValue = "hello ");
  };


 const handleFieldSelection = (event) => {
    setModal(event.target.value);
    setModalResponse(event.target.value);

  };

  // function stringToColor(string) {
  //   let hash = 0;
  //   let i;

  //   /* eslint-disable no-bitwise */
  //   for (i = 0; i < string.length; i += 1) {
  //     hash = string.charCodeAt(i) + ((hash << 5) - hash);
  //   }

  //   let color = "#";

  //   for (i = 0; i < 3; i += 1) {
  //     const value = (hash >> (i * 8)) & 0xff;
  //     color += `00${value.toString(16)}`.slice(-2);
  //   }
  //   /* eslint-enable no-bitwise */

  //   return color;
  // }

  // function stringAvatar(name) {
  //   return {
  //     sx: {
  //       bgcolor: stringToColor(name),
  //     },
  //     children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  //   };
  // }

  const blur =(pastVal)=>(e )=>{
    console.log(pastVal)
    console.log(e.target.value)
  }

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 5;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
        width: 200,
        fontWeight: 800,
      },
    },
  };

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) { hash = string.charCodeAt(i) + ((hash << 5) - hash) }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return  `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`
    // return {
    //   sx: { bgcolor: stringToColor(name),},
    //   children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    // };
  }
  const  clean = ()=>{
    //setMessage((values) => (''));
    setMsg('');
  }


  try {
    
    return (

      <Box sx={{ flexGrow: 1 }}  >
        
        <AppBar 
          position="relative" 
          enableColorOnDark 
          sx= {{ 
            height: 55, 
            top: '1px',
            background:`${isCorporate ? `var(${tokens.colors.green['80'].name})`:`var(${tokens.colors.blue['60'].name})`}`, 
            border:'1px solid black' 
          }}
        >
          <React.Fragment>

            <Typography 
              sx={{
                  position: "absolute",   //  backgroundColor: "none", borderRadius: "50%", //  border:'1px solid red', //opacity: .6,
                  transform: `translate(${position.x}px, ${position.y}px)`,            
                  pointerEvents: "none",
                  justifyContent:'flex-end', alignItems:'flex-end',
                  borderRadius:'50%',
                  height:'35px',
                  width:'35px',
                  zIndex:20,                  //backgroundColor:'pink',
                  left: 15,
                  top: 15,  //                      //border:'1px solid pink',  
                  fontFamily:'Arial',
                  color:'blue',
                  fontSize:fs(.45, .6, .9)                     
              }}                            
            >juxaroo</Typography>        

             {/* <Typography sx={{color:'blue', fontSize:fs(), fontWeight:800}}>{windowSize.innerWidth}</Typography> */}
          
            <Toolbar variant="dense" >     
              <Stack direction="row" gap={3} alignItems="center" >      
                <Stack> <img src={pic} alt="logo" height={"50ch"} /> </Stack>
                  {/* <Stack>
                    <input
                      className="date-time-input"
                      type="datetime-local"   
                      min="2024-06-07T00:00"
                      max="2025-06-14T00:00"  
                      required                    //pattern="[a-z]+"          
                      onBlur={blur('hello')}
                    />                 

                  </Stack>

                <div style={{ fontSize:10, color:'transparent' }}>{windowSize.innerWidth} {windowSize.outerWidth} </div> */}

              </Stack>

              <Container>
                <Toolbar disableGutters variant="dense" >
                  
                  {fields.length > 0 && (            ///SEARCH BOXES  for the TABLES FIELDS //sx={{justifyContent: 'space-between',}}
                    <Stack direction={{ xs: "column", md: "row" }} >         

                      <Stack sx={{display: tableName !== undefined ? "flex" : "none", }}  >                      
                        <StyledSearch>
                          <Select
                            displayEmpty                          //sx={{ color: "inherit", }}
                            value={modal}
                            name='fieldsSelector'
                            id='fs12'
                            onChange={handleFieldSelection}
                            input={<StyledInputBase />}
                            inputProps={MenuProps}
                          >
                            {fields.length > 0 && ( <MenuItem value="">{""}</MenuItem> )}
                            {fields}
                          </Select>
                        </StyledSearch>
                      </Stack>

                      <Stack sx={{display: tableName !== undefined ? "flex" : "none",}}>
                        <StyledSearch>
                          <SearchIconWrapper><SearchIcon /></SearchIconWrapper> 

                          <StyledInputBase
                            sx={{ml:1}}
                            autoFocus
                            value={msg}
                            disabled={!modalResponce}
                            placeholder="Search . . ."
                            name='searchBox'
                            id='sb11'                          ///inputProps={{ "aria-label": "searching" }}
                            onChange={(e) => { 
                              setMsg(e.target.value);
                              setMessage(e.target.value)

                            }} //setModal(true)    ///{(e)=>message !== undefined && setMessage(e.target.value) }
                            onKeyDown={(k) => {
                              
                              k.key === "Enter" 
                                ? 
                                  handler(false) 
                                : 
                                  !!modalResponce  && handler(true) 
                            }}

                            //onClick={() => {handler(true)}}
                            onBlur={(e) =>{
                                setModalResponse(undefined);
                                ///!!modalResponce && 
                                //setMessage('');
                                setMsg('');
              
                              }
                            }                          //onDoubleClick={()=>setModal(false)}
                          />
                        </StyledSearch>
                      </Stack>

                    </Stack>
                  )}
                  
                  <Box sx={{ flexGrow: {xs:.5, md:1}, display:{ xs: "flex", md: "none" } }} >
                    <IconButton
                      size="large"
                      onClick={handleOpenAdminMenu(true)}
                      color="inherit"
                    >    <MenuIcon />                    </IconButton>
                  </Box>

                  <Box name="MAIN MENU ADMIN ITEMS and JOBS ">
                    <Menu
                      id="menu-admin"
                      anchorEl={ancorElAdmin}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(ancorElAdmin)}
                      onClose={handleCloseAdminMenu}
                      sx={{ my: 0.8, color: "white", display: "block" }}
                    >
                      {isJobsMenu && (
                        <MenuItem onClick={() => doNavigate("/jobs")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}
                            textAlign="center"
                          >
                            Jobs
                          </Typography>
                        </MenuItem>
                      )}

                      {isAdmin && (
                        <MenuItem onClick={() => doNavigate("/clients")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}
                            textAlign="center"
                          >
                            Clients
                          </Typography>
                        </MenuItem>
                      )}
                      {isAdmin && (
                        <MenuItem onClick={() => doNavigate("/customers")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}
                            textAlign="center"
                          >
                            Customers
                          </Typography>
                        </MenuItem>
                      )}
                      {isAdmin && (
                        <MenuItem onClick={() => doNavigate("/users")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}
                            textAlign="center"
                          >
                            Users
                          </Typography>
                        </MenuItem>
                      )}
                      {isAdmin && (
                        <MenuItem onClick={() => doNavigate("/sites")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}
                          >
                            Sites
                          </Typography>
                        </MenuItem>
                      )}
                      {isAdmin && (
                        <MenuItem onClick={() => doNavigate("/jobparts")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}
                          >
                            Parts
                          </Typography>
                        </MenuItem>
                      )}
                      {isAdmin && (
                        <MenuItem onClick={() => doNavigate("/warehouses")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}
    
                          >
                            Warehouses
                          </Typography>
                        </MenuItem>
                      )}
                      {isAdmin && (
                        <MenuItem onClick={() => doNavigate("/contractors")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}

                          >
                            Service Providers
                          </Typography>
                        </MenuItem>
                      )}
                      <Divider />
                      {isAdmin && (
                        <MenuItem onClick={() => navigate("/batches")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}
                          >
                            Batch Upload
                          </Typography>
                        </MenuItem>
                      )}
                      <Divider />
                      {isAdmin && (
                        <MenuItem onClick={() => navigate("/staff")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}
                            textAlign="center"
                          >
                            Staff Location
                          </Typography>
                        </MenuItem>
                      )}
                      {isAdmin && (
                        <MenuItem onClick={() => navigate("/suppliers")}>
                          <Typography
                            fontSize={'clamp(0.75rem, .8vw, 1rem)'}
                            fontWeight={600}
                          >
                            SP Location
                          </Typography>
                        </MenuItem>
                      )}
                    </Menu>
                  </Box>

                  <Box    name="MENU BUTTONS 2: jobs list"
                    sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                  >
                    <Tooltip title="Job's List">
                      <Button
                        onClick={() => navigate("/jobs")}
                        sx={{ my: 2, color: "white", display: "block" }}
                      > Jobs
                      </Button>
                    </Tooltip>

                    {isAdmin && (
                      <Tooltip title="Administration's List Menu">
                        <Button
                          onClick={handleOpenAdminMenu(false)}
                          sx={{ my: 2, color: "white", display: "block" }}
                        >
                          Administration
                        </Button>
                      </Tooltip>
                    )}
                  </Box>

                  <Box name="USER MENUS AND AVATAR" sx={{ display: "flex" }}>

                    <Tooltip title="Open settings menu">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 2 }}>
                        <Avatar sx={{ bgcolor: stringToColor(`${user.attributes?.given_name} ${user.attributes?.family_name}`) }}  >
                          { stringAvatar(userfullname )}               
                          {/* //`${user.attributes?.given_name} ${user.attributes?.family_name}`) } */}
                        </Avatar> {/*  {...stringAvatar(user.attributes.name ? user.attributes.name : `${user.attributes.given_name} ${user.attributes.family_name}`) }/> */}
                        {/* <AccountCircle />   alt={user} src="../src/resource/images/Fred.png" */}
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Navigate to Home Screen">
                      <Link
                        sx={{
                          alignItems:'center',
                          justifyContent:'flex-end',
                          display: { xs:  "flex" },                        //flexGrow: 1,
                          fontSize: 13,
                          fontFamily: "Arial",
                          color: "inherit",                //"text.secondary",//
                          cursor:'pointer'                          //textDecoration: "none",
                        }} //component="button"               //href="/"                        //onClick={() => window.location.replace(window.location.origin)}// navigate("/")} //
                        onClick={() => navigate("/")}
                        underline="hover"
                      >
                      
                        {/* {user.attributes.name ? user.attributes.name : `${user.attributes.given_name} ${user.attributes.family_name}` } */}
                        {userfullname}
                        {/* {`${user.attributes?.given_name === undefined ? '' : user.attributes?.given_name } ${user.attributes.family_name  === undefined ? '' : user.attributes.family_name }` } */}
                      </Link>
                    </Tooltip>

                    <Menu
                      sx={{ mt: "50px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {isCorporate && <MenuItem onClick={() => navigate("/dashboard")}><Typography fontSize={'clamp(0.75rem, .8vw, 1rem)'} fontWeight={600} >Dashboard </Typography> </MenuItem>}
                      <MenuItem onClick={() => navigate("/profile")}><Typography fontSize={'clamp(0.75rem, .8vw, 1rem)'} fontWeight={600} >Profile</Typography> </MenuItem>
                      <MenuItem onClick={() => navigate("/about")}><Typography fontSize={'clamp(0.75rem, .8vw, 1rem)'} fontWeight={600} >About</Typography> </MenuItem>
                      <MenuItem onClick={()=>handleClose(isCorporate)}><Typography fontSize={'clamp(0.75rem, .8vw, 1rem)'} fontWeight={600} >Logout</Typography> </MenuItem>
                    </Menu>
                    
                  </Box>

                </Toolbar >

              </Container>

            </Toolbar>
          </React.Fragment>
        
        </AppBar>



      </Box>
    );
  } catch (err) {
    console.log(err);
    Log(fl(755, 'AppBar'), err)
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="primary" enableColorOnDark>
          <div
            style={{
              position: "absolute",
              backgroundColor: "pink",
              borderRadius: "50%",
              opacity: 0.6,
              transform: `translate(${position.x}px, ${position.y}px)`,
              pointerEvents: "none",
              left: -15,
              top: -15,
              width: 30,
              height: 30,
              zIndex: 10,
            }}
          >
            {err}
          </div>
        </AppBar>

      </Box>
    );
  }
};
export default ApplicationBar;

      //   {/* <DialogueResponce
      //   isOpen={open}
      //   setIsOpen={setOpen}
      //   buttonFlag={true}
      //   // setIinitialFlag={setInitialFlag}
      //   // setValue={setDialogValue}
      //   title={'Test'}
      //   dialogueTxt={'Hello'}        
      // /> */}

  // {/* 
  //                     <BootstrapInput
  //                       autoFocus
  //                       value={msg}
  //                       placeholder="Search . . ."
  //                       inputProps={{ "aria-label": "searching" }}
  //                       onChange={(e) => { setMsg(e.target.value)}} //setModal(true)    ///{(e)=>message !== undefined && setMessage(e.target.value) }
  //                       onKeyDown={(k) => {k.key === "Enter" && handler(false)}}
  //                       onClick={() => {handler(true)}}                
                      
  //                     /> */}

        ///   ego sum optimus

      //   <Image
      //   alt="logo"
      //   //marginTop={1}
      //   padding={tokens.space.xs}
      //   height={'80px'}
      //   objectPosition="50% 50%"
      //   src={pic} //"https://docs.amplify.aws/assets/logo-dark.svg"
      //   overflow='hidden'
      //   onClick={() => window.location.reload()}
      // />

          // {/* <div
          //   style={{
          //     width:'10ch',
          //     height:'5ch',
          //     position: "absolute",
          //     backgroundColor: "pink",
          //     borderRadius: "50%",
          //     opacity: 0.6,
          //     transform: `translate(${position.x}px, ${position.y}px)`,
          //     pointerEvents: "none",
          //     left: -15,
          //     top: -15,
          //     width: 30,
          //     height: 30,
          //     zIndex: 10,
          //   }}
          // /> */}

// useEffect(()=>{

//     console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAA useEffect: ', modal,  ' ---------  ')
//     console.log(modalResponce)
//     //modal =='' && setModalResponse('');
//     // console.log(tableName)
//     // console.log('-------------------' ,   children,  ) //modalResponce
//     // console.log(fields)
//     // console.log(window.navigation.activation.navigationType)
//     // console.log('AAAAAAAAAAA vvvvvvvvvvvvvvvv ', user, location.pathname)
//     // if(window.navigation.activation.navigationType =='reload')navigate('/')
// },[modal, msg, modalResponce])

// {/* <Typography
//         variant="h6"
//         noWrap
//         component="a"
//         href={'/'}
//         sx={{ mr: 2,
//             display: { xs: 'none', md: 'flex' },            //fontFamily: 'monospace',
//             fontWeight: 600,
//             letterSpacing: '.2rem',
//             color: 'inherit',
//             textDecoration: 'none'
//         }}
//     >Autom 8</Typography>

//     <Typography
//         variant="h5"
//         noWrap
//         component="a"
//         href={'/'}
//         sx={{
//             mr: 0,
//             display: { xs: 'flex', md: 'none' },
//             flexGrow: 1,
//             fontWeight: 600,
//             // letterSpacing: '.3rem',
//             color: 'inherit',
//             textDecoration: 'none',
//         }}
//     > A8 </Typography> */}

// {/* <Button
//                             onClick={()=>navigate('/jobs')}
//                             sx={{ color: 'white', display: 'block' }}
//                         >Jobs</Button>
//                         {isAdmin &&
//                             <IconButton
//                             size="large"
//                             onClick={handleOpenAdminMenu}
//                             color="inherit"
//                             >    <MenuIcon />
//                             </IconButton>
//                         } */}

// {/* <Typography
//             // variant="h7"
//             onClick={handleOpenUserMenu}
//             noWrap

//             sx={{
//                 mr: 1,
//                 mt:3,
//                 display: { xs: 'none', md: 'flex' },
//                 flexGrow: 1,
//                 fontWeight: 400,
//                 fontFamily:'Arial',
//                 color: 'inherit',
//                 textDecoration: 'none',
//             }}
//         >  */}
