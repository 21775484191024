import React, { useState } from "react";
import App from "./App";
import { Stack } from "@mui/material";
import "../src/pages/home/home.scss";

const LandingPage = () => {
  const [isCorporate, setIsCorporate] = useState(false);
  const [show, setShow] = useState(false);

  const handleClickCorp = () => {
    //console.log('CORP CLICKED')    
    setIsCorporate(true);
    setShow(true);
  };

  const handleClickRet = (flag ) => {
    if(flag){
       window.location.assign(window.location.origin)
      //  window.location.reload(true)
    }
    //console.log('RET CLICKED')
    setIsCorporate(false);
    setShow(true);
  };

  return (  
  
    <div className="1main"> 
      {!show ?    
        <div > 
          {window.location.pathname ==='/' ?  handleClickRet() :  window.location.pathname ==='/business' ? handleClickCorp() : handleClickRet(true) }                                         
        </div>      
      :      
        <App flag={isCorporate} />
      }
      </div>
  
  );
};

export default LandingPage;

              //// {/* className="juxaroo" sx={{ justifyContent: "center", alignItems: "center", }}  border='10px solid red'> */}
              // {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{xs:5, sm:25}}  border='10px solid red'> */}  {/*  handleClickRet()     <button className="btn1" onClick={handleClickRet} >Retail</button>          <button className="btn1" onClick={handleClickCorp} >Corporate</button> */}    
