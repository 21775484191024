import React, { useState, createContext, useEffect, useRef } from "react"; //import './app.scss';//import '../../Client/src/styles/main.scss';
import AppRoutes from "./components/AppRoutes.jsx";
import { Amplify, Hub, Cache   } from "aws-amplify"; //Auth 
import {
  Authenticator,
  useTheme,
  Heading,
  View,
  Image,
  Button,
  useAuthenticator,
  CheckboxField,
  SelectField,
  ThemeProvider,
  Text,
} from "@aws-amplify/ui-react"; // withAuthenticator,   // import { useLocation } from 'react-router-dom';
import "../src/pages/home/home.scss";
import "@aws-amplify/ui-react/styles.css";
import pic from "../src/resource/images/juxaroo.jpg"; //import { useNavigate, useLocation } from "react-router-dom";
import config from "./aws-exports";
import { useJsApiLoader } from "@react-google-maps/api"; // import {useUnload} from '../../Client/src/util/Functions.jsx';//import { } from '@mui/material';
import { DialogContentText,  Typography } from "@mui/material";
import CryptoJS from "crypto-js"; 
import { DialogueResponce } from "./components/DialogueAlert.jsx";
import { LoginSearchAddress, Log, fl } from "../../Client/src/util/Functions.jsx"; //import { LocalFireDepartment } from "@mui/icons-material";

Amplify.configure(config);

const DEBUG = true; //process.env.REACT_APP_DEBUG;
const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
const localHost =  process.env.REACT_APP_API_HTTP +  process.env.REACT_APP_API_LOCAL_DOMAIN +  process.env.REACT_APP_API_LOCAL_PORT;
const DOMAIN = `${process.env.NODE_ENV === "production" ? productionHost : localHost}`;


//EXPORT logged in user details for other modules
export const UserLogInContext = createContext("Default value"); //{ signOut, user }

const toHashPassword = (pwd) => {
  const ciphertext = CryptoJS.AES.encrypt( pwd, process.env.REACT_APP_SECRET_KEY).toString();
  console.log(ciphertext);
  return ciphertext;
};

const verifyHashedPassword = (pwd) => {
  try {
    const bytes = CryptoJS.AES.decrypt(pwd.trim(), process.env.REACT_APP_SECRET_KEY);
    //DEBUG && console.log(bytes.toString(CryptoJS.enc.Utf8))
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    //DEBUG && console.log('OLD PASSWORD ERROR :', err);
    return "Old Password cannot be retreaved";
  }
};

function App(props) {
  const [userId, setUserId] = useState(1); 
  const [userSub, setUserSub] =useState(null);   //const [sub, setSub]= useState('');  //const [alert, setAlert]= useState(true);
  const [libraries, setLibraries] = useState([ "localContext", "places", "geometry", "marker"]);  //  const [inputs, setInputs] = useState({});
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState(false);
  const [initialFlag, setInitialFlag] = useState(true)  ///IMPORTANT  ---->>>>  !!!!!!!!!!!!!!!!!  CHANGE FOR PROD to true

  //const [isOpen, setIsOpen] = useState(false);
  // const [retValue, setRetValue] = useState('test');
  // const [title, setTitle]= useState('');

  const [customerAddress, setCustomerAddress] = useState("");
  // const [clientSuburb, setClientSuburb] = useState("");
  // const [customerState, setCustomerState] = useState("");
  // const [clientPostCode, setClientPostCode] = useState("");


  const [custType, setCustType] =useState('');  ///const [phone, setPhone] =useState('');
  const [windowSize, setWindowSize] = useState(getWindowSize());   //const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

  const psw = useRef();
  const username = useRef();
  const firstName = useRef();
  const familyName = useRef();
  const email = useRef('');
  const phone =useRef('');  //const custType = useRef('');
  const userType = useRef('');
  const userSubType = useRef('');
  const verifiedAddress = useRef('');
  //const windowWidth = useRef('')
  // const lat = useRef(0);
  // const lng = useRef(0);
  // const addressCoordinates= useRef({lat:0, lng:0})
  //const logger = new ConsoleLogger('foo');

  useEffect(()=>{

    if(userSub !== null){
      addNewUsertDataToDB({ 
        'name':username.current,
        'userFullName':firstName.current + " " + familyName.current,
        'passWord': toHashPassword(psw.current),
        'userEmail':  email.current,
        'sub': userSub,
        'userType':userType.current, //'Retail Customer',
        'userSubType':userSubType.current, //'Retail Customer'
      })

      setUserSub(null)
    }
  },[userSub])

  useEffect(() => {

    function handleWindowResize() { setWindowSize(getWindowSize())}

    window.addEventListener("resize", handleWindowResize);
    return () => { window.removeEventListener("resize", handleWindowResize)    };
  }, []);

      

  // useEffect(() => { windowWidth.current =  (windowSize.innerWidth / (windowSize.innerWidth / 715) ).toString() }, [windowSize]);

  useEffect(() => {
    
    Hub.listen("auth", (data) => {       
      const { payload } = data; //      DEBUG && console.log(payload); // DEBUG && console.log(data)      //  console.log(`A new auth event has happened at: ${new Date()} `, payload.data.attributes['custom:Role']??"No Role has been setup") //Date.now()

      if (payload.event === "signIn") {                       //role = payload.data.attributes['custom:Role'] ?? "No Role has been setup"    //window.location.assign(`${process.env.NODE_ENV==='production' ? 'https://autom8systems.com.au/': 'http://localhost:3000/'} `)        //  TURN BACK ON getSub(`${url_main}${payload.data.attributes.sub}`);        //setUserId(payload.data.attributes.sub); 
        
        getSub(`${DOMAIN}${process.env.REACT_APP_API_USER_GET_SUB}${payload.data.attributes.sub}`); //setLoging(false) //window.location.assign(`${process.env.NODE_ENV==='production' ? 'https://autom8systems.com.au/': 'http://localhost:3000/'} `)
        //console.log(payload.data, " -----------" + payload.message);
        sessionStorage.setItem("username", payload.data.username);
        sessionStorage.setItem("test", 555);
        Cache.setItem('Hello_Key', 'testingsss')
        //logger.info('sign in bar');
      

      } else if (payload.event === "signOut") {                       //setLoging(false)        //document.cookie = "username=John Jack; expires=Thu, 1 Dec 1970 12:00:00 UTC";        // window.localStorage.clear()           // window.location.reload()
        
        DEBUG && console.log(payload.data.username, " ---------- " + payload.message,  '+++  ' +window.location.origin   );        //window.location.assign(`${process.env.NODE_ENV==='production' ? 'https://autom8systems.com.au/': 'http://localhost:3000/'} `)        ///setSub('')      ///props.flag ?  window.location.assign(`${window.location.origin}/business`) : window.location.assign(window.location.origin); 
        Log(fl(137, 'App.jsx'), payload.data.username,  payload.message, window.location )
        
      

      } else if (payload.event == "signUp") {      
        setUserSub(payload.data.userSub)        //window.location.reload();  // console.log(payload.event);        // console.log(payload.data);        // console.log(payload.data.userSub);    
      
      } else {                                    // console.log(payload.event);        // console.log(data);
        
        if(payload.event == 'signIn_failure') refresh(120) //window.location.href = window.location.href.split('#')[0];          //window.location.href = window.location.pathname;          ///return false;        
      }
    });
  }, []);

  const addNewUsertDataToDB = async (BodyInput) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8"},
      body: JSON.stringify({BodyInput}),
    };
    const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_ADD_NEW_USER}`, requestOptions);
    DEBUG && console.log(response.status);
    if (response.status ==200){



    }
    
  };

  const addNewCustomerDataToDB = async (BodyInput) => {  //DOMAIN + process.env.REACT_APP_API_ADD_NEW_CUSTOMER, inputs //console.log(BodyInput) /// text/plain; */*; charset=utf-8
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({ BodyInput }),
    };
    const response = await fetch(DOMAIN + process.env.REACT_APP_API_ADD_NEW_CUSTOMER, requestOptions);
    const responseData = await response.json();
  
  };

  const openDialog = () => {setTimeout(() => { setOpen(initialFlag ? true: false)}, 1)   };

  //const openAddressDialog = () => { setTimeout(() => { setIsOpen(true)})   };

  const refresh = (refreshRateInSec)=> {  
    const rate = !refreshRateInSec ? 1 :refreshRateInSec * 1000
    console.log(rate);
    setTimeout(function () {    
      console.log('uuuuuuuuuuuuuuuuuuuu');          //window.location.reload(true)
      
      window.location.assign(window.location.origin)          
    }, rate);
  };

  //   useEffect(() => {
  //     document.addEventListener('click', ()=>console.log('eeeeeeeeeee'));
  //     return () => { document.removeEventListener('click', ()=>console.log('e333333'))};
  //  },[]);

  const formFields = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: "Username",
      
      },
      password: {
        labelHidden: true,
        placeholder: "Password", 
      },
    },

    signUp: {

      username: { 
        placeholder: 'Username',
        order: 1,
        isRequired:true,          //dialCodeList: ['+1', '+123', '+227', '+229']
      },
      email: {
        autocomplete:'email',
        placeholder: 'Email',
        isRequired:true,
        order: 2,
      },
      password: {
        placeholder: 'Password',
        isRequired:true,
        order: 3,
      },
      confirm_password: {
        isRequired:true,
        placeholder: 'Confirm Password',
        order: 4,
      },
      given_name: {
        isRequired:true,
        placeholder: 'Given Name',
        ///Value:firstName.current,
        order: 5,
      },
      family_name: {
        placeholder: 'Family Name',
        isRequired:true,
        order: 6,
      },   

      // address: {
      // //  order: 8,
      //   //defaultValue: '',
      //   placeholder: 'Address',
      //   display:'none',
      //   //isRequired:true,
      // },

      phone_number: {
        placeholder: 'Mobile Phone Number',        //dialCodeList: ['+55', '+61', '+64'],
        dialCode: '+61',
        isRequired:true,
        order: 7,
      },
      
      // hello: {
      //   //order: 9,
      //   //Value: custType, 
      //   display:'none',
      // }, 
      // 'custom:CustomerRole': {        
      //   display:'none'
      // },
      'custom:Role': {
        defaultValue:'Retail Customer',
        display:'none'
      },
      'custom:account': {
        defaultValue:'F8',
        display:'none'
      },
      'custom:SubRole': {
        defaultValue:'Retail Customer',  
        display:'none'
      },
    }
  };

  function getWindowSize() {
    const { innerWidth, innerHeight, outerWidth } = window; // let zoom = (outerWidth / innerWidth).toFixed(2) // DEBUG && console.log('zoom: ', parseFloat(zoom)) // DEBUG && console.log(outerWidth, innerWidth)
    //Log( fl(278,' app.js'),  innerWidth, innerHeight, outerWidth  )
    return { innerWidth, innerHeight };
  }    

  const AuthenticatorComponent = {

    Header() {
      const { tokens } = useTheme();
      const {route, hasValidationErrors} = useAuthenticator();      // console.log(tokens)
      //console.log(useAuthenticator());
      
      return (
        <View
          textAlign="center"          //padding={tokens.space.xs}
          border={ `1px solid ${!props.flag ?'blue':'green'}`} 
          borderRadius="2px"          //marginTop="15ch"
          backgroundColor={!props.flag ? `var(${tokens.colors.blue['20'].name})`:  `var(${tokens.colors.green['20'].name})`}
          height='6ch'                
        >
          <Image
            alt="logo"             //marginTop={1}             //padding={tokens.space.xs}
            height={'60px'}
            objectPosition="50% 50%"
            src={pic} //"https://docs.amplify.aws/assets/logo-dark.svg"
            overflow='hidden'
            onClick={()=>refresh(5)}   //window.location.reload(true)         //return false;          
          />
        </View>
    ) },
  
    Footer() {
      const { tokens } = useTheme();

      return (
        <View
          textAlign="center"           //border="1px solid gray"
          borderRadius="2px"
          backgroundColor={!props.flag ? `var(${tokens.colors.blue['20'].name})`:  `var(${tokens.colors.green['20'].name})`}// "var(--amplify-colors-white)"
          padding={tokens.space.xs}
          border="1px solid transparent"          //backgroundColor='lightblue'
        >
          <Typography fontSize={12} fontWeight={600} fontFamily={'Arial'} color={`var(${tokens.colors.blue['80'].name})`}> &copy; All Rights Reserved </Typography>
          <Button
              fontWeight="normal"
              onClick={() => {                // window.location.reload(true) //openDialog() / /return false;
                setInitialFlag(true)                
                openDialog();              
              }}
              marginTop={'3ch'}
              fontFamily={'Arial'}
              fontSize={'11px'}
              variation="link"
              size="small"
              textDecoration='underline'
          > Terms and Conditions          </Button>

        </View>
    ) },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (

          <Heading
            color="darkred"
            textAlign="center"
            padding={`${tokens.space.xl} 0 ${tokens.space.small} ${tokens.space.small}`}
            level={6} 
          >
            <Typography fontWeight={800} fontSize={20} fontFamily={'Arial'} >{`Sign into Your ${!props.flag ?'Personal':'Business'} Account`}</Typography>    
          </Heading>
        );
      },

      Footer() {
        const { toResetPassword } = useAuthenticator();       //  const { toSignUp } = useAuthenticator();         //const { tokens } = useTheme()      //  console.log(useAuthenticator());
        const { tokens } = useTheme();

        return (
          <View textAlign="center" padding={`${tokens.space.small} 0 0 ${tokens.space.small}`}>
            <Button
              onClick={toResetPassword}
              size="small"              //  isFullWidth              //width={160} //paddingLeft={-1}
              marginBottom={20}
              variation="link" //  variation="primary"
              fontFamily={'Arial'}
              color= {`var(${tokens.colors.blue['100'].name})`} 
            > Reset Password </Button>
          </View>
        );
      },
    },

    ResetPassword: {
      Header() {    const { tokens } = useTheme()

        return (
          <Heading
            color="darkred"
            textAlign="center"
            padding={`${tokens.space.xl} 0 0 0`}
            level={6}            //  marginBottom={20} //  padding={`${tokens.space.l} 0 0 ${tokens.space.l}`}
          >   
              <Typography fontWeight={800} fontSize={20}  fontFamily={'Arial'}  >Reset Password</Typography>
          </Heading>                                  //  {/* //<Heading>Reset your password</Heading> //パスワード再交付 */}
        );
      },
    },

    SignUp: {
      FormFields() {
        const { validationErrors, updateForm } = useAuthenticator();        
        console.log(useAuthenticator()) 

        return (
          <>
            <SelectField 
              isRequired  
              value={custType}
              name= 'custom:CustomerRole'                 //
              placeholder="Select Your User Type"            
              onChange ={(e)=>{setCustType(e.target.value)  }}//</>                       // openAddressDialog(true)                // setTitle(e.target.value=='' ? "" : e.target.value=='Retail Customer' ? 'Please Provide your Home Address': 'Please Provide your Business Address')              
              
            >   {/* <option value=''>Select Your User Type</option> */}
              <option value="Retail Customer">Retail Customer</option>
              <option value="Service Provider">Service Provider (Contractors) </option>                
            </SelectField>

            {(customerAddress  ) ?
                                                      
              <>
                <Text    
                  as="strong"
                  variation="warning"
                  lineHeight="1.5em"
                  fontWeight={600}
                  fontSize="1em"
                  fontStyle="normal"
                  textDecoration="none"
                  width="20vw"
                >{customerAddress}
                </Text>
                
                <CheckboxField
                  label="Is this a correct Address"
                  name="address"
                  value={customerAddress}
                  onChange={(e) => !e.target.checked  && setCustomerAddress('')}
                />

              </>

            :
              <LoginSearchAddress              
                setDestination={setCustomerAddress}
                size={414}
              />

            }

            <Authenticator.SignUp.FormFields/>                        

            <CheckboxField 
              errorMessage={validationErrors.acknowledgement}
              hasError={!!validationErrors.acknowledgement}
              name="acknowledgement"
              value="yes"
              label="I agree with the Terms and Conditions"
            />

          </>
      ) },  

      Header() {
        const { tokens } = useTheme();      //  console.log(initialFlag);
        openDialog()
      
        return (
          <Heading 
            color="darkred"
            textAlign="center"
            padding={`${tokens.space.xl} 0 0 0`}
            level={6} 
          > <Typography fontWeight={800} fontSize={20}  fontFamily={'Arial'} > Create new Account 🚀 </Typography>
          </Heading>
        );
      },
    },
  };

  const services = {
    async validateCustomSignUp(formData) {
      console.log(formData);

      const phoneLengthValidation = !formData.phone_number ?  true: formData.phone_number.length != 10 ? true : false       //0 : formData.phone_number.length 
      const phoneSttyleValidation = !!formData.phone_number ? isNaN(formData.phone_number) :false
      const acknowledgeValidation = !formData.acknowledgement;
      // const addressValidation = !formData.address;

      const nameValidation =  !formData.family_name ? true : formData.family_name.length < 3 ? true : false                                                    
      const phoneValidation = phoneLengthValidation  || phoneSttyleValidation 

      const acknowledgeError = acknowledgeValidation ? <Typography style={{ fontSize: `16px`, fontFamily:'Arial',  fontWeight: 400, }} > You must agree to the Terms and Conditions !</Typography> :'';
      const nameError = nameValidation ? <Typography style={{ fontSize: `16px`, fontFamily:'Arial',  fontWeight: 400, }} >You must put your full name!!!!!</Typography> : '';
      const phoneError = phoneValidation ?<Typography style={{ fontSize: `16px`, fontFamily:'Arial',  fontWeight: 400, }} >Your phone number must be 10 digits long</Typography>:'';
      // const addressError = addressValidation ? <Typography style={{ fontSize: `16px`, fontFamily:'Arial',  fontWeight: 400, }}>Should confirm address </Typography> :''
        
        // console.log(nameValidation);
        // console.log(!formData.family_name);          //console.log(!formData.acknowledgement );

      psw.current = formData.password
      username.current = formData.username;
      firstName.current = formData.given_name;
      familyName.current = formData.family_name;
      email.current = formData.email;
      phone.current = formData.phone_number;
      //setCustType(formData['custom:CustomerRole'])
      userType.current = formData['custom:Role'];
      userSubType.current = formData['custom:SubRole'];
      verifiedAddress.current = formData.address;

      if (acknowledgeValidation || phoneValidation || nameValidation) {
        return {

          family_name: nameError,
          acknowledgement: acknowledgeError,
          phone_number:phoneError,


        };
      }
    },
  };

  const signUpAttributes = [
    "email",
    "family_name",
    "given_name",
    "address",    // 'birthdate',// 'email',// 'family_name',// 'gender',// 'given_name',// 'locale',// 'middle_name',
    //'name',    // 'nickname',
    'phone_number',//'picture',// 'preferred_username',    // 'profile',    // 'updated_at',// 'website',// 'zoneinfo',
  ];                                    // const url_main = `${DOMAIN}${process.env.REACT_APP_API_USER_GET_SUB}`;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    libraries: libraries,
    region: "Australia",
  });

  const getSub = async (url) => {
    const response = await fetch(url);
    console.log(response);
    const responseData = await response.json();
    console.log(responseData[0]);
    setUserId(responseData[0]?.userId);     //responseData[0].active === 0 && setLoging (true)
    //return responseData[0]?.userId
  };

  const { tokens } = useTheme();  
  // console.log(tokens);
  // console.log(tokens.components);  //console.log(tokens.components);

  const theme = {
    name: 'custom-theme',
    tokens: {
      components: {
        
        authenticator: {          
          router: {
            boxShadow: `0 0 1px ${tokens.colors.red['60']}`,
            borderWidth: '10px',
          },

          form: {
            padding: `${tokens.space.xl} ${tokens.space.xl} ${tokens.space.xl}`,
          },
        },

        button: {
          primary: {
            backgroundColor: tokens.colors.red['60'],   },
          _hover:{
            backgroundColor: tokens.colors.green['60'],
          },
          // link: {
          //   color: tokens.colors.purple['80'],
          // },
      
        },

        fieldcontrol: {
          _focus: { boxShadow: `0 0 0 1px ${tokens.colors.red['60']}`, },
          _disabled: {  backgroundColor: tokens.colors.red['60']},
        },

        tabs: {
          backgroundColor: { value: '{colors.background.secondary}' },
      
          item: {
            backgroundColor: { value: '{colors.background.secondary}' },
            color: tokens.colors.red['40'],
            fontSize: tokens.fontSizes.medium,
            fontWeight:tokens.fontWeights.medium,
            
            transitionDuration:tokens.time.long,
            
          
                
            _active: {
              borderColor: tokens.colors.red['100'],
              color: tokens.colors.purple['80'],//color="purple.80"
              backgroundColor:tokens.colors.blue['40'],                                    
            },
            _hover: { 
              color: tokens.colors.red['80']
            },
            _focus:{
              color: tokens.colors.red['100']
            }
          }, 
          
          gap:10,
        },        
      
      },

    },
  };   //templateColumns={{ base: "1fr 0", medium: "1fr 1fr" }}

  return (
    < >

      <ThemeProvider theme={theme} >

        <Authenticator                          
          className='main'
          loginMechanisms={["username", "email"]} //{['email']}/
          formFields={formFields}
          components={AuthenticatorComponent}
          variation="modal"           //initialState={props.flag ? 'signIn' :'signUp'}
          hideSignUp={props.flag}
          services={services}
          signUpAttributes={signUpAttributes}

        >
          {({ signOut, user }) => (                

            <UserLogInContext.Provider      
              value={{
                user: user.username, //signInUserSession.idToken.payload['cognito:username'], //loggedInUser,  //  userName: user.attributes.given_name + " " + user.attributes.family_name,
                group: user.signInUserSession.idToken.payload["cognito:groups"] ?? ["NOGROUP",], //loggedInGroup,
                subId: user.attributes.sub,
                role: user.attributes["custom:Role"] ?? "No Role Detected", //user.signInUserSession.idToken.payload['custom:Role'] ?? "No Role Detected",
                subrole: user.attributes["custom:SubRole"] ?? "No Sub Role Detected", //user.signInUserSession.idToken.payload['custom:SubRole'] ?? "No SubRole Detected",
                customerrole: user.attributes["custom:CustomerRole"] ?? "No CustomerRole Detected", //user.signInUserSession.idToken.payload['custom:CustomerRole'] ?? 'No User Name',
                account: user.attributes["custom:account"] ?? "No Account Detected",
                userfullname: `${user.attributes.given_name} ${user.attributes.family_name}` ,
                userId: userId,   // getSub(`${DOMAIN}${process.env.REACT_APP_API_USER_GET_SUB}${user.attributes.sub}`),//userId, //fullName:user.attributes.sub,//
                time: user.signInUserSession.idToken.payload.auth_time,
                isLoaded: isLoaded,
                isSuperUser:  user.signInUserSession.idToken.payload["cognito:groups"] == "Admin",              //setLoging:setLoging,
                isCorporate: props.flag,
                logout: signOut,
              }}
            >
              {/* {console.log(user)} */}
              <AppRoutes />

            </UserLogInContext.Provider>
            
          )}
        </Authenticator>

        <DialogueResponce
          isOpen={open}
          setIsOpen={setOpen}
          buttonFlag={true}
          setIinitialFlag={setInitialFlag}
          setValue={setDialogValue}
          title={'Terms And Conditions'}
          dialogueTxt={
            <DialogContentText color="#2d187be0" fontWeight={400} backgroundColor={{ xs: 'orange', md: 'yellow' }}>  
            Lorem ipsum dolor sit amet,  consectetur adipiscing elit,<br/> 
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br/>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi<br/>
            ut aliquip ex ea commodo consequat. <br/>
            Duis aute irure dolor in reprehenderit in dolore eu fugiat nulla pariatur.<br/> 
            voluptate velit esse cillum Excepteur sint occaecat cupidatat non proident, <br/>
            sunt in culpa qui officia deserunt mollit anim id est laborum. <br/>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem<br/> 
            accusantium doloremque laudantium, otam rem aperiam, eaque ipsa quae ab illo <br/>
            inventore veritatis et quasi architecto beatae vitae dicta sunt <br/>
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, <br/>
            sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. <br/>
            Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, <br/>
            sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat <br/>
            voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam,<br/>
            nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate <br/>
            velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas <br/>
            nulla pariatur<br/><br/>

            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum<br/> 
            deleniti atque corrupti quos dolores et quas molestias <br/>
            excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt <br/>
            mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.<br/>
            Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod<br/>
              maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. <br/>
              Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut <br/>
              et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur <br/>
              a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis <br/>
              doloribus asperiores repellat.<br/><br/><br/>
            
            
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium <br/>
            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati <br/>
            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, <br/>
            id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. <br/>
            Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus <br/>
            id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. <br/>
            Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et <br/>
            voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, <br/>
            ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.'  <br/>             
            </DialogContentText>
          }        
        />

      </ThemeProvider>
      
  
      
    
    </>  
  );
}

export default App;



  // {/* <DialogueAddressResponce
  //       isOpen={isOpen} 
  //       setIsOpen={setIsOpen} 
  //       setValue={setCustomerAddress} 
  //       title={title}                //dialogueTxt={<DialogContentText color="#2d187be0" fontWeight={400} >New text goes <br/>into here !!</DialogContentText>}
  //     /> */}





                // {/* <SelectField 
                //   name= 'address' //custom:SubRole'//value={customerAddress}//</>onChange ={(e)=>{//openAddressDialog(true)//  setTitle(e.target.value=='' ? "" : e.target.value=='Retail Customer' ? 'Please Provide your Home Address': 'Please Provide your Business Address')//  }}                            
                //   backgroundColor='lightsteelblue'
                //   labelHidden
                //   onChange ={(e)=>{setCustomerAddress(e.target.value)  }}//</> 
                
                // >
                //   <option value=''>Select New Address</option>
                //   <option value={customerAddress}>{customerAddress}</option>             
                // </SelectField> */}


        // Footer() {          
        //   const { toSignUp, validationErrors } = useAuthenticator();           
        //    return (
        //     <View textAlign="center"  fontFamily={'Arial'}  >
        //       <CheckboxField
        //             errorMessage={validationErrors.acknowledgement}
        //             hasError={!!validationErrors.acknowledgement}
        //             name="acknowledgement"
        //             value="yes"
        //             label="I agree with the Terms and Conditions"
        //       />
        //       {/* <Button
        //         onClick={toSignUp}
        //         size="large"
        //         isFullWidth={true}
        //         width={410} //paddingLeft={-1}
        //         marginBottom={20}
        //         variation="link" //  variation="primary"
        //       >
        //         <Typography fontSize={16} fontWeight={600}> SingUp 🚀 </Typography>
        //       </Button> */}
        //     </View>
        //    );
        // },




    // <Heading
        //   backgroundColor="var(--amplify-colors-white)"
        //   //width='100%'  //height="auto"
        //   border='.5px solid black'
        //   borderRadius='5px'
        // >
          // <Image
          //     width="100%"        //     marginTop={5}
          //     height={115}        //     objectFit="none"
          //     objectPosition="50% 50%" //     src={pic}        //     alt="Sign In Autom8"        
          //     overflow='hidden'        //     onClick={() => alert('📸 Say cheese!')}
          // />
        // </Heading>   Name: "custom:Role",


// tokens:
// borderWidths : {small: {…}, medium: {…}, large: {…}}
// colors : 
  // background// :// {primary: {…}, secondary: {…}, tertiary: {…}, quaternary: {…}, disabled: {…}, …}
  // black// : // {name: '--amplify-colors-black', path: Array(2), value: 'hsl(0, 0%, 0%)', original: 'hsl(0, 0%, 0%)', toString: ƒ}
  // blue// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // border// : // {primary: {…}, secondary: {…}, tertiary: {…}, disabled: {…}, pressed: {…}, …}
  // brand// : // {primary: {…}, secondary: {…}}
  // font// : // {primary: {…}, secondary: {…}, tertiary: {…}, disabled: {…}, inverse: {…}, …}
  // green// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // neutral// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // orange// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // overlay// : // {10: {…}, 20: {…}, 30: {…}, 40: {…}, 50: {…}, 60: {…}, 70: {…}, 80: {…}, 90: {…}}
  // pink// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // purple// : //{10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // red// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // shadow// : // {primary: {…}, secondary: {…}, tertiary: {…}}
  // teal// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}
  // transparent// : // {name: '--amplify-colors-transparent', path: Array(2), value: 'transparent', original: 'transparent', toString: ƒ}
  // white// : // {name: '--amplify-colors-white', path: Array(2), value: 'hsl(0, 0%, 100%)', original: 'hsl(0, 0%, 100%)', toString: ƒ}
  // yellow// : // {10: {…}, 20: {…}, 40: {…}, 60: {…}, 80: {…}, 90: {…}, 100: {…}}

// components : {alert: {…}, authenticator: {…}, badge: {…}, button: {…}, card: {…}, …}
// fontSizes :  {xxxs: {…}, xxs: {…}, xs: {…}, small: {…}, medium: {…}, …}
// fontWeights :  {hairline: {…}, thin: {…}, light: {…}, normal: {…}, medium: {…}, …}
// fonts :  {default: {…}}
// lineHeights :  {small: {…}, medium: {…}, large: {…}}
// opacities :  {0: {…}, 10: {…}, 20: {…}, 30: {…}, 40: {…}, 50: {…}, 60: {…}, 70: {…}, 80: {…}, 90: {…}, 100: {…}}
// outlineOffsets :  {small: {…}, medium: {…}, large: {…}}
// outlineWidths/ :  {small: {…}, medium: {…}, large: {…}}
// radii :  {xs: {…}, small: {…}, medium: {…}, large: {…}, xl: {…}, …}
// shadows :  {small: {…}, medium: {…}, large: {…}}
// space :  {zero: {…}, xxxs: {…}, xxs: {…}, xs: {…}, small: {…}, …}
// time :  {short: {…}, medium: {…}, long: {…}}
// transforms :  {slideX: {…}}



// Data : codeDeliveryDetails: 
// AttributeName :  "email"
// DeliveryMedium :  "EMAIL"
// Destination :  "a***@f***"

// user: CognitoUser
// Session :  null
// authenticationFlowType :  "USER_SRP_AUTH"
// client : Client
// endpoint : "https://cognito-idp.ap-southeast-2.amazonaws.com/"
// fetchOptions :  {}

// keyPrefix :  "CognitoIdentityServiceProvider.64h81hl6ntvf7m36lr17hb2h7e"
// pool :  CognitoUserPool {userPoolId: 'ap-southeast-2_NGws2rnc0', clientId: '64h81hl6ntvf7m36lr17hb2h7e', client: Client, advancedSecurityDataCollectionFlag: true, storage: Storage, …}
// signInUserSession : null
// storage : Storage {length: 0}
// userDataKey :  "CognitoIdentityServiceProvider.64h81hl6ntvf7m36lr17hb2h7e.aa3.userData"
// username : "aa3"

// userConfirmed : false
// userSub :  "84878d03-fd23-49fc-97b0-137408666dd8"

// event : "signUp"
// message :  "aa3 has signed up successfully"


// const AlertExample = () => {
//   return (
//       <Alert
//           hasIcon={true}
//           heading="Alert "
//           variation="error"
//           isDismissible={true}
//         //  onDismiss={() =>setAlert(false)}
//         >If you want to set up New or Reset fogotten password select 'Set New / Reset Password' button, otherwise provide username, password and select 'Sign in' button.
//         </Alert>
//   )
// };

//    {/* {alert &&  <AlertExample/>  } */}

////////////////////////////////////////
//   useEffect(() => {
//     const handleTabClose = (e) => {
//       e.preventDefault();
//       console.log('beforeunload event triggered');
//       log.current=true
//       console.log(e);
//       console.log(log.current);
//       //window.location.reload()
//       return (e.returnValue ='Are you sure you want to exit?');
//     };

//     window.addEventListener('beforeunload', handleTabClose);

//     return () => {
//       log.current= !log.current
//       window.removeEventListener('beforeunload', handleTabClose)
//       console.log('tttttttttttttt', log.current)
//     };
//  });
//////////////////////////////////////////////////

//<View
//textAlign="center" paddingTop={tokens.space.xl} width="100%"
//>
//   <Text
//     color='darkred'
//     fontWeight={700}
//     fontSize={26}
//     fontStyle="normal"
//     //textDecoration="none"
//   >Sign In</Text>
// </View>

// Footer() {
//   const { tokens } = useTheme()
//   return (
//     <View textAlign="center" padding={tokens.space.xxxl}>
//       <Text color={`${tokens.colors.neutral['80']}`}>
//         &copy; All Rights Reserved
//       </Text>
//     </View>
//   )
// },

// signUp: {
//   Header() {
//     const { tokens } = useTheme()
//     return (
//        <View textAlign="center" paddingTop={tokens.space.xl} width="100%">
//           <Text
//             color='darkred'
//             fontWeight={700}
//             fontSize={26}
//             fontStyle="normal"
//             //textDecoration="none"
//           >Create </Text>
//         </View>
//     )
//   },

//   Footer() {
//     const { tokens } = useTheme()
//     return (
//       <View textAlign="center" padding={tokens.space.xxxl}>
//         <Text color={`${tokens.colors.neutral['80']}`}>
//           &copy; All Rights Reserved hello
//         </Text>
//       </View>
//     )
//   },  // },

//  {/* {debug && console.log(user)} */}
//        {/* {debug && console.log(user.attributes['custom:Role']??"No Role has been setup")}
//        {debug && console.log(user.attributes['custom:CustomerRole']??"No Customer Role has been setup")} */}
//        {/* <button onClick={signOut}>Sign out</button> */}

// const components = {
//   tokens: {
//     colors: {
//       background: {
//         primary: { value: tokens.colors.neutral['90'].value, },
//         secondary: {value: tokens.colors.neutral['100'].value, },
//       },
//       font: {
//         interactive: { value: tokens.colors.red['60'].value, },
//         default: {
//          variable: { value: 'Raleway, sans-serif' },
//            static: { value: 'Raleway, sans-serif' },
//         },
//       },
//       brand: {
//         primary: {
//           '10': tokens.colors.teal['100'],
//           '80': tokens.colors.teal['40'],
//           '90': tokens.colors.teal['20'],
//           '100': tokens.colors.teal['10'],
//         },
//       },
//     },
//     components: {
//       tabs: {
//         item: {
//             _focus: {
//               color: {value: tokens.colors.white.value,},
//             },
//             _hover: {
//               color: {value: tokens.colors.yellow['60'].value,},
//             },
//             _active: {
//               color: { value: tokens.colors.white.value, },
//             },
//         },
//       },
//     },
//  },
// };
///////////////

// {/* {console.log(user.signInUserSession.idToken)},   */}
//           {/* {console.log(user)}  */}
//           {/* <button width="30px" height ="10px" onClick={checkUser}>Check User</button> */}
//           {/* {localStorage.setItem('isReloaded', true)} */}

//           {/* {console.log(Auth.currentlyAuthenticatedUser())}          */}

// signOut={signOut}
// loggedInUser={loggedInUser}
// loggedInGroup={loggedInGroup}

//useEffect(() => {
// Hub.listen('auth', (data) => {
//    const { payload } = data
//    console.log('A new auth event has happened: ', data)
//    if (payload.event === 'signIn') {
//      console.log(payload.message)
//      console.log(payload.data.username )
//       //checkUser()
//      setLoggedInUser(user.signInUserSession.idToken.payload['cognito:username'])
//      setLoggedInGroup(user.signInUserSession.accessToken.payload['cognito:groups'][0])

//    }
//    if (payload.event === 'signOut') {
//       setLoggedInUser('')
//       setLoggedInGroup("")
//       console.log(payload.message,'  a user has signed out!')
//       console.log(payload.data.username )

//       console.log(loggedInUser)
//    }
// // })
// setLoggedInUser(user.signInUserSession.idToken.payload['cognito:username']||'')
// setLoggedInGroup(user.signInUserSession.idToken.payload['cognito:groups'][0]||'')
//console.log(user)

// }, [])

// function checkUser() {
//   Auth.currentAuthenticatedUser()
//     .then(user => console.log( user))//['username'] ))
//     .catch(err => console.log(err))
// }

// import Main from './Main.jsx'
// import Home from '../src/pages/home/Home.jsx'
/////////////////////////
// import Table from './components/Table.jsx';
// // import Header from './components/Header.jsx';
// //import Modal from "react-modal";
// //import {columnsCustomers, columnsClients, columnsJobbook} from './Columns.js';
// import GetTableData from './components/TableData.jsx';
// import GetTableColumns from './components/TableColumns.jsx'
// import AddNewClientScreen from './components/AddNewClientScreen.js';
// import ApplicationBar from './components/AppBar.jsx';

// import NavButtons from './components/NavButtons.jsx';
//  import Navbar from './components/Navbar.jsx';
//  import Sidebar from './components/Sidebar.jsx'

// const DATA = useRef([]);
// const [isOpen, setIsOpen] = useState(false);
// const [serverData, setServerData]= useState([{}]);
// //const [query, setQuery] = useState('SELECT * FROM customers');
// const [table, setTable] = useState({query : "SELECT * FROM clients",
//                                     tableName: 'clients', });

// useEffect(()=>{
//   loadDataFromServer(table.query, table.tableName);
// },[table])

// function toggleModal() {
//   setIsOpen(!isOpen);
// }

// const respData = (inp)=>{setServerData(inp)}
// async function loadDataFromServer(query, tableN) {
//     const response = await fetch(`http://localhost:3002/api?query=${query}`,
//         { method: 'POST',
//           headers: {'Content-Type': 'application/json; text/plain; */*; charset=utf-8' },
//           body: ''
//         });
//     const responseData = await response.json();
//     setServerData(responseData)
// };

//           {/* <div className='appContainer'>

//             <Navbar/>

//             </div>  */}
//              {/* <Sidebar/> */}
//             {/* <div className="appContainer">
//               <Navbar/>

//               {/* <AddNewClientScreen isOpen={isOpen} setIsOpen={setIsOpen} /> */}
//               {/* <Navbar/> */}
//             {/* ideba  <Sr/> */}
//               {/* <ApplicationBar /> */}
//               {/* <NavButtons toggleModal={toggleModal} setTable={setTable}/>
//               <Table columns= {GetTableColumns(table.tableName, {table} )} data={GetTableData( serverData, table.tableName)}/>
//               *}
//             </div>
//             {/* <AppRoutes /> */}
//              {/* <Main/>
//             </div> */}

//             {/* <Home/> */}

// /////////////
// {/* <SectionStyle></SectionStyle> */}

// <Header title={''} children={''}>
// {/* <Input className="something" placeholder="Search" backgroundColor="white" textColor="red" size=".2em"/>
// <PasswordInput className="something"  backgroundColor="white" size=".3em"/> */}
// {/* <Label><CheckInput className="something"  defaultChecked /><LabelText $mode="">Foo</LabelText></Label> */}
// </Header>       {/* &lt; 💅🏾 */}

//////////////IMPORTS

//import styled from 'styled-components'
//import { useTable } from 'react-table'
// import {
//           Title,
//           SectionStyle,
//           //..ButtonStyle,
//           TomatoButton,
//           //..Thing,
//           //..RotateDiv,
//           FadeInButton,
//           Spinner,
//           //...CheckInput,
//           //... Label,
//           //....LabelText,
//          } from './components/Styles.jsx'

// import * as dotenv from 'dotenv'
// dotenv.config({path: '../env'})
//require('dotenv').config()
// Modal.setAppElement("#root");

//  setLoggedInUser('')  //  setLoggedInGroup('')
// setLoggedInUser(payload.data.username )
// setLoggedInGroup(payload.data.signInUserSession.idToken.payload['cognito:groups'][0])
//console.log(payload)       //checkUser()

// ConfirmSignIn: {
//   Header() {
//     return <Heading>ログイン</Heading>
//   },
// },

// ResetPassword: {
//   Header() {
//     return <Text
//             color='darkred'
//             fontWeight={600}
//             fontSize={18}
//             fontStyle="normal"
//             textDecoration="none"
//           >
//             Reset your password
//           </Text> //<Heading>Reset your password</Heading> //パスワード再交付
//   },
// },

// <Heading
//   padding={tokens.space.xxxl}
//   width='100%'
//   //  height="50%"
//   color="green"
//   fontWeight="bold"
//   level={5}
//   //paddingTop ='45px'
//   //marginLeft = '30px'
//   //border='1px dotted red'
// >     // </Heading>

