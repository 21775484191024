import React, {useState, useRef, useEffect, } from 'react';
//import {UserLogInContext} from '../../App';
import Button from '@mui/material/Button';     
//import Autocomplete from '@mui/material/Autocomplete';  
import TextField from '@mui/material/TextField';  
import Stack from '@mui/material/Stack'; 
import Typography from '@mui/material/Typography'; 
import Box from '@mui/material/Box'; 
import './newitem.scss';
import {  companyABN , eMail, BSBformat, Accountformat, validName, mobileNumber} from '../Regex.js';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import NewContact from './NewContact';//import {CountrySelect} from '../../util/Functions';//import { Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react';
import styled from 'styled-components';
//import { Fab } from '@mui/material';
import { SearchAddress,fs } from '../../util/Functions.jsx';
import { DialogueResponce} from '../../components/DialogueAlert.jsx'


const Container = styled.div`
  //background: ${props => props.bgc};
  // color: ${props => props.primary ? "black" : "transparent"};
 margin:1ch;
  //padding: 1ch 1ch;
  padding-top:1ch;
 // border: solid ${props=> props.bc || 'lightsteelblue' } ;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:'center';
  height:${props=>props.h};
  width:${props=>props.w};
  box-shadow:1px 1px 2px 1px lightsteelblue;

`;

const NewContractor = ({action}) => {
    const [contractorName, setContractorName] = useState('');
    const [contractorABN, setContractorABN] = useState('');
    const [contractorPointCont,setContractorPointCont] = useState('')
    const [contractorEmail,setContractorEmail] = useState('')
    const [contractorPhoneNumber,setContractorPhoneNumber] = useState('')
    const [contractorAddress,setContractorAddress] = useState('')
    const [contractorBSB, setContractorBSB] = useState('')
    const [contractorAccn, setContractorAccn] = useState('')
    const [contractorPostCode,setContractorPostCode] = useState('')
    const [contractorRegion, setContractorRegion] = useState('')
    const [contractorState,setContractorState] = useState('')
    const [sheqsyId,setSheqsyId] = useState(null)
    const [contractorSuburb, setContractorSuburb] = useState('')
    const [active, setActive] = useState(1);    
    const [inputs, setInputs] = useState({});    //const [loaded, setLoaded] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [retValue, setRetValue] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const windowWidth = useRef('');
    // const windowHeight = useRef(window.innerHeight);

    const lat =useRef('0');
    const lng =useRef('0');

    const google = window.google      ////IMPORTANT FOR GOOGLE MAPS TO WORK
    let { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    ////////////////
    const productionHost=process.env.REACT_APP_API_HTTP + window.location.host
    const localHost=process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_LOCAL_DOMAIN + process.env.REACT_APP_API_LOCAL_PORT
    const DOMAIN =`${process.env.NODE_ENV==='production'?productionHost:localHost}`;
    const DEBUG= process.env.REACT_APP_DEBUG// && DEBUG && console.log(DOMAIN) /////////////  //const loading = true;
    //const {user, group, subId, role, customerrole, userId, isLoaded} = useContext(UserLogInContext);

    useEffect(()=>{ 
        const controller = new AbortController()
    
        action ==='update' && handleGetDataForUpdate()        //setLoaded(true)

        return () => { controller.abort() };
    }, [])

    useEffect(()=>{
        setInputs(values=>({...values, ['supplierPostalAddress']: contractorAddress}));
        setInputs(values=>({...values, ['position']: {x:parseFloat(lng.current), y:parseFloat(lat.current)}}));
        setInputs(values=>({...values, ['supplierSuburb']: contractorSuburb }));    
        setInputs(values=>({...values, ['supplierPostCode']: contractorPostCode})); 
        setInputs(values=>({...values, ['supplierState']: contractorState}));
    },[contractorAddress]);

    useEffect(() => {
        
        function handleWindowResize() {
            setWindowSize(getWindowSize())
        }

        window.addEventListener('resize', handleWindowResize);    
        return ()=>{ window.removeEventListener('resize', handleWindowResize) };
     }, []);

     useEffect(() => {
        windowWidth.current = ((windowSize.innerWidth)/(windowSize.innerWidth/720))

     }, [windowSize]);

     useEffect(() => {

        if (retValue){
            deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_CONTRACTOR_DATA}delete/${id}/d`) /// `http://localhost:3002/api/${type}/delete/${id}`
            navigate('/contractors')                     
        }

        setRetValue(false)  
        setIsOpen(false)    
     }, [retValue]);


    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
     }

    //getting data for update
    async function getContractorData(Url) {  
        //(`${DOMAIN}${process.env.REACT_APP_API_GET_CONTRACTOR_DATA}${id}`)
        const response = await fetch(Url);
        const responseData = await response.json();
        const data = responseData[0];          // DEBUG && console.log(Url)       // DEBUG && console.log(data)       //return (responseData || "test");
        const keys= Object.keys(data)
        keys.forEach(key=>{setInputs(values=>({...values, [key]: data[key]}))})

        setContractorName(data.supplier ?? '');
        setContractorEmail(data.supplierEmail ?? '');
        setContractorPhoneNumber(data.supplierPhone ?? '') ;
        setContractorPointCont(data.supplierContactPerson ?? '');
        setContractorAddress(data.supplierPostalAddress ??= '');
        setContractorABN(data.abn ??'' );

        setContractorBSB(data.supplierBSB ??= '' )
        setContractorAccn(data.supplierAccountNumber ??= '')
        setContractorPostCode(data.supplierPostCode)
        setContractorRegion(data.supplierRegion)
        setContractorState(data.supplierState)
        setContractorSuburb(data.supplierSuburb)
        setSheqsyId(data.sheqsyUId ??= '')        
        lat.current=data.position?.y;
        lng.current=data.position?.x;
    }

    const handleClose=()=>{ navigate(-1)};

    const handleAdd=()=>{ 
        postNewContractortData(DOMAIN + process.env.REACT_APP_API_ADD_NEW_CONTRACTOR, inputs)
        navigate(-1)
    }

    const handleGetDataForUpdate=()=>{
        getContractorData(`${DOMAIN}${process.env.REACT_APP_API_GET_CONTRACTOR_DATA}${id}`)
    }

    const handleUpdate=()=>{ 
        //DEBUG && console.log(inputs)
        putContractorData(`${DOMAIN}${process.env.REACT_APP_API_UPDATE_CONTRACTOR_DATA}${id}`, inputs)
        navigate(-1); 
    } 


    const getLongLat= (siteAddress)=>{
        DEBUG && console.log( google, siteAddress)

        if(google !== undefined){

            const geocoder = new google.maps.Geocoder(); 
            geocoder.geocode( { 'address': siteAddress}, function(results, status) {

                if (status == google.maps.GeocoderStatus.OK) {
                    lat.current = results[0].geometry.location.lat();
                    lng.current = results[0].geometry.location.lng();

                    setInputs(values=>({...values, ['position']: {x:parseFloat(lng.current), y:parseFloat(lat.current)}}));
                    DEBUG && console.log(lat.current, lng.current);
                } else{
                    DEBUG && console.log('Wrong Address supplied')
                }
            }); 
        }
    };

    async function putContractorData(Url, BodyInput) {    //DEBUG && console.log(BodyInput) /// text/plain; */*; charset=utf-8
        const requestOptions = {   
            method: 'PUT', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8' },   
        } 

        const response = await fetch(Url, requestOptions).catch(console.log('tttttteet'));
        
        if(response.status === 400){
            console.log('Error: Unable to update this provider \n')
            console.log(await response.json())
            return;

        }else{
            const responseData = await response.json();  

        }
        
    }

    async function postNewContractortData(Url, BodyInput) {    //DEBUG && console.log(BodyInput) /// text/plain; */*; charset=utf-8
        const requestOptions = {   
            method: 'POST', 
            headers: {'Content-Type':'application/json; charset=utf-8' }, 
            body: JSON.stringify({ BodyInput }),    
        } 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  
        DEBUG && console.log(responseData)
    }

    const validateField = (inputText, pattern)=>{ return  pattern===""? true : inputText.match(pattern)?true: inputText.length===0 ?true: false }

    const handleChange=(e, validated)=>{
        const name= e.target.name
        const value = e.target.value ==''?null:e.target.value
        setInputs(values=>({...values, [name]: value})); 
    }  

    const handlePosChange =(e)=>{ setInputs(values=>({...values, ['position']: {x:lng.current, y:lat.current}})) }

    async function deleteData(a) {
        DEBUG && console.log(a)
        const response = await fetch(a, {method: 'DELETE'});
        const responseData = await response.json();  
        //DEBUG && console.log(responseData.ok)  `${DOMAIN}/api/${type}/delete/${id}`                         
    }
    
    const handleDelete = ()=>{
        setIsOpen(true)
        
        // let a = prompt(`\n\nWARNING:\n\nYou are about to Deactivate this Contractor\nIf You wish to proceed - type 'yes' and then click OK button\n`,'NO')
        // if(a===null)(a="no")/ if (a.toLowerCase() === 'yes'){     deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_CONTRACTOR_DATA}delete/${id}/d`) /// `http://localhost:3002/api/${type}/delete/${id}`
        //     navigate(-1)        // }
    }

    const isValidField = (inputText, flag)=>{ 
        let response = false
        // DEBUG && console.log(inputText, flag)
        flag===''? response = true: inputText.match(flag)? response = true: response = false
        return response
    }


  return (
        <>                                 
            <Stack  name="MAIN" sx={{p:2, width: {xs:'45ch', sm:'60ch', md:'90ch'}, gap:1.2}}>

                <Stack name= 'HEADER' sx={{ p:'0ch 0px 1ch 10ch' }}>                     
                    <Typography 
                        color='darkblue'
                        fontSize={fs(0.95, 1.3, 1.4)} 
                        fontWeight={600}
                    > {action === 'update'? `Updating Service Provider: '${contractorName}'` : contractorName==''?'':`Adding Service Provider: '${contractorName}'`}  
                    </Typography>        
                </Stack> 

                <Stack name = 'ROWS - FIELDS' gap={3}>    

                    <Stack  name='FIRST ROW - name' >
                        <TextField  
                            label={'Name'} 
                            value={contractorName ?? ''}                             //required
                            color='info'
                            autoFocus={true}
                            size='small'
                            name ="supplier"                     //style={{width:'77ch'}}       // sx={{fontSize:10}}                      //helperText={contractorName ===''?"Enter valid Name": contractorName !==null && !isValidField(contractorName, validName) ? "Name is invalid":''}
                            error = {contractorName !==null && !isValidField(contractorName, validName)}  //helperText={!VALID.current? "Required field, populate with some value":''} 
                            // error={!isValidField(contractorName, notEmpty)} //{!VALID.current? true:false}     // helperText={contractorName ==''? "Required field, please enter valid Name":!isValidField(contractorName, validName) ? "Invalid Entry: the name must starts with a letter":''}         
                            onChange={(e)=>{
                                setContractorName(e.target.value) ///  VALID.current = validateField(e.target.value, validName)
                                handleChange(e)
                            }}
                        />
                    </Stack>
                
                    <Stack  name='SEC ROW' direction="row" gap={2}>
                            <TextField  
                                label={'Email'} 
                                value={contractorEmail ?? ""} 
                                size='small'
                                name ="supplierEmail"                                                                 
                                style={{width:'51ch',}}
                                //helperText = {contractorEmail ===''?"Please enter valid email": contractorEmail !==null && !isValidField(contractorEmail, eMail) ? "Email is invalid":''}
                                error = {!isValidField(contractorEmail, eMail)}  //helperText={!VALID.current? "Required field, populate with some value":''}   mailformat, notEmpty, companyABN , eMail
                                onChange={(e)=>{
                                    setContractorEmail(e.target.value) //    VALID.current = validateField(e.target.value,  eMail )
                                    handleChange(e)
                                }}
                            />
                            <TextField  
                                label={'Mobile Phone' } 
                                value={contractorPhoneNumber ??''} 
                                size='small'
                                name ="supplierPhone" 
                                //style={{width:'20ch', }}
                                error = {!contractorPhoneNumber  && !isValidField(contractorPhoneNumber, mobileNumber)}  //helperText={!VALID.current? "Required field, populate with some value":''} 
                                onChange={(e)=>{
                                    setContractorPhoneNumber(e.target.value)// VALID.current = validateField(e.target.value, phonenum)
                                    handleChange(e)
                                }}
                            />
                    </Stack>   

                    <Stack  name='THIRD ROW' direction="row" gap={2} >
                        <TextField  
                            label={'ABN'}                             ///value={contractorABN == 'null' || contractorABN == 'undefined' || contractorABN == null || contractorABN == undefined ? '': contractorABN } 
                            value={contractorABN ?? '' }                             //required
                            size='small'
                            name ="abn"                                 
                            style={{width:'32ch'}}
                            error={!isValidField(contractorABN, companyABN)}
                            helperText= {
                                !contractorABN  ?
                                        <Typography fontSize={10}>Enter ABN number </Typography> : 
                                            isValidField(contractorABN, companyABN) ?  <Typography fontSize={10}>Format is valid</Typography> : 
                                    <Typography fontSize={10}>Format is invalid</Typography>
                            }                   //contractorABN ===''?'':isValidField(contractorABN, companyABN) ? "":<Typography fontSize={10}>ABN is invalid</Typography>} //{contractorABN ===''?"Please enter valid ABN": contractorABN !==null && !isValidField(contractorABN, companyABN) ? "ABN is invalid":''}
                            onChange={(e)=>{
                                setContractorABN(e.target.value) 
                                handleChange(e)
                            }}
                        />  
                            <TextField  
                                label={'BSB' } 
                                value={contractorBSB =='null'|| contractorBSB ==null || contractorBSB =='undefined' || contractorBSB ==undefined ? '' :contractorBSB } 
                                //required
                                size='small'
                                name ="supplierBSB" 
                                style={{width:'12ch'}}
                                error={!isValidField(contractorBSB, BSBformat)}
                                helperText= {
                                    contractorBSB === '' || contractorBSB =='null'|| contractorBSB == null || contractorBSB =='undefined' || contractorBSB == undefined ?
                                    <Typography fontSize={10}>Enter BSB </Typography> : 
                                    isValidField(contractorBSB, BSBformat) ?
                                    <Typography fontSize={10}>Format is valid</Typography> :
                                    <Typography fontSize={10}>Format is invalid</Typography>
                                }             
                                    //contractorBSB === ''?<Typography fontSize={12}>BSB is empty </Typography> : isValidField(contractorBSB, BSBformat) ? "" :  <Typography fontSize={11}>BSB is invalid</Typography>}
                                onChange={(e)=>{
                                    setContractorBSB(e.target.value) 
                                    handleChange(e)
                                }}
                            />                          
        
                            <TextField  
                                label={'Account Number'} 
                                value={contractorAccn =='null'|| contractorAccn == null || contractorAccn =='undefined' || contractorAccn == undefined ? '' : contractorAccn }                          
                                style={{width:'30ch', marginLeft:'-2ch'}}
                                size='small'
                                name ="supplierAccountNumber" 
                                error={!isValidField(contractorAccn, Accountformat)}
                                helperText= {
                                                contractorAccn === '' || contractorAccn =='null'|| contractorAccn == null || contractorAccn =='undefined' || contractorAccn == undefined ?
                                                <Typography fontSize={10}>Enter Accnt Number</Typography> : 
                                                isValidField(contractorAccn, Accountformat) ?
                                                <Typography fontSize={10}>Format is valid</Typography> :
                                                <Typography fontSize={10}>Format is invalid</Typography>
                                            }                                    ///contractorAccn ===''?"Please enter valid Accoiunt Number":  ? "Acount Number is invalid":''}
                                onChange={(e)=>{
                                    setContractorAccn(e.target.value) 
                                    handleChange(e)
                                }}
                            />  
                    
                        
                    </Stack>

                    <Stack  name=' ROW' direction="row" gap={1}>

                        <TextField  
                            label={'Contact Name'} 
                            value={contractorPointCont ??''} 
                            //fullWidth
                            ///required
                            size='small'
                            margin='none'
                            type='text'
                            name ="supplierContactPerson" 
                            style={{width:'76ch', }}
                            //helperText={contractorPointCont ===''&& "Enter valid Name"}
                            //error = {contractorPointCont !==null}  
                            //helperText={contractorPointCont ===''?"Please enter Point of Contact": ''}
                            fontFamily='cursive'
                            color='primary'
                            error = {contractorPointCont==''?true:false} 
                            //sx={{fontSize:10, backgroundColor:'transparent', border:'1px solid transparent', borderRadius:2}}
                            onChange={(e)=>{
                                setContractorPointCont(e.target.value)
                                handleChange(e)
                            }}
                        />


                    </Stack>   

                    <Stack name='SIXTH ROW' >
                        <SearchAddress
                            destination={contractorAddress}  
                            setDestination={setContractorAddress} 
                            setLat={lat}
                            setLng={lng}
                            setSiteState={setContractorState}
                            setSitePostCode={setContractorPostCode}
                            setSiteSuburb={setContractorSuburb}
                            //size={windowWidth.current}

                        />
                    </Stack>

                    <Stack name='FORTH ROW A ' direction={{ sm: "column", md: "row" }} gap={2} sx={{pt:3}}>
                    
                        <TextField                                                      
                            disabled
                            value={lat.current}                                 
                            label={<Typography fontSize={fs(.8, .9, 1.1)}>Lat</Typography> }  
                            size='small'
                            style={{width:'15ch',}}
                            sx={{ backgroundColor:'ivory',}}
                        /> 
                        
                        <TextField  
                            label={<Typography fontSize={fs(.8, .9, 1.1)}>Lng</Typography> }  
                            disabled
                            size='small'                                   
                            style={{width:'15ch'}}
                            sx={{ backgroundColor:'ivory', }}                                
                            value={lng.current} 
                        /> 
                            
                    </Stack> 
                </Stack> 

                <Stack name="CONTACTS CONTAINER" sx={{p:0}}>     
                    {action ==='update' && 
                        <NewContact 
                            entityID={id} 
                            entitySearchField={'contractorid'} 
                            
                        />  
                    }                                                                                   {/* <div> <h4>Width: {windowSize.innerWidth}</h4> <h4>Height: {windowSize.innerHeight}</h4> </div> */}
                </Stack>  

                <Stack name='BUTTONS' 
                    direction={{ sm: "column", md: "row" }} 
                    //width={{xs: '45ch', sm: "60ch", md:'85ch'}} 
                    justifyContent='center' 
                    alignItems='center' 
                    gap={{xs:1.5, sm:3, md:5}} 
                    sx={{p:2}}
        
                >                                     
                    <Button 
                        onClick={ action==='update'? handleUpdate : handleAdd }
                        className='Button1 tab'   
                        sx={{ fontSize: fs()}}  
                        variant="outlined"   
                    ><Typography sx={{fontSize:fs()}}>  {action==='update'? 'Update ' : 'Add '}</Typography></Button>
                    
                    {action==='update' && 
                        <Button 
                            className='Button1 tab'   
                            sx={{color:'red', fontSize: fs()}}  
                            variant="outlined"   
                            onClick={handleDelete}//{active === 1? handleDelete: ReActivate}                                        //variant="outlined" /                                        //sx={{color:'red', fontSize:11, borderRadius:'5px'}} 
                        ><Typography sx={{fontSize:fs()}}>  {active === 1? 'Deactivate': 'Re-Activate'}</Typography> </Button>
                    }
                    <Button 
                        className='Button1 tab'   
                        sx={{ fontSize: fs()}} ///'clamp(0.65rem, .6vw, 1rem)'}}  
                        variant="outlined"   
                        onClick={handleClose} 
                    ><Typography sx={{fontSize:fs()}}>close </Typography></Button>
                
                </Stack>
            </Stack>



            <DialogueResponce 
                isOpen={isOpen} 
                setIsOpen={setIsOpen} 
                setValue={setRetValue} 
                title={'Deactivation Warning'} 
                dialogueTxt={`You are about to change this site from ${inputs.active===1 ? 'active':'inactive'} to ${inputs.active===1 ? 'inactive':'active'}. Would You like to proceed with ${inputs.active !==1 ? 'activation':'deactivation'}?` }                    
            />  

        </>  
  )
}
export default NewContractor;


//  Box component="form" sx={{ "& .MuiTextField-root": { ml: 0} }} autoComplete="off"





                // {/* <DialogueAlert 
                //     isOpen={isOpen} 
                //     openOff={setIsOpen} 
                //     setValue={setRetValue} 
                //     title={'Deactivation Warning'} 
                //     dialogueTxt={'Confirm deactivation for this site by typing "yes" and clicking OK button'}
                // /> */}

                //     {/* <Stack >
                //                 <TextField  
                //                     //label="Suburb" 
                //                     value={contractorSuburb} 
                //                     label={<Typography>Suburb</Typography> }                    //disabled={true}
                //                     size="small"
                //                     style={{width:'23ch'}}
                //                     sx={{ backgroundColor:'ivory',}}
                //                 />
                //             </Stack>

                //             <Stack >
                //                 <TextField  
                //                     value={contractorPostCode} 
                //                     label={<Typography fontSize={13}>Post Code</Typography> }  
                //                     size='small'
                //                     style={{width:'9ch', height:'4ch'}}
                //                     sx={{ backgroundColor:'ivory',}}
                                    
                //                 />
                //             </Stack>

                //             <Stack >
                //                 <TextField  
                //                     value={contractorState} 
                //                     label={<Typography fontSize={13}>State</Typography> }  
                //                     size='small'
                //                     style={{width:'7ch', height:'4ch'}}
                //                     sx={{ backgroundColor:'ivory',}}
                                    
                //                 /> 
                //             </Stack> */}

                // {/* <Stack  name='SEVENTH ROW' direction="row" height={'7ch'} gap={2}>
                //             <TextField  
                //                 label={<Typography fontSize={13}>Sheqsy ID </Typography> }                                    //value={sheqsyId===null?'':sheqsyId} 
                //                 value={sheqsyId ?? ''} 
                //                 size='small'
                //                 name ="sheqsyUId" 
                //                 sx={{height:'5ch', fontFamily:'cursive', fontSize:'14px', fontWeight:600}}
                //                 style={{width:'43ch'}}
                //                 onChange={(e)=>{
                //                     setSheqsyId(e.target.value)
                //                     handleChange(e)
                //                 }}
                //                 onPaste={e=>{
                //                     setSheqsyId(e.target.value)
                //                     handleChange(e)

                //                 }}
                //             />

                //             <TextField  
                //                 label={<Typography fontSize={13}>Region</Typography> } 
                //                 value={contractorRegion} 
                //                 size='small'
                //                 name ="supplierRegion" 
                //                 style={{width:'41ch'}}
                //                 sx={{height:'5ch', fontFamily:'cursive', fontSize:'14px', fontWeight:600}}
                //                 //helperText={contractorRegion ===''?"Please enter Region": ''}
                //                 error = {contractorRegion==''?true:false}
                //                 onChange={(e)=>{
                //                     setContractorRegion(e.target.value)
                //                     handleChange(e)
                //                 }}
                //             />
                //     </Stack> */}

