import React, {useState, useEffect } from 'react';
import {Stack, Button, TextField, Typography} from '@mui/material'; // import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit'; //import { mailformat, mobileNumber} from '../Regex.js';// import { useParams, useNavigate} from "react-router-dom";
import {Flex, TabItem, Tabs, View } from '@aws-amplify/ui-react';
import {useNavigate } from "react-router-dom";
import {Log, fl,} from '../../util/Functions.jsx'
//import styled from 'styled-components'; // import { Padding } from '@mui/icons-material';
import {regExpr} from '../Regex.js';
import "./newitem.scss";

// const Container = styled.div`
//   background: ${props => props.bgc};
//   color: ${props => props.primary ? "black" : "transparent"};
//   margin-top:0ch;
//   border: 3px solid ${props=> props.bc } ;
//   border-radius: 10px;
//   display: flex;
//   flex-direction: column;
//   height:${props=>props.h};
//   width:${props=>props.w};
//   box-shadow:0 2px 6px gray;
// `; // const labelStyle={fontWeight:600, fontFamily:'Arial', fontSize: 'clamp(0.75rem, .7vw, 1rem)'} ///color:'#2d187be0', 




const NewContact = ({entityID, entitySearchField, containerWidth}) => {

    const [contactName, setContactName] = useState('');
    const [contacEmail, setContactEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');     //const [contactId, setContactId] =useState('')    //const [newContactId, setNewContactId] = useState('')    //const [entityName, setEntityName] = useState('contacts') 
    const [contactInputs, setContactInputs] = useState([]);
    const [contactsData, setContactsData] = useState({});
    const [contactsNumbers,setContactsNumbers] =useState(0);
    const [value, setValue]=useState(0);    //const [contactKey,setContactKey] =useState(0)
    const [refresh, setRefresh] = useState(false);    //const [isFieldValid, setIsFieldValid] = useState(false);

    const {
        REACT_APP_API_HTTP,
        REACT_APP_API_LOCAL_DOMAIN,
        REACT_APP_API_LOCAL_PORT,
        REACT_APP_API_GET_CONTACT_DATA,
        REACT_APP_API_ADD_NEW_CONTACT,
        REACT_APP_API_CONTACT_UPDATE,
        REACT_APP_API_CONTACT_DELETE,
        NODE_ENV,
      } = process.env;
    
    // const VALID = useRef({   name:false, 
    //                         mobNumber: false, 
    //                         email:false
    //                     })
    // const VALID_Name = useRef(false);
    // const VALID_MobNumber = useRef(false);    //const [loaded, setLoaded] = useState(true)
    // const VALID_Email= useRef(false);    

    const navigate = useNavigate();
    //const { tokens } = useTheme();
    const productionHost = REACT_APP_API_HTTP + window.location.host;
    const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
    const DOMAIN = `${NODE_ENV==='production' ? productionHost : localHost }`; 
    
    useEffect(()=>{      
        refresh && getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)///getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)
        handleNewTabClick()
        setRefresh(false)
        //const a = Array.name
    }, [refresh]);

    useEffect(()=>{      
        setContactInputs(values=>({...values, ['entityID']: entityID, ['entitySearchField']: entitySearchField  }))
        //setContactInputs(values=>({...values, ['entitySearchField']: entitySearchField }))
        getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)           //setLoaded(false)    
    }, []);

    const handleAddContact = ()=>{
        //console.log(contactInputs)// if (contactInputs.contactName === undefined || contactInputs.contactName === null){   alert(`In order to add a contact You have to provide at least Contact's Name`); //     return;        // }
        postNewContactData(DOMAIN + REACT_APP_API_ADD_NEW_CONTACT, contactInputs)
        setContactInputs([]);
        setContactName('');
        setContactNumber('');
        setContactEmail('');        //getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)
        setRefresh(true);        //window.location.reload()
    }

    const handleUpdateContact=(contID)=>{
        putData(`${DOMAIN}${REACT_APP_API_CONTACT_UPDATE}${contID}`, contactInputs);       // console.log(contID) // console.log(entitySearchField, entityID)     //getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)
        setRefresh(true);   //window.location.reload()
    } 


    async function getContactData(Url) {      
        const response = await fetch(Url).catch((err)=>console.log('ERROR ', err));
        console.log(response.status);
        const contacts = await response.json().catch(e=>{
            console.log(`ERROR: Unable to convert fron JSON. Check your SQL response/requst. Error description ${e}`);
            Log(fl(3571,'NewContact.jsx'), `ERROR: Unable to convert fron JSON. Check your SQL response/requst. Error description ${e}`);
            return [];
        } );  
        console.log(contacts)

        setContactsNumbers(contacts.length);
        setValue(contacts.length);
        setContactsData(contacts);
        const keys= Object.keys(contacts);         //console.log(keys)       //setContactKey(contacts.length)
    }

    async function postNewContactData(Url, BodyInput) {
        //.log(BodyInput)
        const requestOptions = {   
            method: 'POST', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8' },   
        } 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  

        //setNewContactId(responseData.insertId)
        //console.log(responseData.insertId)                                 
    }

    async function putData(Url, BodyInput) {    
        //console.log(BodyInput)
        const requestOptions = {   
            method: 'PUT', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8' },   
        } 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  
        //console.log(responseData)
    }

    const handleDeleteContact=(contID)=>{
        // const a = prompt('Are You sure you want to delete this contact?','No')
        // console.log(a)
        // if (a === null || a === "No"){
        //     //console.log('contact ID : ' + contID, " client ID : " + id)
        // }else{
        DeleteData(`${DOMAIN}${REACT_APP_API_CONTACT_DELETE}${contID}`);
        // console.log(contID);
        // console.log(entityID, entityName);    
        //getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${id}`);
        //window.location.reload()
        navigate(-1)
            
        
    } 

    async function DeleteData(Url) {    

        console.log(Url)
        const requestOptions = {method: 'DELETE'} 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  
    }


    const handleContactChange=(e, rExp)=>{
        //action === 'new'?  setContactInputs(values=>({...values, ['clientId']: newClientId})) :  setContactInputs(values=>({...values, ['clientId']: id}))  
        //setContactInputs(values=>({...values, ['entityID']: entityID, ['entitySearchField']: entitySearchField }))
        //setContactInputs(values=>({...values, ['entitySearchField']: entitySearchField }))
        const name= e.target.name ?? "KEY";
        const value = e.target.value ?? 'INVALID';
        setContactInputs(values=>({...values, ['entityID']: entityID, ['entitySearchField']: entitySearchField, [name]: value})) //: setContactInputs(values=>({...values, [name]: 'INVALID'}))           
        // isValid(value, name, rExp)

    }

    const handleTabClick=(c, i)=>{
        //  console.log(contactsData)//  console.log(value)//  console.log(i)//setContactKey(i)
        setContactName(c.contactName)
        setContactNumber(c.contactPhoneNumber)   
        setContactEmail(c.contactEmail)         //setContactId(c.contactId)   
        setContactInputs(values => (
            {
                ...values, 
                ['contactName']: c.contactName, 
                ['contactPhoneNumber']: c.contactPhoneNumber,
                ['contactEmail']: c.contactEmail,
                ['contactId']: c.contactId
            }
        ));
        // setContactInputs(values=>({...values, ['contactPhoneNumber']: c.contactPhoneNumber}))  // setContactInputs(values=>({...values, ['contactEmail']: c.contactEmail}))        // setContactInputs(values=>({...values, ['contactId']: c.contactId}))// VALID_contactNumber.current = isValidField(c.contactPhoneNumber, mobileNumber)
        // VALID_contacEmail.current = isValidField(c.contactEmail, mailformat)
    }

    const handleNewTabClick=()=>{
        setContactName("")
        setContactNumber('')   
        setContactEmail('')         //setContactId('')// VALID_contactNumber.current = false        // VALID_contacEmail.current = false
        setContactInputs([{}]) 

    }

    //if (action ==='update' && loaded ){    // if (loaded ){    //     setContactInputs(values=>({...values, ['entityID']: entityID }))    //     setContactInputs(values=>({...values, ['entitySearchField']: entitySearchField }))    //     getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)        
    //     setLoaded(false)    // }

    const AllContats = () => {     

        return (
            <Stack sx={{ width: containerWidth, boxShadow:'0 0 2px 1px gray'}}>
                <Tabs
                    //justifyContent="flex-start" //align-items = 'cente'  //justify-self= 'center'
                    currentIndex={value}             //{contactKey} //indicatorPosition="top"  //defaultIndex={contactsNumbers===0 ? 0: contactsNumbers }  //spacing='relative'                    //width={{sx:'20ch', md: '65ch'}}                    //height={30}
                    onChange={(v)=>{setValue(parseInt(v))} }
                >

                    {
                        contactsData.map((c, i) => (
                            <TabItem 
                                key = {i} 
                                title = { <Typography color="blueviolet" fontSize={{xs:10, sm:12, md:14}} fontWeight={i === value ? 600 : 400} > {c.contactName }</Typography> } 
                                backgroundColor = {i === value ?'aquamarine':'aliceblue'}//
                                border = { i === value ? '2px solid blue':'2px solid lightgreen'}
                                borderRadius = '5px'                            //height={17}                
                                onClick={()=>handleTabClick(c, i) }
                            >
                                <Stack direction="column" gap={1} boxShadow="0 0 2px 2px var(--amplify-colors-blue-20)">

                                    <Stack direction="column" gap={2} padding = '20px 25px 0px 25px' >

                                        <Stack className='fields' >
                                                <TextField  
                                                    label={'Name' }                                                            
                                                    value = {contactName}
                                                    name ={`contactName`}
                                                    size='small' // style={{fontFamily:"cursive", }}         // helperText={//     contactName===''?//         <text style={{fontSize:10, fontWeight:400 }}>Enter Contact Name</text>//     ://         regExpr.notEmpty.test(contactName)?//             <text style={{fontSize:10, fontWeight:400 }}>Not Valid</text>//://<text style={{fontSize:10, fontWeight:400,}}>Valid</text>// }
                                                    error={!regExpr.notEmpty.test(contactName)}//!isValidField(contactName, regExpr.notEmpty, 'NAME_ADD')}
                                                    onChange={(e)=>{
                                                        setContactName(e.target.value) //isValidField(e.target.value, "")
                                                        handleContactChange(e)
                                                    }}
                                                />
                                            </Stack>

                                            <Stack className='fields'>                               {/* {console.log(contactNumber.search(regExpr.mobileNumber)) }                          {console.log(regExpr.mobileNumber.test(contactNumber)) } */}
                                                
                                                <TextField             
                                                    label={'Mobile Number '} /// label={!contactNumber.search(regExpr.mobileNumber) ? 'Mobile Number ' : 'Enter Mobile Number'}
                                                    value={contactNumber}
                                                    name ={"contactPhoneNumber" }                                            
                                                    size='small' //style={{fontFamily:"cursive",}} //helperText = {contactNumber ===''? "Enter Mobile number": !isValidField(contactNumber, mobileNumber) ? "Number is invalid":''}                                    error = {!isValidField(contactNumber, regExpr.mobileNumber)}  ///{!VALID_contactNumber.current? true:false}   
                                                    // helperText={ //     contactNumber===''?                                                     //         <text style={{fontSize:10, fontWeight:400,}}>Enter Mobile Number</text>                                                    //     :                                                     //         contactNumber.search(regExpr.mobileNumber) ?                                                     //             <text style={{fontSize:10, fontWeight:400 }}>Number is Invalid</text>                                                     //         :                                                    //             <text style={{fontSize:10, fontWeight:400}}>Valid</text>                                                       // } 
                                                    error = {contactNumber.search(regExpr.mobileNumber)}  /// !isValidField(contactNumber, regExpr.mobileNumber, "MOBILENUMBER_ADD")} 
                                                    onChange={(e)=>{
                                                        setContactNumber(e.target.value)    ///VALID_contactNumber.current = validateField(e.target.value, phonenum)
                                                        handleContactChange(e)
                                                    } }
                                                /> 
                                            </Stack>

                                            <Stack className='fields'> 
                                                <TextField  
                                                    label={ 'Email' } 
                                                    size='small'
                                                    value={contacEmail}                                        // style={{height:'5ch'}}
                                                    sx={{zIndex:0}}
                                                    name = "contactEmail" //helperText={contacEmail ===''?"Enter email": !isValidField(contacEmail, mailformat) ? "Email is invalid":''}  error = {!isValidField(contacEmail, mailformat)}   ///{!VALID_contacEmail.current? true:false}  
                                                    // helperText={//     contacEmail===''?//<text style={{fontSize:10}}>Enter Email Please </text>                                                    //     : contacEmail.search(regExpr.eMail) ?                                                     //         <text style={{fontSize:10}}>Email is Invalid</text>                                                     //         :                                                    //             <text style={{fontSize:10}}>Valid</text>                                                    // }                                 
                                                    error = {contacEmail.search(regExpr.eMail) }///!isValidField(contacEmail, regExpr.mailformat, 'EMAIL_ADD')}
                                                    onChange={(e)=>{
                                                        setContactEmail(e.target.value)                              ///VALID_contacEmail.current = validateField(e.target.value, mailformat)
                                                        handleContactChange(e)
                                                    }}        
                                                />  

                                            </Stack>

                                    </Stack>

                                    <Stack gap={3} sx={{p:'1ch'}} justifyContent='center' direction="row" >
                                        <Button 
                                            onClick= {()=>{
                                                handleUpdateContact(c.contactId)
                                                handleNewTabClick()
                                            }}                                                                                 
                                            variant="outlined"   
                                            disabled={!!(contactNumber.search(regExpr.mobileNumber) || !regExpr.notEmpty.test(contactName) || contacEmail.search(regExpr.eMail))}
                                        > 
                                            <EditIcon sx={{pr:.5, }} /> 
                                            <Typography style={{fontWeight:600, fontSize:'clamp(0.6rem, .75vw, 1.1rem)' }}>Update Contact</Typography>
                                        </Button>
                                
                                        <Button 
                                            onClick= {()=>handleDeleteContact(c.contactId)}       //variant="extended"  //size="small" 
                                            color="warning" 
                                            variant="outlined" 
                                        > 
                                            <DeleteIcon sx={{pr:.5, }}  />
                                            <Typography style={{fontWeight:600, fontSize:'clamp(0.6rem, .75vw, 1.1rem)' }}>Delete Contact</Typography>                                      
                                        </Button>

                                    </Stack>

                                </Stack >                    
                            </TabItem> 
                        ))                        
                    }

                    <TabItem 
                        title={<Typography color="red" fontSize={{xs:10, sm:12, md:14}} fontWeight={contactsNumbers === value ? 600 : 400} >New Contact</Typography>} 
                        backgroundColor = {'lavenderblush'}   //contactsNumbers === value ?'lavenderblush':'aliceblue' }
                        border = { parseInt(contactsNumbers) === value ? '2px solid red' : '2px solid pink' }                    
                        borderRadius = '5px'                    //height={17}                    //border='1px solid white'
                        onClick={()=>handleNewTabClick()}                   
                    >                                                                                                         {/* <Card  //variation="elevated" backgroundColor='antiquewhite'//'#fff7f7'boxShadow="1px 1px 2px 2px var(--amplify-colors-red-20)"> {/* </Card> */} 
                        <Stack 
                            direction="column" 
                            display='flex'
                            gap={1} 
                            boxShadow="0 0 2px 2px var(--amplify-colors-red-20)" 
                            backgroundColor='antiquewhite'
                        >                            
                            <Stack
                                direction="column"  //display='flex'//justifyContent='flex-start'//alignItems='center'
                                gap={2} 
                                padding = '20px 25px 0px 25px'
                            >   
                                              
                                <Stack className='fields'  >
                                    <TextField  
                                        label={<Typography fontSize={{xs:11, sm:12, md:14}}>Name</Typography> }
                                        value = {contactName} 
                                        name ="contactName"
                                        size='small'
                                        autoComplete='off'    // sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, zIndex:0}}
                                        // helperText={
                                        //     contactName==='' ? 
                                        //         <text style={{fontSize:10, fontWeight:400, }}>Please Enter Contact Name</text>
                                        //     :
                                        //         contactName.search(regExpr.notEmpty) ? 
                                        //             <text style={{fontSize:10, fontWeight:400, }}>Not Valid</text> 
                                        //         : 
                                        //             <text style={{fontSize:10, fontWeight:400, }}>Valid</text>
                                        // }
                                        error={contactName.search(regExpr.notEmpty)}// !isValidField(contactName, regExpr.notEmpty, 'NAME_ADD')}
                                        onChange={(e)=>{
                                            setContactName(e.target.value)                                            // isValidField(e.target.value, regExpr.notEmpty, 'contactName')
                                            handleContactChange(e,regExpr.notEmpty)
                                        }}
                                    />

                                </Stack>

                                <Stack className='fields' >                                               {/*{console.log(contactNumber.search(regExpr.mobileNumber)) }                         {console.log(!!regExpr.mobileNumber.test(contactNumber)) } */}

                                    <TextField                                        
                                        label={<Typography fontSize={{xs:11, sm:12, md:14}}>Mobile Number </Typography>  }
                                        value={contactNumber}
                                        name ="contactPhoneNumber" 
                                        size='small'            //disabled={!isValidField(contactNumber, regExpr.mobileNumber)}                                        //style={{ height:'5ch'}} //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, zIndex:0}} // helperText={contactNumber ===''?"Please enter phone number": !VALID_contactNumber.current?"Phone number is invalid":''}
                                        // helperText={contactNumber===''? <text style={{fontSize:10, fontWeight:400,}}>Enter Mobile Number</text> : contactNumber.search(regExpr.mobileNumber) ?  <text style={{fontSize:10, fontWeight:400 }}>Number is Invalid</text> 
                                        //         :    <text style={{fontSize:10, fontWeight:400}}>Valid</text>}  
                                        error = {contactNumber.search(regExpr.mobileNumber)} ///!isValidField(contactNumber, regExpr.mobileNumber, "MOBILENUMBER_ADD")} 
                                        onChange={(e)=>{
                                            setContactNumber(e.target.value) // VALID_MobNumber.current = isValidField(contactNumber, regExpr.mobileNumber, "MOBILENUMBER_ADD")///isValidField(contactNumber, regExpr.mobileNumber ,"contactPhoneNumber")
                                            handleContactChange(e, regExpr.mobileNumber)
                                        } }
                                    /> 
                                </Stack>
                                                                
                                <Stack className='fields' > 
                                    <TextField  
                                        label={<Typography fontSize={{xs:11, sm:12, md:14}}>Email</Typography>} 
                                        size='small'
                                        value={contacEmail}                                    
                                        sx={{ zIndex:0}}                                        
                                        name = "contactEmail"                                                            
                                        // helperText={
                                        //     contacEmail==='' ? 
                                        //         <text style={{fontSize:10, fontWeight:400}}>Please Enter Email</text>
                                        //     :
                                        //         contacEmail.search(regExpr.eMail) ? 
                                        //             <text style={{fontSize:10,fontWeight:400}}>Email is Invalid</text> 
                                        //         :
                                        //             <text style={{fontSize:10, fontWeight:400}}>Valid</text>                                                                                                                          
                                        // }    
                                        error = {contacEmail.search(regExpr.eMail)}///!isValidField(contacEmail, regExpr.mailformat, 'EMAIL_ADD')}
                                        onChange={(e)=>{
                                            setContactEmail(e.target.value)                                         //VALID_contacEmail.current = isValidField(e.target.value, mailformat)// {contacEmail !=="" && !VALID_contacEmail.current? true:false}  
                                            handleContactChange(e, regExpr.eMail)
                                        }}        
                                    />  
                                    
                                
                                </Stack>

                            </Stack>                

                            <Stack 
                                sx={{p:'1ch'}} 
                                justifyContent='center' 
                                direction="row"
                            >    
                                <Button 
                                    onClick= { handleAddContact}                                         //sx={{ fontSize: 'clamp(0.55rem, .8vw, 1rem)', }}   
                                    variant="outlined"   
                                    disabled={!!(contactNumber.search(regExpr.mobileNumber) || contactName.search(regExpr.notEmpty) || contacEmail.search(regExpr.eMail) )}
                                >  
                                    <AddIcon sx={{pr:.5, }} /> 
                                    <Typography style={{fontWeight:600, fontSize:'clamp(0.6rem, .75vw, 1.1rem)' }}>Add Contact</Typography> 
                                </Button>
                            </Stack> 
                              
                        </Stack>

                    </TabItem>

                </Tabs>  
            </Stack>   
         );
    };


    return (
        

        <>
            {/* {console.log(!contactsData.length)            } */}
            {console.log(contactsData.length !== undefined)            }
            {contactsData.length !== undefined && AllContats() }  
        </>
    )

}
export default NewContact




    // // const validateField = (inputText, flag)=>{ 

    // //     flag===''? VALID.current = true: inputText.match(flag)? VALID.current = true: VALID.current = false
    // //     return  VALID.current 

    // // }

    // const isValidField = (inputText, flag, fieldName)=>{ 
    //     // console.log(fieldName)
    //     // console.log("SEARCH ", inputText.search(flag)) /// if found ret 0 not found ret -1
    //     // console.log('MATCH ',  inputText.match(flag))  // if found ret array [0433333] not found ret null


    //     // if (inputText.search(flag) === 0 ){
    //     //     switch (fieldName) {
    //     //         case 'NAME_ADD': 
    //     //             VALID_Name.current=true; 
    //     //             return true;    

    //     //         case "MOBILENUMBER_ADD": 
    //     //             console.log(inputText, flag)                
    //     //             VALID_MobNumber.current=true;
    //     //             return true;

    //     //         case 'EMAIL_ADD':
    //     //             VALID_Email.current = true;  
    //     //             return true;
    //     //     }    

    //     // }else{
    //     //     switch (fieldName) {
    //     //         case 'NAME_ADD':  
    //     //             VALID_Name.current=false; 
    //     //             return false;

    //     //         case "MOBILENUMBER_ADD":  
    //     //             VALID_MobNumber.current=false; 
    //     //             return false;

    //     //         case 'EMAIL_ADD': 
    //     //             VALID_Email.current = false;  
    //     //             return false;
    //     //     } 

            
    //     // }

    //     // flag===''? setIsFieldValid(false): inputText.match(flag)? setIsFieldValid(true): setIsFieldValid(false)
    //     // return flag===''? true: inputText.match(flag)? true:  false
    //     //return response
    // }

    // // const isValid = (fVal, fieldName, rExp )=>{

    // //      console.log("fieldValue : " + fVal , ", fieldName : " + fieldName,  ", regExp :   "+rExp)
    // //     // console.log(!!rExp.test(fVal))   
    // //     // console.log(VALID.current.mobNumber)
            

        
    // //     switch (fieldName) {
    // //         case 'NAME_ADD': //"MOBILENUMBER_ADD": 'EMAIL_ADD':
    // //             VALID_Name.current= !fVal.search(rExp); 
    // //             VALID.current.name= !fVal.search(rExp);
    // //             break;    

    // //         case "MOBILENUMBER_ADD": 
            
    // //             VALID_MobNumber.current = !fVal.search(rExp);
    // //             VALID.current.mobNumber= !fVal.search(rExp); 
    // //             break;

    // //         case 'EMAIL_ADD':
    // //             VALID_Email.current = !fVal.search(rExp);  
    // //             VALID.current.email= !fVal.search(rExp); 
    // //             break;
    // //     } 
        
    // //     console.log( 'NAME : ' + VALID_Name.current, 'MOB NUMBER : '+  VALID_MobNumber.current, 'EMAIL :  '+ VALID_Email.current)
    // //     console.log( 'NAME : ' + VALID.current.name, 'MOB NUMBER : '+  VALID.current.mobNumber, 'EMAIL :  '+ VALID.current.email)

    // // }

//backgroundColor={'var(--amplify-colors-neutral-20)'}            //border="1px solid red"            // boxShadow=" 1px 11px 1px gray"            // borderRadius={2}            //height={210}             // width={'85ch'}            // textAlign="center"