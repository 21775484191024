import React, { useState, useRef, useEffect } from "react";
import {
  Stack,
  Typography,
  Button,
  TextField,
  Switch,
  MenuItem,
  ///DialogContentText,
  FormControlLabel,
  
} from "@mui/material"; // Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Autocomplete
import "./newitem.scss";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import NewContact from "./NewContact.jsx"; //import { Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react';
import styled from "styled-components";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  PhoneNumberField,
  // useAuthenticator,
  // ThemeProvider,
} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import {
  mailformat,
  // companyABN,
  // phonenum,
  BSBformat,
  Accountformat,
  //notEmpty,
//  regExp,
  mobileNumber
} from "../Regex.js";
import { DialogueResponce } from "../../components/DialogueAlert.jsx";
import { SearchAddress, Log, fl, fs } from "../../util/Functions.jsx";
import { red } from "@mui/material/colors";


const Container = styled.div`
  //background: ${(props) => props.bgc};
  //color: ${(props) => (props.primary ? "black" : "transparent")};
  margin-top: 0ch;
  padding: 1ch 4ch;
  ///padding-top:5ch;
  //border: 3px solid ${(props) => props.bc} ;
  border-radius: 5px;
  // display: flex;
  // flex-direction: column;
  height: ${(props) => props.h};
  width: ${(props) => props.w};
  //box-shadow:0px 0px 3px 2px ${(props) => props.bc};
`;

//const fSize= fs()// (color)=>{   return {fontSize:'clamp(0.65rem, .6vw, 1rem)', color:color}}

const NewCustomer = ({ action }) => {
  ///const { user } = useAuthenticator((context) => [ context.user]);  //const [customerName, setCustomerName] = useState('');     //const [clientID, setClientID] = useState('');    //const [clientName, setClientName] = useState([{name:'', id:null,}]);
  const [selectedClientName, setSelectedClientName] = useState("");
  const [clients, setClients] = useState([]); //const [customerLogo, setCustomerLogo] = useState('');    //const [customerEmail, setCustomerEmail] = useState('');
  const [active, setActive] = useState(1);
  const [isCorporate, setIsCorporate] = useState(false);
  const [inputs, setInputs] = useState({});
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState(false);
  const [customerAddress, setCustomerAddress] = useState("");
  const [clientSuburb, setClientSuburb] = useState("");
  const [customerState, setCustomerState] = useState("");
  const [clientPostCode, setClientPostCode] = useState("");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [phoneNum, setPhoneNum] = useState('');  //0455230620
  const [dialCode, setDialCode]= useState('');  
  const [base64IMG,setBase64IMG]=useState('')  //const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

  const windowWidth = useRef("");
  const lat = useRef(0);
  const lng = useRef(0);

  const inputRef = useRef(''); //    
  const dialCodeRef = useRef();

  const VALID_Name = useRef(false);
  const VALID_email = useRef(false);  // const VALID_client = useRef(false);  // const VALID_businessName = useRef(false);  // const VALID_registeredBisinessName = useRef(false);  // const VALID_abn = useRef(false);  // const VALID_PhoneNumber = useRef(false); //"clientPhoneNumber"
  const VALID_BSB = useRef(false);
  const VALID_AccountNumber = useRef(false);

  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  ////////////////
  const RETAILCLIENTNAME = "Retail Customers"; // get from the DB
  const RETAILCLIENTID = 65; // get from the DB find the better solution for LATER
  //////////////

  const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
  const localHost =    process.env.REACT_APP_API_HTTP +    process.env.REACT_APP_API_LOCAL_DOMAIN +    process.env.REACT_APP_API_LOCAL_PORT;
  const DOMAIN = `${process.env.NODE_ENV === "production" ? productionHost : localHost  }`;
  const DEBUG = process.env.REACT_APP_DEBUG; ///&& console.log(DOMAIN) /////////////  //const loading = true;


  //const newLine=()=> {return <br />}; //{newLine}
  
  // const theme = {
  //   name: 'table-theme',
  //   tokens: {
  //     components: {
  //       table: {
  //         row: {
  //           hover: {
  //             backgroundColor: { value: '{colors.blue.20}' },
  //           },

  //           striped: {
  //             backgroundColor: { value: '{colors.blue.10}' },
  //           },
  //         },

  //         header: {
  //           color: { value: '{colors.blue.80}' },
  //           fontSize: { value: '{fontSizes.xl}' },
  //         },

  //         data: {
  //           fontWeight: { value: '{fontWeights.semibold}' },
  //         },
  //       },
  //     },
  //   },
  // };

  const getClientList = async () => {
    const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_CLIENTS_LIST}`);
    const responseData = await response.json(); //setClientID(responseData.map(d=>d.clientId))   //setClientName(responseData.map(d=>d.clientName))    //setClientName(values=>({...values, ['name']: responseData.map(d=>d.clientName), ['id']: responseData.map(d=>d.clientId)}))
    setClients(responseData);    
    // setClients([    //   "Select Client ...",     //   ...responseData.map((c) => c.clientId !== RETAILCLIENTID && c.clientName   ),    // ])
  };

  function getWindowSize() {
    const { innerWidth, innerHeight, outerWidth } = window; // let zoom = (outerWidth / innerWidth).toFixed(2) // DEBUG && console.log('zoom: ', parseFloat(zoom)) // DEBUG && console.log(outerWidth, innerWidth)
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    const controller = new AbortController();  
    getClientList();
    if (action === "update") {
      getCustomerData(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}${id}`);
    } else {
      setInputs((values) => ({ ...values, 
        ["customerName"]: "", 
         ["customerEmail"]: "",
         ["customerLogo"]: "",
         ["corporate"]: false,
         ["clientId"]: parseInt(RETAILCLIENTID)
      }));     
        
    }

    return () => { controller.abort() };
  }, []);

  useEffect(() => {
    function handleWindowResize() {setWindowSize(getWindowSize())}

    window.addEventListener("resize", handleWindowResize);
    return () => { window.removeEventListener("resize", handleWindowResize) };
  }, []);

  // useEffect(() => {
  //   windowWidth.current = (windowSize.innerWidth /(windowSize.innerWidth / 615)).toString();
  // }, [windowSize]);

  useEffect(() => {
    if (dialogValue) {deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}delete/${id}/d`)} /// `http://localhost:3002/api/${type}/delete/${id}`      //navigate(-1);    
    setDialogValue(false);
    setOpen(false);
  }, [dialogValue]);

  useEffect(() => {
    setInputs((values) => ({
       ...values, 
       ["customerAddress"]: customerAddress, 
       ["lat"]: lat.current,
       ["lng"]: lng.current,
    }));

  }, [customerAddress]);

  // const handleSubmit = (e) => {  //     e.preventDefault();  //     if (dialogValue.toLowerCase() === 'yes'){  deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}delete/${id}/d`) /// `http://localhost:3002/api/${type}/delete/${id}`
  //         navigate(-1)  //     }  //     handleCloseDialog();  // };  // const IconArrowDropDown = () => {  //   return <Icon pathData="M7 10L12 15L17 10H7Z" />;  // };

  const openDialog = () => { setTimeout(() => {setOpen(true)})  };

  const handleOnDialCodeChange = (e)=> {
    //setPhoneNum('');        // setDialCode(e.target.value )
   e.preventDefault();         //setPhoneNum('');         //dialCodeRef.current= e.target.value
   setDialCode(dialCodeRef.current.value);//   console.log(e.target.value)   console.log(dialCodeRef.current.value)   console.log(`${dialCodeRef.current.value} ${phoneNum}` )     //setPhoneNum(`(${dialCodeRef.current.value}) ${phoneNum}`)
   setInputs(values=>({...values, ['customerPhone']: `${dialCodeRef.current.value}${phoneNum}`}))   
  };

  const handleChangePhone=(e) =>{ 
    // console.log(dialCodeRef.current.value, phoneNum)    //setPhoneNum(e.target.value.trim().replaceAll(' ','')); //setInputs(values=>({...values, ['phoneNumber']: `${dialCodeRef.current.value} ${e.target.value}`}))
    // console.log(dialCode)         
    // console.log(dialCodeRef.current.value )     //dialCodeRef.current.value="+10"
    if(!dialCode )setDialCode(()=>dialCodeRef.current.value)
    
    setInputs(values=>({...values, ['customerPhone']: `${dialCode}${e.target.value}`}))     // console.log(`(${dialCode}) ${e.target.value}`)       // console.log(dialCodeRef.current )          //(e.target.value.replaceAll(' ',''))    // console.log(inputRef.current.value)
    setPhoneNum(e.target.value)      //dialCodeRef.current.value phoneNum    
  };

  //getting data for update
  const getCustomerData = async (Url) => {
    const response = await fetch(Url);
    if (response.status !== 400) {
      const responseCustomersData = await response.json();      //      Log(fl(188, 'newCust.jsx'),  response.statusText);
      const customer = responseCustomersData[0];                //console.log(customer);      //Log(fl(191,'NewCustomer.jsx'), customer)
      
      setPhoneNum( !customer.customerPhone ? "" : customer.customerPhone.slice(3,13) ); 
      setDialCode( !customer.customerPhone  ? "" : customer.customerPhone.slice(0,3));  
      dialCodeRef.current.value= !customer.customerPhone  ? "" : customer.customerPhone.slice(0,3)

      const keys = Object.keys(customer);
      keys.forEach(key => { setInputs((values) => ({ ...values, [key]: customer[key] }))});

      setSelectedClientName(customer.clientName);
      setActive(customer.active);
      setIsCorporate(customer.corporate == 1 ? true : false);
      setCustomerAddress(customer.customerAddress);
      setBase64IMG(customer.customerLogo)

      VALID_BSB.current = validateField(customer.customerBSB, BSBformat);
      VALID_AccountNumber.current = validateField(customer.customerAccountNumber,Accountformat);
      VALID_Name.current = customer.customerName === "" ? true : false;

      keys.forEach((k) => {
        if (k == "customerEmail") {
          if (customer[k].length > 0) {VALID_email.current = true;
          } else {VALID_email.current = false;
          }
        }
      });
    } else { console.log( "Error has occured while processing your request " + response.statusText )  }
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleAdd = () => {
    //DEBUG && console.log(inputs); // getClientList()
    postNewUsertData(DOMAIN + process.env.REACT_APP_API_ADD_NEW_CUSTOMER, inputs);
    navigate(-1);
  };

  const handleUpdate = () => {            //log(252, inputs, `${DOMAIN}${process.env.REACT_APP_API_UPDATE_CUSTOMER_DATA}${id}`);    
    Log(fl(263,'Cust'), inputs)
    putCusomerData( `${DOMAIN}${process.env.REACT_APP_API_UPDATE_CUSTOMER_DATA}${id}`, inputs);
    navigate(-1);
  };

  const putCusomerData = async (Url, BodyInput) => {     //console.log(BodyInput) /// text/plain; */*; charset=utf-8
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify({ BodyInput }),
      headers: { "Content-Type": "application/json; charset=utf-8" },
    };

    Log(fl(275, 'newCust.jsx'), requestOptions, Url);
    const response = await fetch(Url, requestOptions);

    // if (response.status == 200) {      const responseData = await response.json()    }
  };

  const postNewUsertData = async (Url, BodyInput) => {  //DOMAIN + process.env.REACT_APP_API_ADD_NEW_CUSTOMER, inputs //console.log(BodyInput) /// text/plain; */*; charset=utf-8
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({ BodyInput }),
    };
    const response = await fetch(Url, requestOptions);
    const responseData = await response.json();
    //DEBUG && console.log(responseData);
  };

  const validateField = (textToTest, regExp) => {    return regExp === "" ? true : textToTest?.match(regExp) ? true : false;  };

  // const handleChange=(e, validated)=>{
  //     const names= e.target.name
  //     const value = e.target.value
  //     setInputs(values=>({...values, [names]: value, isValidated: validated,}));  //value, valid:
  // }

  async function deleteData(a) {
    DEBUG && console.log(a);
    const response = await fetch(a, { method: "DELETE" });
    const responseData = await response.json();
    navigate(-1)

  }

  const convertToBase64 = (e) => {
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = () => {
      console.log('called: ', reader)
      setBase64IMG(reader.result)
      setInputs((values) => ({ ...values, ["customerLogo"]: reader.result }));
    }
  }

  return (
    <>  
      <Stack  sx={{p:2, width: {xs:'45ch', sm:'67ch', md:'90ch'} }}>

            <Table
              caption={
                <Stack 
                  direction="row" 
                  sx={{fontFamily:'Arial', fontWeight:'600', border: "1px solid steelblue", paddingY: 1, backgroundColor: "lightsteelblue",}} 
                  justifyContent="center" 
                  gap={2} 
                >
                  {action == "update" ? `Updating Customer:  ${inputs.customerName} `: `Adding Customer: ${inputs.customerName} `} 
                </Stack> 
              }
              size="small"              //highlightOnHover              //variation="striped"
              //border='2px solid lightgray'            
              backgroundColor={"antiquewhite"}
            > 
              <TableBody >  

                <TableRow> 

                  <TableCell border='1px solid lightgray'>
                    <Typography fontWeight={600} fontSize={fs(.75, .8, 1.1)}>Type</Typography>
                  </TableCell> 

                  <TableCell border='1px solid lightgray'>
                      <Stack sx={{height:'4ch', width: {xs:'30ch', sm:'45ch', md:'70ch'},  }} >

                        <FormControlLabel 
                          control={
                            <Switch
                              color="secondary"                          //size="small"
                              id='switchBox'
                              checked={isCorporate} //{inputs.isCorporate}
                              onChange={(e) => {
                                setIsCorporate(e.target.checked);
                                setInputs((values) => ({...values, ["corporate"]: e.target.checked, }));
                                !e.target.checked && setInputs((values) => ({...values, ["clientId"]: parseInt(RETAILCLIENTID),}));
                              }}
                            /> 
                          } 
                          label={<Typography fontWeight={600} fontSize={fs(.75, .8, 1.1)}>
                                    {isCorporate ? "Corporate Customer" : "Retail Customer"}
                                  </Typography>
                                } 
                        />
                     </Stack>
    
                  </TableCell>

                </TableRow> 
              
                <TableRow>  
                  <TableCell border='1px solid lightgray'>
                    <Typography fontWeight={600} fontSize={fs(.75, .8, 1.1)}>Name</Typography>
                  </TableCell> 
                                 
                  <TableCell border='1px solid lightgray'>    
                      <Stack >                                       
                        <TextField 
                          style={{fontSize:10}}                                                                      
                          value={inputs.customerName} //&#x20AC;                             //variant='filled'                                    //defaultValue={customerName}
                          required
                          autoFocus
                          //  multiline
                          size="small"
                          name="customerName"
                          fullWidth
                          error={VALID_Name.current}
                          helperText={ VALID_Name.current && ( 
                            <Typography style={{fontSize:10}}> Please enter Customer Name &uarr; </Typography>
                          ) }
                          onChange={(e) => {
                            VALID_Name.current = e.target.value === "" ? true : false;
                            setInputs((values) => ({...values,[e.target.name]: e.target.value,}));
                          }}
                        />   
                      </Stack>                                                                
                    
                  </TableCell>
                </TableRow>
                
                {!isCorporate &&
                  <>                
                     <TableRow>  
                          <TableCell border='1px solid lightgray'>
                            <Typography fontWeight={600} fontSize={fs(.75, .8, 1.1)}>BSB</Typography>
                          </TableCell> 
                                  
                          <TableCell border='1px solid lightgray'>
                            <TextField
                              size="small"                              
                              value={inputs.customerBSB}
                              name="customerBSB"
                              fullWidth
                              error={!VALID_BSB.current}
                              helperText={VALID_BSB.current ? ("") : inputs.clientBSB === "" ? ("") : 
                                (<Typography fontSize={10}>BSB is INVALID</Typography>)
                              }                    
                              onChange={(e) => {
                                VALID_BSB.current = validateField(e.target.value,BSBformat);
                                setInputs((values) => ({...values, [e.target.name]: e.target.value}));
                              }}
                            />
                          </TableCell>

                      </TableRow>
                    
                      <TableRow>  
                        <TableCell border='1px solid lightgray'>
                          <Typography fontWeight={600} fontSize={fs(.75, .8, 1.1)}>Account Number</Typography>
                        </TableCell> 

                        <TableCell border='1px solid lightgray'>
                            <TextField                            
                              size="small"
                              fullWidth                              
                              value={inputs.customerAccountNumber} // setContactAccountNumber(e.target.value)
                              name="customerAccountNumber"                          
                              error={!VALID_AccountNumber.current}
                              helperText={VALID_AccountNumber.current ? ("") : inputs.customerAccountNumber === "" ? ("") : 
                                (<Typography fontSize={10}>Account Number is INVALID</Typography>)
                              }
                              onChange={(e) => {
                                VALID_AccountNumber.current = validateField(e.target.value,Accountformat);                            // inputs.corporate === 1 ?//   setInputs(values=>({...values, ['clientAccountNumber']: e.target.value}))//   :
                                setInputs((values) => ({...values,[e.target.name]: e.target.value,}));
                              }}
                            />
                        </TableCell>    
                      </TableRow>

                      <TableRow>  
                        <TableCell border='1px solid lightgray'>
                          <Typography fontWeight={600} fontSize={fs(.75, .8, 1.1)}>Address</Typography>
                        </TableCell> 

                        <TableCell border='1px solid lightgray'>   
                          <Stack  sx={{height:'4ch', width: {xs:'30ch', sm:'45ch', md:'70ch'},  }}   >                  
                              <SearchAddress
                                destination={customerAddress}
                                setLat={lat}
                                setLng={lng}
                                setDestination={setCustomerAddress}
                                setSiteState={setCustomerState}
                                setSitePostCode={setClientPostCode}
                                setSiteSuburb={setClientSuburb}
                                //size={windowSize.innerWidth < 600 ? 11 : 15}
                              /> 
                          </Stack>           
                        </TableCell>

                      </TableRow>

                      <TableRow>  
                        <TableCell border='1px solid lightgray'>
                          <Typography fontWeight={600} fontSize={fs(.75, .8, 1.1)}>Mobile</Typography>
                        </TableCell> 

                        <TableCell border='1px solid lightgray'>                       

                          <PhoneNumberField
                              defaultDialCode={'+61'}
                              dialCodeList={['+61', '+55', '+44', '+64']}
                              ref={inputRef}
                              dialCodeRef={dialCodeRef}                                                //borderwidth={tokens.borderWidths.large}
                              value={phoneNum} //`${phoneNum === '' ?'' :phoneNum}`}      //placeholder="Phone number" //border={`1px solid ${tokens.colors.green[60]}`}
                              labelHidden //dialCodeLabel="Dial code" //phonenumberfield ="1111" ///width={'42.8ch'} //backgroundColor={'azure'}
                              type='tel'
                              hasError={phoneNum.trim() !== ''  && dialCode !==''  ? phoneNum.trim().match(mobileNumber) !== null? false : true : true}
                              errorMessage= {<Typography fontSize={11} >Not a valid number! 😱</Typography>}
                              onChange={handleChangePhone}    ///{e=>setPhoneNum(e.target.value)}                     //onBlur={onBlur}             //onCountryCodeChange={handleOnCountryCodeChange}
                              onDialCodeChange={handleOnDialCodeChange}
                          />
                      
                        </TableCell>   

                      </TableRow>
    
                  </>
                }

                {isCorporate &&
                  <TableRow>  
                    <TableCell border='1px solid lightgray'>
                      <Typography fontWeight={600} fontSize={fs(.75, .8, 1.1)}>Clients</Typography>
                    </TableCell> 

                    <TableCell border='1px solid lightgray'>
                        <TextField
                          size="small"
                          fullWidth
                          select
                          id='selectId'
                          name="selectName"
                          value={selectedClientName}
                          onChange={(e) => {
                            setSelectedClientName( e.target.value == "Select Client ..." ? "" : e.target.value );
                            clients.forEach((c) => { e.target.value === c.clientName && setInputs((values) => ({...values, ["clientId"]: parseInt(c.clientId), }))        })
                          }}
                        
                        >
                          {clients.map((c, i) => (<MenuItem key={i} value={c.clientName}>{c.clientName}</MenuItem>))}
                        </TextField>
                    </TableCell>

                  </TableRow>
                }

                {isCorporate &&                
                  <TableRow>  
                    <TableCell border='1px solid lightgray'>
                      <Typography fontWeight={600} fontSize={fs(.75, .8, 1.1)}>Logo</Typography>
                    </TableCell> 

                    <TableCell border='1px solid lightgray'>
                            <Stack direction='row'>
                              <Stack width='18ch'>
                                  <input
                                    className="logoFileIput"
                                    type='file'
                                    accept='.jpg, .gif, .png'
                                    id='image'                              
                                    onChange={convertToBase64}
                                    
                                  />
                                </Stack>
                              <img width='40px' height='40px' src={base64IMG} />
                            </Stack>
                        
                      </TableCell>
                  </TableRow>
                }

                  <TableRow>  
                    <TableCell border='1px solid lightgray'>
                      <Typography fontWeight={600} fontSize={fs(.75, .8, 1.1)}>Email</Typography>
                    </TableCell> 

                    <TableCell border='1px solid lightgray'>             
                      <TextField
                        className="tabRow"
                        value={inputs.customerEmail}
                        required
                        fullWidth
                        size="small"
                        name="customerEmail"
                        error={!VALID_email.current}
                        helperText={VALID_email.current ? '' : inputs.clientEmail =='' ? '' : (<Typography fontSize={11}>Email format is INVALID &#128561;</Typography>)}
                        onChange={(e) => {
                          VALID_email.current = validateField(e.target.value,mailformat); //setCustomerEmail(e.target.value)
                          setInputs((values) =>({...values,[e.target.name]: e.target.value,}));
                        }}
                      />                  
                  </TableCell>     
                </TableRow>
            
                {/* {isCorporate &&
                  <Stack className='action' direction={{ sm: "column", md: "row" }}>
                    <Stack className='tableCellName'fontSize={fs(.6, .75, 1)}>CC UUID</Stack>
                    <Stack className='tableCellVal' fontSize={fs(.6, .75, 1)}>
                        <TextField
                          value={inputs.customerCCUUID}
                          required
                          fullWidth
                          size="small"
                          name="customerCCUUID"
                          // error={!VALID_email.current}
                          // helperText={<Typography fontSize={11} color="blue">Obtain the ID from the Carton Cloud app</Typography>}
                          onChange={(e) => {
                            //VALID_email.current  = validateField(e.target.value, mailformat) //setCustomerEmail(e.target.value)
                            setInputs((values) => ({...values,[e.target.name]: e.target.value,}));
                          }}
                        />
                    
                    </Stack>
                  </Stack>
                } */}

              </TableBody>
            </Table>
          

        <Stack sx={{ pt:1}}>
          {action == "update" && isCorporate && (<NewContact entityID={id} entitySearchField={"customerId"} />)}
        </Stack>
  

        <Stack className='stack1' direction={{ sm: "column", md: "row" }} >  {/* spacing={4} paddingTop={3} sx={{flex:'1 1 100%', justifyContent:'center', alignItems:'center', mt: action =='update' ? 12 : -35}} > */}
          {active === 1 && (
            <Button
              className="Button1 tab" //disabled={selectedClientName == ""}
              onClick={action === "update" ? handleUpdate : handleAdd}
              variant="outlined"
              sx={{fontSize:fs()}}
            >{action === "update" ? "Update " : "Add "}
            </Button>
          )}
          {action === "update" && (

            <Button
              className="Button1 tab"
              onClick={openDialog} //{active === 1? handleDelete: ReActivate}
              variant="outlined"
              sx={{fontSize:fs(), color:'red'}}

            > 
            {active === 1 ? "Deactivate" : "Re-Activate"} 
            </Button>
          )}
          <Button
            className="Button1 tab"
            onClick={handleClose}            
            variant="outlined"
            sx={{fontSize:fs()}}        //sx={{ fontSize: 11, borderRadius: "5px" }}
          >
            <Typography sx={{fontSize:fs()}}> close</Typography>
          </Button>
        </Stack>

      </Stack>

      <DialogueResponce
          isOpen={open}
          setIsOpen={setOpen}
          setValue={setDialogValue}
          title={<Stack className='rowCell' fontSize={'clamp(0.75rem, .88vw, 1.1rem)'} fontWeight={800} color={'red'}>Customer De-activation Warning:</Stack> }///`${ inputs.active !== 1 ? "Reactivation Warning" : "Deactivation Warning"}`}              
          dialogueTxt= {<Stack className='rowCell' fontSize={'clamp(0.6rem, .78vw, 1rem)'} color={'#2d187be0'}> You are about to disactivate this customer. <br/>Would You like to proceed with de-activation?</Stack>}
      />
          {/* <br/>    &#10; &#13;  &real;     change customer from { inputs.active === 1 ? "active" : "inactive" } to { inputs.active === 1 ? "inactive" : "active" } state  {inputs.active !== 1 ? 'activation' : 'deactivation'}  */}

    </>  

    
    // </Box>
  );
};
export default NewCustomer;





        //       {/* <div 
        //                   //className='p1' 
        //                   contenteditable='plaintext-only'  
        //                   spellcheck="true"  
        //                   // onKeyUp={(e)=>{
        //                   //     console.log('key: ', e.key)
        //                   //     //console.log(e)
        //                   //     console.log(e.target.innerText)
        //                   //   }
        //                   //}
                          

        //                 >eeeedsv dfvsd ee
        //                 </div> */}


        //                 {/* <div
        //                   className="test"
        //                   contenteditable='true'
        //                   onInput={e => console.log(e.currentTarget.textContent)}
        //                 >Hello</div>

        //                 <div className='p1 donotuse special1' >bbbb</div> */}
        //                 {/* <TextField {inputs.customerLogo} 
        //                   value={inputs.customerLogo}
        //                   size="small"
        //                   fullWidth
        //                   name="customerLogo" //style={{width:'55ch', }}   //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, mr:3}}
        //                   onChange={(e) => {setInputs((values) => ({ ...values, [e.target.name]: e.target.value, }))}} //onBlur={e=>setInputs(values=>({...values, ['customerLogo']: e.target.value.trim() ===''? null : e.target.value.trim()}))}
        //                 /> */}
        // {/* </ThemeProvider> */}
        // {/* <progress className="progress" max="100"> </progress> */}
        // {/* <div className="test">jj</div>
        // <div className="success">aaa</div>
        // <div className="error">aaa</div>
        // <div className='r' >ff</div>  */}
        // {/* <div 
        //   className='p' 
        //   contenteditable='plaintext-only'  
        //   spellcheck="true"  
        //   onKeyUp={(e)=>{
        //       console.log('key: ', e.key)
        //       //console.log(e)
        //       console.log(e.target.innerText)
        //     }
        //  />  } */}
        // {/* ='plaintext-only' */}
  
      
        // {/* <input type='range' id="vol" name="vol" min="0" max="50"></input>  ee
        // <Stack direction='row'>
        //   <input
        //     type='file'
        //     accept='.jpg, .gif, .png|image/*'
        //     id='image'
        
        //     onChange={convertToBase64}
        //   />
        //   <img width='35px' height='35px' src={base64IMG} />
        // </Stack> */}


  // const ReActivate = () => {
  //   let a = prompt(`\n\nWARNING: You are about to Re-activate this Customers! \nIf You wish to proceed type 'yes' and then click OK button\n`, "NO");
  //   if (a.toLowerCase() === "yes") {
  //     DEBUG && console.log("INNNN");      //console.log(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}delete/${id}`)
  //     deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}delete/${id}/a`); /// `http://localhost:3002/api/${type}/delete/${id}`
  //     navigate(-1);
  //   }
  // };

                // {/* <TableRow>
                //   <TableCell>
                //     <Typography
                //       style={{
                //         color: "#2d187be0",
                //         fontWeight: 600,
                //         fontSize: 14,
                //       }}
                //     > GC Customer ID
                //     </Typography>
                //   </TableCell>
                //   <TableCell >
                //     <TextField
                //       fullWidth
                //       value={
                //         inputs.corporate == 1
                //           ? inputs.GC_customerUUID
                //           : inputs.GC_customercustomerUUID
                //       }
                //       size="small"
                //       name="GC_customer"
                //       disabled={true}
                //     />
                //   </TableCell>
                // </TableRow>

                // <TableRow>
                //   <TableCell >
                //     <Typography
                //       style={{
                //         color: "#2d187be0",
                //         fontWeight: 600,
                //         fontSize: 14,
                //       }}
                //     >
                //       GC Bank ID
                //     </Typography>
                //   </TableCell>
                //   <TableCell >
                //     <TextField
                //       fullWidth
                //       value={
                //         inputs.corporate == 1
                //           ? inputs.GC_bankaccountUUID
                //           : inputs.GC_customerbankaccountUUID
                //       }
                //       size="small"
                //       //name ="GC_bankaccount"
                //       disabled={true}
                //     />
                //   </TableCell>
                // </TableRow>

                // <TableRow>
                //   <TableCell >
                //     <Typography
                //       style={{
                //         color: "#2d187be0",
                //         fontWeight: 600,
                //         fontSize: 14,
                //       }}
                //     >
                //       GC Mandate ID
                //     </Typography>
                //   </TableCell>
                //   <TableCell >
                //     <TextField
                //       fullWidth
                //       value={
                //         inputs.corporate == 1
                //           ? inputs.GC_mandateUUID
                //           : inputs.GC_customermandateUUID
                //       }
                //       size="small"
                //       name="GC_mandate"
                //       disabled={true}
                //     />
                //   </TableCell>
                // </TableRow> */}


                        // {/* <TextField
                        //   value={inputs.customerPhone} //setClientPhoneNumber(e.target.value)
                        //   required
                        //   size="small"
                        //   name="customerPhone"
                        //   fullWidth
                        //   error={!VALID_PhoneNumber.current}
                        //   helperText={ VALID_PhoneNumber.current ? ( "" ) : inputs.clientPhoneNumber === "" ? ( "" ) : ( <Typography fontSize={11}> Phone Number format is INVALID</Typography> )
                        //   }
                        //   onChange={(e) => {
                        //     VALID_PhoneNumber.current = validateField(e.target.value,phonenum                            );
                        //     setInputs((values) => ({...values,[e.target.name]: e.target.value,}));
                        //   }}
                        //> */}

                      // {/* <SelectField
                      //   style={{ fontFamily: "Arial", fontSize: 16 }}
                      //   icon={<IconArrowDropDown />}
                      //   iconColor="red" //variation="quiet"     //fontSize={14} //fontWeight={600}
                      //   size="small"
                      //   value={selectedClientName == "" ? null : selectedClientName}
                      //   options={[ "Select Client ...",  ...clients.map((c) => c.clientId !== RETAILCLIENTID && c.clientName)]}                      //options={clients}
                      //   descriptiveText={selectedClientName === "" ? ( <Typography fontSize={11} color={"crimson"}>Please select Client from the list  </Typography> ) : ("" )     }
                      //   onChange={(e) => {
                      //     console.log(clients);
                      //     setSelectedClientName( e.target.value == "Select Client ..."? "": e.target.value );
                      //     clients.forEach((c) => e.target.value === c.clientName ? setInputs((values) => ({...values, ["clientId"]: parseInt(c.clientId), })) : "" ); //console.log(e.target.value);
                      //   }}
                      // ></SelectField> */}

      // {/* {console.log(`${document.documentURI}` )  } */}

      //       {/* <text >
      //         line 1{newLine()}
      //         line 2{newLine()}
      //         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&alefsym;
      //       </text>

      //       <text> H&nbsp;~&nbsp;this is a test message.</text>
      //       <text style={{ fontWeight: "bold", whitespace: "pre-wrap" }}>
      //         {newLine()}
      //           test 
      //           {newLine()}
      //           hello
      //       </text> 
      //     // <text>
      //     //     <br />
      //     //     Hi~<br />
      //     //     <br />
      //     //     this is a test message.<br />
      //     // </text> */}


//   {/* <Autocomplete
//                     name='clientList'
//                     fontSize={12}
//                     fontWeight={600}
//                     style={{fontFamily:'Arial'}}
//                     value={selectedClientName}    //{console.log(clientName)}                             //value={ selectedClientName}
//                     freeSolo={true}
//                     required
//                     options={clientName.name}
//                     autoHighlight
//                     size='small'
//                     onChange={(event, newInputValue) => {
//                         setSelectedClientName(newInputValue)
//                         clients.map(c => newInputValue === c.clientName ? setInputs(values=>( {...values, ['clientId']: parseInt(c.clientId)})) : '')
//                     } }
//                     renderInput={(params) => (
//                         <TextField {...params}
//                             helperText = { selectedClientName ===''?  <Typography fontSize={11}>Select Client from the list</Typography>:''}
//                             error ={selectedClientName === ''? true:false}
//                             inputProps = {{...params.inputProps, type: 'search', }}
//                         />
//                     )}
//                 />   */}

// {/* <Dialog open={open} onClose={handleCloseDialog}>
//     <form onSubmit={handleSubmit}>
//         <DialogTitle><Typography style={{ fontWeight:500 }}>DEACTIVATION WARNING</Typography></DialogTitle>
//         <DialogContent>
//         <DialogContentText>
//             <Typography style={{fontSize:16}}>{`You are about to Deactivate this Customer. If You wish to proceed - type 'yes' and then click OK button`}</Typography>
//         </DialogContentText>
//             <TextField
//                 autoFocus
//                 margin="dense"
//                 id="name"
//                 value={dialogValue}
//                 placeholder='No'
//                 type="text"
//                 variant="standard"
//                 onChange={(e) => setDialogValue(e.target.value) }
//             />

//         </DialogContent>
//         <DialogActions>
//             <Button onClick={handleCloseDialog}><Typography style={{fontWeight:500}}>Cancel</Typography></Button>
//             <Button type="submit"><Typography style={{fontWeight:500}}>OK</Typography></Button>
//         </DialogActions>
//     </form>
// </Dialog>  */}

// if (action ==='update' && loaded){
//     handleGetDataForUpdate()
//     setLoaded(false)
// }

// const handleDelete = ()=>{

//     //let a = prompt(`\n\nWARNING:\n\nYou are about to Deactivate this Customer\nIf You wish to proceed - type 'yes' and then click OK button\n`,'NO')
//     openDialog()

//     // if(value===null)(a="no")
//     // console.log(value)

//     // if (value.toLowerCase() === 'yes'){
//     //     //deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMER_DATA}delete/${id}/d`) /// `http://localhost:3002/api/${type}/delete/${id}`
//     //     navigate(-1)
//     // }
// }

// {/* <Menu  menuAlign="end"  size="small"
//  onOpenChange={(open)=>console.log(open)}
//  trigger={
//     <MenuButton size="small" width="50%"> Custom trigger button 🚀</MenuButton>
//   }

// >
//     <MenuItem onClick={() => alert('Download')}>     Download         </MenuItem>
//     <MenuItem onClick={() => alert('Create a Copy')}>Create a Copy    </MenuItem>
//     <MenuItem onClick={() => alert('Mark as Draft')}>Mark as Draft    </MenuItem>
//     <MenuItem isDisabled onClick={() => alert('Delete')}>  Delete     </MenuItem>
// </Menu> */}
////noValidate autoComplete="off" border={'1px solid red'}

//   {/* <Link  to={{pathname: `/customers`}}  style={{ textDecoration: "none" }}  > */}
//             {/* </Link> */}
//              {/* //<ButtonStyle primary onClick={handleClose} > Close </ButtonStyle> */}
//             {/* <Link  to={{pathname: `/customers`}} style={{ textDecoration: "none" }}  > */}

// async function testData(Url){
//     const response = await fetch(Url);
//     const responseData = await response.json()
//     console.log(responseData)
//     //console.log(responseData[0]['COUNT(userId)']) }

// async function getData(Url) {
//     const response = await fetch(Url);
//     const responseData = await response.json();
//     const data=responseData[0]
//     const keys= Object.keys(data)
//     // setBusinessName(data.clientName)
//     // setInputs(values=>({...values, ['businessName']: data.clientName, isValidated: true,}));
//     // setEmailBusness(data.clientEmail)
//     // setInputs(values=>({...values, ['businessEmail']: data.clientEmail, isValidated: true,}));
//     // setRegisteredBisinessName(data.clientRegName)
//     // setInputs(values=>({...values, ['registeredBusinessName']: data.clientRegName, isValidated: true,}));
//     // setAbn(responseData[0].abn)
//     // setInputs(values=>({...values, ['abn']: data.abn, isValidated: true,}));
//     // setContactNumberBusness(data.clientPhoneNumber)
//     // setInputs(values=>({...values, ['businessContactNumber']: data.clientPhoneNumber, isValidated: true,}));

// }

//import { styled } from '@mui/material/styles';
//import { Link } from "react-router-dom";
//import { css, cx } from '@emotion/react';
//import {ButtonStyle, Thing, Input, PasswordInput, Label, LabelText, EmailInput } from './Styles.jsx';
